import React, { Component } from "react";
import {fetchToken,fetchRoles} from '../../Util.js';
import uuid from 'react-native-uuid';
import {appInsights} from '../../common/AppInsights/AppInsights';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";
import Select from 'react-select';


export class EditApproverDropDown extends Component{

    constructor(props){
        
        super(props)       
        this.state={readOnly:true,selectedApprover:null ,approverlist:[]
        }        
    }

    handleApproverChange = async(selectedApprover) => {
      await this.setState(
        { 
            selectedApprover:(selectedApprover  == null ? [] : selectedApprover)
        },
        () => console.log(`Option selected:`, this.state.selectedApprover)
      );
      await this.props.pmContactData(selectedApprover);
    };

    

    async componentDidMount() {

        appInsights.trackTrace({ message: 'EditApproverDropDown Component Loaded.' },
                     {
                         userName: this.props.email,
                         "ComponentId" : COMPONENT_ID,
                         "ComponentName" : COMPONENT_NAME,
                         "EnvironmentName" : window.env.ENVIRONMENT,
                         "ServiceLineName" : SERVICE_LINE,
                         "ServiceName" : SERVICE_NAME,
                         "ServiceOfferingName" : SERVICE_OFFERING,
                         "Correlation Id": uuid.v1(),
                         "Component": 'EditApproverDropDown Page',
                     });
        var token = await fetchToken();
        var bearer = 'Bearer ' + token;
        var correlationId = uuid.v1();
        var apimUrl = window.env.APIMURL + "/api/outage/getl2approverlist";
        
        fetch(apimUrl,{
            method: 'GET',
            headers: {
                'authorization': bearer,
                'Accept' : 'application/json',
                'CorrelationId': correlationId,
                'Content-Type': 'application/json'
            }})
          .then(response => {
            return response.json();
          })        
          .then(data => {   
            this.setState({approverlist:data});
            })
            .catch(error => {
              var err =
              {
                "message": "Exception occured in EditApproverDropdown page while getting the L2 approver list from the keyvault",
                "ComponentId" : COMPONENT_ID,
                "ComponentName" : COMPONENT_NAME,
                "EnvironmentName" : window.env.ENVIRONMENT,
                "ServiceLineName" : SERVICE_LINE,
                "ServiceName" : SERVICE_NAME,
                "ServiceOfferingName" : SERVICE_OFFERING,
                "Component": 'Dropdown Page',
                 "userName": this.props.email,
                 //roles: this.props.role != null || '' ? this.props.role : "",
                 "exception": error.message,
                 "Correlation Id": correlationId
              }
             appInsights.trackException({exception: error,properties : err});
                
            })
        
    }

    returnValues(array)
    {
      const options = array.map(v => ({
        label: v,
        value: v
      }));
      return options;
    }
        render(){  //code change from here .
            const { selectedApprover} = this.state;
            const approvers = [...new Set(this.state.approverlist)];            
            const approverArray = this.returnValues(approvers);              
            let mapApprover;
                  
            if(selectedApprover!=null)
            {    
                mapApprover = selectedApprover;              
            } 
            else if(this.props.event.pmContact !== undefined && this.props.event.pmContact !== null)
            {
                mapApprover = this.returnValues(this.props.event.pmContact.split(","));
            }                     
            
        
 return(          
    <div>

          <div className="form-group required">
            <label htmlFor="pmcontact" class="control-label">Approver Contact</label>
              <Select required aria-label="pmContact" name="pmcontact" title="Select Approver Contact" 
              isDisabled={this.props.readOnly}
              isMulti = {false}              
              value={mapApprover||""}
              onChange={this.handleApproverChange}
              options={approverArray}
              onBlurResetsInput={false}
              isClearable={true}
              >
              </Select>
              <span style={{color: '#E22335'}}>{this.props.pmcontactError}</span>
          </div>

           
          
            </div>
        )      
    }
}
export default EditApproverDropDown;