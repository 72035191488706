import moment from 'moment';
import React from 'react';
import uuid from 'react-native-uuid';
import {fetchToken} from '../../Util.js';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID,SHIPROOM,HighTide} from "../../common/Constants/Constants";
import ReactEditDropDown from '../ServiceDropdown/EditDropDown';
import {appInsights} from '../../common/AppInsights/AppInsights';
import AlertDialog from "../../common/AlertDialog/AlertDialog";
import { Radio, RadioGroup} from 'react-radio-group';
import {ButtonType} from 'office-ui-fabric-react/lib/Button'  
import * as Datetime from 'react-datetime';
import { Button,Modal } from 'react-bootstrap';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { trackPromise } from 'react-promise-tracker'
import LoadingIndicator from '../../common/PromiseTracker/PromiseTracker';
import Switch from "react-switch";
import Select from 'react-select';
import EditPlantRegionDropDown from '../PlantRegionDropDown/EditPlantRegionDropDown';

class EditCREWHolidayEvent extends React.Component{

    constructor(props){     
      super(props);    
      this.state = {readOnly:true,isdisabled:false,clicked:'',date: null,
      enddate:null,showDialog:false,outageId : '',showDeleteDialog:false,
      updateCompleted:false,
      dateError:'', enddateError:'',
      externalcontact: 'valid',externalcontactError:'',engcontact :'valid',engcontactError :''
      ,region:'valid',country:'valid',holidayName: 'valid',holidayNameError: '',holidayType:'valid',holidayTypeError:'',regionError:'',
      countryError:'',plantName:'valid',plantError:'',rowId:'',timezone:"",istimezoneselectchange:false,localstartdate:null,localenddate:null
      }
      this.handleChange = this.handleChange.bind(this);
      this.checkErrors = this.checkErrors.bind(this);
      this.methodInvoke = this.methodInvoke.bind(this);
      this.handleOnChange = this.handleOnChange.bind(this);
      this.returnArrayValues = this.returnArrayValues.bind(this);
      this.onChange = this.onChange.bind(this);
      this.onEndChange = this.onEndChange.bind(this);
      this.flip = this.flip.bind(this);
    }  
  
    ToggleReadonly = () => {
      
      this.setState({readOnly:!this.state.readOnly});
      this.setState({isdisabled:!this.state.isdisabled});
    }
  
    flip = async() => 
    {
      this.setState({showDialog:!this.state.showDialog})
    }
  
    handleOnChange(e){
     
      this.setState({ clicked:e})
      }
  
    handleChange(e){
        this.setState({[e.target.name]: e.target.value, [`${e.target.name}Error`]:  '' })
      }
  
    onChange(date)
    { 
      this.setState({ date:date, dateError :''})
    }
  
    closeDialog() {
      this.setState( {showDialog: false});
    }
  
    closeDeleteDialog = async () => {
      this.setState( {showDeleteDialog: false,updateCompleted:false,});
      this.props.closeModalPopUpFunc(false);
    }
  
    deleteDialog = async() => {
      this.setState( {showDialog: false});
      await this.deleteConfirmation(this.state.outageId);
      this.setState({showDeleteDialog: true});
    }
  
    cancelDialog = async () => {
      this.setState( {showDialog: false } );
      this.props.closeModalPopUpFunc(true);
    }
  
    onEndChange(date)
    {
      this.setState({enddate:date,enddateError:''})
    }
  
    returnArrayValues(event)
    {
      let opts = [], opt;
      for (let i = 0, len = event.length; i < len; i++) {
        opt = event[i].value;
        opts.push(opt);
       }
      return opts.join().length > 0 ? opts.join() : event.value;
    }
    
    convertDateToUTC(date)
    {
      var now_utc =  date;
      now_utc.setHours(now_utc.getHours()+5);
      now_utc.setMinutes(now_utc.getMinutes()+30);
      return now_utc.toUTCString();
    }

    // convert PST date to UTC date .
    convertPSTDateToUTC(date)
    {
      var now_pst =  date;
      var isDST=this.checkDateForDaylightSaving(date);
      if (isDST===true)
      {
        now_pst.setHours(now_pst.getHours()+7);
      }
      else
      {
        now_pst.setHours(now_pst.getHours()+8);
      } 
      return now_pst;
    }
    // convert UTC date to CST date .
    convertUTCDateToCST(date)
    {
      var now_utc =  date;
      now_utc.setHours(now_utc.getHours()+8);
      return now_utc;
    }
    // convert UTC date to PST date . 
    convertUTCDateToPST(date)
    {
      var now_utc =  date;
      var isDST=this.checkDateForDaylightSaving(date);
      if (isDST===true)
      {
        now_utc.setHours(now_utc.getHours()-7); //during daylight saving months .
      }
      else
      {
        now_utc.setHours(now_utc.getHours()-8);
      } 
      return now_utc;
    }
    // convert CST date to UTC date .
    convertCSTDateToUTC(date)
    {
      var now_cst =  date;
      now_cst.setHours(now_cst.getHours()-8);
      return now_cst;
    }
    convertPSTDateToCST(date)
    {
      var now_pst =  date;
      var isDST=this.checkDateForDaylightSaving(date);
      if (isDST===true)
      {
         now_pst.setHours(now_pst.getHours()+7);
         now_pst.setHours(now_pst.getHours()+8);
      }
      else
      {
        now_pst.setHours(now_pst.getHours()+8);
        now_pst.setHours(now_pst.getHours()+8);
      }
      //return now_cst.toUTCString();
      return now_pst;
    }
    convertCSTDateToPST(date)
    {
      var now_cst =  date;
      var isDST=this.checkDateForDaylightSaving(date);
      if (isDST===true)
      {
      now_cst.setHours(now_cst.getHours()-8);
      now_cst.setHours(now_cst.getHours()-7);
      }
      else
      {
        now_cst.setHours(now_cst.getHours()-8);
        now_cst.setHours(now_cst.getHours()-8);
      }
      //return now_cst.toUTCString();
      return now_cst;
    }

    //calculate daylight saving start date , end date  in a year . 
  
    calculateDSTStartDate()
    {
      const days = {
        Mon: 1,
        Tue: 2,
        Wed: 3,
        Thu: 4,
        Fri: 5,
        Sat: 6,
        Sun: 0
      }
     
      var weekNumber,day,monthMoment;
      var year=new Date().getFullYear();
      var marchdate=year+'-03-01';
      var dststartdate;

      // 2nd Sunday of March . 
      weekNumber=2;
      day=days.Sun;
      monthMoment=moment(marchdate);
     
      let m = monthMoment.clone()
         .startOf('month')                     
         .day(day)
        if (m.month() !== monthMoment.month()) 
        {
          m.add(7, 'd');
        }
        //dststartdate= m.add(7 * (weekNumber - 1), 'd').format('YYYY-MM-DDTHH:mm:ss.fff Z');
        dststartdate= m.add(7 * (weekNumber - 1), 'd');
        dststartdate=moment(dststartdate).add(2, 'hours');
        return dststartdate;            
       
    }

    calculateDSTEndDate()
    {
      const days = {
        Mon: 1,
        Tue: 2,
        Wed: 3,
        Thu: 4,
        Fri: 5,
        Sat: 6,
        Sun: 0
      }
     
      var weekNumber,day,monthMoment;
      var year=new Date().getFullYear();
      var novdate=year+'-11-01';
      var dstenddate;

      // 1st Sunday of November .  
      weekNumber=1;
      day=days.Sun;
      monthMoment=moment(novdate);

      let m = monthMoment.clone()
      .startOf('month')                     
      .day(day)
       if (m.month() !== monthMoment.month()) 
      {
         m.add(7, 'd');
      }
      //dstenddate= m.add(7 * (weekNumber - 1), 'd').format('YYYY-MM-DDTHH:mm:ss.fff Z');
      dstenddate= m.add(7 * (weekNumber - 1), 'd');
      dstenddate=moment(dstenddate).add(2, 'hours');
      return dstenddate;        
       
    }
    //check daylight saving is active or not . 
    checkDateForDaylightSaving(date)
    {
       var DaylightStartDate= this.calculateDSTStartDate();
       var DaylightEndDate=this.calculateDSTEndDate();
       if(date >= DaylightStartDate && date <=DaylightEndDate)
       {
         return true;
       }
       else
       {
         return false;
       }
    }


    onTimezoneChange=async(e) =>{
      this.selectedtimezoneEventData(e.target.value);
      this.setState({ timezone:e.target.value,istimezoneselectchange:true });

      console.log(this.state.timezone );
    }

    selectedtimezoneEventData(selectedtimezone)
    {
      var prevselectedtimezone=this.props.event.Timezone;
     
       if(prevselectedtimezone==="UTC" || prevselectedtimezone===null || prevselectedtimezone==='' || prevselectedtimezone===undefined)
       {
            if(selectedtimezone==="CST")
            {                         
              if(this.state.date===null && this.state.enddate===null)
              {
                  this.props.event.start=new Date(this.convertUTCDateToCST(new Date(this.props.event.start))); 
                  this.props.event.end=new Date(this.convertUTCDateToCST(new Date(this.props.event.end))); 
              }
              else if(this.state.date!==null && this.state.enddate===null)
              {
                this.setState({date:Date.parse(new Date(this.convertUTCDateToCST(new Date(this.state.date))))});
                this.props.event.start=new Date(this.convertUTCDateToCST(new Date(this.state.date))); 
                this.props.event.end=new Date(this.convertUTCDateToCST(new Date(this.props.event.end))); 
              }
              else if(this.state.date===null && this.state.enddate!==null)
              {
                this.setState({enddate:Date.parse(new Date(this.convertUTCDateToCST(new Date(this.state.enddate)))) });
                this.props.event.start=new Date(this.convertUTCDateToCST(new Date(this.props.event.start))); 
                this.props.event.end=new Date(this.convertUTCDateToCST(new Date(this.state.enddate))); 
              }
              else if(this.state.date!==null && this.state.enddate!==null)
              {
                  this.setState({date:Date.parse(new Date(this.convertUTCDateToCST(new Date(this.state.date)))) , 
                            enddate:Date.parse(new Date(this.convertUTCDateToCST(new Date(this.state.enddate)))) });
                  this.props.event.start=new Date(this.convertUTCDateToCST(new Date(this.state.date))); 
                  this.props.event.end=new Date(this.convertUTCDateToCST(new Date(this.state.enddate))); 
              }

              this.props.event.Timezone=selectedtimezone;
            }
            else if(selectedtimezone==="PST")
            {                       
              if(this.state.date===null && this.state.enddate===null)
              {
                  this.props.event.start=new Date(this.convertUTCDateToPST(new Date(this.props.event.start))); 
                  this.props.event.end=new Date(this.convertUTCDateToPST(new Date(this.props.event.end))); 
              }
              else if(this.state.date!==null && this.state.enddate===null)
              {
                this.setState({date:Date.parse(new Date(this.convertUTCDateToPST(new Date(this.state.date))))});
                this.props.event.start=new Date(this.convertUTCDateToPST(new Date(this.state.date))); 
                this.props.event.end=new Date(this.convertUTCDateToPST(new Date(this.props.event.end))); 
              }
              else if(this.state.date===null && this.state.enddate!==null)
              {
                this.setState({enddate:Date.parse(new Date(this.convertUTCDateToPST(new Date(this.state.enddate)))) });
                this.props.event.start=new Date(this.convertUTCDateToPST(new Date(this.props.event.start))); 
                this.props.event.end=new Date(this.convertUTCDateToPST(new Date(this.state.enddate))); 
              }
              else if(this.state.date!==null && this.state.enddate!==null)
              {
                  this.setState({date:Date.parse(new Date(this.convertUTCDateToPST(new Date(this.state.date)))) , 
                            enddate:Date.parse(new Date(this.convertUTCDateToPST(new Date(this.state.enddate)))) });
                  this.props.event.start=new Date(this.convertUTCDateToPST(new Date(this.state.date))); 
                  this.props.event.end=new Date(this.convertUTCDateToPST(new Date(this.state.enddate))); 
              }

              this.props.event.Timezone=selectedtimezone;             
            }
            else if(selectedtimezone==="UTC")
            {                       
              if(this.state.date!==null)
              {
                this.setState({date:new Date(this.state.date)});
                this.props.event.start=new Date(this.state.date);                 
              }
              else if(this.state.enddate!==null)
              {
                this.setState({enddate:new Date(this.state.enddate)});
                this.props.event.end=new Date(this.state.enddate); 
              }
              this.props.event.Timezone=selectedtimezone;             
            }
       }
       else if(prevselectedtimezone==="CST")
       {
            if(selectedtimezone==="UTC")
            {                      
              if(this.state.date===null && this.state.enddate===null)
              {
                this.props.event.start=new Date(this.convertCSTDateToUTC(new Date(this.props.event.start)));
                this.props.event.end=new Date(this.convertCSTDateToUTC(new Date(this.props.event.end)));
              }
              else if(this.state.date!==null && this.state.enddate===null)
              {
                this.setState({date:Date.parse(new Date(this.convertCSTDateToUTC(new Date(this.state.date))))});
                this.props.event.start=new Date(this.convertCSTDateToUTC(new Date(this.state.date)));
                this.props.event.end=new Date(this.convertCSTDateToUTC(new Date(this.props.event.end)));
              }
              else if(this.state.date===null && this.state.enddate!==null)
              {
                this.setState({enddate:Date.parse(new Date(this.convertCSTDateToUTC(new Date(this.state.enddate)))) });
                this.props.event.start=new Date(this.convertCSTDateToUTC(new Date(this.props.event.start)));
                this.props.event.end=new Date(this.convertCSTDateToUTC(new Date(this.state.enddate)));
              }
              else if(this.state.date!==null && this.state.enddate!==null)
              {
                  this.setState({date:Date.parse(new Date(this.convertCSTDateToUTC(new Date(this.state.date)))) , 
                            enddate:Date.parse(new Date(this.convertCSTDateToUTC(new Date(this.state.enddate)))) });
                  this.props.event.start=new Date(this.convertCSTDateToUTC(new Date(this.state.date))); 
                  this.props.event.end=new Date(this.convertCSTDateToUTC(new Date(this.state.enddate))); 
              }

              this.props.event.Timezone=selectedtimezone;               
              
            }
            else if(selectedtimezone==="PST")
            {  
              if(this.state.date===null && this.state.enddate===null)
              {
                this.props.event.start=new Date(this.convertCSTDateToPST(new Date(this.props.event.start)));
                this.props.event.end=new Date(this.convertCSTDateToPST(new Date(this.props.event.end)));
              }
              else if(this.state.date!==null && this.state.enddate===null)
              {
                this.setState({date:Date.parse(new Date(this.convertCSTDateToPST(new Date(this.state.date))))});
                this.props.event.start=new Date(this.convertCSTDateToPST(new Date(this.state.date)));
                this.props.event.end=new Date(this.convertCSTDateToPST(new Date(this.props.event.end)));
              }
              else if(this.state.date===null && this.state.enddate!==null)
              {
                this.setState({enddate:Date.parse(new Date(this.convertCSTDateToPST(new Date(this.state.enddate)))) });
                this.props.event.start=new Date(this.convertCSTDateToPST(new Date(this.props.event.start)));
                this.props.event.end=new Date(this.convertCSTDateToPST(new Date(this.state.enddate)));
              }
              else if(this.state.date!==null && this.state.enddate!==null)
              {
                  this.setState({date:Date.parse(new Date(this.convertCSTDateToPST(new Date(this.state.date)))) , 
                            enddate:Date.parse(new Date(this.convertCSTDateToPST(new Date(this.state.enddate)))) });
                  this.props.event.start=new Date(this.convertCSTDateToPST(new Date(this.state.date))); 
                  this.props.event.end=new Date(this.convertCSTDateToPST(new Date(this.state.enddate))); 
              }

              this.props.event.Timezone=selectedtimezone;                    
                            
            }
            else if(selectedtimezone==="CST")
            {                       
              if(this.state.date!==null)
              {
                this.setState({date:new Date(this.state.date)});
                this.props.event.start=new Date(this.state.date);                 
              }
              else if(this.state.enddate!==null)
              {
                this.setState({enddate:new Date(this.state.enddate)});
                this.props.event.end=new Date(this.state.enddate); 
              }
              this.props.event.Timezone=selectedtimezone;             
            }
       }
       else if(prevselectedtimezone==="PST")
       {
            if(selectedtimezone==="UTC")
            { 
              
              if(this.state.date===null && this.state.enddate===null)
              {
                this.props.event.start=new Date(this.convertPSTDateToUTC(new Date(this.props.event.start)));
                this.props.event.end=new Date(this.convertPSTDateToUTC(new Date(this.props.event.end)));
              }
              else if(this.state.date!==null && this.state.enddate===null)
              {
                this.setState({date:Date.parse(new Date(this.convertPSTDateToUTC(new Date(this.state.date))))});
                this.props.event.start=new Date(this.convertPSTDateToUTC(new Date(this.state.date)));
                this.props.event.end=new Date(this.convertPSTDateToUTC(new Date(this.props.event.end)));
              }
              else if(this.state.date===null && this.state.enddate!==null)
              {
                this.setState({enddate:Date.parse(new Date(this.convertPSTDateToUTC(new Date(this.state.enddate)))) });
                this.props.event.start=new Date(this.convertPSTDateToUTC(new Date(this.props.event.start)));
                this.props.event.end=new Date(this.convertPSTDateToUTC(new Date(this.state.enddate)));
              }
              else if(this.state.date!==null && this.state.enddate!==null)
              {
                  this.setState({date:Date.parse(new Date(this.convertPSTDateToUTC(new Date(this.state.date)))) , 
                            enddate:Date.parse(new Date(this.convertPSTDateToUTC(new Date(this.state.enddate)))) });
                  this.props.event.start=new Date(this.convertPSTDateToUTC(new Date(this.state.date))); 
                  this.props.event.end=new Date(this.convertPSTDateToUTC(new Date(this.state.enddate))); 
              }

              this.props.event.Timezone=selectedtimezone;  
                   
            }
            else if(selectedtimezone==="CST")
            {  
              if(this.state.date===null && this.state.enddate===null)
              {
                this.props.event.start=new Date(this.convertPSTDateToCST(new Date(this.props.event.start)));
                this.props.event.end=new Date(this.convertPSTDateToCST(new Date(this.props.event.end)));
              }
              else if(this.state.date!==null && this.state.enddate===null)
              {
                this.setState({date:Date.parse(new Date(this.convertPSTDateToCST(new Date(this.state.date))))});
                this.props.event.start=new Date(this.convertPSTDateToCST(new Date(this.state.date)));
                this.props.event.end=new Date(this.convertPSTDateToCST(new Date(this.props.event.end)));
              }
              else if(this.state.date===null && this.state.enddate!==null)
              {
                this.setState({enddate:Date.parse(new Date(this.convertPSTDateToCST(new Date(this.state.enddate)))) });
                this.props.event.start=new Date(this.convertPSTDateToCST(new Date(this.props.event.start)));
                this.props.event.end=new Date(this.convertPSTDateToCST(new Date(this.state.enddate)));
              }
              else if(this.state.date!==null && this.state.enddate!==null)
              {
                  this.setState({date:Date.parse(new Date(this.convertPSTDateToCST(new Date(this.state.date)))) , 
                            enddate:Date.parse(new Date(this.convertPSTDateToCST(new Date(this.state.enddate)))) });
                  this.props.event.start=new Date(this.convertPSTDateToCST(new Date(this.state.date))); 
                  this.props.event.end=new Date(this.convertPSTDateToCST(new Date(this.state.enddate))); 
              }

              this.props.event.Timezone=selectedtimezone;           
                           
            }
            else if(selectedtimezone==="PST")
            {                       
              if(this.state.date!==null)
              {
                this.setState({date:new Date(this.state.date)});
                this.props.event.start=new Date(this.state.date);                 
              }
              else if(this.state.enddate!==null)
              {
                this.setState({enddate:new Date(this.state.enddate)});
                this.props.event.end=new Date(this.state.enddate); 
              }
              this.props.event.Timezone=selectedtimezone;             
            }
       }      

    }

    formatUTCDate(date)
    {
      var now_utc =  date;
      return now_utc.toUTCString('MM/DD/YYYY hh:mm:ss A');
    }

    
    getLocalisedDate()
    {        
     
        if(this.state.istimezoneselectchange===false)  //when timezone radio button in Edit UI screen is not changed by user . 
        {
          /* if (this.props.event.Timezone==="PST")
           {
              if(this.state.localstartdate===null)
              {
              this.setState({localstartdate:Date.parse(new Date(this.convertUTCDateToPST(new Date(this.props.event.start))))});  
              this.props.event.start=new Date(this.convertUTCDateToPST(new Date(this.props.event.start))); 
              }
              if(this.state.localenddate===null)
              {
              this.setState({localenddate:Date.parse(new Date(this.convertUTCDateToPST(new Date(this.props.event.end))))});  
              this.props.event.end=new Date(this.convertUTCDateToPST(new Date(this.props.event.end)));  
              }
                       
           }
           else if(this.props.event.Timezone==="CST")
           {            
              if(this.state.localstartdate===null)
              {
                this.setState({localstartdate:Date.parse(new Date(this.convertUTCDateToCST(new Date(this.props.event.start))))});  
                this.props.event.start=new Date(this.convertUTCDateToCST(new Date(this.props.event.start)));
              }
              if(this.state.localenddate===null)
              {
                this.setState({localenddate:Date.parse(new Date(this.convertUTCDateToCST(new Date(this.props.event.end))))});  
                this.props.event.end=new Date(this.convertUTCDateToCST(new Date(this.props.event.end)));  
              }
     
           }
           else if(this.props.event.Timezone==="UTC")
           {            
              if(this.state.localstartdate===null)
              {
                this.setState({localstartdate:Date.parse(new Date(this.props.event.start))});  
                
              }
              if(this.state.localenddate===null)
              {
                this.setState({localenddate:Date.parse(new Date(this.props.event.end))});  
               
              }
     
           } */

          /*    if(this.state.localstartdate===null || Number.isNaN(this.state.localstartdate) || this.state.localstartdate===undefined || this.state.localstartdate==='')
              {
              //  this.setState({localstartdate:Date.parse(new Date(this.props.event.start))});  
              this.state.localstartdate= Date.parse(new Date(this.props.event.start));
                
              }
              if(this.state.localenddate===null || Number.isNaN(this.state.localenddate) || this.state.localenddate===undefined || this.state.localenddate==='')
              {
                //this.setState({localenddate:Date.parse(new Date(this.props.event.end))});  
                this.state.localenddate= Date.parse(new Date(this.props.event.end));
               
              } */
         
        }   

      
    
    }


    checkErrors = () => {
        const validEmailRegex = 
        RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        const {region,country,plantName,timezone} = this.state;
        var obj = {};
        var date = this.state.date;
        var enddate = this.state.enddate;
        date = (date == null) ? this.props.event.start : date;
        enddate = (enddate == null) ? this.props.event.end : enddate;
        var holidayname=(this.state.holidayName===''|'valid'|null)?this.props.event.outageTitle:this.state.holidayName;
        var holidaytypevalue=(this.state.holidayType===''|null|'valid')?this.props.event.category:this.state.holidayType;
        var plantname=(this.state.plantName===''|null|'valid')?this.props.event.factory:this.state.plantName;
        var regionvalue=(this.state.region===''|null)?this.props.event.region:this.state.region;
        var countryvalue=(this.state.country===''|null)?this.props.event.country:this.state.country;
        var updttimezone=(timezone === "") ? this.props.event.Timezone : timezone;

      if(this.state.date === null && this.state.timezone==='') // this means that in EditCREWHolidayEvent screen startdate value not changed .
      {
            if (updttimezone==="PST")
            {
                date=Date.parse(new Date(this.convertUTCDateToPST(new Date(date))));                                
            }
            else if(updttimezone==="CST")
            {              
              date=Date.parse(new Date(this.convertUTCDateToCST(new Date(date))));                           
            }           
      }
     
      if(this.state.enddate == null && this.state.timezone==='') // this means that in EditCREWHolidayEvent screen enddate value not changed .
      {
            if (updttimezone==="PST")
            {
              enddate=Date.parse(new Date(this.convertUTCDateToPST(new Date(enddate))));              
            }
            else if(updttimezone==="CST")
            {              
              enddate=Date.parse(new Date(this.convertUTCDateToCST(new Date(enddate))));                
            }            
      }

        const { userEmail} = this.props;
        let validEmail = (userEmail != undefined ? userEmail.toLowerCase().includes("microsoft.com") : null);
            
        if (holidayname.length === 0) {
          this.setState({
            holidayNameError: 'Holiday Name should not be blank.'
          })
          obj.prop = ++obj.prop || 0;
        }
        if (holidaytypevalue.length === 0 ) {
            this.setState({
              holidayTypeError: 'Holiday Type should be selected.'
            })
            obj.prop = ++obj.prop || 0;
      
          }
          if (regionvalue.length === 0 ) {
            this.setState({
              regionError: 'Region should be selected.'
            })
            obj.prop = ++obj.prop || 0;
      
          }
          if (countryvalue.length === 0 ) {
            this.setState({
              countryError: 'Country should be selected.'
            })
            obj.prop = ++obj.prop || 0;
      
          }

      if (date === '') {
        this.setState({
          dateError: 'Start Date field should not be blank.'
        })  
        obj.prop = ++obj.prop || 0;
      }
     
      if (enddate == '') {
        this.setState({
          enddateError: 'End Date field should not be blank.'
        })  
        obj.prop = ++obj.prop || 0;
      }
     else if(date && enddate)
      {
        if (Date.parse(new Date(this.convertDateToUTC(new Date(date)))) > Date.parse(new Date(this.convertDateToUTC(new Date(enddate))))) {
          this.setState({
            enddateError: 'End Datetime should be greater or equal than Start Datetime.'
          })  
          obj.prop = ++obj.prop || 0;
        }       
      } 

        if(obj.prop >=0)
        {
        return true;
        }
        else
        {
        return false;
        }
      } 
      renderError(name) {
        const { holidayNameError,
            dateError,enddateError,holidayTypeError,regionError,countryError,plantError} = this.state;
        switch(name) {
          case 'holidayName':
            if (holidayNameError) {
              return <div style ={{backgroundColor:'#f8d7da'}}>{holidayNameError}</div>;
            }
            break;
          case  'holidayType' :
            if (holidayTypeError) {
                return <div style ={{backgroundColor:'#f8d7da'}}>{holidayTypeError}</div>;
            }
            break;   
          case  'date' :
            if (dateError) {
                   return <div style ={{backgroundColor:'#f8d7da'}}>{dateError}</div>;
            }
            break;
       
          case  'enddate' :
            if (enddateError) {
                return <div style ={{backgroundColor:'#f8d7da'}}>{enddateError}</div>;
            }
            break; 
          case  'region' :
            if (regionError) {
                return <div style ={{backgroundColor:'#f8d7da'}}>{regionError}</div>;
            }
            break;  
          case  'country' :
            if (countryError) {
                return <div style ={{backgroundColor:'#f8d7da'}}>{countryError}</div>;
            }
            break;  
            
                     
          default: 
          break;
          //return null;
            
          }       
      }
  
    methodInvoke = async(event) =>
    {
      let errors = null;
      let prop = this;
      event.preventDefault();
      event.persist();
      try {                        
      if(this.checkErrors())
      {
          this.props.closeModalPopUpFunc(true);
          return;
      }
      const title = event.target.holidayName.value;
      var startdate,EndTime;
      var selectedtimezone=(prop.state.timezone!=="" ? prop.state.timezone: (prop.props.event.Timezone!==""?prop.props.event.Timezone:''));
      var start = prop.state.date == null ? new Date(prop.props.event.start) : new Date(prop.state.date);
      var end = prop.state.enddate == null ? new Date(prop.props.event.end) : new Date(prop.state.enddate);
      startdate = moment(start, 'YYYY-MM-DDTHH:mm:ss.fff Z');
      startdate = startdate.format('YYYY-MM-DDTHH:mm:ss'); 
      EndTime= moment(end, 'YYYY-MM-DDTHH:mm:ss.fff Z');
      EndTime = EndTime.format('YYYY-MM-DDTHH:mm:ss');
      
    /*  if(prop.state.date === null && prop.state.timezone==='') // this means that in EditCREWHolidayEvent screen startdate value not changed .
      {
            if (selectedtimezone==="PST")
            {
                startdate=Date.parse(new Date(this.convertUTCDateToPST(new Date(start))));
                startdate = moment(new Date(startdate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");                
            }
            else if(selectedtimezone==="CST")
            {              
                startdate=Date.parse(new Date(this.convertUTCDateToCST(new Date(start))));
                startdate = moment(new Date(startdate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");              
            }
            else
            {
              startdate = moment(start, 'YYYY-MM-DDTHH:mm:ss.fff Z');
              startdate = startdate.format('YYYY-MM-DDTHH:mm:ss');  
            }
      }
      else 
      {
            startdate = moment(start, 'YYYY-MM-DDTHH:mm:ss.fff Z');
            startdate = startdate.format('YYYY-MM-DDTHH:mm:ss');           
      }

      if(prop.state.enddate == null && prop.state.timezone==='') // this means that in EditCREWHolidayEvent screen enddate value not changed .
      {
            if (selectedtimezone==="PST")
            {
                EndTime=Date.parse(new Date(this.convertUTCDateToPST(new Date(end))));
                EndTime = moment(new Date(EndTime),"YYYY-MM-DDTHH:mm:ss.fff Z");
                EndTime = EndTime.format("YYYY-MM-DDTHH:mm:ss");
            }
            else if(selectedtimezone==="CST")
            {              
                EndTime=Date.parse(new Date(this.convertUTCDateToCST(new Date(end))));
                EndTime = moment(new Date(EndTime),"YYYY-MM-DDTHH:mm:ss.fff Z");
                EndTime = EndTime.format("YYYY-MM-DDTHH:mm:ss");
            }
            else
            {
              EndTime= moment(end, 'YYYY-MM-DDTHH:mm:ss.fff Z');
              EndTime = EndTime.format('YYYY-MM-DDTHH:mm:ss');
            }
      }
      else 
      {
            EndTime= moment(end, 'YYYY-MM-DDTHH:mm:ss.fff Z');
            EndTime = EndTime.format('YYYY-MM-DDTHH:mm:ss');
      } */

     
      
      var org = event.target.organization == undefined ? null : '';
      var extcontact = event.target.externalcontact == undefined ? null : event.target.externalcontact.value ;
      var engcontact = event.target.engcontact == undefined ? null : event.target.engcontact.value;
           
      var bodyvalues = JSON.stringify({
        EventId : prop.props.event.outageId,
        title: title,
        Description:'',
        StartTime: startdate,
        EndTime: EndTime,
        organization: org,
        ExternalContact: extcontact,
        engContact: null,
        pmContact: null, 
        teamgroup:null,
        service:null,
        component:null,
        servicegroup:null,
        division:"",
        orgName:"",
        eventStatus:"",
        NextSteps:"",                            
        outageType: prop.props.event.outageType,
        region: event.target.region.value==='--Select Region--'?'':event.target.region.value,
        country: event.target.country.value==='--Select Country--'?'':event.target.country.value,
        factory: event.target.plant.value==='--Select Plant--'?'':event.target.plant.value,
        NACategory: "CREW Holiday Calendar" ,
        category:prop.state.holidayType.value!==undefined|null|''|'valid'?prop.state.holidayType.value:prop.props.event.category,
        Location:'',
        Recurrence:false,
        Timezone:selectedtimezone
      })      
       appInsights.trackTrace({ message: 'Calendar Edit Holiday Event Component Loaded.' },
      {
          "userName": this.props.userEmail,
          "ComponentId" : COMPONENT_ID,
          "ComponentName" : COMPONENT_NAME,
          "EnvironmentName" : window.env.ENVIRONMENT,
          "ServiceLineName" : SERVICE_LINE,
          "ServiceName" : SERVICE_NAME,
          "ServiceOfferingName" : SERVICE_OFFERING,
          "Correlation Id": uuid.v1(),
          "Component": 'Edit Holiday Event Calendar Page',
      });       
       var token = await fetchToken();  
       var correlationId = uuid.v1();
      var bearer = 'Bearer ' + token;
      var apimVerifyDataUrl = window.env.APIMURL + "/api/outage/verifyholidaydata"; 
      var apimUrlUpdateEvent = window.env.APIMURL + "/api/outage/updateoutage";  

      if((event.target.holidayName.value===prop.props.event.title)&&(prop.state.date===null)&&(prop.state.enddate===null)&&(event.target.region.value===prop.props.event.region)&&(event.target.country.value===prop.props.event.country))
      {
      trackPromise( 
     
              fetch(apimUrlUpdateEvent, {
                method: 'PUT',
                headers: {
                //'Accept': 'application/json',
                //'Access-Control-Allow-Origin': '*',
                'CorrelationId': correlationId,
                'Content-Type': 'application/json',
                'authorization': bearer,
                },
               body: bodyvalues       
              }).then(response => {        
                return response.json()
              }).then(json => {
               var values = JSON.parse(bodyvalues); 
               values["outageId"] = prop.props.event.outageId;
               values["start"] = new Date(values.StartTime);
               values["end"] = new Date(values.EndTime);
               values["outageTitle"] = values.title;
               values["timezone"] = values.Timezone;                               
               prop.setState({
                date: null,
                enddate:null,
                clicked:'',
                readOnly:true,
                updateCompleted : true,
               })
              prop.props.updateEvent(values);
              })
              .catch(error => 
                {
                 var err =
                 {
                    "message": "Exception occured while updating the calendar edit holiday event",
                    "ComponentId" : COMPONENT_ID,
                    "ComponentName" : COMPONENT_NAME,
                    "EnvironmentName" : window.env.ENVIRONMENT,
                    "ServiceLineName" : SERVICE_LINE,
                    "ServiceName" : SERVICE_NAME,
                    "ServiceOfferingName" : SERVICE_OFFERING,
                    "Component" : "Calendar Edit Holiday Event Page",
                    "userName": this.props.userEmail,
                    "roles": this.props.role != null || '' ? this.props.role : "",
                    "exception": error.message,
                    "Correlation Id": correlationId
                 }
                 appInsights.trackException({exception: error,properties : err}); 
                })                                   
        
      );
      }
      else  // when there is any change in HolidayName,StartDate,EndDate,Region,Country then holiday validator is called .
      {
        trackPromise( 
     
          fetch(apimVerifyDataUrl, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'CorrelationId': correlationId,
            'authorization': bearer,
            },
           body: bodyvalues
          }).then(response => {
            return response.json()
                })              
          .then( async val => {
              this.setState({
              result:val
              }); 
              
              if(this.state.result===0)  //this means there is no such data . 
              {
                fetch(apimUrlUpdateEvent, {
                  method: 'PUT',
                  headers: {
                  //'Accept': 'application/json',
                  //'Access-Control-Allow-Origin': '*',
                  'CorrelationId': correlationId,
                  'Content-Type': 'application/json',
                  'authorization': bearer,
                  },
                 body: bodyvalues       
                }).then(response => {        
                  return response.json()
                }).then(json => {
                 var values = JSON.parse(bodyvalues); 
                 values["outageId"] = prop.props.event.outageId;
                 values["start"] = new Date(values.StartTime);
                 values["end"] = new Date(values.EndTime);
                 values["outageTitle"] = values.title;  
                 values["timezone"] = values.Timezone;                             
                 prop.setState({
                  date: null,
                  enddate:null,
                  clicked:'',
                  readOnly:true,
                  updateCompleted : true,
                 })
                prop.props.updateEvent(values);
                })
                .catch(error => 
                  {
                   var err =
                   {
                      "message": "Exception occured while updating the calendar edit holiday event",
                      "ComponentId" : COMPONENT_ID,
                      "ComponentName" : COMPONENT_NAME,
                      "EnvironmentName" : window.env.ENVIRONMENT,
                      "ServiceLineName" : SERVICE_LINE,
                      "ServiceName" : SERVICE_NAME,
                      "ServiceOfferingName" : SERVICE_OFFERING,
                      "Component" : "Calendar Edit Holiday Event Page",
                      "userName": this.props.userEmail,
                      "roles": this.props.role != null || '' ? this.props.role : "",
                      "exception": error.message,
                      "Correlation Id": correlationId
                   }
                   appInsights.trackException({exception: error,properties : err}); 
                  })
              }
              else //this means data already exists .
              {
                await this.showHolidayErrorDialog();
              }                            
          })
          .catch( async error =>
                {
                  //alert("Error Occured");  
                  await this.showErrorDialog();
                  var err = 
                  {
                      "message": "Exception occured while validating holiday event in BI ",
                      "ComponentId" : COMPONENT_ID,
                      "ComponentName" : COMPONENT_NAME,
                      "EnvironmentName" : window.env.ENVIRONMENT,
                      "ServiceLineName" : SERVICE_LINE,
                      "ServiceName" : SERVICE_NAME,
                      "ServiceOfferingName" : SERVICE_OFFERING,
                      "Component" : "Calendar Add Event Page",
                      "exception": error.message,
                      "Correlation Id": correlationId
                  }               
                 appInsights.trackException({exception: error,properties : err});  
               }) 
        );
      }
      }
      catch (e) {
        errors = e;
    }
    }
  
    deleteConfirmation = async(outageID) =>
    {
      let prop = this;
      var apimUrl = window.env.APIMURL + "/api/outage/deleteoutage/?outageid="; 
      var correlationId = uuid.v1();
      let url = apimUrl + outageID;
      var token = await fetchToken();  
      var bearer = 'Bearer ' + token;
      var bodyvalues = JSON.stringify({
        outageid : outageID,
       }) 
       
       appInsights.trackTrace({ message: 'Calendar Component Loaded and user clicked on Delete Holiday event.' },
      {
          userName: this.props.userEmail,
          "ComponentId" : COMPONENT_ID,
          "ComponentName" : COMPONENT_NAME,
          "EnvironmentName" : window.env.ENVIRONMENT,
          "ServiceLineName" : SERVICE_LINE,
          "ServiceName" : SERVICE_NAME,
          "ServiceOfferingName" : SERVICE_OFFERING,
          "Correlation Id": correlationId,
          "Component": 'Calendar Delete Holiday Event Operation',
      });
      fetch(url, {
        method: 'DELETE',
        headers: {
        'CorrelationId': correlationId,
        'Content-Type': 'application/json',
        'authorization': bearer,
        },
      }).then(response => {     
        return response.text()
      }).then(res => {       
        try {
          prop.props.removeEvent(outageID);
        } catch(err) {
          
          var error = 
         {
            "message": "Exception occured in Calendar Delete component while submitting the delete request",
            "ComponentId" : COMPONENT_ID,
            "ComponentName" : COMPONENT_NAME,
            "EnvironmentName" : window.env.ENVIRONMENT,
            "ServiceLineName" : SERVICE_LINE,
            "ServiceName" : SERVICE_NAME,
            "ServiceOfferingName" : SERVICE_OFFERING,
            "Component": "Calendar Delete Holiday Event",
            "userName": this.props.userEmail,
            "roles": this.props.role != null || '' ? this.props.role : "",
            "exception": err.message,
            "Correlation Id": correlationId
         }
         appInsights.trackException({exception: error,properties : error});
        }
      }).catch(error =>
        {
        
         var err = 
         {
            "message": "Exception occured in Calendar Delete component while submitting the delete request",
            "ComponentId" : COMPONENT_ID,
            "ComponentName" : COMPONENT_NAME,
            "EnvironmentName" : window.env.ENVIRONMENT,
            "ServiceLineName" : SERVICE_LINE,
            "ServiceName" : SERVICE_NAME,
            "ServiceOfferingName" : SERVICE_OFFERING,
            "Component": "Calendar Delete Holiday Event",
            "userName": this.props.userEmail,
            "roles": this.props.role != null || '' ? this.props.role : "",
            "exception": error.message,
            "Correlation Id": correlationId
         }
         appInsights.trackException({exception: error,properties : err}); 
        })
      
  
    }
  
    DeleteEvent = async()=>{
      
      let outageID = this.props.event.outageId;
      this.setState({outageId:outageID});
      var shouldDelete = await this.flip();

      //await this.Sample(outageID);
      // Service Delete Operetion
      // 
       }
  
       
    async updatePlant(value){
      
        this.setState({plantName: value });
        
      }

    async setPlantErrorValue(value){
      
        this.setState({
            plantError: value
           });
    }  
    
    async updateRegion(value){
      
        this.setState({region: value });
        
      }
  
      async setRegionErrorValue(value){
        
        this.setState({
            regionError: value
           });
      }
  
      async updateCountry(value){
        
        this.setState({country: value });
        
      }
  
      async setCountryErrorValue(value){
        
        this.setState({
            countryError: value
           });
      }
   
  
    returnValues(array)
    {
      const options = array.map(v => ({
        label: v,
        value: v
      }));
      return options;
    }

    handleHolidayTypeChange = async(selectedholidayType) => {
        await this.setState(
          {  holidayType:(selectedholidayType  == null ? [] : selectedholidayType),holidayTypeError:''},
          () => console.log(`Option selected:`, this.state.holidayType)
        );
     
      };

       

    render(){
      
      let subText,display;
      const { userEmail} = this.props;
      let validEmail = (userEmail !== undefined  ? userEmail.toLowerCase().includes("microsoft.com") : null);
      const { holidayType} = this.state;

      //this.getLocalisedDate();
     
      if(this.state.showDeleteDialog)
      {
        subText = 'We have successfully processed your request';
        display = true;
      }
      if(this.state.updateCompleted)
      {
        subText = 'Updated successfully';
        display = true;
      }

      const holidayTypeArray=[
        { value: 'Public Holiday', label: 'Public Holiday' },
        { value: 'Plant Holiday', label: 'Plant Holiday' }
      ];

      let mapholidayType;

              
      if(holidayType!=null && holidayType!='' && holidayType!='valid')
      {    
        mapholidayType = holidayType;
        
      } else if(this.props.event.category !== undefined && this.props.event.category !== null)
      {
        mapholidayType = this.props.event.category;
        this.setState({holidayType:mapholidayType});
      }

      let inputProps = {
        placeholder: "Select a Date",
        readOnly: true,
        disabled : this.state.readOnly            
      };

      return (
          <div>
            
            <AlertDialog  title= {'Success!'} cancelButtonLabel={'Ok'}
                          message= {subText} open={display} onClickCancel={this.closeDeleteDialog}/> 
            <AlertDialog okButtonLabel = {'Delete'} okButtonType = {ButtonType.Danger} onClickOk={this.deleteDialog} title= {'Confirm Dialog'} cancelButtonLabel={'Cancel'}
                          message= {'Are you sure you want to delete this event?'} open={this.state.showDialog} onClickCancel={this.cancelDialog}/> 
            <Modal style = {{'overflow': 'visible'}} role="dialog" data-keyboard="false" show={this.props.show} size="lg" onHide ={()=> {this.props.closeModalPopUpFunc(false)}} backdrop="static" centered autoFocus="true" >
              <Modal.Header closeButton>
                <Modal.Title>Edit Holiday Event</Modal.Title>
              </Modal.Header>
              <LoadingIndicator/>
              <form id = "updateevent" onSubmit = {this.methodInvoke.bind(this)}>
                <Modal.Body style={{'maxHeight': 'calc(100vh - 210px)', 'overflowY': 'auto'}}>
                  <div className="form-group required">
                    <label for="HolidayName" class="control-label">Holiday Name</label>
                    <input required type="text" className="form-control" name="holidayName" title="holidayName" readOnly={this.state.readOnly} defaultValue={this.props.event.holidayName} onChange={(e) => this.handleChange(e)} />
                    <span style={{color: '#E22335'}}>{this.renderError('holidayName')}</span>
                  </div>

                <div className="form-group required">
                <label htmlFor="holidayType" class="control-label">Holiday Type</label>
                <Select  required aria-label="HolidayType" name="holidaytype" title="Select Holiday Type" 
                    isMulti = {false}
                    isDisabled={this.state.readOnly}
                    value={mapholidayType||''}
                    onChange={this.handleHolidayTypeChange} 
                    options={holidayTypeArray} 
                    onBlurResetsInput={false}
                    isClearable={true}  
                    placeholder={mapholidayType||''}                  
                    >                                  
                </Select>  
                <span style={{color: '#E22335'}}>{this.renderError('holidayType')}</span>            
              </div>  

              <div  className="form-group required">
               
               <label> Timezone : </label>
               &nbsp;
               <input type="radio" value="UTC" name="radio-group"  placeholder="UTC" checked={this.props.event.Timezone === "UTC" || this.props.event.Timezone===null || this.state.timezone==="UTC"} onChange={this.onTimezoneChange}/> UTC
                &nbsp;
                <input type="radio" value="PST" name="radio-group" placeholder="PST" checked={this.props.event.Timezone === "PST" || this.state.timezone==="PST"} onChange={this.onTimezoneChange}/> PST 
                &nbsp;
                <input type="radio" value="CST" name="radio-group" placeholder="CST" checked={this.props.event.Timezone === "CST" || this.state.timezone==="CST"} onChange={this.onTimezoneChange}/> CST
                &nbsp;           
               </div> 
                                
              <div className="form-group required">              
                <label htmlFor="startDate" class="control-label">StartDate</label>
                <div className="form-group">
                <Datetime required inputProps={inputProps}  calendarAriaLabel="StartTimeCalender" yearAriaLabel="Year" monthAriaLabel="Month" dayAriaLabel="Day" 
                  name="startDate"  dateFormat="DD-MMM-YYYY" timeFormat={false}  onChange={this.onChange} value={this.state.date == null ? this.props.event.start : this.state.date}></Datetime>    
                 <span style={{color: '#E22335'}}>{this.renderError('date')}</span>
                </div>            
              </div>
              <div className="form-group required">
                <label htmlFor="endtime" class="control-label">EndDate</label>
                <Datetime required inputProps={inputProps}   calendarAriaLabel="EndTimeCalender" yearAriaLabel="Year" monthAriaLabel="Month" dayAriaLabel="Day" 
                  name="endtime" dateFormat="DD-MMM-YYYY" timeFormat={false}   onChange={this.onEndChange} value={this.state.enddate == null ? this.props.event.end  : this.state.enddate}></Datetime>
                <span style={{color: '#E22335'}}>{this.renderError('enddate')}</span>
              </div>
                 
              <div className="form-group">
                      <EditPlantRegionDropDown
                      countryData={this.updateCountry.bind(this)}                      
                      email = {userEmail}
                      setCountryErrorValue = {this.setCountryErrorValue.bind(this)}                    
                      countryError = {this.renderError('country')}
                      plantData={this.updatePlant.bind(this)} 
                      regionError={this.renderError('region')}                      
                      setRegionErrorValue = {this.setRegionErrorValue.bind(this)}                      
                      regionData={this.updateRegion.bind(this)} 
                      event = {this.props.event} 
                      readOnly={this.state.readOnly}
                    />
                      
                    </div>
                 
               </Modal.Body>              
                <Modal.Footer>
                    {this.props.role === 'Admin' || this.props.role === 'Writer' || this.props.role === 'Approver' ?
                  <Button aria-label="Delete" variant="secondary" onClick={()=> {
                    this.DeleteEvent()
                    }}>
                    Delete
                  </Button>
                   : null }
                  <Button aria-label="Close" variant="secondary" onClick={()=> {this.setState({readOnly:true});this.props.closeModalPopUpFunc(false)}}>
                    Close
                  </Button>
                  {this.props.role !== 'Reader' && this.props.role !== 'BusinessUser ChannelManagers' ?
                  <Button aria-label="Toggle" variant="secondary" onClick={()=> {this.ToggleReadonly()}}>
                    {
                      (this.state.readOnly)? "Edit":"Back"
                    }
                  </Button>
                  : null }
                  <Button aria-label="Edit Event" variant="primary" form = "updateevent" type="Submit" hidden={Boolean(this.state.readOnly)} onClick={()=> {
                      
                      this.props.closeModalPopUpFunc(true);                   
                      
                      }}>
                    Save Changes
                  </Button>
                </Modal.Footer>
              </form>
            </Modal>
          </div>
      );
    }
  }

export default EditCREWHolidayEvent;