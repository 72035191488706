import React, { Component } from "react";
import {Form }  from 'react-bootstrap'
import {fetchToken} from '../../Util.js';
import { reactAI } from "react-appinsights";
import uuid from 'react-native-uuid';
import {appInsights} from '../../common/AppInsights/AppInsights';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";
import { event } from "jquery";
import Select from 'react-select';

export class ApproverDropDown extends Component{

    constructor(){
        super()        
        this.state={approverlist:[],selectedApprover:[],pmcontactError:''}
    }

    handleApproverNameChange = async(selectedApprover) => {
         await  this.setState({ selectedApprover,pmcontactError:''},
         () => console.log(`Option selected:`, this.state.selectedApprover));
         await this.props.pmContactData(selectedApprover);
         await this.props.setpmContactErrorValue(this.state.pmcontactError);
       };

    async componentDidMount() {
      
        appInsights.trackTrace({ message: 'Approver list Component Loaded.' },
                     {
                         userName: this.props.email,
                         "ComponentId" : COMPONENT_ID,
                         "ComponentName" : COMPONENT_NAME,
                         "EnvironmentName" : window.env.ENVIRONMENT,
                         "ServiceLineName" : SERVICE_LINE,
                         "ServiceName" : SERVICE_NAME,
                         "ServiceOfferingName" : SERVICE_OFFERING,
                         "Correlation Id": uuid.v1(),
                         "Component": 'Status Page',
                     });
        var token = await fetchToken();
        var correlationId = uuid.v1();
        var bearer = 'Bearer ' + token;
        var apimUrl = window.env.APIMURL + "/api/outage/getl2approverlist";
        fetch(apimUrl,{
            method: 'GET',
            headers: {
                'authorization': bearer,
                'Accept' : 'application/json',
                'CorrelationId': correlationId,
                'Content-Type': 'application/json'
            }})
          .then(response => {
            return response.json();
          })        
          .then(data => {   
                     
              this.setState({ approverlist:data
              });
            })
            .catch(error => {
                var err = 
                {
                 "message": "Exception occured in Approver list page while getting the Approver list",
                 "ComponentId" : COMPONENT_ID,
                 "ComponentName" : COMPONENT_NAME,
                 "EnvironmentName" : window.env.ENVIRONMENT,
                 "ServiceLineName" : SERVICE_LINE,
                 "ServiceName" : SERVICE_NAME,
                 "ServiceOfferingName" : SERVICE_OFFERING,
                 "Component": 'Status Page',
                 "userName": this.props.email,                   
                 "exception": error.message,
                 "Correlation Id": correlationId,
                }
                appInsights.trackException({exception: error,properties : err});
            })
        
    }

    returnValues(array)
    {
      const options = array.map(v => ({
        label: v,
        value: v
      }));
      return options;
    }

        render(){ 
            const { selectedApprover} = this.state;  
            const approvers = [...new Set(this.state.approverlist)];    
            const approverArray = this.returnValues(approvers);
        return(          
            <div>
             <div className="form-group required">    
            <label htmlFor="pmcontact" class="control-label">Approver Contact</label>
            <div>
            <Select required aria-label="pmContact" name="pmcontact" title="Select Approver Contact" 
              isMulti = {false}
              value={selectedApprover}
              onChange={this.handleApproverNameChange}  
              options={approverArray}        
            >
            </Select>
            <span style={{color: '#E22335'}}>{this.props.pmcontactError}</span>
            </div>
            </div>

           
        </div>  
        )      
    }
}

export default ApproverDropDown;