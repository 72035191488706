import React,{Fragment} from 'react';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../OutageHub.css';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import {Button,Modal} from 'react-bootstrap';
import CustomSpinner from "../../common/Spinner/Spinner";
import uuid from 'react-native-uuid';
import {appInsights} from '../../common/AppInsights/AppInsights';
import $ from 'jquery';
import '../../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";
import Search from "../../common/Search/Search";
import AddEventModalPopUp from "../../components/AddEvent/AddEvent";
import EditModalPopUp from "../../components/EditEvent/EditEvent";
import '../../../node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css';
import { Link } from 'react-router-dom';
import {fetchToken,getValidRolePendingReqTab} from '../../Util.js';
import '../../common/AlertDialog/AlertDialog.scss'
import AlertDialog from '../../common/AlertDialog/AlertDialog.js';
import {ButtonType} from 'office-ui-fabric-react/lib/Button';
import Design_New from '../../common/Images/Design_new.png';
import Plan_New from '../../common/Images/Plan_new.png';
import Source_New from '../../common/Images/Source_new.png';
import Make_New from '../../common/Images/Make_new.png';
import Deliver_New from '../../common/Images/Deliver_new.png';
import Care_New from '../../common/Images/Care_new.png';
import Enable_New from '../../common/Images/Enable_new.png';
import Approved from '../../common/Images/Approved.png';
import Pending from '../../common/Images/Pending.png';
import Health from '../../common/Images/Health.png';
import ServiceHealth from '../../components/ServiceHealth/ServiceHealth';
import QoS from '../../components/QoS/QoS';
import ApprovedRequests from '../../components/Requests/Approved';
import CreatedRequests from '../../components/Requests/Created';
import FCM from '../ADO/ADO';
import AddChannelEventModalPopUp from "../../components/AddEvent/AddChannelManagerEvent";
import EditChannelEventModalPopUp from "../../components/EditEvent/EditChannelManagerEvent";
import 'react-confirm-alert/src/react-confirm-alert.css';
import AddSAPReleaseEventModalPopUp from "../../components/AddEvent/AddSAPReleaseEvent";
import EditSAPReleaseEventModalPopUp from "../../components/EditEvent/EditSAPReleaseEvent";
import SAPReleaseSchedule from "../../components/SAPReleaseSchedule/SAPReleaseSchedule";
import EditCREWHolidayEvent from '../EditEvent/EditCREWHolidayEvent';
import moment from 'moment';
import * as Datetime from 'react-datetime';
import { ValidationState, labelProperties } from 'office-ui-fabric-react';
import { isThisMinute } from 'date-fns/esm';
import ApprovalFlow from "../../components/ApprovalWorkflow/SingleLevelApprovalFlow";
import MultipleApprovalFlow from "../../components/ApprovalWorkflow/MultiLevelApprovalFlow";
import JDM1FactoryApprovalFlow from "../../components/ApprovalWorkflow/JDM1FactoryFlow";


class Welcome extends React.Component{
    constructor(props){
        super(props)
        this.state = {
          display : true,
          showAddEventModal: false,
          event:{},
          showEditModal:false,
          events:props.events,
          upcomingEvents: [],
          upcomingevents : props.events,
          dropdownvalue : this.props.role === 'BusinessUser SAP'||'BusinessUser SAPReader' ? "SAP" : "SCE",
          pendingdropdownvalue: '',
          child:null,
          serviceHealth : null,
          eventsByUser:null,
          QoS : null,
          approvalLoading : false,
          QoSChild:null,
          adoRelease:null,
          approverContact:null,
          filters : props.filters,
          pendingRequests : props.approvals,
          pendingevents : props.approvals,
          type:'',
          fullapprove:'',
          outageid :null,
          pmcontact:null,
          flipDialog:null,
          showApprovalDialog:false,
          showErrorDialog:false,
          showDialog:false,
          approvedOutageID:'',
          isFetching:false,
          sapreleaseschedule:props.sapreleaseschedule,
          rejectedRequests : props.rejections,
          rejectedevents : props.rejections,
          rejectreasons:'',
          show:false,
          rejecteventdata:null,
          L1Approvers:props.l1approvers,
          newTimeZone:'',
          userOrgName:props.userOrgName,
          showApprovalModal:false,
          eventapprovaldata:props.eventapprovaldetails,
          eventcreatedata:'',
          eventrejectdata:'',
          eventapprovedata:'',
          eventl1approvedata:'',
          eventl2approvedata:'',
          eventl1aapprovedata:'',
          eventl1bapprovedata:'',
          eventapproverdata:props.eventapproverdetails          
                    
        }
        this.showModal = this.showModal.bind(this);
        this.routeChange = this.routeChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.showEditModalWindow=this.showEditModalWindow.bind(this);
        this.flip = this.flip.bind(this);  
        this.ApproveRejectEvent=this.ApproveRejectEvent.bind(this);  
        this.ShowRemarksModal = this.ShowRemarksModal.bind(this); 
        this.submitReason = this.submitReason.bind(this);   
        this.showApprovalModalWindow = this.showApprovalModalWindow.bind(this);   
    }
    
    showModal() {
      this.setState( {showAddEventModal:true});
    } 

    showApprovalModalWindow=(data) =>
    {

      let updatedsteps=[];
      var eventcreate_username='',eventapprove_username='',eventreject_username='',eventl1approve_username='',eventl2approve_username='',eventl1aapprove_username='',eventl1bapprove_username='';
      var event_assignedl1approver='',event_assignedl2approver='',event_assignedl1aapprover='',event_assignedl1bapprover='';
      //passing the event data . 
      var bodyvalues='';
      if(this.props.role==='BusinessUser SAP' || this.props.role==='BusinessUser SAPReader') // this section is for SAP Release Calendar events .
      {
        bodyvalues = JSON.stringify({
          outageId:data.eventId,
          outageTitle:data.title,
          organization:data.organization,
          outageDescription:data.description,
          category:data.category,
          location:data.location,
          outageType:data.outageType,
          allDay: data.allDayEvent,
          recurrence: data.recurrence,
       //   end: new Date(data.endTime),
        //  start: new Date(data.startTime),
          end : data.timezone==="PST" ?  Date.parse(new Date(this.convertUTCDateToPST(new Date(data.endTime))))  : data.timezone==="CST" ?  Date.parse(new Date(this.convertUTCDateToCST(new Date(data.endTime)))) :  Date.parse(new Date(this.formatUTCDate(new Date(data.endTime)))) ,
          start : data.timezone==="PST" ?  Date.parse(new Date(this.convertUTCDateToPST(new Date(data.startTime))))  : data.timezone==="CST" ?  Date.parse(new Date(this.convertUTCDateToCST(new Date(data.startTime)))) :  Date.parse(new Date(this.formatUTCDate(new Date(data.startTime)))),
        //  end : Date.parse(new Date(this.formatUTCDate(new Date(data.endTime)))),
        //  start : Date.parse(new Date(this.formatUTCDate(new Date(data.startTime)))),
          title:data.title,
          nacategory:data.naCategory,
          Timezone:data.timezone      

       })     
      
      }
      else
      {
        bodyvalues = JSON.stringify({
          outageId:data.eventId,
          outageTitle:data.title,
          organization:data.organization,
          outageDescription:data.description,
          outageImpact:data.impact,
          pmContact:data.pmcontact,
          engContact:data.engContact,
          extContact:data.externalContact,
          outageType:data.outageType,
          region:data.region,
          country:data.country,
          teamgroup:data.teamGroup,
          service:data.service,
          component:data.component,
          allDay: data.allDayEvent,
          category:data.category,
        //  end: new Date(data.endTime),
        //  start: new Date(data.startTime),
          end : data.timezone==="PST" ?  Date.parse(new Date(this.convertUTCDateToPST(new Date(data.endTime))))  : data.timezone==="CST" ?  Date.parse(new Date(this.convertUTCDateToCST(new Date(data.endTime)))) : Date.parse(new Date(this.formatUTCDate(new Date(data.endTime)))),
          start : data.timezone==="PST" ?  Date.parse(new Date(this.convertUTCDateToPST(new Date(data.startTime))))  : data.timezone==="CST" ?  Date.parse(new Date(this.convertUTCDateToCST(new Date(data.startTime)))) : Date.parse(new Date(this.formatUTCDate(new Date(data.startTime)))),
        //  end : Date.parse(new Date(this.formatUTCDate(new Date(data.endTime)))),
        //  start : Date.parse(new Date(this.formatUTCDate(new Date(data.startTime)))),
          title:data.title,
          status:data.approvalStatus,
          servicegroup:data.serviceGroup,
          factory:data.factoryName,
          division:data.division,
          orgName:data.orgName,
          eventstatus:data.eventStatus,
          nextsteps:data.nextSteps,
          nacategory:data.naCategory,
          incidentNumber:data.incidentNumber,
          Timezone:data.timezone,
          severity:data.severity,
          bridgeurls:data.bridgeUrls,
          updatedata:data.updateData,
          resolutiondata:data.resolutionData,
          timelinedata:data.timelineData     
             
       })     
      
      }
      var values = JSON.parse(bodyvalues);




      if(data.outageType==='UnPlanned' || data.NACategory==='CREW Holiday Calendar')
      {
          eventcreate_username=this.state.eventapprovaldata.filter(x=>x.recordoperation==='CREATE' && x.eventId===data.eventId);
          eventapprove_username=this.state.eventapprovaldata.filter(x=>x.recordoperation==='APPROVAL' && x.approvalStatus===3 && x.eventId===data.eventId);
          eventreject_username=this.state.eventapprovaldata.filter(x=>x.recordoperation==='APPROVAL' && x.approvalStatus===4 && x.eventId===data.eventId);
          event_assignedl2approver=this.state.eventapproverdata.filter(x=>(x.type==='MailToBSM' || x.type==='MailToRegionalPOC' || x.type==='MailToSuperUser') && x.eventId===data.eventId);
        

          this.setState( {event:values,showApprovalModal:true,eventcreate:eventcreate_username,eventreject:eventreject_username,eventapprove:eventapprove_username,eventapprover:event_assignedl2approver});      
      }
      else if(data.outageType==='Planned' )
      {
        if( data.factoryName==="JDM1" && data.externalContact===null)
        {

        eventcreate_username=this.state.eventapprovaldata.filter(x=>x.recordoperation==='CREATE' && x.eventId===data.eventId);
        eventl1approve_username=this.state.eventapprovaldata.filter(x=>x.recordoperation==='APPROVAL' && x.approvalStatus===2 && x.eventId===data.eventId);
        eventl2approve_username=this.state.eventapprovaldata.filter(x=>x.recordoperation==='APPROVAL' && x.approvalStatus===3 && x.eventId===data.eventId);
        eventreject_username=this.state.eventapprovaldata.filter(x=>x.recordoperation==='APPROVAL' && x.approvalStatus===4 && x.eventId===data.eventId);
        event_assignedl1approver=this.state.eventapproverdata.filter(x=>(x.type==='MailToBSM' || x.type==='MailToFM' || x.type==='MailToL1ApproverInfra') && x.eventId===data.eventId);
        event_assignedl2approver=this.state.eventapproverdata.filter(x=>x.type==='MailToPM' && x.eventId===data.eventId);
        
        this.setState( {event:values,showApprovalModal:true,eventcreate:eventcreate_username,eventreject:eventreject_username,eventl1approve:eventl1approve_username,eventl2approve:eventl2approve_username,eventassignedl1approver:event_assignedl1approver,eventassignedl2approver:event_assignedl2approver});      
        }     
        else if(data.factoryName==="JDM1" && data.externalContact!==null)
        {
        eventcreate_username=this.state.eventapprovaldata.filter(x=>x.recordoperation==='CREATE' && x.eventId===data.eventId);
        eventl1aapprove_username=this.state.eventapprovaldata.filter(x=>x.recordoperation==='APPROVAL' && x.approvalStatus===12 && x.eventId===data.eventId);
        eventl1bapprove_username=this.state.eventapprovaldata.filter(x=>x.recordoperation==='APPROVAL' && x.approvalStatus===2 && x.eventId===data.eventId);
        eventl2approve_username=this.state.eventapprovaldata.filter(x=>x.recordoperation==='APPROVAL' && x.approvalStatus===3 && x.eventId===data.eventId);
        eventreject_username=this.state.eventapprovaldata.filter(x=>x.recordoperation==='APPROVAL' && x.approvalStatus===4 && x.eventId===data.eventId);
        event_assignedl2approver=this.state.eventapproverdata.filter(x=> x.type==='MailToPM' && x.eventId===data.eventId);
        event_assignedl1approver=this.state.eventapproverdata.filter(x=>x.type==='MailToFM'  && x.eventId===data.eventId);
        
        this.setState( {event:values,showApprovalModal:true,eventcreate:eventcreate_username,eventreject:eventreject_username,eventl1aapprove:eventl1aapprove_username,eventl1bapprove:eventl1bapprove_username,eventl2approve:eventl2approve_username,eventassignedl1approver:event_assignedl1approver,eventassignedl2approver:event_assignedl2approver});      
        }
        else
        {
        eventcreate_username=this.state.eventapprovaldata.filter(x=>x.recordoperation==='CREATE' && x.eventId===data.eventId);
        eventl1approve_username=this.state.eventapprovaldata.filter(x=>x.recordoperation==='APPROVAL' && x.approvalStatus===2 && x.eventId===data.eventId);
        eventl2approve_username=this.state.eventapprovaldata.filter(x=>x.recordoperation==='APPROVAL' && x.approvalStatus===3 && x.eventId===data.eventId);
        eventreject_username=this.state.eventapprovaldata.filter(x=>x.recordoperation==='APPROVAL' && x.approvalStatus===4 && x.eventId===data.eventId);
        event_assignedl1approver=this.state.eventapproverdata.filter(x=>(x.type==='MailToBSM' || x.type==='MailToFM' || x.type==='MailToL1ApproverInfra') && x.eventId===data.eventId);
        event_assignedl2approver=this.state.eventapproverdata.filter(x=>x.type==='MailToPM' && x.eventId===data.eventId);
        
        this.setState( {event:values,showApprovalModal:true,eventcreate:eventcreate_username,eventreject:eventreject_username,eventl1approve:eventl1approve_username,eventl2approve:eventl2approve_username,eventassignedl1approver:event_assignedl1approver,eventassignedl2approver:event_assignedl2approver});      
        
        }
      }
       
  }

    
    ShowRemarksModal(event,status)
    {
      this.setState( {show:status , rejecteventdata:event});
      
    }

    submitReason(event)
    {
      this.ApproveRejectEvent(0,0,this.state.rejecteventdata,this.state.rejectreasons);
    }

    handleAddEventModalPopUp = (show) => {
      this.setState({showAddEventModal:show});
    }

    handleAddEvent = (event) =>{        
    }

    handleTextAreaChange= async(event) => {
      this.setState({rejectreasons: event.target.value});
    }
 

    showEditModalWindow=(data)=>
    {
      console.log("DATA BEING PASSED: "+JSON.stringify(data))
      var bodyvalues='';
      if(null) // this section is for SAP Release Calendar events .
      {
        bodyvalues = JSON.stringify({
          outageId:data.eventId,
          outageTitle:data.title,
          organization:data.organization,
          outageDescription:data.description,
          category:data.category,
          location:data.location,
          outageType:data.outageType,
          allDay: data.allDayEvent,
          recurrence: data.recurrence,
       //   end: new Date(data.endTime),
        //  start: new Date(data.startTime),
          end : data.timezone==="PST" ?  Date.parse(new Date(this.convertUTCDateToPST(new Date(data.endTime))))  : data.timezone==="CST" ?  Date.parse(new Date(this.convertUTCDateToCST(new Date(data.endTime)))) :  Date.parse(new Date(this.formatUTCDate(new Date(data.endTime)))) ,
          start : data.timezone==="PST" ?  Date.parse(new Date(this.convertUTCDateToPST(new Date(data.startTime))))  : data.timezone==="CST" ?  Date.parse(new Date(this.convertUTCDateToCST(new Date(data.startTime)))) :  Date.parse(new Date(this.formatUTCDate(new Date(data.startTime)))),
        //  end : Date.parse(new Date(this.formatUTCDate(new Date(data.endTime)))),
        //  start : Date.parse(new Date(this.formatUTCDate(new Date(data.startTime)))),
          title:data.title,
          nacategory:data.naCategory,
          Timezone:data.timezone,
          updatedata:data.updateData,
          resolutiondata:data.resolutionData,
          timelinedata:data.timelineData        

       })     
      
      }
      else
      {
        bodyvalues = JSON.stringify({
          outageId:data.eventId,
          outageTitle:data.title,
          organization:data.organization,
          outageDescription:data.description,
          outageImpact:data.impact,
          pmContact:data.pmcontact,
          engContact:data.engContact,
          extContact:data.externalContact,
          outageType:data.outageType,
          region:data.region,
          country:data.country,
          teamgroup:data.teamGroup,
          service:data.service,
          component:data.component,
          allDay: data.allDayEvent,
          category:data.category,
        //  end: new Date(data.endTime),
        //  start: new Date(data.startTime),
          end : data.timezone==="PST" ?  Date.parse(new Date(this.convertUTCDateToPST(new Date(data.endTime))))  : data.timezone==="CST" ?  Date.parse(new Date(this.convertUTCDateToCST(new Date(data.endTime)))) : Date.parse(new Date(this.formatUTCDate(new Date(data.endTime)))),
          start : data.timezone==="PST" ?  Date.parse(new Date(this.convertUTCDateToPST(new Date(data.startTime))))  : data.timezone==="CST" ?  Date.parse(new Date(this.convertUTCDateToCST(new Date(data.startTime)))) : Date.parse(new Date(this.formatUTCDate(new Date(data.startTime)))),
        //  end : Date.parse(new Date(this.formatUTCDate(new Date(data.endTime)))),
        //  start : Date.parse(new Date(this.formatUTCDate(new Date(data.startTime)))),
          title:data.title,
          status:data.approvalStatus,
          servicegroup:data.serviceGroup,
          factory:data.factoryName,
          division:data.division,
          orgName:data.orgName,
          eventstatus:data.eventStatus,
          nextsteps:data.nextSteps,
          nacategory:data.naCategory,
          incidentNumber:data.incidentNumber,
          Timezone:data.timezone,
          severity:data.severity,
          bridgeurls:data.bridgeUrls,
          updatedata:data.updateData,
          resolutiondata:data.resolutionData,
          timelinedata:data.timelineData     
             
       })     
      
      }
      var values = JSON.parse(bodyvalues);  
      this.setState( {event:values,showEditModal:true});
      console.log("BODY VALUES "+ bodyvalues)
       
    }    

    closeModalPopUp = (show) => {
      this.setState({showEditModal:show}); 
           
    }  

    
    closeApprovalModalPopUp = (show) => {
      this.setState({showApprovalModal:show}); 
           
    }  

    handleApprovalModalPopUp = (show) => {
      this.setState({showApprovalModal:show});
    }
  

    formatUTCDate(date)
    {
      var now_utc =  date;
      //return now_utc.toUTCString('MM/DD/YYYY hh:mm:ss A');
      return now_utc.toString('MM/DD/YYYY hh:mm:ss A');
    }

     // convert UTC date to CST date .
     convertUTCDateToCST(date)
     {
       var now_utc =  date;
       now_utc.setHours(now_utc.getHours()+8);
       return now_utc.toUTCString();
     }
     // convert UTC date to PST date . 
     convertUTCDateToPST(date)
     {
       var now_utc =  date;
       var isDST=this.checkDateForDaylightSaving(date);

      if (isDST===true)
      {
        now_utc.setHours(now_utc.getHours()-7); //during daylight saving months .
      }
      else
      {
        now_utc.setHours(now_utc.getHours()-8);
      } 
       return now_utc.toUTCString();
     }

     //calculate daylight saving start date , end date  in a year . 
  
    calculateDSTStartDate()
    {
      const days = {
        Mon: 1,
        Tue: 2,
        Wed: 3,
        Thu: 4,
        Fri: 5,
        Sat: 6,
        Sun: 0
      }
     
      var weekNumber,day,monthMoment;
      var year=new Date().getFullYear();
      var marchdate=year+'-03-01';
      var dststartdate;

      // 2nd Sunday of March . 
      weekNumber=2;
      day=days.Sun;
      monthMoment=moment(marchdate);
     
      let m = monthMoment.clone()
         .startOf('month')                     
         .day(day)
        if (m.month() !== monthMoment.month()) 
        {
          m.add(7, 'd');
        }
        //dststartdate= m.add(7 * (weekNumber - 1), 'd').format('YYYY-MM-DDTHH:mm:ss.fff Z');
        dststartdate= m.add(7 * (weekNumber - 1), 'd');
        dststartdate=moment(dststartdate).add(2, 'hours');
        return dststartdate;            
       
    }

    calculateDSTEndDate()
    {
      const days = {
        Mon: 1,
        Tue: 2,
        Wed: 3,
        Thu: 4,
        Fri: 5,
        Sat: 6,
        Sun: 0
      }
     
      var weekNumber,day,monthMoment;
      var year=new Date().getFullYear();
      var novdate=year+'-11-01';
      var dstenddate;

      // 1st Sunday of November .  
      weekNumber=1;
      day=days.Sun;
      monthMoment=moment(novdate);

      let m = monthMoment.clone()
      .startOf('month')                     
      .day(day)
       if (m.month() !== monthMoment.month()) 
      {
         m.add(7, 'd');
      }
      //dstenddate= m.add(7 * (weekNumber - 1), 'd').format('YYYY-MM-DDTHH:mm:ss.fff Z');
      dstenddate= m.add(7 * (weekNumber - 1), 'd');
      dstenddate=moment(dstenddate).add(2, 'hours');
      return dstenddate;        
       
    }
    //check daylight saving is active or not . 
    checkDateForDaylightSaving(date)
    {
       var DaylightStartDate= this.calculateDSTStartDate();
       var DaylightEndDate=this.calculateDSTEndDate();
       if(date >= DaylightStartDate && date <=DaylightEndDate)
       {
         return true;
       }
       else
       {
         return false;
       }
    }

    flip = async() => 
    {
      this.setState({showDialog:true})
    }

    approveDialog = async() => {
      this.setState( {showDialog: false});  
      await this.processApproval();  
       
    }

    processApproval = async() =>
    {
  
         let type=this.state.type;
         let fullapprove=this.state.fullapprove;
         let outageID = this.state.event.eventId;
         let pmcontact = this.state.event.pmcontact;
         let rejectreason=this.state.rejectreasons;
         var correlationId = uuid.v1();
         this.setState({ approvalLoading: true});
         var apimUrl = window.env.APIMURL + "/api/outage/approveoutage/?outageid=";
        
        let url = apimUrl
                  + outageID + '&approvalflag=' + type + '&completeapproval=' + fullapprove + '&pmcontact=' + pmcontact  + '&rejectreason=' + rejectreason;
        var token = await fetchToken();  
        var bearer = 'Bearer ' + token;
           
        fetch(url, {
        method: 'PUT',
        headers: {
    
            'CorrelationId': correlationId,
            'Content-Type': 'application/json',
            'authorization': bearer,
          },
        }).then(response => {
     
        this.setState({ ReturnStatus: response.status});

        if(response.status !== 400)
        {           
          this.setState({
            approvedOutageID:outageID,   
            pendingRequests:this.state.pendingRequests.filter(x=>x.eventId!==outageID),     
            outageID :'',
            pmcontact:'',approvalLoading:false
          },this.successDialog());          
        }
        else
        {
            this.errorDialog();
        }
        
      }).catch(error =>
      {
      var err = 
      {
        "message": "Exception occured while approving the request ",
        "ComponentId" : COMPONENT_ID,
        "ComponentName" : COMPONENT_NAME,
        "EnvironmentName" : window.env.ENVIRONMENT,
        "ServiceLineName" : SERVICE_LINE,
        "ServiceName" : SERVICE_NAME,
         "ServiceOfferingName" : SERVICE_OFFERING,
        "Component": 'Welcome Page Approval activity',
         "userName": this.props.userEmail,
         "roles": this.props.role != null || '' ? this.props.role : "",
         "exception": error.message,
         "Correlation Id": correlationId
      }
       appInsights.trackException({exception: error,properties : err});  
   })
}


successDialog()
{ 
  this.setState( {showApprovalDialog: true});
}

errorDialog()
{  
  this.setState( {showErrorDialog: true,approvalLoading:false});
} 

 ApproveRejectEvent=async(param1,param2,data,rejectremarks)=>
{ 
  let showPopup;
  if(param1 == '1' && param2 == '0') 
  {
    showPopup = 'Approve';
  }
  else if(param1 == '0' && param2 =='0')
  {
    showPopup = 'Reject';
  }
  else if(param1 == '1' && param2 == '1')
  {
    showPopup = 'FullApprove';
  }
  
  this.setState({type:param1,fullapprove:param2,flipDialog:showPopup,event:data,rejectreasons:rejectremarks});
  this.ShowRemarksModal(null,false);
  if(data.eventId === null || data.eventId === "")
  {
    this.setState({showNoEventsApprovalsDialog:true});   
    return;
  }
 
  var shouldDelete = await this.flip();   
} 

cancelDialog = async () => {
  const { onClickCancel } = this.props;
  if (typeof onClickCancel === "function") { await onClickCancel(); }
  this.setState( {showDialog: false,outageid :'',
  pmcontact:''});
}

closeApprovalDialog = async () => {
  const { onClickCancel } = this.props;
  if (typeof onClickCancel === "function") { await onClickCancel(); }
  this.setState( {showApprovalDialog: false,outageid :'',
  pmcontact:''});
}
closeDialog = async() => {
  const { onClickCancel } = this.props;
  if (typeof onClickCancel === "function") { await onClickCancel(); }
  this.setState( {showErrorDialog:false,outageid :'',
    pmcontact:'' } );
}



    routeChange(event) {
      let path = `/calendar`;
      this.props.history.push(path,{
        myProp: new Date("2020-08-09"),
        start: new Date("2020-08-09"),
        end : new Date("2020-08-10"),
        foo: 'bar'
      });
    }

    handleClick = (event) =>
    {
      let path = `/calendar`;
      this.props.history.push(path,{
        myProp: new Date(event.startTime),
        start: new Date(event.startTime),
        end : new Date(event.endTime),
        foo: 'bar'
      });
    };

    fetchICMData = (props) => {
      var prop = this;
      switch(props) {
        case "design":
          return prop.props.serviceHealth.filter(x=>x.teamGroupName === 'Design' && x.recordType === 'ICM');
        case "plan":
          return prop.props.serviceHealth.filter(x=>x.teamGroupName === 'Plan' && x.recordType === 'ICM');
        case "source":
          return prop.props.serviceHealth.filter(x=>x.teamGroupName === 'Source' && x.recordType === 'ICM');
        case "make":
          return prop.props.serviceHealth.filter(x=>x.teamGroupName === 'Make' && x.recordType === 'ICM');
        case "deliver":
          return prop.props.serviceHealth.filter(x=>x.teamGroupName === 'Deliver' && x.recordType === 'ICM');
        case "crew":
          return prop.props.serviceHealth.filter(x=>x.teamGroupName === 'Customer Care Services' && x.recordType === 'ICM');
        case "enable":
          return prop.props.serviceHealth.filter(x=>x.teamGroupName === 'Enable' && x.recordType === 'ICM');

        default:
        return null;
      }
    }

    fetchADOData = (props) => {
      var prop = this;
      switch(props) {
        case "design":
          return prop.props.serviceHealth.filter(x=>x.teamGroupName === 'Design' && x.recordType === 'ADORelease');
        case "plan":
          return prop.props.serviceHealth.filter(x=>x.teamGroupName === 'Plan' && x.recordType === 'ADORelease');
        case "source":
          return prop.props.serviceHealth.filter(x=>x.teamGroupName === 'Source' && x.recordType === 'ADORelease');
        case "make":
          return prop.props.serviceHealth.filter(x=>x.teamGroupName === 'Make' && x.recordType === 'ADORelease');
        case "deliver":
          return prop.props.serviceHealth.filter(x=>x.teamGroupName === 'Deliver' && x.recordType === 'ADORelease');
        case "crew":
          return prop.props.serviceHealth.filter(x=>x.teamGroupName === 'Customer Care Services' && x.recordType === 'ADORelease');
        case "enable":
          return prop.props.serviceHealth.filter(x=>x.teamGroupName === 'Enable' && x.recordType === 'ADORelease');
        default:
        return null;
      }
    }

    fetchHealthData = (props) => {
      var prop = this;
      switch(props) {
        case "design":
          return prop.props.qos.filter(x=>x.teamGroupName === 'Design');
        case "plan":
          return prop.props.qos.filter(x=>x.teamGroupName === 'Plan');
        case "source":
          return prop.props.qos.filter(x=>x.teamGroupName === 'Source');
        case "make":
          return prop.props.qos.filter(x=>x.teamGroupName === 'Make');
        case "deliver":
          return prop.props.qos.filter(x=>x.teamGroupName === 'Deliver');
        case "crew":
          return prop.props.qos.filter(x=>x.teamGroupName === 'Customer Care Services');
        case "enable":
          return prop.props.qos.filter(x=>x.teamGroupName === 'Enable');
        default:
        return null;
      }
    }

    fetchHealthDataChildNodes = (props) => {
      var prop = this;
      switch(props) {
        case "design":
          return prop.props.qosChild.filter(x=>x.teamGroupName === 'Design');
        case "plan":
          return prop.props.qosChild.filter(x=>x.teamGroupName === 'Plan');
        case "source":
          return prop.props.qosChild.filter(x=>x.teamGroupName === 'Source');
        case "make":
          return prop.props.qosChild.filter(x=>x.teamGroupName === 'Make');
        case "deliver":
          return prop.props.qosChild.filter(x=>x.teamGroupName === 'Deliver');
        case "crew":
          return prop.props.qosChild.filter(x=>x.teamGroupName === 'Customer Care Services');
        case "enable":
          return prop.props.qosChild.filter(x=>x.teamGroupName === 'Enable');
        default:
        return null;
      }
    }

  RemoveEvent = (outageId) => {  
      const pointerToThis = this;    
      this.setState({
        pendingRequests:pointerToThis.state.pendingRequests.filter(x=>x.eventId !== outageId)}); 
      this.closeModalPopUp(false);
  }
  
  UpdateEvent = (event) => {      
    let prevEvents = this.state.pendingRequests;
    var values = prevEvents; 
    let currentEvents = prevEvents.findIndex((newevent)=>{return newevent.eventId === event.EventId})
    values[currentEvents] = event;
    values[currentEvents]["allDayEvent"] = event.AllDayEvent;
    values[currentEvents]["nacategory"] = event.NACategory;
    values[currentEvents]["naCategory"] = event.NACategory;
    values[currentEvents]["description"] = event.Description;
    values[currentEvents]["endTime"] = event.EndTime;
    values[currentEvents]["eventId"] = event.EventId;
    values[currentEvents]["externalContact"] = event.extContact;
    values[currentEvents]["factoryName"] = event.factory;
    values[currentEvents]["impact"] = event.Impact;
    values[currentEvents]["location"] = event.Location;
    values[currentEvents]["nextSteps"] = event.NextSteps;
    values[currentEvents]["serviceGroup"] = event.servicegroup;
    values[currentEvents]["startTime"] = event.StartTime;
    values[currentEvents]["teamGroup"] = event.teamgroup;
    values[currentEvents]["title"] = event.title;
    values[currentEvents]["pmcontact"] = event.pmContact;
    values[currentEvents]["timezone"] = event.Timezone;
    this.setState({pendingRequests:values,newTimeZone: event.Timezone});
    //this.closeModalPopUp(false);
  }

    handleChange = (event) =>
    {
     var prop = this;
     var filteredevents;
     if(event.target.value.toLowerCase() === "sap")
     {
       filteredevents = prop.props.events.filter(x=>x.naCategory === 'BusinessUser SAP');
       prop.setState({events : filteredevents,dropdownvalue :event.target.value})
     }
     else
     {
      filteredevents = prop.state.upcomingevents.filter(x=>x.naCategory !== 'BusinessUser SAP');
      prop.setState({events : filteredevents,dropdownvalue :event.target.value});
     }
    }

  

    handlePendingChange = (event) =>
    {
      var filteredevents;
     if(event.target.value.toLowerCase() === "sap")
     {
      filteredevents = this.state.pendingevents.filter(x=>x.naCategory === 'BusinessUser SAP');
       this.setState({pendingRequests : filteredevents,pendingdropdownvalue :event.target.value})
     }
     else
     {
      filteredevents = this.state.pendingevents.filter(x=>x.naCategory !== 'BusinessUser SAP');
      this.setState({pendingRequests : filteredevents,pendingdropdownvalue :event.target.value});
     }
    }

     updateInput(event){
      this.setState({username : event.target.value})
      }

      handleUploadImage(ev) {
        alert("Your file is being uploaded!");
    }
      
      

    componentWillUpdate(nextProps, nextState) {
      if(nextProps.events !== nextState.events)
      {
        $('#upcoming').dataTable().fnDestroy();
        $('#upcomingsap').dataTable().fnDestroy();
      }  

      if(this.props.role!=="Reader")
      {
      if(nextProps.pendingRequests !== nextState.pendingRequests)
      {
        $('#pending').dataTable().fnDestroy();
      } 
      if(nextProps.rejectedRequests !== nextState.rejectedRequests)
      {
        $('#rejected').dataTable().fnDestroy();
      }
      }    
    }

    async fetchPendingData(action)
  {
    // this.setState({ isFetching: true });
    var prop=this;
  var token = await fetchToken();
  var filteredEvents;
  var correlationId = uuid.v1();
  var bearer = 'Bearer ' + token;
  this.setState({isFetching:true});
  var upcomingEvents = window.env.APIMURL + "/api/outage/getallevents/?action=" + action.trim();
  var actionName='';

  switch(action.trim())
  {
    case "Upcoming Activities":
    actionName="upcoming";
    break;

    case "My Requests":
    actionName="pending";
    break;

    case "Rejected Requests":
    actionName="rejected";
    break;

    default:
    break;
  }
  //var upcoming = (action.trim() === "Upcoming Activities") ? true : false
  Promise.all([
        fetch(upcomingEvents,{
          method: 'GET',
          headers: {
              'authorization': bearer,
              'CorrelationId': correlationId,
              'Accept' : 'application/json',
              'Content-Type': 'application/json'
          }})])
      .then(result => Promise.all(
        result.map(v => v.json()),
        result.map(v =>this.setState({statusCode : v.status})),
        console.log("THIS IS FETCHED DATA: ", result)
        ))
      .then(result => 
      {
        
      if(actionName==="upcoming")
      {
        if(prop.props.role!=="Reader"){
          this.getOutagesFiltered();
        }
       // approach one
     

        // approach two
        // var apimUrl = window.env.APIMURL + "/api/outage/getoutages/?action=" + action.trim();;
        // // var apimUrl = window.env.APIMURL + "/api/outage/getupcomingevents";
        // // this.setState({ isFetching: true})
        // fetch(apimUrl,{
        //     method: 'GET',
        //     headers: {
        //         'authorization': bearer,
        //         'Accept' : 'application/json',
        //         'CorrelationId': correlationId,
        //         'Content-Type': 'application/json'
        //     }})
        //     .then(response => {
        //       const statusCode = response.status;
        //       const data = response.json();
        //       return Promise.all([statusCode, data]);          
        //     })       
        //     .then((res) => {
        //       //const { statusCode, data } = res;
        //       const statusCode = res[0];
        //       const data = res[1];                  
        //       this.setState({ upcomingEvents: data,
        //       });
        //       // console.log("THIS IS TESTING VAR "+ this.state.upcomingEvents)
        //       console.log("STRINGIFY "+ JSON.stringify(this.state.upcomingEvents))
    
              
        //   const sixtyDaysAgo = new Date();
        //   sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
    
        //   // Filter the events array to only include events from the past 60 days
        //   const eventsPastSixtyDays = this.state.upcomingEvents.filter((event) => {
        //     const eventDate = new Date(event.startTime);
        //     return eventDate >= sixtyDaysAgo && event.approvalStatus == '3'
        //   });
    
        // // Set the state to the filtered array
        // this.setState({ upcomingEvents: eventsPastSixtyDays});
        //     })
        //     .catch(error => {
        //       console.log('Error:', error);
        //       this.setState({ isFetching: false });
        //       alert('Error fetching data. Please try again later.');
        //     });
      }
      else if(actionName==="pending")
      {
        if(prop.props.role!=="Reader")
        {
          filteredEvents = ((prop.props.role === "BusinessUser SAP" || prop.props.role ==='BusinessUser SAPReader') ? result[0].pendingEvents.filter(x=>x.naCategory  === "BusinessUser SAP") : result[0].pendingEvents.filter(x=>x.naCategory !== "BusinessUser SAP"));
          this.setState({pendingRequests:filteredEvents,eventsByUser:result[0].eventsByUser,pendingevents:filteredEvents,isFetching:false});              
        }
      }
      else if(actionName==="rejected")
      {
        if(prop.props.role!=="Reader")
        {
          filteredEvents = ((prop.props.role === "BusinessUser SAP" || prop.props.role ==='BusinessUser SAPReader') ? result[0].rejectedEvents.filter(x=>x.naCategory  === "BusinessUser SAP") : result[0].rejectedEvents.filter(x=>x.naCategory !== "BusinessUser SAP"));
          this.setState({rejectedRequests:filteredEvents,eventsByUser:result[0].eventsByUser,rejectedevents:filteredEvents,isFetching:false});              
        }
      }})     
        .catch(error => {          
          var err = {
                "message": "Exception occured while fetching the getallevents from database",
                "ComponentId" : COMPONENT_ID,
                "ComponentName" : COMPONENT_NAME,
                "EnvironmentName" : window.env.ENVIRONMENT,
                "ServiceLineName" : SERVICE_LINE,
                "ServiceName" : SERVICE_NAME,
                "ServiceOfferingName" : SERVICE_OFFERING,
                "userName": this.props.userEmail,
                "roles": prop.props.role != null || '' ? prop.props.role : "",
                "exception": error.message,
                "CorrelationId": correlationId
               }
            appInsights.trackException({exception: error,properties : err});
            this.setState({errorMessage: error});
        })
}


getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === variable) {
          return decodeURIComponent(pair[1]);
      }
  }
  console.log('Query variable %s not found', variable);
}


async getOutagesFiltered(action) {

  //document.getElementsByClassName('selection-cell').setAttribute('aria-label', 'changeQuestionMessage');
  // this.setState({ isFetching: tru });
    appInsights.trackTrace({ message: 'pending requests bug fix.' },
                     {
                         userName: this.props.user.displayName,
                         roles: this.props.role != null || '' ? this.props.role : "",
                         "ComponentId" : COMPONENT_ID,
                         "ComponentName" : COMPONENT_NAME,
                         "EnvironmentName" : window.env.ENVIRONMENT,
                         "ServiceLineName" : SERVICE_LINE,
                         "ServiceName" : SERVICE_NAME,
                         "ServiceOfferingName" : SERVICE_OFFERING,
                         "Correlation Id": uuid.v1(),
                         "Component": 'Approval Page',
                     });
   
    var token = await fetchToken();
    var correlationId = uuid.v1();
    var bearer = 'Bearer ' + token;
    //var apimUrl = window.env.APIMURL + "/api/outage/getapprovals";
    var apimUrl = window.env.APIMURL + "/api/outage/getoutages"
    // var apimUrl = window.env.APIMURL + "/api/outage/getupcomingevents";
    // this.setState({ isFetching: true})
    fetch(apimUrl,{
        method: 'GET',
        headers: {
            'authorization': bearer,
            'Accept' : 'application/json',
            'CorrelationId': correlationId,
            'Content-Type': 'application/json'
        }})
        .then(response => {
          const statusCode = response.status;
          const data = response.json();
          return Promise.all([statusCode, data]);          
        })       
        .then((res) => {
          //const { statusCode, data } = res;
          const statusCode = res[0];
          const data = res[1];                  
          this.setState({ upcomingEvents: data,
          });

      //console.log("Length of upcoming events array: " + this.state.upcomingEvents.length)
      const sixtyDaysAgo = new Date();
      sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);

      // Filter the events array to only include events from the past 60 days & not display events from CREW holiday calendar
      const eventsPastSixtyDays = this.state.upcomingEvents.filter((event) => {
        const eventDate = new Date(event.startTime);
        return eventDate >= sixtyDaysAgo && event.approvalStatus == '3' && event.naCategory !== 'CREW Holiday Calendar'
      });

    // Set the state to the filtered array
    this.setState({ upcomingEvents: eventsPastSixtyDays, isFetching:false });
        })
        .catch(error => {

          var err = {
            "message": "Exception occured Approval page while getting the approvals for the logged in user",
            "ComponentId" : COMPONENT_ID,
                         "ComponentName" : COMPONENT_NAME,
                         "EnvironmentName" : window.env.ENVIRONMENT,
                         "ServiceLineName" : SERVICE_LINE,
                         "ServiceName" : SERVICE_NAME,
                         "ServiceOfferingName" : SERVICE_OFFERING,
            "Component": 'Approval Page',
             "userName": this.props.userEmail,
             "roles": this.props.role != null || '' ? this.props.role : "",
             "exception": error.message,
             "Correlation Id": correlationId
          }
            appInsights.trackException({exception: error,properties : err});  
        })
      
    
}
    

componentDidMount = () =>
    {  
      // this.getOutagesFiltered();
      var prop = this;
      var filteredEvents = (prop.props.role === "BusinessUser SAP") ? prop.state.events.filter(x=>x.naCategory  === "BusinessUser SAP") : prop.state.events.filter(x=>x.naCategory !== "BusinessUser SAP");  
      this.setState({events:filteredEvents});
      var queryString = this.getQueryVariable('id');
      var queryEvent = this.getQueryVariable('event');
      var id = '#' + 'pills-'+queryEvent+'-tab';
      if(queryString!== null && queryString!== undefined)
      {
        $(id).tab('show');
      } 
      var prop = this;
     
        $('#myTabs a[data-toggle="tab"]').on( 'shown.bs.tab', function (e) {
            var currentTab = $(e.target).text();
            currentTab = currentTab.toLowerCase().trim()            
            var icmEvents = prop.fetchICMData(currentTab);
            var adoEvents = prop.fetchADOData(currentTab);
            var healthEvents = prop.fetchHealthData(currentTab);
            var healthEventsChildData=prop.fetchHealthDataChildNodes(currentTab);
            prop.setState({serviceHealth:icmEvents,QoS:healthEvents,QosChild:healthEventsChildData,adoRelease:adoEvents});             
        }); 
      $('#pills-tab a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        var currentTab = $(e.target).text();
         if(currentTab.trim() !== "Service Availability"  && currentTab.trim() !== "SAP Release Schedule")
         {
            var filterValue = (prop.props.role === "BusinessUser SAP" || prop.props.role ==="BusinessUser SAPReader") ? "SAP" :"SCE";
            prop.setState({dropdownvalue:filterValue});
            prop.fetchPendingData(currentTab,prop.props.role);
         }
        });  
                  
     

        if(prop.props.role!=="Reader")  // Pending Requests , Rejected Requests tab are not visible for reader role . 
        {
          if ( $.fn.dataTable.isDataTable('#pending')) 
          {
            $('#pending').DataTable();
          }
          else 
          {
            var pendingTable =  $('#pending').DataTable({
            pageLength : 5,
            lengthMenu: [[5, 10, 20, -1], [5, 10, 20, 'Todos']],
            //"bPaginate": false,
            "bLengthChange": false,
            "bDestroy": true, 
            "bFilter": true,
            'searchable': true,
            "autoWidth": false,
            stateSave: true,
            stateDuration:-1,
            initComplete: function () {                    
             this.api().page(0).draw('page');
            },
            "fnDrawCallback": function() {                
              prop.forceUpdate();         
            }                       
          });
          
          if(queryString!== null && queryString!== undefined)
            {
              pendingTable.search(queryString).draw(); 
            }  

          $('#pending tbody').on('click', 'td.details-control', function(){
            var tr = $(this).closest('tr');
            var row = pendingTable.row( tr );
            
          if ( row.child.isShown() ) {
              // This row is already open - close it
              row.child.hide();
              tr.removeClass('shown');
          }
          else {
              // Open this row
              var parentrow = row.data();
              var tabFormat = '<table><caption>Approver Details</caption>';  
              if(prop.props.role==='Writer' || prop.props.role==='Reader')
              {                   
                tabFormat = tabFormat +'<tr><td>Approver Contact :</td><td>'+parentrow[7]+'' +
                // '<button type="submit" id = "pendingsave"><i class="fas fa-save"></i> Save</button></td>' +
                '</td></tr>' +'<tr><td>Event Id :</td><td>'+parentrow[6]+'</td></tr>';                               
                row.child(tabFormat+'</table>').show();              
                tr.addClass('shown'); 
              }
              else
              {                 
                tabFormat = tabFormat +'<tr><td>Approver Contact :</td><td>'+parentrow[8]+'' +
                // '<button type="submit" id = "pendingsave"><i class="fas fa-save"></i> Save</button></td>' +
                '</td></tr>' +'<tr><td>Event Id :</td><td>'+parentrow[7]+'</td></tr>';                               
                row.child(tabFormat+'</table>').show();              
                tr.addClass('shown');      
              }         
          }
        });

        $('#pending tbody').on('keydown',  'td.details-control',function(e) {
          const keyD = e.key !== undefined ? e.key : e.keyCode;
          // e.key && e.keycode have mixed support - keycode is deprecated but support is greater than e.key
          // I tested within IE11, Firefox, Chrome, Edge (latest) & all had good support for e.key
        
            if ( (keyD === 'Enter' || keyD === 13) || (['Spacebar', ' '].indexOf(keyD) >= 0 || keyD === 32)) {
            // In IE11 and lower, e.key will equal "Spacebar" instead of ' '
        
            // Default behavior is prevented to prevent the page to scroll when "space" is pressed
            e.preventDefault();
            this.click();
          }
        });
        

        // Edit Column Section

        }

         if ( $.fn.dataTable.isDataTable('#rejected')) 
          {
             $('#rejected').DataTable();          
          }
        else {
           var rejectedtable =   $('#rejected').DataTable({           
              pageLength : 5,        
              lengthMenu: [[5, 10, 20, -1], [5, 10, 20, 'Todos']],
              "bLengthChange": false,
              "bDestroy": true,
              "bFilter": true,
              "bAutoWidth": false,
              "searchable": true,
               stateSave: true,
               stateDuration:-1,
               initComplete: function () {                    
                this.api().page(0).draw('page');
            }
            }); 

            if(queryString!== null && queryString!== undefined)
            {
              rejectedtable.search(queryString).draw(); 
            }  


            $('#rejected tbody').on('click', 'td.details-control', function(){
              var tr = $(this).closest('tr');
              var row = rejectedtable.row( tr );
              
            if ( row.child.isShown() ) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            }
            else {
                // Open this row
                var parentrow = row.data();
                var tabFormat = '<table><caption>Rejection Details</caption>';  
                              
                  tabFormat = tabFormat +'<tr><td>Event Id :</td><td>'+parentrow[8]+'</td></tr>' + 
                  '<tr><td>Rejected By :</td><td className = "editColumn">'+parentrow[9]+'' + '</td></tr>' +
                  '<tr><td>Reject Reason :</td><td>'+parentrow[10]+'</td></tr>' ;                           
                  row.child(tabFormat+'</table>').show();              
                  tr.addClass('shown');      
                        
            }
          });

          $('#rejected tbody').on('keydown',  'td.details-control',function(e) {
            const keyD = e.key !== undefined ? e.key : e.keyCode;
            // e.key && e.keycode have mixed support - keycode is deprecated but support is greater than e.key
            // I tested within IE11, Firefox, Chrome, Edge (latest) & all had good support for e.key
          
              if ( (keyD === 'Enter' || keyD === 13) || (['Spacebar', ' '].indexOf(keyD) >= 0 || keyD === 32)) {
              // In IE11 and lower, e.key will equal "Spacebar" instead of ' '
          
              // Default behavior is prevented to prevent the page to scroll when "space" is pressed
              e.preventDefault();
              this.click();
            }
          });
                         
        }

         }

         if ( $.fn.dataTable.isDataTable('#upcoming')) 
         {
            $('#upcoming').DataTable();          
         }
       else {
         var upcomingTable =  $('#upcoming').DataTable({
           pageLength : 5,
           lengthMenu: [[5, 10, 20, -1], [5, 10, 20, 'Todos']],
           //"bPaginate": false,
           "bLengthChange": false,
           "bDestroy": true, 
           "bFilter": true,
           'searchable': true,
           "autoWidth": false,
           stateSave: true,
           stateDuration:-1,
           initComplete: function () {                    
            this.api().page(0).draw('page');
           },
           "fnDrawCallback": function() {                
             prop.forceUpdate();         
           }                       
         });
          
           if(queryString!== null && queryString!== undefined)
           {
             upcomingTable.search(queryString).draw(); 
           }          
       } 


       if ( $.fn.dataTable.isDataTable('#upcomingsap')) 
       {
          $('#upcomingsap').DataTable();          
       }
     else {
       var upcomingsapTable =  $('#upcomingsap').DataTable({
         pageLength : 5,
         lengthMenu: [[5, 10, 20, -1], [5, 10, 20, 'Todos']],
         //"bPaginate": false,
         "bLengthChange": false,
         "bDestroy": true, 
         "bFilter": true,
         'searchable': true,
         "autoWidth": false,
         stateSave: true,
         stateDuration:-1,
         initComplete: function () {                    
          this.api().page(0).draw('page');
         },
         "fnDrawCallback": function() {                
           prop.forceUpdate();         
         }                       
       });
        
         if(queryString!== null && queryString!== undefined)
         {
           upcomingsapTable.search(queryString).draw(); 
         }
        
        }

    }

     componentDidUpdate = () =>
    {  
          
           var table2= $('#upcoming').DataTable({
              pageLength : 5,
              lengthMenu: [[5, 10, 20, -1], [5, 10, 20, 'Todos']],
              "bLengthChange": false,
              "bFilter": true,
              "bAutoWidth": false,
              stateSave: true,
              stateDuration:-1              
            });  
            
            var table3= $('#upcomingsap').DataTable({
              pageLength : 5,
              lengthMenu: [[5, 10, 20, -1], [5, 10, 20, 'Todos']],
              "bLengthChange": false,
              "bFilter": true,
              "bAutoWidth": false,
              stateSave: true,
              stateDuration:-1
              
            });  

           if( this.props.role!=="Reader")
           {
                var table =  $('#pending').DataTable({
            pageLength : 5,
            lengthMenu: [[5, 10, 20, -1], [5, 10, 20, 'Todos']],
            //"bPaginate": false,
            "bLengthChange": false,
            "bFilter": true,
            "dom": '<"toolbar">frtip',
            "bAutoWidth": false,
             stateSave: true,
             stateDuration:-1
             
                });    
                $("div.toolbar").html('<label>Pending Requests</label>');

                var table1=$('#rejected').DataTable({
            pageLength : 5,
            lengthMenu: [[5, 10, 20, -1], [5, 10, 20, 'Todos']],
            //"bPaginate": false,
            "bLengthChange": false,
            "bFilter": true,
            "dom": '<"toolbar">frtip',
            "bAutoWidth": false,
             stateSave: true,
             stateDuration:-1
             
                });    
           }
           

    }

    buildOptions(){
      var arr = [];
      var selectValues = [{
        "0": "My View"},
        {"1": "SAP"},
        {"2": "Partner"},
        {"3": "Channel Managers"},
      ];
      for (let i = 0; i < selectValues.length; i++) {
          var newValue = selectValues[i];
          newValue = newValue[i];
          arr.push(<option key={i} value={newValue}>{newValue}</option>)
      }

      return arr; 
  }

 
   render(){
      const { showAddEventModal,showEditModal,event,showRejectModal,L1Approvers,showApprovalModal} = this.state;
      let optionTemplate = this.props.filters["InternalView"].map(v => (
        <option value={v.value}>{v.name}</option>
      ));
      let title,subText,okButtonLabel,cancelButtonLabel,internalView,externalView;
      let validRole = getValidRolePendingReqTab(this.props.role);
      let internalUser = this.props.internalUser;
      let AddEvent = null,EditEvent = null,DisplayApproval=null;
      internalView = 'tab-pane fade show active';
      externalView = 'tab-pane fade';

      let isL1ApproverRole=false;
    
    for (var i = 0; i < this.state.L1Approvers.length; i++) 
    {
         if(this.props.user.email.toLowerCase()===this.state.L1Approvers[i]["userName"].toLowerCase())
         {
          isL1ApproverRole=true;
          break;
         }
    }
     
      if (this.props.user.displayName === undefined)
      {
      return <CustomSpinner class="ms-Spinner ms-Spinner--large" label="Loading" />;  
      }
        
      if(this.state.showDialog && this.state.flipDialog === 'Approve')
      {
        title = 'Confirmation Dialog';
        subText = 'Are you sure you want to approve this event?';
        okButtonLabel = 'L1 Approve';
        cancelButtonLabel = 'Cancel';
  
      } else if (this.state.showDialog && this.state.flipDialog === 'Reject')
      {
        title = 'Confirmation Dialog';
        subText = 'Are you sure you want to reject this event?';
        okButtonLabel = 'Reject';
        cancelButtonLabel = 'Cancel';
      }
      else if (this.state.showDialog && this.state.flipDialog === 'FullApprove')
    {
      title = 'Confirmation Dialog';
      subText = 'Are you sure you want to complete this event?';
      okButtonLabel = 'L2 Approve';
      cancelButtonLabel = 'Cancel';
    
    }
    if(showAddEventModal)
    {
      if(this.props.role === 'BusinessUser ChannelManagers')
      {
        AddEvent =
          <AddChannelEventModalPopUp
          show={this.state.showAddEventModal}
          submitModalPopUpFunc = {this.handleAddEventModalPopUp} 
          userEmail = {this.props.user.email}
          role = {this.props.role}  
          addEvent = {this.handleAddEvent}        
      />
      }
      else if(this.props.role === 'BusinessUser SAP' || this.props.role==='BusinessUser SAPReader')
      {
        AddEvent =  <AddSAPReleaseEventModalPopUp 
        show={this.state.showAddEventModal}  
        event = {this.state.event}
        submitModalPopUpFunc = {this.handleAddEventModalPopUp}
        userEmail = {this.props.user.email}
        role = {this.props.role}
        addEvent = {this.handleAddEvent} 
        />
      }
      else{
        AddEvent =
            <AddEventModalPopUp
            show={this.state.showAddEventModal}
            submitModalPopUpFunc = {this.handleAddEventModalPopUp} 
            userEmail = {this.props.user.email}
            role = {this.props.role}  
            addEvent = {this.handleAddEvent}        
        />
      }
    }
      if(showEditModal)
      {
        let role = this.props.role;
        let previousRole = event["nacategory"];
        let extContact=this.state.event.extContact;
        
        if(role === "BusinessUser ChannelManagers") 
        {
          if(previousRole === "BusinessUser ChannelManagers")
          {
            EditEvent =
            <EditChannelEventModalPopUp
            show={this.state.showEditModal}
            userEmail = {this.props.user.email}
            role = {this.props.role}  
            event={this.state.event}  
            closeModalPopUpFunc = {this.closeModalPopUp}      
            removeEvent = {this.RemoveEvent} 
            updateEvent = {this.UpdateEvent}
            
            />
          }
          else if (previousRole === "BusinessUser SAP" || previousRole==='BusinessUser SAPReader')
           {
            EditEvent = <EditSAPReleaseEventModalPopUp
            show= {this.state.showEditModal}
            event = {this.state.event}
            closeModalPopUpFunc = {this.closeModalPopUp} 
            userEmail = {this.props.user.email}
            role = {this.props.role}
            removeEvent = {this.RemoveEvent}  
            updateEvent = {this.UpdateEvent}     
            
            /> 
           }
           else if(previousRole==="CREW Holiday Calendar")  // Crew Holiday Calendar Event
           {
            EditEvent =
            <EditCREWHolidayEvent
            show={this.state.showEditModal}
            userEmail = {this.props.user.email}
            role = {this.props.role}  
            event={this.state.event}  
            closeModalPopUpFunc = {this.closeModalPopUp}     
            removeEvent = {this.RemoveEvent} 
            updateEvent = {this.UpdateEvent}                  
            />
           }
           else if(previousRole==="N.A" && extContact!==null )  // partner event
           {
            EditEvent =
            <EditModalPopUp
            show={this.state.showEditModal}
            userEmail = {this.props.user.email}
            role = {this.props.role}  
            event={this.state.event}  
            closeModalPopUpFunc = {this.closeModalPopUp}     
            removeEvent = {this.RemoveEvent} 
            updateEvent = {this.UpdateEvent}                  
            />
           }          
            else
            {
              EditEvent =
              <EditModalPopUp
              show={this.state.showEditModal}
              userEmail = {this.props.user.email}
              role = {this.props.role}  
              event={this.state.event}  
              closeModalPopUpFunc = {this.closeModalPopUp}      
              removeEvent = {this.RemoveEvent} 
              updateEvent = {this.UpdateEvent}                    
          />
            }
          }
        else if(role === "BusinessUser SAP" || role==='BusinessUser SAPReader')
        {
          if(previousRole === "BusinessUser ChannelManagers")
          {
            EditEvent =
            <EditChannelEventModalPopUp
            show={this.state.showEditModal}
            userEmail = {this.props.user.email}
            role = {this.props.role}  
            event={this.state.event}  
            closeModalPopUpFunc = {this.closeModalPopUp}      
            removeEvent = {this.RemoveEvent} 
            updateEvent = {this.UpdateEvent}
            
            />
          }
          else if (previousRole === "BusinessUser SAP" || previousRole==='BusinessUser SAPReader')
           {
            EditEvent = <EditSAPReleaseEventModalPopUp
            show= {this.state.showEditModal}
            event = {this.state.event}
            closeModalPopUpFunc = {this.closeModalPopUp} 
            userEmail = {this.props.user.email}
            role = {this.props.role}
            removeEvent = {this.RemoveEvent}  
            updateEvent = {this.UpdateEvent}    
            
            /> 
           }
           else if(previousRole==="CREW Holiday Calendar")  // Crew Holiday Calendar Event
           {
            EditEvent =
            <EditCREWHolidayEvent
            show={this.state.showEditModal}
            userEmail = {this.props.user.email}
            role = {this.props.role}  
            event={this.state.event}  
            closeModalPopUpFunc = {this.closeModalPopUp}     
            removeEvent = {this.RemoveEvent} 
            updateEvent = {this.UpdateEvent}                  
            />
           }
           else if(previousRole==="N.A" && extContact!==null )  // partner event
           {
            EditEvent =
            <EditModalPopUp
            show={this.state.showEditModal}
            userEmail = {this.props.user.email}
            role = {this.props.role}  
            event={this.state.event}  
            closeModalPopUpFunc = {this.closeModalPopUp}      
            removeEvent = {this.RemoveEvent} 
            updateEvent = {this.UpdateEvent}  
                  
            />
           }
           else
           {
            EditEvent =
            <EditModalPopUp
            show={this.state.showEditModal}
            userEmail = {this.props.user.email}
            role = {this.props.role}  
            event={this.state.event}  
            closeModalPopUpFunc = {this.closeModalPopUp}      
            removeEvent = {this.RemoveEvent} 
            updateEvent = {this.UpdateEvent} 
            
        />
           }
        }  
        
        else
        {
          if(previousRole === "BusinessUser ChannelManagers")
          {
            EditEvent =
            <EditChannelEventModalPopUp
            show={this.state.showEditModal}
            userEmail = {this.props.user.email}
            role = {this.props.role}  
            event={this.state.event}  
            closeModalPopUpFunc = {this.closeModalPopUp}      
            removeEvent = {this.RemoveEvent} 
            updateEvent = {this.UpdateEvent}
            
            />
          }
          else if (previousRole === "BusinessUser SAP" || previousRole==='BusinessUser SAPReader')
           {
            EditEvent = <EditSAPReleaseEventModalPopUp
            show= {this.state.showEditModal}
            event = {this.state.event}
            closeModalPopUpFunc = {this.closeModalPopUp} 
            userEmail = {this.props.user.email}
            role = {this.props.role}
            removeEvent = {this.RemoveEvent}  
            updateEvent = {this.UpdateEvent} 
            
            /> 
           }
           else if(previousRole==="CREW Holiday Calendar")  // Crew Holiday Calendar Event
           {
            EditEvent =
            <EditCREWHolidayEvent
            show={this.state.showEditModal}
            userEmail = {this.props.user.email}
            role = {this.props.role}  
            event={this.state.event}  
            closeModalPopUpFunc = {this.closeModalPopUp}     
            removeEvent = {this.RemoveEvent} 
            updateEvent = {this.UpdateEvent}                  
            />
           }

           else if(previousRole==="N.A" && extContact!==null) // partner event
           {
            EditEvent =
            <EditModalPopUp
            show={this.state.showEditModal}
            userEmail = {this.props.user.email}
            role = {this.props.role}  
            event={this.state.event}  
            closeModalPopUpFunc = {this.closeModalPopUp}      
            removeEvent = {this.RemoveEvent} 
            updateEvent = {this.UpdateEvent}             
            />
           }
           else
           {
            EditEvent =
            <EditModalPopUp
            show={this.state.showEditModal}
            userEmail = {this.props.user.email}
            role = {this.props.role}  
            event={this.state.event}  
            closeModalPopUpFunc = {this.closeModalPopUp}      
            removeEvent = {this.RemoveEvent} 
            updateEvent = {this.UpdateEvent} 
                
        />
           }
        }         
      }

    if (showApprovalModal)
    {
      if(event['outageType']==='UnPlanned' || event['nacategory']==='CREW Holiday Calendar')
      {
      DisplayApproval =
      <ApprovalFlow
      show={this.state.showApprovalModal}
      submitModalPopUpFunc = {this.handleApprovalModalPopUp} 
      userEmail = {this.props.user.email}
      role = {this.props.role} 
      eventcreatedata={this.state.eventcreate}
      eventrejectdata={this.state.eventreject}
      eventapprovedata={this.state.eventapprove}
      eventl2approverdata={this.state.eventapprover}
      closeModalPopUpFunc = {this.closeApprovalModalPopUp} 
      event={this.state.event}        
      />
      }
      else if(event['outageType']==='Planned') 
      {
        if( event['factory']==="JDM1" && event['extContact']===null)
      {
      DisplayApproval =
      <MultipleApprovalFlow
      show={this.state.showApprovalModal}
      submitModalPopUpFunc = {this.handleApprovalModalPopUp} 
      userEmail = {this.props.user.email}
      role = {this.props.role} 
      eventcreatedata={this.state.eventcreate}
      eventrejectdata={this.state.eventreject}
      eventl1approvedata={this.state.eventl1approve}
      eventl2approvedata={this.state.eventl2approve}
      eventl2approverdata={this.state.eventassignedl2approver}
      eventl1approverdata={this.state.eventassignedl1approver}
      closeModalPopUpFunc = {this.closeApprovalModalPopUp} 
      event={this.state.event}        
      />
      }
      else if(event['factory']==="JDM1" && event['extContact']!==null)
      {
        DisplayApproval =
      <JDM1FactoryApprovalFlow
      show={this.state.showApprovalModal}
      submitModalPopUpFunc = {this.handleApprovalModalPopUp} 
      userEmail = {this.props.user.email}
      role = {this.props.role} 
      eventcreatedata={this.state.eventcreate}
      eventrejectdata={this.state.eventreject}
      eventl1aapprovedata={this.state.eventl1aapprove}
      eventl1bapprovedata={this.state.eventl1bapprove}
      eventl2approvedata={this.state.eventl2approve}
      eventl2approverdata={this.state.eventassignedl2approver}
      eventl1approverdata={this.state.eventassignedl1approver}
      closeModalPopUpFunc = {this.closeApprovalModalPopUp}   
      event={this.state.event}      
      />

      } 
      else
      {
        DisplayApproval =
      <MultipleApprovalFlow
      show={this.state.showApprovalModal}
      submitModalPopUpFunc = {this.handleApprovalModalPopUp} 
      userEmail = {this.props.user.email}
      role = {this.props.role} 
      eventcreatedata={this.state.eventcreate}
      eventrejectdata={this.state.eventreject}
      eventl1approvedata={this.state.eventl1approve}
      eventl2approvedata={this.state.eventl2approve}
      eventl2approverdata={this.state.eventassignedl2approver}
      eventl1approverdata={this.state.eventassignedl1approver}
      closeModalPopUpFunc = {this.closeApprovalModalPopUp} 
      event={this.state.event}        
      />

      }
    }
    }  
        return(
                  <Fragment>               
                  <div className="row" role="main" aria-label="sampmainlandmark">
                  <div className="col-md-12"  aria-labelledby='mainbanner'>
                    <h1 className = "portal-name">Service Availability & Management Portal</h1>
                    <hr/>
                    </div>                    
                  
                 
                  { internalUser === true ? 
                  <div id="mainmenu_internaluser" role="navigation" aria-labelledby="mainnavigationmenu">
                  <ul className="nav nav-tabs" id="pills-tab" role="tablist">                 
                  <li className="nav-item" role="tablist" aria-labelledby="pills-health-tab">
                  <a className="nav-link active"  id="pills-health-tab" data-toggle="tab" href="#pills-health" role="tab" aria-controls="pills-health" aria-selected="false">
                    <span style = {{"fontSize": "1.1rem"}}>
                      <img className = "text-muted" alt = "Service Availability" aria-label="Service Availability" src={Health} style={{width: '30px'}}/>
                      &nbsp;Service Availability
                    </span>
                  </a>
                  </li>
                   <li className="nav-item" role="tablist" aria-labelledby="pills-upcoming-tab">
                   <a className="nav-link" id="pills-upcoming-tab" data-toggle="tab" href="#pills-upcoming" role="tab" aria-controls="pills-upcoming" aria-selected="false"><span style = {{"fontSize": "1.1rem"}}><img className = "text-muted" alt = "Upcoming Activities" aria-label="Upcoming Activities" src={Approved} style={{width: '25px'}}/>&nbsp;Upcoming Activities</span></a>
                   </li> 
              {(this.props.role === 'Admin' || this.props.role === 'Approver' || this.props.role==='Writer') ?                                       
              <li className="nav-item" role="tablist" aria-labelledby="pills-pending-tab">
                 <a className="nav-link" id="pills-pending-tab" data-toggle="tab" href="#pills-pending" role="tab" aria-controls="pills-pending" aria-selected="false"><span style = {{"fontSize": "1.1rem"}}><img className = "text-muted" alt = "My Requests" aria-label="My Requests" src={Pending} style={{width: '25px'}}/>&nbsp;My Requests{this.state.pendingRequests.length > 0 && <span class="badge"><i class="fa fa-exclamation" style ={{'color':'red'}} aria-hidden="true"></i></span>}</span></a>
              </li>:null}
              {(this.props.role==='BusinessUser SAP' || this.props.role==='BusinessUser SAPReader') ?
                <li className="nav-item" role="tablist" aria-labelledby="pills-saprelease-tab">
                 <a className="nav-link" id="pills-saprelease-tab" data-toggle="tab" href="#pills-saprelease" role="tab" aria-controls="pills-saprelease" aria-selected="false"><span style = {{"fontSize": "1.1rem"}}><img className = "text-muted" alt = "SAP Release Schedule" aria-label="SAP Release Schedule" src={Pending}  style={{width: '25px'}}/>&nbsp;SAP Release Schedule</span></a>
                </li>
                :null}
              {(this.props.role === 'Admin' || this.props.role === 'Approver' || this.props.role==='Writer') ? 
              <li className="nav-item" role="tablist" aria-labelledby="pills-rejected-tab">
               <a className="nav-link" id="pills-rejected-tab" data-toggle="tab" href="#pills-rejected" role="tab" aria-controls="pills-rejected" aria-selected="false"><span style = {{"fontSize": "1.1rem"}}><img className = "text-muted" alt = "Rejected Requests" aria-label="Rejected Requests" src={Pending} style={{width: '25px'}}/>&nbsp;Rejected Requests</span></a>
              </li>:null}
               <li className="nav-item" role="tab" aria-labelledby="pills-dummy-tab"></li>
              </ul> </div>
              : //partners 
              <div id="mainmenu_partner" role="navigation" aria-labelledby="mainnavigationmenu">
              <ul className="nav nav-tabs" id="pills-tab" role="tablist">
               <li className="nav-item" role="tablist" aria-labelledby="pills-health-tab">
                  <a className="nav-link active" id="pills-health-tab" data-toggle="tab" href="#pills-health" role="tab" aria-controls="pills-health" aria-selected="false"><span style = {{"fontSize": "1.1rem"}}><img className = "text-muted" alt = "Service Availability" aria-label="Service Availability" src={Health} style={{width: '30px'}}/>&nbsp;Service Availability</span></a>
                  </li> 
               <li className="nav-item" role="tablist" aria-labelledby="pills-upcoming-tab">
               <a className="nav-link" id="pills-upcoming-tab" data-toggle="tab" href="#pills-upcoming" role="tab" aria-controls="pills-upcoming" aria-selected="true"><span style = {{"fontSize": "1.1rem"}}><img className = "text-muted" alt = "Upcoming Activities" aria-label="Upcoming Activities" src={Approved} style={{width: '25px'}}/>&nbsp;Upcoming Activities</span></a>
               </li>                               
              <li className="nav-item" role="tablist" aria-labelledby="pills-pending-tab">
               <a className="nav-link" id="pills-pending-tab" data-toggle="tab" href="#pills-pending" role="tab" aria-controls="pills-pending" aria-selected="false"><span style = {{"fontSize": "1.1rem"}}><img className = "text-muted" alt = "My Requests" aria-label="My Requests" src={Pending} style={{width: '25px'}}/>&nbsp;My Requests{this.state.pendingRequests.length > 0 && <span class="badge"><i class="fa fa-exclamation" style ={{'color':'red'}} aria-hidden="true"></i></span>}</span></a>
              </li>
              <li className="nav-item" role="tablist" aria-labelledby="pills-rejected-tab">
               <a className="nav-link" id="pills-rejected-tab" data-toggle="tab" href="#pills-rejected" role="tab" aria-controls="pills-rejected" aria-selected="false"><span style = {{"fontSize": "1.1rem"}}><img className = "text-muted" alt = "Rejected Requests" aria-label="Rejected Requests" src={Pending} style={{width: '25px'}}/>&nbsp;Rejected Requests</span></a>
              </li> 
              <li className="nav-item" role="tab" aria-labelledby="pills-dummy-tab"></li>
              </ul></div>}
           
           
           <div className="tab-content" id="pills-tabContent">
               
                  
                <div className={internalView} id="pills-health" role="tabpanel" aria-labelledby="pills-health-tab">
                 <div className="row">
                 <div className ="col-md-12" style ={{'padding':'20px'}}> 
                 {/* <div className = "inline-headers" ><h4 className = "portal-name" style = {{"fontSize": "1.1rem"}}>Service Availability View</h4></div> */}
                 
                 {/* <div className="row" style ={{'padding':'10px'}}>
                 <div class="col-sm-7"></div>
                 <div class="col-sm-5">&nbsp;&nbsp;<span><img className = "text-muted" alt = "Check Logo" aria-label="Check Logo" src={check} style={{width: '20px'}}/>&nbsp;Available</span> &nbsp;
                 <span><img className = "text-muted" alt = "Check Logo" aria-label="Check Logo" src={info} style={{width: '20px'}}/>&nbsp;Degraded</span> &nbsp;
                 <span><img className = "text-muted" alt = "Check Logo" aria-label="Check Logo" src={fill} style={{width: '20px'}}/>&nbsp;Unavailable</span>
                 </div>
                 </div> */}
                 
                 <ul style ={{'padding':'10px'}} id="myTabs" className="nav nav-tabs" role="tablist">
                   <li className="nav-item" role="tablist">
                  <a className="nav-link active" href="#design" role="tab" data-toggle="tab" aria-expanded="false">
                  <span><img className = "text-muted" alt = "Design Logo" aria-label="Design Logo" src={Design_New} style={{width: '20px'}}/>&nbsp;<span className = "text-dark">Design</span></span>
                   </a></li>
                 <li className="nav-item" role="tablist"><a className="nav-link" href="#profile" role="tab" data-toggle="tab" aria-expanded="true">
                 <span><img className = "text-muted" alt = "Plan Logo" aria-label="Plan Logo" src={Plan_New} style={{width: '20px'}}/>&nbsp;<span className = "text-dark">Plan</span></span>
                 </a></li>
                 <li className="nav-item" role="tablist"><a class="nav-link" href="#profile" role="tab" data-toggle="tab">
                 <span><img className = "text-muted" alt = "Source Logo" aria-label="Source Logo" src={Source_New} style={{width: '20px'}}/>&nbsp;<span className = "text-dark">Source</span></span>
                 </a></li>
                 <li className="nav-item" role="tablist"><a className="nav-link" href="#profile" role="tab" data-toggle="tab">
                 <span><img className = "text-muted" alt = "Make Logo" aria-label="Make Logo" src={Make_New} style={{width: '30px'}}/>&nbsp;<span className = "text-dark">Make</span></span>
                 </a></li>
                 <li className="nav-item" role="tablist"><a className="nav-link" href="#profile" role="tab" data-toggle="tab">
                 <span><img className = "text-muted" alt = "Deliver Logo" aria-label="Deliver Logo" src={Deliver_New} style={{width: '30px'}}/>&nbsp;<span className = "text-dark">Deliver</span></span>
                 </a></li>
                 <li className="nav-item" role="tablist"><a className="nav-link" href="#profile" role="tab" data-toggle="tab">
                 <span><img className = "text-muted" alt = "Crew Logo" aria-label="Crew Logo" src={Care_New} style={{width: '20px'}}/>&nbsp;<span className = "text-dark">Crew</span></span>
                 </a></li>
                 <li className="nav-item" role="tablist"><a className="nav-link" href="#profile" role="tab" data-toggle="tab">
                 <span><img className = "text-muted" alt = "Enable Logo" aria-label="Enable Logo" src={Enable_New} style={{width: '30px'}}/>&nbsp;<span className = "text-dark">Enable</span></span>
                 </a></li>
                 <li className="nav-item" role="tab"/>
                 </ul>
                 
                
                   <div className="tab-content">
                   <div className="tab-pane active" id="design">
                  
                   <QoS events = { this.state.QoS !== null ? this.state.QoS : this.props.qos.filter(x=>x.teamGroupName === 'Design')} 
                        child = {this.state.QoSChild !== null ? this.state.QoSChild : this.props.qosChild}></QoS>  
                   {internalUser === true &&
                   <ServiceHealth events = { this.state.serviceHealth !== null ? this.state.serviceHealth : this.props.serviceHealth.filter(x=>x.teamGroupName === 'Design' && x.recordType === 'ICM')}></ServiceHealth>}                   
                  {internalUser === true && <FCM events = { this.state.adoRelease !== null ? this.state.adoRelease : this.props.serviceHealth.filter(x=>x.teamGroupName === 'Design' && x.recordType === 'ADORelease')}></FCM>}
                  </div>
                   </div>

                 </div>
                 </div>
                 </div>
                  

               {/* QoS View  */}
               
                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                 <div className="row">
                 <div className ="col-md-12" style ={{'padding':'20px'}}> 
                 <div className = "inline-headers" ><h4 class="text-info" style = {{"fontSize": "1.1rem"}}>Health Metrics</h4></div>
                 
                 {/* <div className="row" style ={{'padding':'10px'}}>
                 <div class="col-sm-7"></div>
                 <div class="col-sm-5">&nbsp;&nbsp;<span><img className = "text-muted" alt = "Check Logo" aria-label="Check Logo" src={check} style={{width: '20px'}}/>&nbsp;Available</span> &nbsp;
                 <span><img className = "text-muted" alt = "Check Logo" aria-label="Check Logo" src={info} style={{width: '20px'}}/>&nbsp;Degraded</span> &nbsp;
                 <span><img className = "text-muted" alt = "Check Logo" aria-label="Check Logo" src={fill} style={{width: '20px'}}/>&nbsp;Unavailable</span>
                 </div>
                 </div> */}
                 
                 <ul style ={{'padding':'10px'}} id="myQoS" className="nav nav-tabs" role="tablist">
                   <li className="nav-item">
                  <a className="nav-link active" href="#design" role="tab" data-toggle="tab" aria-expanded="false">
                  <span><img className = "text-muted" alt = "Design Logo" aria-label="Design Logo" src={Design_New} style={{width: '20px'}}/>&nbsp;<span class = "text-dark">Design</span></span>
                   </a></li>
                 <li><a className="nav-link" href="#profile" role="tab" data-toggle="tab" aria-expanded="true">
                 <span><img className = "text-muted" alt = "Plan Logo" aria-label="Plan Logo" src={Plan_New} style={{width: '25px'}}/>&nbsp;<span class = "text-dark">Plan</span></span>
                 </a></li>
                 <li><a className="nav-link" href="#profile" role="tab" data-toggle="tab">
                 <span><img className = "text-muted" alt = "Source Logo" aria-label="Source Logo" src={Source_New} style={{width: '25px'}}/>&nbsp;<span class = "text-dark">Source</span></span>
                 </a></li>
                 <li><a className="nav-link" href="#profile" role="tab" data-toggle="tab">
                 <span><img className = "text-muted" alt = "Make Logo" aria-label="Make Logo" src={Make_New} style={{width: '30px'}}/>&nbsp;<span class = "text-dark">Make</span></span>
                 </a></li>
                 <li><a className="nav-link" href="#profile" role="tab" data-toggle="tab">
                 <span><img className = "text-muted" alt = "Deliver Logo" aria-label="Deliver Logo" src={Deliver_New} style={{width: '25px'}}/>&nbsp;<span class = "text-dark">Deliver</span></span>
                 </a></li>
                 <li><a className="nav-link" href="#profile" role="tab" data-toggle="tab">
                 <span><img className = "text-muted" alt = "Crew Logo" aria-label="Crew Logo" src={Care_New} style={{width: '20px'}}/>&nbsp;<span class = "text-dark">Crew</span></span>
                 </a></li>
                 <li><a className="nav-link" href="#profile" role="tab" data-toggle="tab">
                 <span><img className = "text-muted" alt = "Enable Logo" aria-label="Enable Logo" src={Enable_New} style={{width: '25px'}}/>&nbsp;<span class = "text-dark">Enable</span></span>
                 </a></li>
                 </ul>
                 
                
                   <div className="tab-content">
                   <div className="tab-pane active" id="design">
                   {/* <div class="table-responsive"> */}
                   {/* <QoS events = { this.state.QoS != null ? this.state.QoS : this.props.qos.filter(x=>x.teamGroupName === 'Design')}></QoS>                    */}
                  {/* </div> */}
                  </div>
                   </div>

                 </div>
                 </div>
                  </div>
                
               
                {/* Upcoming requests grid */}
                
               <div className="tab-pane fade" id="pills-upcoming" role="tabpanel" aria-labelledby="pills-upcoming-tab">
                  
                  
                 <div className ="col-md-12" style ={{'padding':'20px'}}>                  
                  
                   {internalUser &&
                  <label><i class="fa fa-filter" aria-hidden="true"></i>&nbsp;
                  <select  aria-label='internalfilterdd' value={this.state.dropdownvalue} onChange={this.handleChange}>
                        {optionTemplate}
                       </select></label>}
                 
                 {(this.props.role!=="Reader") ?
                  <Button variant="primary" size="md" className = "eventbuttons" onClick={this.showModal}>Create Event</Button>
                   :null }
                  
                 </div>
                  {/* {this.state.dropdownvalue === 'SCE' && this.state.events.filter(x=>x.status.toLowerCase() === "approved").length > 0 ?   */}
                   {this.state.dropdownvalue === 'SCE' && this.state.events!==null  ?  
                    
                    <LoadingOverlay  active={this.state.isFetching} styles={{
                  overlay: (base) => ({
                 ...base,
                 background: 'light gray'
                  }),
                  spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                      stroke: 'blue'
                    }
                  })
                }} spinner={<BounceLoader color = '#006ee5'/>}>
                  {/* THIS IS UPCOMING ACTIVITIES TABLE*/}
                      <table className="table table-hover upcoming" id = "upcoming" ref={(el) => (this.el = el)}>                      
                        <caption>Approved Activities for the past 60 days</caption>
                        <thead>
                          <tr>
                            <th scope="col">Title</th>
                            <th scope="col">Timezone</th>
                            <th scope="col">Start Date</th>
                            <th scope="col">End Date</th>
                            <th scope="col">Status</th>
                            <th scope="col" style = {{"width":"14%"}}>More Details</th>
                            <th scope="col" style = {{"display":"none"}}>Id</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.props.role === 'BusinessUser ChannelManagers' && this.state.upcomingEvents.map(item => {
                        let startdate,enddate;
                        if (item.timezone==="PST")
                        {
                            startdate=Date.parse(new Date(this.convertUTCDateToPST(new Date(item.startTime))));
                            startdate = moment(new Date(startdate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                            startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");
                            enddate=Date.parse(new Date(this.convertUTCDateToPST(new Date(item.endTime))));
                            enddate = moment(new Date(enddate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                            enddate = enddate.format("YYYY-MM-DDTHH:mm:ss");
                        }
                        else if(item.timezone==="CST")
                        {              
                          startdate=Date.parse(new Date(this.convertUTCDateToCST(new Date(item.startTime))));
                          startdate = moment(new Date(startdate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                          startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");
                          enddate=Date.parse(new Date(this.convertUTCDateToCST(new Date(item.endTime))));
                          enddate = moment(new Date(enddate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                          enddate = enddate.format("YYYY-MM-DDTHH:mm:ss");
                        }
                        else if((item.timezone==="UTC")|| (item.timezone===null))
                        {
                          startdate=item.startTime;
                          enddate=item.endTime;
                        }
                        return (
                          <tr key={item.eventId}>
                           
                            <td className="hasTooltip">{item.title}<span>{item.title}</span></td>
                            <td>{item.timezone}</td>
                            <td className="hasTooltip">{startdate}<span>{startdate}</span></td>
                            <td className="hasTooltip">{enddate}<span>{enddate}</span></td>
                            <td><div className="approval">Approved</div></td>
                            <td>
                              <Button tabIndex="0" variant="primary" size="md" className = "eventbuttons" onClick={() => this.showEditModalWindow(item)} >Event Info</Button>
                              <Button tabIndex="0" variant="primary" size="md" className = "eventbuttons" onClick={()=> {this.showApprovalModalWindow(item)}}>Approval Info</Button>                                                                         
                              
                            </td>
                            <td style = {{"display":"none"}}>{item.eventId}</td>
                          
                          </tr>
                        )
                      })}

                      {this.props.role !== 'BusinessUser ChannelManagers' && this.state.upcomingEvents.map(item => {
                        let startdate,enddate;
                        if (item.timezone==="PST")
                        {
                            startdate=Date.parse(new Date(this.convertUTCDateToPST(new Date(item.startTime))));
                            startdate = moment(new Date(startdate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                            startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");
                            enddate=Date.parse(new Date(this.convertUTCDateToPST(new Date(item.endTime))));
                            enddate = moment(new Date(enddate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                            enddate = enddate.format("YYYY-MM-DDTHH:mm:ss");
                        }
                        else if(item.timezone==="CST")
                        {              
                          startdate=Date.parse(new Date(this.convertUTCDateToCST(new Date(item.startTime))));
                          startdate = moment(new Date(startdate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                          startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");
                          enddate=Date.parse(new Date(this.convertUTCDateToCST(new Date(item.endTime))));
                          enddate = moment(new Date(enddate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                          enddate = enddate.format("YYYY-MM-DDTHH:mm:ss");
                        }
                        else if((item.timezone==="UTC")|| (item.timezone===null))
                        {
                          startdate=item.startTime;
                          enddate=item.endTime;
                        }
                        return (
                          <tr key={item.eventId}>
                            
                            <td className="hasTooltip">{item.title}<span>{item.title}</span></td>
                            <td>{item.timezone}</td>
                            <td className="hasTooltip">{startdate}<span>{startdate}</span></td>
                            <td className="hasTooltip">{enddate}<span>{enddate}</span></td>
                            <td><div className="approval">Approved</div></td>
                            <td>
                              {/* THIS IS BUTTON IN UPCOMIG ACTIVITIES PIECE/BUTTON */}
                              <Button tabIndex="0" variant="primary" size="md" className = "eventbuttons" onClick={() => this.showEditModalWindow(item)} >Event Info</Button>
                              &nbsp;
                              <Button tabIndex="0" variant="primary" size="md" className = "eventbuttons" onClick={()=> {this.showApprovalModalWindow(item)}}>Approval Info</Button>
                              
                            </td>
                            <td style = {{"display":"none"}}>{item.eventId}</td>
                          
                          </tr>
                        )

                      })}
                     </tbody>
                      </table>
                      </LoadingOverlay> 
                                                  
                  : 
                  (this.state.dropdownvalue === 'SAP' && this.state.events!==null) ?
                  
                  <LoadingOverlay  active={this.state.isFetching} styles={{
                  overlay: (base) => ({
                 ...base,
                 background: 'light gray'
                  }),
                  spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                      stroke: 'blue'
                    }
                  })
                }} spinner={<BounceLoader color = '#006ee5'/>}>
                  <table className="table table-hover upcomingsap" id = "upcomingsap" ref={(el) => (this.el = el)}>                 
                    <caption>Upcoming Activities</caption>
                    <thead>
                      <tr>
                        <th scope="col" class="w-20">Title</th>
                        <th scope="col">Timezone</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">End Date</th>
                        <th scope="col" class="w-20">Category</th>
                        <th scope="col">More Details</th>
                        <th scope="col" style = {{"display":"none"}}>Id</th>
                       </tr>
                    </thead>
                    <tbody>
                    {this.state.events.map(item => {
                      let startdate,enddate;
                      if (item.timezone==="PST")
                      {
                          startdate=Date.parse(new Date(this.convertUTCDateToPST(new Date(item.startTime))));
                          startdate = moment(new Date(startdate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                          startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");
                          enddate=Date.parse(new Date(this.convertUTCDateToPST(new Date(item.endTime))));
                          enddate = moment(new Date(enddate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                          enddate = enddate.format("YYYY-MM-DDTHH:mm:ss");
                      }
                      else if(item.timezone==="CST")
                      {              
                        startdate=Date.parse(new Date(this.convertUTCDateToCST(new Date(item.startTime))));
                        startdate = moment(new Date(startdate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                        startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");
                        enddate=Date.parse(new Date(this.convertUTCDateToCST(new Date(item.endTime))));
                        enddate = moment(new Date(enddate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                        enddate = enddate.format("YYYY-MM-DDTHH:mm:ss");
                      }
                      else if((item.timezone==="UTC")|| (item.timezone===null))
                      {
                        startdate=item.startTime;
                        enddate=item.endTime;
                      }
                    return (
                      <tr key={item.eventId}>
                        <td className="hasTooltip">{item.title}<span>{item.title}</span></td>
                        <td>{item.timezone}</td>
                        <td className="hasTooltip">{startdate}<span>{startdate}</span></td>
                        <td className="hasTooltip">{enddate}<span>{enddate}</span></td>
                        <td className="hasTooltip">{item.category}<span>{item.category}</span></td>
                        <td>
                            <Button tabIndex="0" variant="primary" size="md" className = "eventbuttons" onClick={() => this.showEditModalWindow(item)} >Event Info </Button>
                        </td>
                        <td style = {{"display":"none"}}>{item.eventId}</td>
                       </tr>
                    )
                  })}                  
                 </tbody>
                  </table>
                  </LoadingOverlay>
                    
                    
                  :
                  null
                  }
                    
                  <br/>
                </div>


                {/* Pending requests grid */}
               
                <div className="tab-pane fade" id="pills-pending" role="tabpanel" aria-labelledby="pills-pending-tab">
                  <div className="row">  
                  <div id="modal-root"></div>                               
                    <div className="col-md-12" style ={{'marginTop':'20px'}}>
                    
                    {internalUser && <div className ="col-md-12">
                  <label><i class="fa fa-filter" aria-hidden="true"></i>&nbsp;
                  <select aria-label='internalfilterdd' value={this.state.pendingdropdownvalue} onChange={this.handlePendingChange}>
                        {optionTemplate}
                       </select></label></div>}  
                      <LoadingOverlay  active={this.state.approvalLoading || this.state.isFetching} styles={{
                  overlay: (base) => ({
                 ...base,
                 background: 'light gray'
                  }),
                  spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                      stroke: 'blue'
                    }
                  })
                }} spinner={<BounceLoader color = '#006ee5'/>}>
                    {/* <h4 className = "portal-name" style = {{"font-size": "1.1rem"}}>Upcoming Activities</h4> */}
                      <table className="table table-hover pending" id = "pending" ref={(el) => (this.el = el)}>
                        <caption>Pending Requests</caption>
                        <thead>
                          <tr>
                            <th scope = "col" style = {{"width":"1%"}}></th>
                            <th scope="col" >Title</th>
                            <th scope="col" style = {{"width":"7%"}}>Timezone</th>
                            <th scope="col" style = {{"width":"10%"}}>Start Date</th>
                            <th scope="col" style = {{"width":"10%"}}>End Date</th>
                            {/* <th scope="col">Impact</th> */}
                            {/* <th scope="col">Status</th> */}
                            {(validRole && this.props.role!=='Writer') &&
                            <th scope="col" style = {{"width":"15%"}} >Actions</th>}
                            <th scope="col" style = {{"width":"12%"}}>More Details</th> 
                            {/* <th scope="col">Approval Workflow</th> */}
                            <th scope="col" style = {{"display":"none"}}>Id</th>     
                            <th scope="col" style = {{"display":"none"}}>PM Contact</th>                                                   
                          </tr>
                        </thead>
                        <tbody>
             {this.props.role !== "BusinessUser ChannelManagers" && this.state.pendingRequests && this.state.pendingRequests.map(item =>  {
              let startdate,enddate;
              if (item.timezone==="PST" && item.timezone!==this.state.newTimeZone)
              {
                  startdate=Date.parse(new Date(this.convertUTCDateToPST(new Date(item.startTime))));
                  startdate = moment(new Date(startdate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                  startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");
                  enddate=Date.parse(new Date(this.convertUTCDateToPST(new Date(item.endTime))));
                  enddate = moment(new Date(enddate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                  enddate = enddate.format("YYYY-MM-DDTHH:mm:ss");
              }
              else if (item.timezone==="PST" && item.timezone===this.state.newTimeZone)
              {
                startdate=item.startTime;
                enddate=item.endTime;
              }
              
              if(item.timezone==="CST" && item.timezone!==this.state.newTimeZone)
              {              
                startdate=Date.parse(new Date(this.convertUTCDateToCST(new Date(item.startTime))));
                startdate = moment(new Date(startdate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");
                enddate=Date.parse(new Date(this.convertUTCDateToCST(new Date(item.endTime))));
                enddate = moment(new Date(enddate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                enddate = enddate.format("YYYY-MM-DDTHH:mm:ss");
              }
              else if(item.timezone==="CST" && item.timezone===this.state.newTimeZone)
              {
                startdate=item.startTime;
                enddate=item.endTime;
              }

              if((item.timezone==="UTC")|| (item.timezone===null))
              {
                startdate=item.startTime;
                enddate=item.endTime;
              }
            
            return (              
             
                <tr key = {item.eventId}>
                    <td className="details-control" aria-labelledby='expandandcollapsebtn' role="button" tabIndex="0" title="ExpandCollapseButton" ></td>
                    <td className="hasTooltip">{item.title}<span>{item.title}</span></td>
                    <td>{item.timezone}</td>
                    <td className="hasTooltip"> {startdate}<span>{startdate}</span></td>
                    <td className="hasTooltip">{enddate}<span>{enddate}</span></td>
                    {/* <td className="hasTooltip">{item.impact}<span>{item.impact}</span></td> */}
                    {/* <td><div className ="pending">Pending Review</div></td> */}

                    {/*Admins will see L1 Approve , L2 Approve , Reject buttons for SCE Planned events */}
                    {/*Approvers who are only for L1 approval step will see L1 Approve , Reject buttons for SCE Planned events */}
                    {/*Approvers who are only for L2 approval step will see L2 Approve , Reject buttons for SCE Planned events */}
                    {/*Admins,Approvers who are for L2 approval step will see L2 Approve , Reject buttons for SCE UnPlanned events,CREW Holiday events. */}
                    { ((validRole && this.props.role.toLowerCase()==='admin')  && (item.naCategory!=='CREW Holiday Calendar' && item.outageType!=='UnPlanned')) ?
                    <td class="approved">  
                        <Button  variant="primary" size="sm" className = "eventbuttons" onClick={()=> {this.ApproveRejectEvent(1,0,item)}}>L1 Approve</Button>                                               
                        &nbsp;
                        <Button  variant="success" size="sm" className = "eventbuttons" onClick={()=> {this.ApproveRejectEvent(1,1,item)}}>L2 Approve</Button>                                               
                        &nbsp;
                        <Button  variant="failure" size="sm" className = "eventbuttons" onClick={()=> {this.ShowRemarksModal(item,true)}}>Reject</Button>                                               
                        
                    </td>
                    : ((validRole && this.props.role.toLowerCase()==='approver')  && (item.naCategory!=='CREW Holiday Calendar' && item.outageType!=='UnPlanned') && (isL1ApproverRole===true)) ?
                    <td class="approved"> 
                        <Button  variant="primary" size="sm" className = "eventbuttons" onClick={()=> {this.ApproveRejectEvent(1,0,item)}}>L1 Approve</Button>                                                                                               
                        &nbsp;
                        <Button  variant="failure" size="sm" className = "eventbuttons" onClick={()=> {this.ShowRemarksModal(item,true)}}>Reject</Button>                                               
                        
                    </td>
                     : ((validRole && this.props.role.toLowerCase()==='approver')  && (item.naCategory!=='CREW Holiday Calendar' && item.outageType!=='UnPlanned') && (isL1ApproverRole===false)) ?
                     <td class="approved">                                                     
                        <Button  variant="success" size="sm" className = "eventbuttons" onClick={()=> {this.ApproveRejectEvent(1,1,item)}}>L2 Approve</Button>                                               
                        &nbsp;
                        <Button variant="failure" size="sm" className = "eventbuttons" onClick={()=> {this.ShowRemarksModal(item,true)}}>Reject</Button>                                               
                     </td>
                    : ((this.props.role.toLowerCase()==='admin' || this.props.role.toLowerCase()==='approver') && (item.naCategory==='CREW Holiday Calendar' || item.outageType==='UnPlanned')) ?
                    <td class="approved">                                                      
                        <Button  variant="success" size="sm" className = "eventbuttons" onClick={()=> {this.ApproveRejectEvent(1,1,item)}}>L2 Approve</Button>                                               
                        &nbsp;
                        <Button  variant="failure" size="sm" className = "eventbuttons" onClick={()=> {this.ShowRemarksModal(item,true)}}>Reject</Button>                                               
                     </td> : null
                     }                   
                     
                    <td> 
                        <Button  variant="primary" size="sm" className = "eventbuttons" onClick={() => this.showEditModalWindow(item)} >Event Info</Button>                                               
                        &nbsp;
                        <Button  variant="primary" size="sm" className = "eventbuttons" onClick={()=> {this.showApprovalModalWindow(item)}}>Approval Info</Button>                                               
                    </td>  
                   
                    <td style = {{"display":"none"}}>{item.eventId}</td>   
                    <td style = {{"display":"none"}}>{item.pmcontact}</td>                
                </tr>
              )           
           })}
            </tbody>                         
            </table>              
            </LoadingOverlay>
           
            <div>
            <Modal style = {{'overflow': 'visible'}} 
            role="dialog"
            data-keyboard="false" 
            show={this.state.show} size="lg" 
            onHide ={(e)=> {this.ShowRemarksModal(e,false)}}  
            backdrop="static" centered autoFocus="true">       
              <form id = "addrejectremarks" >
              <Modal.Body style={{'maxHeight': 'calc(100vh - 100px)',  'overflowY': 'auto'}}> 

            <div className="modal-body">
            <textarea className ="form-control col-xs-12" 
                      value = {this.state.rejectreasons} 
                      onChange={(e) => this.handleTextAreaChange(e)} 
                      rows="7" 
                      cols="50" 
                      placeholder="Enter event rejection reason here."> 
            </textarea>
            &nbsp;
            &nbsp;
            <Button tabIndex="0" variant="primary" size="sm" onClick={(e)=>this.submitReason(e,this.state.rejectreasons)}>Save</Button>
            &nbsp;
            <Button tabIndex="0" variant="secondary" size="sm" onClick={(e)=>this.ShowRemarksModal(e,false)}>Close</Button>
            </div> 
           </Modal.Body>            
            </form>       
          </Modal>
            </div> 
            </div>                    
            </div>
            <LoadingOverlay  active={this.state.approvalLoading || this.state.isFetching} styles={{
                  overlay: (base) => ({
                 ...base,
                 background: 'light gray'
                  }),
                  spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                      stroke: 'blue'
                    }
                  })
                }} spinner={<BounceLoader color = '#006ee5'/>}>
            <ApprovedRequests events = {this.state.eventsByUser !== null ? this.state.eventsByUser.filter(x=>x.recordOperation.toLowerCase() == "approval") : []}></ApprovedRequests>          
            </LoadingOverlay>
            <LoadingOverlay  active={this.state.approvalLoading || this.state.isFetching} styles={{
                  overlay: (base) => ({
                 ...base,
                 background: 'light gray'
                  }),
                  spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                      stroke: 'blue'
                    }
                  })
                }} spinner={<BounceLoader color = '#006ee5'/>}>
            <CreatedRequests events = {this.state.eventsByUser !== null ? this.state.eventsByUser.filter(x=>x.recordOperation.toLowerCase() == "create") : []}></CreatedRequests>  
           </LoadingOverlay>
           </div>   
           {/*SAP Release Schedule grid */}
           <div className="tab-pane fade" id="pills-saprelease" role="tabpanel" aria-labelledby="pills-saprelease-tab">
                  <div className="row">  
                  <div id="modal-root"></div>                               
                    <div className="col-md-12" style ={{'marginTop':'20px'}}>
                    {internalUser && <div className ="col-md-12">
                  <label>&nbsp;</label></div>} 
                 <SAPReleaseSchedule
                 userEmail = {this.props.user.email}
                 role = {this.props.role}                   
                 />
                
            </div>                    
            </div>           
            </div>         
           
           {/*Rejected Requests grid */}
           <div className="tab-pane fade" id="pills-rejected" role="tabpanel" aria-labelledby="pills-rejected-tab">
                  <div className="row">  
                  <div id="modal-root"></div>                               
                    <div className ="col-md-12"> 
                    <LoadingOverlay  active={this.state.approvalLoading || this.state.isFetching}  styles={{
                  overlay: (base) => ({
                 ...base,
                 background: 'light gray'
                  }),
                  spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                      stroke: 'blue'
                    }
                  })
                }}
                      spinner={<BounceLoader color = '#006ee5'/>}>
                      <table className="table table-hover rejected" id = "rejected" ref={(el) => (this.el = el)}>                      
                        <caption>Rejected Requests</caption>
                        <thead>
                          <tr>
                            <th scope = "col" style = {{"width":"3.5%"}}></th>
                            <th scope="col" class="w-20">Title</th>
                            <th scope="col" style = {{"width":"5%"}}>Timezone</th>
                            <th scope="col">Start Date</th>
                            <th scope="col">End Date</th>
                            <th scope="col" style = {{"width":"5%"}}>Status</th>
                            <th scope="col">Reject Reason</th>
                            <th scope="col" style = {{"width":"12%"}}>More Details</th>                            
                            <th scope="col" style = {{"display":"none"}}>Id</th>
                            <th scope="col" style = {{"display":"none"}}>Rejected By</th>   
                            <th scope="col" style = {{"display":"none"}}>Reject Reason</th>   
                          </tr>
                        </thead>
                        <tbody>
                         {this.state.rejectedRequests && this.state.rejectedRequests.map(item =>  {
                           let startdate,enddate;
                           if (item.timezone==="PST")
                           {
                               startdate=Date.parse(new Date(this.convertUTCDateToPST(new Date(item.startTime))));
                               startdate = moment(new Date(startdate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                               startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");
                               enddate=Date.parse(new Date(this.convertUTCDateToPST(new Date(item.endTime))));
                               enddate = moment(new Date(enddate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                               enddate = enddate.format("YYYY-MM-DDTHH:mm:ss");
                           }
                           else if(item.timezone==="CST")
                           {              
                             startdate=Date.parse(new Date(this.convertUTCDateToCST(new Date(item.startTime))));
                             startdate = moment(new Date(startdate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                             startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");
                             enddate=Date.parse(new Date(this.convertUTCDateToCST(new Date(item.endTime))));
                             enddate = moment(new Date(enddate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                             enddate = enddate.format("YYYY-MM-DDTHH:mm:ss");
                           }
                           else if((item.timezone==="UTC")|| (item.timezone===null))
                           {
                             startdate=item.startTime;
                             enddate=item.endTime;
                           }
                         return (
                          <tr key={item.eventId}>
                            <td className="details-control" aria-labelledby='expandandcollapsebtn' role="button" tabIndex="0" title="ExpandCollapseButton"></td>
                            <td className="hasTooltip">{item.title}<span>{item.title}</span></td>
                            <td >{item.timezone}</td>
                            <td className="hasTooltip">{startdate}<span>{startdate}</span></td>
                            <td className="hasTooltip">{enddate}<span>{enddate}</span></td>
                            <td><div className="rejected">{"REJECTED"}</div></td>
                            <td className="hasTooltip">{item.rejectReason}<span>{item.rejectReason}</span></td>                            
                            <td>
                                <Button tabIndex="0" variant="primary" size="sm" className = "eventbuttons" onClick={()=> {this.showApprovalModalWindow(item)}}>Approval Info</Button>
                                &nbsp;
                                <Button tabIndex="0" variant="primary" size="sm" className = "eventbuttons" onClick={()=>this.showEditModalWindow(item)} >Event Info</Button>
                            </td>
                            <td style = {{"display":"none"}}>{item.eventId}</td>
                            <td style = {{"display":"none"}}>{item.eventSigner}</td>   
                            <td style = {{"display":"none"}}>{item.rejectReason}</td>  
                          </tr>
                        )
                        })}
                      </tbody>
                      </table>
                      </LoadingOverlay> 
                </div>
                </div> 
           </div>
                     
           </div>
                </div>
                  <div className="container">
                        <AlertDialog title= {'Success'} cancelButtonLabel={'Close'}
                        message= {'we have sucessfully processed your request'} open={this.state.showApprovalDialog} onClickCancel={this.closeApprovalDialog}/>
                        <AlertDialog title= {'Error'} cancelButtonLabel={'Close'}
                        message= {'Approver contact group is not registered'} open={this.state.showErrorDialog} onClickCancel={this.closeDialog}/>        
                        <AlertDialog title= {title} message= {subText} open={this.state.showDialog} blocking = {!this.state.blocking}
           okButtonLabel = {okButtonLabel} okButtonType = {ButtonType.Danger} cancelButtonLabel={cancelButtonLabel}
          cancelButtonType={ButtonType.Danger} onClickCancel={this.cancelDialog} onClickOk={this.approveDialog}/>
        
                  </div>
                
             {AddEvent}
             {EditEvent} 
             {DisplayApproval}
            </Fragment>           
        )
    }
}

export default Welcome;