import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import AlertDialog from '../../common/AlertDialog/AlertDialog.js';
import { Navbar,NavDropdown,Nav,Form,FormControl,Button,Image } from 'react-bootstrap';
import uuid from 'react-native-uuid';
import {appInsights} from '../../common/AppInsights/AppInsights';
import {fetchToken,getValidApproverRole} from '../../Util.js';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";
import { Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import $ from 'jquery';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,NavItem,NavLink } from 'reactstrap';
import '@fortawesome/fontawesome-free/css/all.css';
import logo from '../../common/Images/logo.png';
import microsoftlogo from '../../common/Images/MicrosoftLogo.jpg'
import './header.css';
//import logo from './components/Header/logo.png';


function UserAvatar(props) {
    // If a user avatar is available, return an img tag with the pic
    if (props.user.avatar) {
        return <img
                
                src={props.user.avatar} alt="user"
                className="rounded-circle align-self-center mr-2"
                style={{width: '32px',color:'white'}}></img>;
    }
    
    // No avatar available, return a default icon
    return <i
            
            className="far fa-user-circle fa-lg rounded-circle align-self-center mr-2"
            style={{width: '32px',color:'white'}}></i>;
    }

function ShowCalendar(props){
    if (props.isAuthenticated) {
        return (
          <Link to="/calendar"  className="nav-link" >Calendar</Link> 
        );
    }
    return (null);
}

function ShowFactoryCalendar(props){
  if (props.props.isAuthenticated && props.props.validFactoryUsers) {
      return (
        <Link to="/factory"  className="nav-link" >JDM1 Calendar</Link> 
      );
  }
  return (null);
}

function ShowApprovalScreen(props){

    if (props.isAuthenticated && props.isAuthenticated.role) {
        return (
          <Link to="/approvals"  className="nav-link" >Approvals</Link> 
        );
    }
    return (null);
} 

function AuthNavItem(props) {
    // If authenticated, return a dropdown with the user's info and a
    // sign out button
    if (props.isAuthenticated) {
      return (
        <UncontrolledDropdown>
          <DropdownToggle aria-label="Logged In User Details" nav caret>
            <UserAvatar user={props.user}/>
          </DropdownToggle>
          <DropdownMenu right>
            <h5 className="dropdown-item-text mb-0">{props.user.displayName}</h5>
            <p className="dropdown-item-text text-muted mb-0">{props.user.email}</p>
            <DropdownItem divider />
            <DropdownItem onClick={props.authButtonMethod}>Sign Out</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
  
    // Not authenticated, return a sign in link
    return (
        <Nav.Link onClick={props.authButtonMethod} style={{color:"white"}}>Sign In</Nav.Link>       
    );
  }

class NavBarHeader extends React.Component{
    constructor(props){
        super(props);
        this.state = {
          category : null,
          remarks : null,
          showDialog : false,
          isFetching : false
        }
    }

    successDialog()
   { 
    this.setState( {showDialog: true});
   }

 
    closeApprovalDialog = async () => {
      const { onClickCancel } = this.props;
      if (typeof onClickCancel === "function") { await onClickCancel(); }
      this.setState( {showDialog: false});
      $("#test-modal-2").modal("hide");
    }

    componentDidMount()
    {
      var prop = this;
      $('#test-modal-2').on('show.bs.modal', function (event) {
        prop.setState({remarks:''});
        var button = $(event.relatedTarget) // Button that triggered the modal
        var recipient = button.data('whatever') // Extract info from data-* attributes
        // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
        // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
        var type = recipient.includes('idea') ? "Idea" : (recipient.includes('issue') ? "Issue" : "Compliment");
        prop.setState({category:type});
        var modal = $(this)
        modal.find('.modal-title').text('Tell us ' + recipient)
        modal.find('.modal-body input').val(recipient)
      })
    }

    handleChange= async(event) => {
      this.setState({remarks: event.target.value});
    }

    submitFeedback = async() =>
    { 
       this.setState({isFetching:true});
        let category =this.state.category;
        let remarks = this.state.remarks;
        var correlationId = uuid.v1();
        var apimUrl = window.env.APIMURL + "/api/outage/addfeedback/?category=";        
        let url = apimUrl + category + '&remarks=' + remarks;
        var token = await fetchToken();  
        var bearer = 'Bearer ' + token;          
        fetch(url, {
        method: 'PUT',
        headers: {   
            'CorrelationId': correlationId,
            'Content-Type': 'application/json',
            'authorization': bearer,
          },
        }).then(response => {    
        this.setState({ ReturnStatus: response.status});
        if(response.status !== 400)
        {           
          this.setState({ isFetching:false
          },this.successDialog());          
        }
        else
        {
          this.errorDialog();
        }       
      }).catch(error =>
      {
      var err = 
      {
        "message": "Exception occured in feedback component ",
        "ComponentId" : COMPONENT_ID,
        "ComponentName" : COMPONENT_NAME,
        "EnvironmentName" : window.env.ENVIRONMENT,
        "ServiceLineName" : SERVICE_LINE,
        "ServiceName" : SERVICE_NAME,
         "ServiceOfferingName" : SERVICE_OFFERING,
        "Component": 'Welcome Page Approval activity',
         "exception": error.message,
         "Correlation Id": correlationId
      }
       appInsights.trackException({exception: error,properties : err});  
   })
}

    render(){
        return(
            
            <React.Fragment>
                <Navbar collapseOnSelect role="banner" expand="lg" bg="primary" variant="dark" sticky="top" style={{padding:'5px'}}>
                  
                  <Navbar.Brand>
                    <div className="navbar-brand"  role="navigation" aria-label="headerbanner">                  
                        <h3>Microsoft Supply Chain</h3>         
                        </div>                 
                  </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ml-auto">
                         <button type="button" aria-labelledby="Feedback" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#test-modal"><i className="fa fa-smile-o fa-lg" title="Feedback" style = {{"color":"white"}}></i></button>
                        </Nav>
                        <AuthNavItem
                            isAuthenticated={this.props.isAuthenticated}
                            authButtonMethod={this.props.authButtonMethod}
                            user={this.props.user} />                    
                    </Navbar.Collapse>
                    
                    
                </Navbar> 
                      
 <div className="modal" id="test-modal" data-backdrop="static" data-keyboard="true" tabIndex="0" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">What kind of feedback do you have for SAM Portal?</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className = "modal-body">
      {/* <button class="btn btn-default" data-toggle="modal" data-target="#test-modal-2">Launch Modal 2</button> */}
      <div className = "feedbackInput" >
        <div className = "feedbackOption" data-toggle="modal" data-dismiss="modal"   tabIndex="0" data-target="#test-modal-2" data-whatever="about the issue">Report an issue</div>
        <div className = "feedbackOption" data-toggle="modal" data-dismiss="modal"   tabIndex="0" data-target="#test-modal-2" data-whatever="about your idea">Share an idea</div>
        <div className = "feedbackOption" data-toggle="modal" data-dismiss="modal"   tabIndex="0" data-target="#test-modal-2" data-whatever="what you like">Give a compliment</div>
        </div>
      </div>
      {/* <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> */}
    </div>
  </div>
</div>

<div className="modal" id="test-modal-2" data-backdrop="static" data-keyboard="false" tabIndex="0" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
  <LoadingOverlay  active={this.state.isFetching} styles={{
                  overlay: (base) => ({
                 ...base,
                 background: 'light gray'
                  }),
                  spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                      stroke: 'blue'
                    }
                  })
                }} spinner={<BounceLoader color = '#006ee5'/>}> 
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Tell us</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {/* <button class="btn btn-default" data-toggle="modal" data-target="#test-modal-2">Launch Modal 2</button> */}
       
      <div className="modal-body">
      <textarea className ="form-control col-xs-12" value = {this.state.remarks} onChange={(e) => this.handleChange(e)} rows="7" cols="50" placeholder="Enter feedback here. To help protect your privacy, don't include personal info, like your address or phone number"></textarea>
      <a target="=blank" href="https://go.microsoft.com/fwlink/?linkid=272895" className="ms-Link privacyLink root-174">Privacy statement</a>
      </div>           
      <div className="modal-footer">        
        <button type="button"  onClick={()=> {this.submitFeedback()}} className="btn btn-primary">Send</button>
        <button type="button" className="btn btn-secondary" data-toggle="modal" data-target="#test-modal" data-dismiss="modal">Go back</button>
      </div>    
  </div>
  </LoadingOverlay>
</div>
</div>

<div className="container">
<AlertDialog title= {'Success'} cancelButtonLabel={'Close'}
                        message= {'Thanks for submitting the feedback!'} open={this.state.showDialog} onClickCancel={this.closeApprovalDialog}/>
    
</div>
</React.Fragment>
    )  
    }
}

export default NavBarHeader;