import moment from 'moment';
import React,{ReactDOM,Component} from 'react';
import { reactAI } from "react-appinsights";
import uuid from 'react-native-uuid';
import {fetchToken,getValidAdminRole,fetchRoles} from '../../Util.js';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";
import {appInsights} from '../../common/AppInsights/AppInsights';
import AlertDialog from "../../common/AlertDialog/AlertDialog";
import { Button,Modal, Table,ButtonToolbar } from 'react-bootstrap';
import 'react-confirm-alert/src/react-confirm-alert.css';
import EventCategoryDropDown from '../FactoryDropDown/EventCategoryDropdown';
import {ButtonType} from 'office-ui-fabric-react/lib/Button'; 
import { trackPromise } from 'react-promise-tracker'
import LoadingIndicator from '../../common/PromiseTracker/PromiseTracker';
import './ReactPaginationTable.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';


class AddEventL1Approver extends Component{
    _isMounted = false;
    constructor(props) {
    
      super(props);
      
        this.state = {result:'',userEmail : '',addeventl1approver : false,showModal: '',
        showDialog:false,showErrorDialog : false,factory:[],showNewDialog:false,newblocking:false, addOutage:[]
        ,factoryError:'',factoryData:'', role : this.props.role,l1approverlist: []
        ,tenantId:'',tenantIdError:'',approverlevel:'',approverlevelError:'',approvername:'',approvernameError:'',
        columns: [
          
          {
          dataField: 'factory',
          text: 'Category( eg:FactoryName/EventType )',
          editable:false,
          sort:true,
          headerStyle: { backgroundColor: '#006ee5', color:'#ffffff'}
        },
        {
          dataField: 'userName',
          text: 'UserName',
          editable:false,
          headerStyle: { backgroundColor: '#006ee5', color:'#ffffff'}
        },
        {
          dataField: 'approverLevel',
          text: 'Approver Level',
          editable:false,
          headerStyle: { backgroundColor: '#006ee5', color:'#ffffff'}
        }     
      ]
       
      };
      
      this.handleChange = this.handleChange.bind(this);
      this.methodInvoke = this.methodInvoke.bind(this);
      this.close = this.close.bind(this);
      this.cofirmApproveReject = this.cofirmApproveReject.bind(this);
      this.flip = this.flip.bind(this);
    }
  
    appInsight = reactAI.appInsights;
    close = () => this.setState({isOpen: false}) 
  
    async componentDidMount() {
      //this._isMounted = true;
      appInsights.trackTrace({ message: 'L1 Approver Screen Loaded.' },
                     {
                         userName: this.props.userEmail,
                         roles: this.props.role != null || '' ? this.props.role : "",
                         "ComponentId" : COMPONENT_ID,
                         "ComponentName" : COMPONENT_NAME,
                         "EnvironmentName" : window.env.ENVIRONMENT,
                         "ServiceLineName" : SERVICE_LINE,
                         "ServiceName" : SERVICE_NAME,
                         "ServiceOfferingName" : SERVICE_OFFERING,
                         "Correlation Id": uuid.v1(),
                         "Component": 'Admin Screen - Add L1 Approver Page',
                     });
   
    var token = await fetchToken();
    var correlationId = uuid.v1();
    var bearer = 'Bearer ' + token;
    var apimUrl = window.env.APIMURL + "/api/outage/getl1approvers";
    this.setState({ isLoading: true})
    fetch(apimUrl,{
        method: 'GET',
        headers: {
            'authorization': bearer,
            'Accept' : 'application/json',
            'CorrelationId': correlationId,
            'Content-Type': 'application/json'
        }})
        .then(response => {
          const statusCode = response.status;
          const data = response.json();
          return Promise.all([statusCode, data]);          
        })       
        .then((res) => {
          //const { statusCode, data } = res;
          const statusCode = res[0];
          const approverlist = res[1];                  
          this.setState({ l1approverlist: approverlist,ReturnStatus: statusCode
          });          
        })
        .catch(error => {

          var err = {
            "message": "Exception occured L1 Approver page while getting the approver list.",
            "ComponentId" : COMPONENT_ID,
                         "ComponentName" : COMPONENT_NAME,
                         "EnvironmentName" : window.env.ENVIRONMENT,
                         "ServiceLineName" : SERVICE_LINE,
                         "ServiceName" : SERVICE_NAME,
                         "ServiceOfferingName" : SERVICE_OFFERING,
            "Component": 'L1 Approver Page',
             "userName": this.props.userEmail,
             "roles": this.props.role != null || '' ? this.props.role : "",
             "exception": error.message,
             "Correlation Id": correlationId
          }
            appInsights.trackException({exception: error,properties : err});  
        })
     
    }

    refreshApproverList = async(event) => {
      //this._isMounted = true;
      appInsights.trackTrace({ message: 'L1 Approver Screen Loaded.' },
                     {
                         userName: '',
                         roles: this.props.role != null || '' ? this.props.role : "",
                         "ComponentId" : COMPONENT_ID,
                         "ComponentName" : COMPONENT_NAME,
                         "EnvironmentName" : window.env.ENVIRONMENT,
                         "ServiceLineName" : SERVICE_LINE,
                         "ServiceName" : SERVICE_NAME,
                         "ServiceOfferingName" : SERVICE_OFFERING,
                         "Correlation Id": uuid.v1(),
                         "Component": 'Admin Screen - Add L1 Approver Page',
                     });
   
    var token = await fetchToken();
    var correlationId = uuid.v1();
    var bearer = 'Bearer ' + token;
    var apimUrl = window.env.APIMURL + "/api/outage/getl1approvers";
    this.setState({ isLoading: true})
    fetch(apimUrl,{
        method: 'GET',
        headers: {
            'authorization': bearer,
            'Accept' : 'application/json',
            'CorrelationId': correlationId,
            'Content-Type': 'application/json'
        }})
        .then(response => {
          const statusCode = response.status;
          const data = response.json();
          return Promise.all([statusCode, data]);          
        })       
        .then((res) => {
          //const { statusCode, data } = res;
          const statusCode = res[0];
          const approverlist = res[1];                  
          this.setState({ l1approverlist: approverlist,ReturnStatus: statusCode
          });          
        })
        .catch(error => {

          var err = {
            "message": "Exception occured L1 Approver page while getting the approver list.",
            "ComponentId" : COMPONENT_ID,
                         "ComponentName" : COMPONENT_NAME,
                         "EnvironmentName" : window.env.ENVIRONMENT,
                         "ServiceLineName" : SERVICE_LINE,
                         "ServiceName" : SERVICE_NAME,
                         "ServiceOfferingName" : SERVICE_OFFERING,
            "Component": 'Admin Screen - Add L1 Approver Page',
             "userName": this.props.userEmail,
             "roles": this.props.role != null || '' ? this.props.role : "",
             "exception": error.message,
             "Correlation Id": correlationId
          }
            appInsights.trackException({exception: error,properties : err});  
        })
     
    }
  
    componentWillUnmount() {
      this._isMounted = false;
    }
  
    close(){
      this.setState({ showModal: false });
    }

      
    checkErrors = () => {
      const validEmailRegex = 
      RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
      const {} = this.state;
      var obj = {};
      const { userEmail} = this.props;
      let validEmail = (userEmail != undefined ? userEmail.toLowerCase().includes("microsoft.com") : null);
          
      if(validEmail) // check for internal users 
      {

        
        if ((this.state.factory == null || this.state.factory.length == 0)) 
        {
          this.setState({
            factoryError: 'Factory should be selected.'
          })  
          obj.prop = ++obj.prop || 0;
        }

        if (this.state.approvername == null || this.state.approvername.length == 0) 
        {
            this.setState({
              approvernameError: 'Approver Name should be mentioned.'
            })  
            obj.prop = ++obj.prop || 0;
        } 
        
        if (this.state.approverlevel == null || this.state.approverlevel.length == 0) 
        {
            this.setState({
              approvernameError: 'Approver Level should be mentioned.'
            })  
            obj.prop = ++obj.prop || 0;
        } 


    }     
    
      if(obj.prop >=0)
      {
      return true;
      }
      else
      {
      return false;
      }
    } 
  
    renderError(name) {
      const { approvernameError,factoryError,approverlevelError} = this.state;
      switch(name) {
       
          
        case 'factory':
            if (factoryError) {
                  return <div style ={{backgroundColor:'#f8d7da'}}>{factoryError}</div>;
              }
            break; 
        case 'approvername':
            if (approvernameError) {
                  return <div style ={{backgroundColor:'#f8d7da'}}>{approvernameError}</div>;
              }
            break; 
        case 'approverlevel':
            if (approverlevelError) {
                  return <div style ={{backgroundColor:'#f8d7da'}}>{approverlevelError}</div>;
                }
            break;          
             
        default: 
        break;
        
      
        }
}
    
successDialog()
{
  this.setState({showDialog : true});
};

 errorDialog()
 {
    this.setState({showErrorDialog : true});
 };
      flip = async() => 
     {
      this.setState({showNewDialog:!this.state.showNewDialog})
     }

      cofirmApproveReject = async() =>
      {      

        const newState = this;
        const bodyvalues = newState.state.addeventl1approver;
        var token = await fetchToken();  
         var correlationId = uuid.v1(); 
        var bearer = 'Bearer ' + token;  
        var apimUrl = window.env.APIMURL + "/api/outage/addl1approver";  
        trackPromise(
        fetch(apimUrl, {
          method: 'POST',
          headers: {
          //'Accept': 'application/json',
          'Content-Type': 'application/json',
          'CorrelationId': correlationId,
          'authorization': bearer,
          },
         body: bodyvalues
        }).then(response => {     
          this.setState({ ReturnStatus: response.status});  
          if(response.status !== 400)
          {           
            this.setState({
              result:response.status,
              addeventl1approver : true 
            },
            this.successDialog()); 
          }
          else
          {
              this.errorDialog();
          }
          
        })
       .catch( async error =>
              {
                this.errorDialog();
                var err = 
                {
                    "message": "Exception occured while submitting l1 approver details.",
                    "ComponentId" : COMPONENT_ID,
                    "ComponentName" : COMPONENT_NAME,
                    "EnvironmentName" : window.env.ENVIRONMENT,
                    "ServiceLineName" : SERVICE_LINE,
                    "ServiceName" : SERVICE_NAME,
                    "ServiceOfferingName" : SERVICE_OFFERING,
                    "Component" : "Calendar Add Event Page",
                    "exception": error.message,
                    "Correlation Id": correlationId
                }               
               appInsights.trackException({exception: error,properties : err});  
             }));
          
      }  
  
    submitAlert = async(event) =>{
      this.setState({showNewDialog:!this.state.showNewDialog})
      
    }

    approveDialog = async(event) => {
      this.setState( {showNewDialog: false});  
      var event = await this.cofirmApproveReject(event);    
    }

    cancelDialog = async () => {
      const { onClickCancel } = this.props;
      if (typeof onClickCancel === "function") { await onClickCancel(); }
      this.setState( {showNewDialog: false});
    }

   

  
    
      methodInvoke = async(event) =>
      {  
        let errors = null; 
      try {   
        event.preventDefault();
        event.persist();
         
        if(this.checkErrors())
        {         
         // this.props.submitModalPopUpFunc(true);
          return;
        }
        else
        {              
       
        const { userEmail} = this.props;
        let validEmail = (userEmail != undefined ? userEmail.toLowerCase().includes("microsoft.com") : null);
                       
        var bodyvalues = JSON.stringify({
         
          factory: event.target.factory === undefined ? null : event.target.factory.value,
          username:event.target.approvername===undefined?'':event.target.approvername.value,
          approverlevel:event.target.approverlevel===undefined?'':event.target.approverlevel.value
          
        })         
        this.setState({addeventl1approver:bodyvalues});
        var event = await this.submitAlert(event);       
      }
    }
    catch (e) {
      errors = e;
  }
     //Catch Block
  }
       
     
      handleChange(e){
        // if(e.target.value.match("^[a-zA-Z ]*$")!=null) {
         this.setState({[e.target.name]: e.target.value, [`${e.target.name}Error`]:  '' })
        //}
      }     
  
    closeDialog= async () => {
      this.setState( {showDialog: false,showErrorDialog: false} );
      //this.props.submitModalPopUpFunc(false); 
    }  
  
    componentDidCatch(error, info) {
      
      this.setState({ hasError: true });      
    }    

    async updateFactoryName(value){
      
      this.setState({
          factory: value
         });
    }

    async setfactoryError(value){
      
      this.setState({
          factoryError: value
         });
    }

        
   render()
   {
       
    let validEmail =  this.props.userEmail;
    let validAdminRole=getValidAdminRole(this.props.role);     
    const borderRadiusStyle = { borderRadius: 2 };
    
    const options = {
      paginationSize: 4,
      sizePerPage: 5, 
      pageStartIndex: 1,
      //alwaysShowAllBtns: true, // Always show next and previous button
      //withFirstAndLast: false, // Hide the going to First and Last page button
      hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
  };
    

       
     
      return (      
        <div> 
          <AlertDialog title= {'Confirmation Dialog'} message= {'Are you sure you want to submit?'} open={this.state.showNewDialog}
           okButtonLabel = {'Yes'} okButtonType = {ButtonType.Danger} cancelButtonLabel={'No'}
          cancelButtonType={ButtonType.Danger} onClickCancel={this.cancelDialog} onClickOk={this.approveDialog}/>
          <AlertDialog  title= {'Success!'} cancelButtonLabel={'Ok'}  cancelButtonType={ButtonType.Danger}
                          message= {'L1 Approver added successfully.'} open={this.state.showDialog} 
                          onClickCancel={this.closeDialog} />  
          <AlertDialog  title= {'Error'} cancelButtonLabel={'Ok'}
                          message= {'Insufficient Access'} open={this.state.showErrorDialog} onClickCancel={this.closeDialog}/>    
           
           
            <form id = "addeventl1approver" onSubmit={this.methodInvoke.bind(this)}>
                             
                         
              { (validEmail && validAdminRole) ?
              <div>  
                   <div className="form-group">
                <EventCategoryDropDown
                          
                        factoryData={this.updateFactoryName.bind(this)}
                        email = {this.props.userEmail}      
                        factoryError = {this.renderError('factory')}
                        setfactoryError={this.setfactoryError.bind(this)}                                        
                />
                </div>  
                <div className="form-group required">
                <label htmlFor="approvername" class="control-label">L1 Approver Name </label>
                <input required aria-label="ApproverName" autoComplete="off" type="text" placeholder="Approver alias" className="form-control" name="approvername" value={this.state.approvername ||""} onChange={(e) => this.handleChange(e)}/>
               
                <span style={{color: '#E22335'}}>{this.renderError('approvername')}</span>
              </div> 

              <div className="form-group">
                <label htmlFor="approverlevel" class="control-label">Approver Level </label>
                <input aria-label="ApproverLevel"  autoComplete="off" type="text" placeholder="Approver Level" className="form-control" name="approverlevel" value={this.state.approverlevel ||""} onChange={(e) => this.handleChange(e)}/>
              </div>                     
              </div>               
              : null } 
             
               &nbsp;
              <Button aria-label="Submit Outage" form = "addeventl1approver" type="submit" variant="primary" >
                Submit
              </Button>
              <div>&nbsp;</div>
              <div>
              <Button aria-label="Refresh Data" variant="primary" onClick={this.refreshApproverList} >
                Refresh Approver List
              </Button>
              </div>
              <div>&nbsp;</div>
             
            <div>
              <BootstrapTable 
                striped 
                bordered 
                hover 
                condensed
                wrapperClasses="table-responsive"
                className = "Table"           
                keyField='id' 
                data={ this.state.l1approverlist } 
                columns={ this.state.columns }
                options={options}
                pagination={ paginationFactory(options) }
              >        
              </BootstrapTable>
            </div>
           </form> 
          
        </div>
    );
    }
  }

export default AddEventL1Approver;