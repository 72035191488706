// import React from "react";
// import PropTypes from "prop-types";
// import { Spinner, SpinnerSize } from "office-ui-fabric-react";
// import "./Spinner.css";
// //import {Spinner} from 'react-bootstrap';

// const CustomSpinner = ({ className, ...props }) => (
// //     <div class="d-flex align-items-center">
// //   <strong>Loading...</strong>
// //   <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
// // </div>
//     <div className={`${className ? `${className} ` : ""}spinner-container vh-100 vw-100 d-flex justify-content-center align-items-center`}>
//         <Spinner {...props} className="spinner-custom" size={SpinnerSize.large} />
//     </div>
// );

// CustomSpinner.propTypes = {
//     className: PropTypes.string,
// };

// export default CustomSpinner;
import * as React from 'react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { IStackProps, Stack } from 'office-ui-fabric-react/lib/Stack';

const CustomSpinner: React.StatelessComponent = () => {
    // This is just for laying out the label and spinner (spinners don't have to be inside a Stack)
    const rowProps: IStackProps = { verticalAlign: 'middle' };
  
    const stackTokens: IStackTokens = {
        childrenGap: 20,
        maxWidth: 2000,
        maxHeight:2000,
        padding:'150px;'
      };
  
    return (
        <Stack tokens={stackTokens}>
        {/* <Stack {...rowProps} tokens={tokens.spinnerStack}>
        <Label>Large spinner</Label>
        <Spinner size={SpinnerSize.large} />
      </Stack> */}
      <div>
        {/* <Label>Spinner with label positioned to left</Label> */}
        <Spinner size={SpinnerSize.large} label="Loading..." ariaLive="assertive"/>
      </div>
      </Stack>
    );
  };
  export default CustomSpinner