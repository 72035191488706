import React, { Fragment } from 'react';
import { Row, Container } from 'react-bootstrap';
import '../Footer/Footer.css';

class Footer extends React.Component {

	render() {
		return (

			<><footer className="navbar navbar-fixed-bottom">
				<div class="footer fixed-bottom">
					<span id='internal' style={{ color: 'white', fontSize: '13px' }}><u><a className={'linkcolor'} target="_blank" href="https://go.microsoft.com/fwlink/?LinkId=518021" rel="noreferrer">Data Privacy Notice</a></u> © 2023 - Microsoft</span>
				</div>
			</footer></>

		)
	}
}

export default Footer;