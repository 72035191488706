import React from 'react';
import $ from 'jquery';
import '../../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css';
import '../../../node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../OutageHub.css';
import moment from 'moment';
import * as Datetime from 'react-datetime';
    
class CreatedRequests extends React.Component{

shouldComponentUpdate(nextProps, nextState){
    if (nextProps.events.length > 0) {
    this.reloadTableData(nextProps.events);
    return true;
    }
    return false;
    }

componentWillUnmount() {
    $('#createdrequests').DataTable().destroy(true);
    }
reloadTableData = (data) => {
        const table = $('#createdrequests').DataTable();
     table.clear();
     table.rows.add(data);
     table.draw();
      }

placeContentCenter(data, type, full, meta)
{
    return '<div class="d-flex justify-content-center">' + data + '</div>';
}
      
componentDidMount() {
    this.$el = $('#createdrequests');
    var table = this.$el.DataTable({
           columns : [
            {
                "className": 'details-control',
                "data": null,
                "defaultContent": "",
                width: "4%",
                "aria-labelledby":"expandandcollapsebtn",
                "role":"button",  
                "tabIndex":"0"
                
                 
              },
            { title: "Title", data:'title'},
            { title: "Timezone", data:'timezone'},
            { title: "Start Date", data:function(row)
            {
              let startdate,now_utc; 
            
              if (row.timezone==="PST")
              {
                  now_utc=new Date(row.startTime);
                  now_utc.setHours(now_utc.getHours()-7);
                  startdate=Date.parse(new Date(now_utc.toUTCString()));
                  startdate = moment(new Date(startdate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                  startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");
                 
              }
              else if(row.timezone==="CST")
              {              
                now_utc=new Date(row.startTime);
                now_utc.setHours(now_utc.getHours()+8);
                startdate=Date.parse(new Date(now_utc.toUTCString()));
                startdate = moment(new Date(startdate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");
                
              }
              else if((row.timezone==="UTC")|| (row.timezone===null))
              {
                startdate=row.startTime;               
              }
            
              return(startdate);
            }},
            { title: "End Date", data: function(row)
            {
              let enddate,now_utc;
              if (row.timezone==="PST")
              {
                now_utc=new Date(row.endTime);
                now_utc.setHours(now_utc.getHours()-7);
                enddate=Date.parse(new Date(now_utc.toUTCString()));  
                enddate = moment(new Date(enddate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                enddate = enddate.format("YYYY-MM-DDTHH:mm:ss");
              }
              else if(row.timezone==="CST")
              {  
                now_utc=new Date(row.endTime);
                now_utc.setHours(now_utc.getHours()+8);
                enddate=Date.parse(new Date(now_utc.toUTCString()));            
                enddate = moment(new Date(enddate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                enddate = enddate.format("YYYY-MM-DDTHH:mm:ss");
              }
              else if((row.timezone==="UTC")|| (row.timezone===null))
              {
                 enddate=row.endTime;
              }
              return(enddate);

            }},
            { title: "Point of Contact", data:"engContact"},
            { title: "PM Contact", data:'pmcontact'},
            ],
           data: this.props.events,
           ordering: true,
           pageLength : 6,
           lengthMenu: [[5, 10, 20, -1], [5, 10, 20, 'Todos']],
            bLengthChange: false,
            bFilter: true,
            bAutoWidth: false,
            autoWidth: false,
            columnDefs : [
                {   
                   targets : [3,4,5,6],
                   "createdCell": function (td, cellData, rowData, row, col) {
                    if (cellData) {
                      $(td).attr('title', cellData);
                    }
                  }
                }
             ]
            
        });  
        
        var childData = this.props.events;
        $('#createdrequests tbody').on('click', 'td.details-control', function(){
            var tr = $(this).closest('tr');
            var row = table.row( tr );
            
          if ( row.child.isShown() ) {
              // This row is already open - close it
              row.child.hide();
              tr.removeClass('shown');
          }
          else {
              // Open this row
              var parentrow = row.data();
              var tabFormat = '<table class="table table-hover childtable"><caption>Event Details</caption>';      
              tabFormat = tabFormat +'<tr><td width="15%">Description :</td><td>'+parentrow.description+'</td></tr>' +'<tr><td>Event Id :</td><td width="20%">'+parentrow.eventId+'</td></tr>';                               
              row.child(tabFormat+'</table>').show();              
              tr.addClass('shown');             
          }
        });

        $('#createdrequests tbody').on('keydown',  'td.details-control',function(e) {
          const keyD = e.key !== undefined ? e.key : e.keyCode;
          // e.key && e.keycode have mixed support - keycode is deprecated but support is greater than e.key
          // I tested within IE11, Firefox, Chrome, Edge (latest) & all had good support for e.key
        
            if ( (keyD === 'Enter' || keyD === 13) || (['Spacebar', ' '].indexOf(keyD) >= 0 || keyD === 32)) {
            // In IE11 and lower, e.key will equal "Spacebar" instead of ' '
        
            // Default behavior is prevented to prevent the page to scroll when "space" is pressed
            e.preventDefault();
            this.click();
          }
        });
    }
    

render(){

    return (
    <div className="row" style={{ paddingTop: "30px" }}>
    <div className ="col-md-12"> 
    <div className="inline-headers">
            <h4 className = "portal-name" style={{ fontSize: "1.1rem" }}>
              Created Requests
            </h4>
          </div>
    <table class="table table-hover createdrequests" width= "100%"
                 id = "createdrequests" ref={(el) => (this.el = el)}>
                   <caption>Created Requests</caption>
    </table> 
    {/* <span className ="note"><i>(Sev4 data displayed only for last 7 days)</i></span>      */}
    </div>                
  </div>
) 
}
}

export default CreatedRequests;