import {fetchToken} from '../../Util.js';
import React, { Component } from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import {Button,ButtonToolbar} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import './ApprovalTable.css';
import LoadingOverlay from 'react-loading-overlay';
// @ts-ignore
import cellEditFactory from "react-bootstrap-table2-editor";
import CustomSpinner from "../../common/Spinner/Spinner";
import {ButtonType} from 'office-ui-fabric-react/lib/Button';
import uuid from 'react-native-uuid';
import {appInsights} from '../../common/AppInsights/AppInsights';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";
import '../../common/AlertDialog/AlertDialog.scss'
import AlertDialog from '../../common/AlertDialog/AlertDialog.js';
import InternalServerError from "../../common/ErrorPages/InternalServerError";
import EditModalPopUp from "../../components/EditEvent/EditEvent";
import EditSAPEventModalPopUp from "../../components/EditEvent/EditSAPEvent";
import EditChannelEventModalPopUp from "../../components/EditEvent/EditChannelManagerEvent";
import EditSAPReleaseEventModalPopUp from "../../components/EditEvent/EditSAPReleaseEvent";
import moment from 'moment';
import * as Datetime from 'react-datetime';

var ReturnStatus = 200;
class ApprovalScreen extends Component {
constructor(props)
{
  super(props);
  
  this.state = {
    products: [],
    flipDialog:null,
    showDialog:false,
    event:{},
    showEditModal:props.show,
    isdisabled : false,
    showErrorDialog:false,
    showNoApprovalsDialog:false,
    showNoEventsApprovalsDialog:false,
    showApprovalDialog:false,
    isLoading: false,
    approvalLoading : false,
    type:'',
    fullapprove:'',
    outageid :null,
    pmcontact:null,
    canEdit: true, 
    showDialog:false,
    title : '',
    subText :'',
    buttonLabel : '',
    blocking : false,
    rejectreasons:'',
    L1Approvers:props.l1approvers,
    columns: [
          
      {
      dataField: 'title',
      text: 'Title',
      editable:false,
      headerStyle: { backgroundColor: '#add8e6'}
    },
    {
      dataField: 'timezone',
      text: 'Timezone',
      editable:false,
      headerStyle: { backgroundColor: '#add8e6'}
    },
    {
      dataField: 'startTime',
      text: 'Start Time',
      editable:false,
      sort: true,
      formatter: this.convertUTCStartDate,
      headerStyle: { backgroundColor: '#add8e6'}
    },
    {
      dataField: 'endTime',
      text: 'End Time',
      editable:false,
      sort: true,
      formatter: this.convertUTCEndDate,
      headerStyle: { backgroundColor: '#add8e6'}
    },      
    {
      dataField: 'impact',
      text: 'Impact',
      editable:false,
      headerStyle: { backgroundColor: '#add8e6'},  
    }, 
    {
      dataField: 'pmcontact',
      text: 'Approver Contact',
      sort: true,
      headerStyle: { backgroundColor: '#add8e6'}
    },
    {
      dataField: "view",
      text: "More Details",
      editable:false,
      formatter: this.GetActionFormat,
      headerStyle: { backgroundColor: '#add8e6'}
    }     
  ]
  }

  this.cellEditProp = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: this.onAfterSaveCell
  };
  this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
  this.ApproveRejectEvent = this.ApproveRejectEvent.bind(this);
  this.flip = this.flip.bind(this);
}

GetActionFormat = (cell, row) => {
  return (
    <div>
      <Link
        onClick= {() => this.showEditModal(row)}>
        More Details
      </Link>
    </div>
  );
};

convertUTCStartDate=(cell,row)=>
{
  let startdate;
  
              
              if (row.timezone==="PST")
              {
                  startdate=Date.parse(new Date(this.convertUTCDateToPST(new Date(row.startTime))));
                  startdate = moment(new Date(startdate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                  startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");
                 
              }
              else if(row.timezone==="CST")
              {              
                startdate=Date.parse(new Date(this.convertUTCDateToCST(new Date(row.startTime))));
                startdate = moment(new Date(startdate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");
                
              }
              else if((row.timezone==="UTC")|| (row.timezone===null))
              {
                startdate=row.startTime;
               
              }
            
  return(startdate);
};

convertUTCEndDate=(cell,row)=>
{
  let enddate;
              if (row.timezone==="PST")
              {
                  enddate=Date.parse(new Date(this.convertUTCDateToPST(new Date(row.endTime))));
                  enddate = moment(new Date(enddate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                  enddate = enddate.format("YYYY-MM-DDTHH:mm:ss");
              }
              else if(row.timezone==="CST")
              {              
                enddate=Date.parse(new Date(this.convertUTCDateToCST(new Date(row.endTime))));
                enddate = moment(new Date(enddate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                enddate = enddate.format("YYYY-MM-DDTHH:mm:ss");
              }
              else if((row.timezone==="UTC")|| (row.timezone===null))
              {
                 enddate=row.endTime;
              }
              return(enddate);
}

 // convert UTC date to CST date .
 convertUTCDateToCST(date)
 {
   var now_utc =  date;
   now_utc.setHours(now_utc.getHours()+8);
   return now_utc.toUTCString();
 }
 // convert UTC date to PST date . 
 convertUTCDateToPST(date)
 {
   var now_utc =  date;
   var isDST=this.checkDateForDaylightSaving(date);

   if (isDST===true)
   {
        now_utc.setHours(now_utc.getHours()-7); //during daylight saving months .
   }
   else
   {
        now_utc.setHours(now_utc.getHours()-8);
   }
   return now_utc.toUTCString();
 }

 //calculate daylight saving start date , end date  in a year . 
  
 calculateDSTStartDate()
 {
   const days = {
     Mon: 1,
     Tue: 2,
     Wed: 3,
     Thu: 4,
     Fri: 5,
     Sat: 6,
     Sun: 0
   }
  
   var weekNumber,day,monthMoment;
   var year=new Date().getFullYear();
   var marchdate=year+'-03-01';
   var dststartdate;

   // 2nd Sunday of March . 
   weekNumber=2;
   day=days.Sun;
   monthMoment=moment(marchdate);
  
   let m = monthMoment.clone()
      .startOf('month')                     
      .day(day)
     if (m.month() !== monthMoment.month()) 
     {
       m.add(7, 'd');
     }
     //dststartdate= m.add(7 * (weekNumber - 1), 'd').format('YYYY-MM-DDTHH:mm:ss.fff Z');
     dststartdate= m.add(7 * (weekNumber - 1), 'd');
     dststartdate=moment(dststartdate).add(2, 'hours');
     return dststartdate;            
    
 }

 calculateDSTEndDate()
 {
   const days = {
     Mon: 1,
     Tue: 2,
     Wed: 3,
     Thu: 4,
     Fri: 5,
     Sat: 6,
     Sun: 0
   }
  
   var weekNumber,day,monthMoment;
   var year=new Date().getFullYear();
   var novdate=year+'-11-01';
   var dstenddate;

   // 1st Sunday of November .  
   weekNumber=1;
   day=days.Sun;
   monthMoment=moment(novdate);

   let m = monthMoment.clone()
   .startOf('month')                     
   .day(day)
    if (m.month() !== monthMoment.month()) 
   {
      m.add(7, 'd');
   }
   //dstenddate= m.add(7 * (weekNumber - 1), 'd').format('YYYY-MM-DDTHH:mm:ss.fff Z');
   dstenddate= m.add(7 * (weekNumber - 1), 'd');
   dstenddate=moment(dstenddate).add(2, 'hours');
   return dstenddate;        
    
 }
 //check daylight saving is active or not . 
 checkDateForDaylightSaving(date)
 {
    var DaylightStartDate= this.calculateDSTStartDate();
    var DaylightEndDate=this.calculateDSTEndDate();
    if(date >= DaylightStartDate && date <=DaylightEndDate)
    {
      return true;
    }
    else
    {
      return false;
    }
 }

RemoveEvent = (outageId) => {  
    const pointerToThis = this; 
    var id = outageId;   
    this.setState({
      products:pointerToThis.state.products.filter(x=>x.eventId !== outageId)}); 
    this.closeModalPopUp(false);
}

UpdateEvent = (event) => {      
  let prevEvents = this.state.products;
  var values = prevEvents; 
  let currentEvents = prevEvents.findIndex((newevent)=>{return newevent.eventId == event.EventId})
  values[currentEvents] = event;
  values[currentEvents]["allDayEvent"] = event.AllDayEvent;
  values[currentEvents]["description"] = event.Description;
  values[currentEvents]["endTime"] = event.EndTime;
  values[currentEvents]["eventId"] = event.EventId;
  values[currentEvents]["externalContact"] = event.extContact;
  values[currentEvents]["factoryName"] = event.factory;
  values[currentEvents]["impact"] = event.Impact;
  values[currentEvents]["location"] = event.Location;
  values[currentEvents]["nextSteps"] = event.NextSteps;
  values[currentEvents]["serviceGroup"] = event.servicegroup;
  values[currentEvents]["startTime"] = event.StartTime;
  values[currentEvents]["teamGroup"] = event.teamgroup;
  values[currentEvents]["title"] = event.title;
  values[currentEvents]["pmcontact"] = event.pmContact;
  this.setState({products:values});
  //this.closeModalPopUp(false);
}

closeModalPopUp = (show) => {
  this.setState({showEditModal:show});          
}  

showEditModal = (data) =>
{
  var bodyvalues='';
  if(this.props.role=='BusinessUser SAP' || this.props.role=='BusinessUser SAPReader') // this section is for SAP Release Calendar events .
  {
    bodyvalues = JSON.stringify({
      outageId:data.eventId,
      outageTitle:data.title,
      organization:data.organization,
      outageDescription:data.description,
      category:data.category,
      location:data.location,
      outageType:data.outageType,
      allDay: data.allDayEvent,
      recurrence: data.recurrence,
    //  end: new Date(data.endTime),
    //  start: new Date(data.startTime),
    //  end : Date.parse(new Date(this.formatUTCDate(new Date(data.endTime)))),
    //  start : Date.parse(new Date(this.formatUTCDate(new Date(data.startTime)))),
      end : data.timezone==="PST" ?  Date.parse(new Date(this.convertUTCDateToPST(new Date(data.endTime))))  : data.timezone==="CST" ?  Date.parse(new Date(this.convertUTCDateToCST(new Date(data.endTime)))) :  Date.parse(new Date(this.formatUTCDate(new Date(data.endTime)))) ,
      start : data.timezone==="PST" ?  Date.parse(new Date(this.convertUTCDateToPST(new Date(data.startTime))))  : data.timezone==="CST" ?  Date.parse(new Date(this.convertUTCDateToCST(new Date(data.startTime)))) :  Date.parse(new Date(this.formatUTCDate(new Date(data.startTime)))),
      title:data.title,
      nacategory:data.naCategory,
      Timezone:data.timezone       
         
   })     
  
  }
  else
  {
    bodyvalues = JSON.stringify({
      outageId:data.eventId,
      outageTitle:data.title,
      organization:data.organization,
      outageDescription:data.description,
      outageImpact:data.impact,
      pmContact:data.pmcontact,
      engContact:data.engContact,
      extContact:data.externalContact,
      outageType:data.outageType,
      region:data.region,
      country:data.country,
      teamgroup:data.teamGroup,
      service:data.service,
      component:data.component,
      allDay: data.allDayEvent,
    //  end: new Date(data.endTime),
    //  start: new Date(data.startTime),
    //  end : Date.parse(new Date(this.formatUTCDate(new Date(data.endTime)))),
    //  start : Date.parse(new Date(this.formatUTCDate(new Date(data.startTime)))),
      end : data.timezone==="PST" ?  Date.parse(new Date(this.convertUTCDateToPST(new Date(data.endTime))))  : data.timezone==="CST" ?  Date.parse(new Date(this.convertUTCDateToCST(new Date(data.endTime)))) :  Date.parse(new Date(this.formatUTCDate(new Date(data.endTime)))) ,
      start : data.timezone==="PST" ?  Date.parse(new Date(this.convertUTCDateToPST(new Date(data.startTime))))  : data.timezone==="CST" ?  Date.parse(new Date(this.convertUTCDateToCST(new Date(data.startTime)))) :  Date.parse(new Date(this.formatUTCDate(new Date(data.startTime)))),
      title:data.title,
      status:data.approvalStatus,
      servicegroup:data.serviceGroup,
      factory:data.factoryName,
      division:data.division,
      orgName:data.orgName,
      eventstatus:data.eventStatus,
      nextsteps:data.nextSteps,
      nacategory:data.naCategory,
      incidentNumber:data.incidentNumber ,
      Timezone:data.timezone,
      severity:data.severity,
      bridgeurls:data.bridgeUrls,
      updatedata:data.updateData,
      resolutiondata:data.resolutionData,
      timelinedata:data.timelineData         
         
   })     
  
  }
  var values = JSON.parse(bodyvalues);  
  
   this.setState( {event:values,showEditModal:true});
   
} 

onAfterSaveCellNew (row, cellName, cellValue) 
  {
   this.setState({pmcontact:cellValue.pmcontact});   
  }
// actionsFormatter = (cell, row) => <ActionsFormatter id={row.id} />;

flip = async() => 
{
  this.setState({showDialog:!this.state.showDialog})
}


handleOnSelectAll = (isSelect, rows) => {
  console.log("rows",rows);
} 

componentDidUpdate()
{
  var index, list = [];
  var inputs = document.getElementsByTagName('input');
  for (var inputIndex = 0; inputIndex < inputs.length; inputIndex++) {
    if (inputs[inputIndex].type == "radio") {
      list.push(inputs[inputIndex]);
    }
  }
 for (index = 0; index < list.length; ++index) {
    list[index].setAttribute('aria-label', 'Radio');
  }
}

async componentDidMount() {

  //document.getElementsByClassName('selection-cell').setAttribute('aria-label', 'changeQuestionMessage');

    appInsights.trackTrace({ message: 'Approval Screen Loaded.' },
                     {
                         userName: this.props.user.displayName,
                         roles: this.props.role != null || '' ? this.props.role : "",
                         "ComponentId" : COMPONENT_ID,
                         "ComponentName" : COMPONENT_NAME,
                         "EnvironmentName" : window.env.ENVIRONMENT,
                         "ServiceLineName" : SERVICE_LINE,
                         "ServiceName" : SERVICE_NAME,
                         "ServiceOfferingName" : SERVICE_OFFERING,
                         "Correlation Id": uuid.v1(),
                         "Component": 'Approval Page',
                     });
   
    var token = await fetchToken();
    var correlationId = uuid.v1();
    var bearer = 'Bearer ' + token;
    var apimUrl = window.env.APIMURL + "/api/outage/getapprovals";
    this.setState({ isLoading: true})
    fetch(apimUrl,{
        method: 'GET',
        headers: {
            'authorization': bearer,
            'Accept' : 'application/json',
            'CorrelationId': correlationId,
            'Content-Type': 'application/json'
        }})
        .then(response => {
          const statusCode = response.status;
          const data = response.json();
          return Promise.all([statusCode, data]);          
        })       
        .then((res) => {
          //const { statusCode, data } = res;
          const statusCode = res[0];
          const data = res[1];                  
          this.setState({ products: data,isLoading: false,ReturnStatus: statusCode
          });
          if(data.length > 0)
          {
            this.setState({showNoApprovalsDialog:false});
          }
          else
           this.setState({showNoApprovalsDialog:true});
        })
        .catch(error => {

          var err = {
            "message": "Exception occured Approval page while getting the approvals for the logged in user",
            "ComponentId" : COMPONENT_ID,
                         "ComponentName" : COMPONENT_NAME,
                         "EnvironmentName" : window.env.ENVIRONMENT,
                         "ServiceLineName" : SERVICE_LINE,
                         "ServiceName" : SERVICE_NAME,
                         "ServiceOfferingName" : SERVICE_OFFERING,
            "Component": 'Approval Page',
             "userName": this.props.userEmail,
             "roles": this.props.role != null || '' ? this.props.role : "",
             "exception": error.message,
             "Correlation Id": correlationId
          }
            appInsights.trackException({exception: error,properties : err});  
        })
      
    
}

successDialog()
{
 
  this.setState( {showApprovalDialog: true});
} 

errorDialog()
{
  
  this.setState( {showErrorDialog: true,approvalLoading:false});
} 

approveDialog = async(event) => {
  this.setState( {showDialog: false});  
  await this.cofirmApproveReject();  
}

closeDialog = async() => {
  const { onClickCancel } = this.props;
  if (typeof onClickCancel === "function") { await onClickCancel(); }
  this.setState( {showErrorDialog:false,outageid :'',
    pmcontact:'' } );
}

cofirmApproveReject = async() =>
{
  const pointerToThis = this;
  const type = this.state.type;
  this.setState({ approvalLoading: true})
  const fullapprove = this.state.fullapprove;
  let outageID = this.state.outageid;
  let pmcontact = this.state.pmcontact;
  let rejectreason=this.state.rejectreasons;
  var correlationId = uuid.v1();
  var apimUrl = window.env.APIMURL + "/api/outage/approveoutage/?outageid=";
  // Service Delete Operetion
  let url = apimUrl
  + outageID + '&approvalflag=' + type + '&completeapproval=' + fullapprove + '&pmcontact=' + pmcontact + '&rejectreason=' + rejectreason ;
  var token = await fetchToken();  
  var bearer = 'Bearer ' + token;
  var bodyvalues = JSON.stringify({
    outageid : outageID,
   })     
  fetch(url, {
    method: 'PUT',
    headers: {
    //'Accept': 'application/json',
    //'Access-Control-Allow-Origin': '*',
    'CorrelationId': correlationId,
    'Content-Type': 'application/json',
    'authorization': bearer,
    },
  }).then(response => {    
    this.setState({ ReturnStatus: response.status});
    if(response.status != 400)
    {
      this.setState({
        products:pointerToThis.state.products.filter(x=>x.eventId !== pointerToThis.state.outageid),outageid :'',
        pmcontact:'',approvalLoading : false
      },this.successDialog);
    }
    else
    {
      this.errorDialog();
    }
    
    //return response.json()
  }).catch(error =>
      {
      var err = 
      {
        "message": "Exception occured in Approval page while approving the request or reject failure error",
        "ComponentId" : COMPONENT_ID,
        "ComponentName" : COMPONENT_NAME,
        "EnvironmentName" : window.env.ENVIRONMENT,
        "ServiceLineName" : SERVICE_LINE,
        "ServiceName" : SERVICE_NAME,
         "ServiceOfferingName" : SERVICE_OFFERING,
        "Component": 'Approval Page Approve/Reject',
         "userName": this.props.userEmail,
         "roles": this.props.role != null || '' ? this.props.role : "",
         "exception": error.message,
         "Correlation Id": correlationId
      }
       appInsights.trackException({exception: error,properties : err});  
   })
}


ApproveRejectEvent = async(param1,param2,param3)=>{ 
  let showPopup;
  if(param1 == '1' && param2 == '0') 
  {
    showPopup = 'Approve';
  }
  else if(param1 == '0' && param2 == '0')
  {
    showPopup = 'Reject';
  }
  else if(param1 == '1' && param2 == '1')
  {
    showPopup = 'FullApprove';
  }
  
  this.setState({type:param1,fullapprove:param2,flipDialog:showPopup});
  if(this.state.outageid == null || this.state.outageid == "")
  {
    this.setState({showNoEventsApprovalsDialog:true});
    return;
  }
  var shouldDelete = await this.flip();   
}

cancelDialog = async () => {
  const { onClickCancel } = this.props;
  if (typeof onClickCancel === "function") { await onClickCancel(); }
  this.setState( {showDialog: false,outageid :'',
  pmcontact:''});
}

formatUTCDate(date)
    {
      var now_utc =  date;
      return now_utc.toUTCString('MM/DD/YYYY hh:mm:ss A');
    }

closeApprovalDialog = async () => {
  const { onClickCancel } = this.props;
  if (typeof onClickCancel === "function") { await onClickCancel(); }
  this.setState( {showApprovalDialog: false,outageid :'',
  pmcontact:''});
}

closeNoEventsDialog = async () => {
  const { onClickCancel } = this.props;
  if (typeof onClickCancel === "function") { await onClickCancel(); }
  
    this.setState( {showNoEventsApprovalsDialog: false,outageid :'',
    pmcontact:''});
  }


render() {
 
    if(this.state.ReturnStatus === 500)
    {
      return <InternalServerError/>;
    }
    const { showAddEventModal,showEditModal,event} = this.state;
    let EditEvent = null;
    let title,subText,okButtonLabel,cancelButtonLabel;  
    const options = {
      paginationSize: 4,
      sizePerPage: 10, 
      pageStartIndex: 1,
      //alwaysShowAllBtns: true, // Always show next and previous button
      //withFirstAndLast: false, // Hide the going to First and Last page button
      hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: 'First',
      prePageText: 'Prev',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
  };
    
    const selectRow1 = {
      mode: 'radio',
      classes: 'selectrow',
      classes: (row, rowIndex) => { return 'selectrow'},
      hideSelectAll: true, // Toggle this flag to enable select all option
      clickToSelect: true,
      clickToEdit: true,
     // bgColor: '#add8e6',
      onSelect: (row, isSelect, rowIndex, e) => {        
          this.setState({outageid : row.eventId,pmcontact: row.pmcontact});        
          var status = row.eventStatus;
          if(status === "Shiproom")
          {
            this.setState({isdisabled:true});
          }
      }
    };

    function onAfterSaveCell(row, cellName, cellValue) {
      
      //alert('Save cell ${cellName} with value ${cellValue}');
    
      this.setState({pmcontact:'${cellValue.pmcontact}'})
      // let rowStr = '';
      // for (const prop in row) {
      //   rowStr += prop + ': ' + row[prop] + '\n';
      // }
    
      //alert('The whole row :\n' + rowStr);
    }
    
    function onBeforeSaveCell(row, cellName, cellValue) {
      // You can do any validation on here for editing value,
      // return false for reject the editing
      return true;
    }
    
    function onBeforeSaveCellAsync(row, cellName, cellValue, done) {
      // if your validation is async, for example: you want to pop a confirm dialog for user to confim
      // in this case, react-bootstrap-table pass a callback function to you
      // you are supposed to call this callback function with a bool value to perfom if it is valid or not
      // in addition, you should return 1 to tell react-bootstrap-table this is a async operation.
    
      // I use setTimeout to perform an async operation.
      // setTimeout(() => {
      //   done(true);  // it's ok to save :)
      //   done(false);   // it's not ok to save :(
      // }, 3000);
      // return 1;  // please return 1
    }

  const {role} = this.props;
  let validRole;
  if(role == 'Admin' || role == 'Approver')
  {
    validRole = true;
  } 
  else validRole = false;  
    if (this.state.isLoading)
    {
    return <CustomSpinner class="ms-Spinner ms-Spinner--large" label="Loading" />;
    }

    if(showEditModal)
    {
      let role = this.props.role;
      let previousRole = event["nacategory"];
      let extContact=this.state.event.extContact;
      
      if(role === "BusinessUser ChannelManagers") 
      {
        if(previousRole === "BusinessUser ChannelManagers")
        {
          EditEvent =
          <EditChannelEventModalPopUp
          show={this.state.showEditModal}
          userEmail = {this.props.user.email}
          role = {this.props.role}  
          event={this.state.event}  
          closeModalPopUpFunc = {this.closeModalPopUp}      
          removeEvent = {this.RemoveEvent} 
          updateEvent = {this.UpdateEvent}
          
          />
        }
        else if ((previousRole === "BusinessUser SAP")||(previousRole === "BusinessUser SAPReader"))
         {
          EditEvent = <EditSAPReleaseEventModalPopUp
          show= {this.state.showEditModal}
          event = {this.state.event}
          closeModalPopUpFunc = {this.closeModalPopUp} 
          userEmail = {this.props.user.email}
          role = {this.props.role}
          removeEvent = {this.RemoveEvent}  
          updateEvent = {this.UpdateEvent}     
          
          /> 
         }
         else if(previousRole==="N.A" && extContact!==null )  // partner event
         {
          EditEvent =
          <EditModalPopUp
          show={this.state.showEditModal}
          userEmail = {this.props.user.email}
          role = {this.props.role}  
          event={this.state.event}  
          closeModalPopUpFunc = {this.closeModalPopUp}      
          removeEvent = {this.RemoveEvent} 
          updateEvent = {this.UpdateEvent}                  
          />
         }
          else
          {
            EditEvent =
            <EditModalPopUp
            show={this.state.showEditModal}
            userEmail = {this.props.user.email}
            role = {this.props.role}  
            event={this.state.event}  
            closeModalPopUpFunc = {this.closeModalPopUp}      
            removeEvent = {this.RemoveEvent} 
            updateEvent = {this.UpdateEvent}                    
        />
          }
        }
      else if((role === "BusinessUser SAP")||(role === "BusinessUser SAPReader"))
      {
        if(previousRole === "BusinessUser ChannelManagers")
        {
          EditEvent =
          <EditChannelEventModalPopUp
          show={this.state.showEditModal}
          userEmail = {this.props.user.email}
          role = {this.props.role}  
          event={this.state.event}  
          closeModalPopUpFunc = {this.closeModalPopUp}      
          removeEvent = {this.RemoveEvent} 
          updateEvent = {this.UpdateEvent}
          
          />
        }
        else if ((previousRole === "BusinessUser SAP")||(previousRole === "BusinessUser SAPReader"))
         {
          EditEvent = <EditSAPReleaseEventModalPopUp
          show= {this.state.showEditModal}
          event = {this.state.event}
          closeModalPopUpFunc = {this.closeModalPopUp} 
          userEmail = {this.props.user.email}
          role = {this.props.role}
          removeEvent = {this.RemoveEvent}  
          updateEvent = {this.UpdateEvent}    
          
          /> 
         }
         else if(previousRole==="N.A" && extContact!==null )  // partner event
         {
          EditEvent =
          <EditModalPopUp
          show={this.state.showEditModal}
          userEmail = {this.props.user.email}
          role = {this.props.role}  
          event={this.state.event}  
          closeModalPopUpFunc = {this.closeModalPopUp}      
          removeEvent = {this.RemoveEvent} 
          updateEvent = {this.UpdateEvent}  
                
          />
         }
         else
         {
          EditEvent =
          <EditModalPopUp
          show={this.state.showEditModal}
          userEmail = {this.props.user.email}
          role = {this.props.role}  
          event={this.state.event}  
          closeModalPopUpFunc = {this.closeModalPopUp}      
          removeEvent = {this.RemoveEvent} 
          updateEvent = {this.UpdateEvent} 
          
      />
         }
      }  
      
      else
      {
        if(previousRole === "BusinessUser ChannelManagers")
        {
          EditEvent =
          <EditChannelEventModalPopUp
          show={this.state.showEditModal}
          userEmail = {this.props.user.email}
          role = {this.props.role}  
          event={this.state.event}  
          closeModalPopUpFunc = {this.closeModalPopUp}      
          removeEvent = {this.RemoveEvent} 
          updateEvent = {this.UpdateEvent}
          
          />
        }
        else if ((previousRole === "BusinessUser SAP")||(previousRole === "BusinessUser SAPReader"))
         {
          EditEvent = <EditSAPReleaseEventModalPopUp
          show= {this.state.showEditModal}
          event = {this.state.event}
          closeModalPopUpFunc = {this.closeModalPopUp} 
          userEmail = {this.props.user.email}
          role = {this.props.role}
          removeEvent = {this.RemoveEvent}  
          updateEvent = {this.UpdateEvent} 
          
          /> 
         }
         else if(previousRole==="N.A" && extContact!==null) // partner event
         {
          EditEvent =
          <EditModalPopUp
          show={this.state.showEditModal}
          userEmail = {this.props.user.email}
          role = {this.props.role}  
          event={this.state.event}  
          closeModalPopUpFunc = {this.closeModalPopUp}      
          removeEvent = {this.RemoveEvent} 
          updateEvent = {this.UpdateEvent}
            
          />
         }
         else
         {
          EditEvent =
          <EditModalPopUp
          show={this.state.showEditModal}
          userEmail = {this.props.user.email}
          role = {this.props.role}  
          event={this.state.event}  
          closeModalPopUpFunc = {this.closeModalPopUp}      
          removeEvent = {this.RemoveEvent} 
          updateEvent = {this.UpdateEvent} 
              />
         }
      }
    }
      

    if(this.state.showDialog && this.state.flipDialog == 'Approve')
    {
      title = 'Confirmation Dialog';
      subText = 'Are you sure you want to approve this event?';
      okButtonLabel = 'Approve';
      cancelButtonLabel = 'Cancel';

    } else if (this.state.showDialog && this.state.flipDialog == 'Reject')
    {
      title = 'Confirmation Dialog';
      subText = 'Are you sure you want to reject this event?';
      okButtonLabel = 'Reject';
      cancelButtonLabel = 'Cancel';
    }
    else if (this.state.showDialog && this.state.flipDialog == 'FullApprove')
    {
      title = 'Confirmation Dialog';
      subText = 'Are you sure you want to complete the approval workflow?';
      okButtonLabel = 'Approve';
      cancelButtonLabel = 'Cancel';
    
    }

    let isL1ApproverRole=false;
    
    for (var i = 0; i < this.state.L1Approvers.length; i++) 
    {
        if(this.props.user.email.toLowerCase()===this.state.L1Approvers[i]["userName"].toLowerCase())
         {
          isL1ApproverRole=true;
          break;
         }
    }
    
    return (

    <div className="container">
        
        <AlertDialog title= {'Success'} cancelButtonLabel={'Close'}
                        message= {'we have sucessfully processed your request'} open={this.state.showApprovalDialog} onClickCancel={this.closeApprovalDialog}/>
        <AlertDialog title= {'Error'} cancelButtonLabel={'Close'}
                        message= {'Approver contact group is not registered'} open={this.state.showErrorDialog} onClickCancel={this.closeDialog}/>
        <AlertDialog title= {'Warning'} cancelButtonLabel={'Close'}
                        message= {'You have not selected any events'} open={this.state.showNoEventsApprovalsDialog} onClickCancel={this.closeNoEventsDialog}/> 
        <AlertDialog title= {title} message= {subText} open={this.state.showDialog} blocking = {!this.state.blocking}
           okButtonLabel = {okButtonLabel} okButtonType = {ButtonType.Danger} cancelButtonLabel={cancelButtonLabel}
          cancelButtonType={ButtonType.Danger} onClickCancel={this.cancelDialog} onClickOk={this.approveDialog}/>
        <LoadingOverlay active={this.state.approvalLoading} spinner text='Processing...'>
        <BootstrapTable 
        striped 
        bordered 
        hover 
        condensed
        wrapperClasses="table-responsive"
        className = "Table"           
        keyField='eventId' 
        data={ this.state.products } 
        columns={ this.state.columns }
        noDataIndication={() => (<div>No Pending Approvals!</div>)} 
        // cellEdit={ cellEditProp}
        //cellEdit={this.cellEditProp}
        cellEdit={cellEditFactory({ mode: 'click',blurToSave: true,// a hook for before saving cell
        afterSaveCell: this.onAfterSaveCellNew.bind(this) }) }
        ///cellEdit={ cellEditProp }
        //selectRow={ selectRow }
        selectRow={ selectRow1}
        options={options}
        pagination={ paginationFactory(options) }
        >        
        </BootstrapTable> 
         </LoadingOverlay>
        <ButtonToolbar className = "approvalbuttons">
      { (this.state.products.length > 0 && isL1ApproverRole===true && this.props.role==='Approver')  ?     
      <Button variant="primary" size="md" active onClick={()=> {
                  this.ApproveRejectEvent(1,0,this)
                  }}>
        L1 Approve
      </Button> 
      :(this.state.products.length > 0 && this.props.role==='Admin')  ?     
      <Button variant="primary" size="md" active onClick={()=> {
                  this.ApproveRejectEvent(1,0,this)
                  }}>
        L1 Approve
      </Button>
      : null }
      &nbsp;&nbsp;&nbsp; 
      {(this.state.products.length > 0 && isL1ApproverRole===false && this.props.role==='Approver')?      
      <Button disabled={this.state.isdisabled} variant="secondary" size="md" active onClick={()=> {
                  this.ApproveRejectEvent(1,1,this)
                  }}>
        L2 Approve
      </Button>
      :(this.state.products.length > 0 && this.props.role==='Admin')?      
      <Button disabled={this.state.isdisabled} variant="secondary" size="md" active onClick={()=> {
                  this.ApproveRejectEvent(1,1,this)
                  }}>
        L2 Approve
      </Button>
      : null}
       &nbsp;&nbsp;&nbsp; 
       { this.state.products.length > 0  ?  
      <Button variant="secondary" size="md" active onClick={()=> {
                  this.ApproveRejectEvent(0,0,this)
                  }}>
        Reject
      </Button>
      : null}
     
      </ButtonToolbar>
      <br/>
      {EditEvent}
      </div>   
    );
  }

componentDidUpdate()
{
  var index, list = [];
  var inputs = document.getElementsByTagName('input');
  for (var inputIndex = 0; inputIndex < inputs.length; inputIndex++) {
    if (inputs[inputIndex].type == "radio") {
      list.push(inputs[inputIndex]);
    }
  }
 for (index = 0; index < list.length; ++index) {
    list[index].setAttribute('aria-label', 'Radio');
  }
}
}



export {ApprovalScreen};



