import React, { Component } from "react";
import {Form }  from 'react-bootstrap'
import {fetchToken} from '../../Util.js';
import { reactAI } from "react-appinsights";
import uuid from 'react-native-uuid';
import {appInsights} from '../../common/AppInsights/AppInsights';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";

export class ReactEditEventStatusDropDown extends Component{

    constructor(props)
    {
       
        super(props)        
        this.state={statusdata:[],selectedStatus:null,readOnly:true,default:null}
      
    }

    handleStatusChange = async(status) => {
        this.setState({ selectedStatus:status.target.value})
        await this.props.statusData(status.target.value);
      };

    async componentDidMount() {
        appInsights.trackTrace({ message: 'EditEventStatus Component Loaded.' },
                     {
                         "userName": this.props.email,
                         "ComponentId" : COMPONENT_ID,
                         "ComponentName" : COMPONENT_NAME,
                         "EnvironmentName" : window.env.ENVIRONMENT,
                         "ServiceLineName" : SERVICE_LINE,
                         "ServiceName" : SERVICE_NAME,
                         "ServiceOfferingName" : SERVICE_OFFERING,
                         "Correlation Id": uuid.v1(),
                         "Component": 'Event Status Page',
                     });
        var token = await fetchToken();
        var bearer = 'Bearer ' + token;
        var correlationId = uuid.v1();
        var apimUrl = window.env.APIMURL + "/api/outage/getstatusmappings"; 
        fetch(apimUrl,{
            method: 'GET',
            headers: {
                'authorization': bearer,
                'Accept' : 'application/json',
                'CorrelationId': correlationId,
                'Content-Type': 'application/json'
            }})
          .then(response => {
            return response.json();
          })        
          .then(data => {   
                   
              this.setState({ statusdata:data
              });
            })
            .catch(error => {
                var err = 
                {
                 "message": "Exception occured in EditEventStatus page while getting the Status Mappings from the DB",
                 "ComponentId" : COMPONENT_ID,
                 "ComponentName" : COMPONENT_NAME,
                 "EnvironmentName" : window.env.ENVIRONMENT,
                 "ServiceLineName" : SERVICE_LINE,
                 "ServiceName" : SERVICE_NAME,
                 "ServiceOfferingName" : SERVICE_OFFERING,
                 "Component": 'EditEventStatus Page',
                 "userName": this.props.email,
                 "exception": error.message,
                 "Correlation Id": correlationId
                }
             appInsights.trackException({exception: error,properties : err});
            })
        
    }

        render(){       
        return(          
            <div>
            <label htmlFor="outageTitle">Status</label>
           
            <Form.Control id="status" title="Select Group" as="select" disabled={this.props.readOnly} value = {this.state.selectedStatus == null | "" ? this.props.event.eventstatus :this.state.selectedStatus} onChange={this.handleStatusChange}>                   
                <option value={null}>--Select Status--</option> 
                {                    
                    [...new Set(this.state.statusdata.map((x)=>x.status))].map((todo, index) => <option value={todo} as="select" >{todo}</option> )
                }
            </Form.Control> 
                   

        </div>  
        )      
    }
}
export default ReactEditEventStatusDropDown;