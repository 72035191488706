import React, { Fragment } from 'react';
import AddEventStakeHolder from '../AdminScreens/AddEventStakeHolder';
import AddEventL1Approver from '../AdminScreens/AddEventL1Approver';
import $ from 'jquery';
import { fetchToken, getValidAdminRole, fetchRoles } from '../../Util.js';
import Health from '../../common/Images/Health.png';
import '../../OutageHub.css';

import { createScope, partnerCenterTheme, button, card } from '@harmony/enablers/react';
import { tabs, tab, tabPanel } from '@harmony/enablers/react';


// Create a Harmony Enabler scope
const scope = createScope({
    theme: partnerCenterTheme,
    reactInstance: React,
});

// Generate React components
const Button = scope.forReact(button);
const Card = scope.forReact(card);
const Tabs = scope.forReact(tabs);
const Tab = scope.forReact(tab);
const TabPanel = scope.forReact(tabPanel);



export default class OnboardFactory extends React.Component {


    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) === variable) {
                return decodeURIComponent(pair[1]);
            }
        }
    }





    render() {
        let validAdminRole = getValidAdminRole(this.props.role);

        return (
            <Fragment>

                {(validAdminRole === true) ?

                    <div>
                        <Tabs>
                            <Tab>Onboard Factory</Tab>
                            <TabPanel>Tab one content.</TabPanel>
                        </Tabs>
                    </div>
                    : null
                }


            </Fragment>
        )
    }

}