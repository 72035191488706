import React,{Fragment} from 'react';
import AddEventStakeHolder from '../AdminScreens/AddEventStakeHolder';
import AddEventL1Approver from '../AdminScreens/AddEventL1Approver';
import $ from 'jquery';
import {fetchToken,getValidAdminRole,fetchRoles} from '../../Util.js';
import Health from '../../common/Images/Health.png';
import '../../OutageHub.css';

export default class UserManagement extends React.Component
{

    
getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    console.log('Query variable %s not found', variable);
  }

       

    

    render()
    {
        //const { userEmail} = this.props.user.email;
        //let validEmail = ( this.props.user.email !== undefined ?  this.props.user.email.toLowerCase().includes("@microsoft.com") : null); 
        let validAdminRole=getValidAdminRole(this.props.role);

    return(
<Fragment>
        
    { (validAdminRole===true) ? 

        <div>
        <ul className="nav nav-tabs" id="pills-tab" role="tablist">                 
           <li className="nav-item" role="tablist" aria-label="Add StakeHolder">
           <a className="nav-link" id="pills-stakeholder-tab"   data-toggle="tab" href="#pills-stakeholder" role="tab" aria-controls="pills-stakeholder" aria-selected="false"><span style = {{"fontSize": "1.1rem"}}><img className = "text-muted" alt = "Add StakeHolder Logo" aria-label="Add StakeHolder Logo" src={Health} style={{width: '30px'}}/>&nbsp;Add StakeHolder</span></a>
           
           </li>
           <li className="nav-item" role="tablist" aria-label="Add L1Approver">
           <a className="nav-link" id="pills-l1approver-tab"  data-toggle="tab" href="#pills-l1approver" role="tab" aria-controls="pills-l1approver" aria-selected="false"><span style = {{"fontSize": "1.1rem"}}><img className = "text-muted" alt = "Add L1 Approver Logo" aria-label="Add L1 Approver Logo" src={Health} style={{width: '30px'}}/>&nbsp;Add L1 Approver</span></a>
           </li>
           <li className="nav-item" role="tab" aria-label="Extra tab"/>
        </ul> 
        </div>
        : null
    }
    
    <div className="tab-content" id="pills-tabContent">

<div className="tab-pane fade" id="pills-stakeholder" role="tabpanel" aria-labelledby="pills-stakeholder-tab">
<div className="row">  
<div id="modal-root"></div>                               
  <div className="col-md-12" style ={{'marginTop':'20px'}}>
  {validAdminRole && <div className ="col-md-12">
                  <label>&nbsp;</label></div>} 
    <AddEventStakeHolder
        userEmail = {this.props.user.email}
        role = {this.props.role}                   
    />                     
  </div>           
</div>         
</div>

<div className="tab-pane fade" id="pills-l1approver" role="tabpanel" aria-labelledby="pills-l1approver-tab">
<div className="row">  
<div id="modal-root"></div>                               
  <div className="col-md-12" style ={{'marginTop':'20px'}}>
  {validAdminRole && <div className ="col-md-12">
                  <label>&nbsp;</label></div>} 
    <AddEventL1Approver
        userEmail = {this.props.user.email}
        role = {this.props.role}                   
    />                     
  </div>           
</div>         
</div>
</div>

</Fragment>
    )
    }

}