import React,{Fragment} from 'react';
import $ from 'jquery';
import {fetchToken,getValidAdminRole,fetchRoles} from '../../Util.js';
import Health from '../../common/Images/Health.png';
import '../../OutageHub.css';
import AlertDialog from "../../common/AlertDialog/AlertDialog";
import {ReactDOM,Component} from 'react';
import {ButtonType} from 'office-ui-fabric-react/lib/Button'; 
import { Button,Modal } from 'react-bootstrap';
import Select from 'react-select';
import { reactAI } from "react-appinsights";
import uuid from 'react-native-uuid';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { trackPromise } from 'react-promise-tracker';
import {appInsights} from '../../common/AppInsights/AppInsights';
import CREWHolidayExcelTemplate from '../../common/Excel Template/CREWHoliday_BulkUpload_Template.xlsx'; 
import JDM1ProductionExcelTemplate from '../../common/Excel Template/JDM1Production_BulkUpload_Template.xlsx'; 


export default class BulkUpload extends React.Component
{  
    _isMounted = false;
    constructor(props) {
    
      super(props);
      
      const date = Date.now();
      this.state = {clicked:false,result:'',userEmail : '',addevent : false,showModal: '',
        showDialog: '',showErrorDialog : '',showNewDialog:false,newblocking:false, addOutage:[]
        , role : this.props.role,calendarType:'',calendarTypeError:'',showBulkUploadNonTemplateFileErrorDialog:'',file:'',showBulkUploadTemplateFileErrorDialog:''
      };
      
      this.handleFilePathChange = this.handleFilePathChange.bind(this);     
      this.methodInvoke = this.methodInvoke.bind(this);
      this.close = this.close.bind(this);
      this.confirmApproveReject = this.confirmApproveReject.bind(this);
      this.flip = this.flip.bind(this);
      this.checkErrors = this.checkErrors.bind(this);
       
    }
  
    appInsight = reactAI.appInsights;
    close = () => this.setState({isOpen: false}) 
  
    componentDidMount() {
      this._isMounted = true;
    }
  
    componentWillUnmount() {
      this._isMounted = false;
    }

    showDialog = async () => {
      this.setState({showDialog : true});
    };

     showErrorDialog = async () => {
        this.setState({showErrorDialog : true});
      };

      showBulkUploadNonTemplateFileErrorDialog = async () => {
        this.setState({showBulkUploadNonTemplateFileErrorDialog : true});
      }; 

      showBulkUploadTemplateFileErrorDialog = async () => {
        this.setState({showBulkUploadTemplateFileErrorDialog : true});
      }; 

      submitAlert = async(event) =>{
        this.setState({showNewDialog:!this.state.showNewDialog});
        await this.showDialog(); 
      }


    flip = async() => 
    {
     this.setState({showNewDialog:!this.state.showNewDialog})
    }

    approveDialog = async(event) => {
      this.setState( {showNewDialog: false});  
      var event = await this.confirmApproveReject(event); 
      
    }

    cancelDialog = async () => {
      const { onClickCancel } = this.props;
      if (typeof onClickCancel === "function") { await onClickCancel(); }
      this.setState( {showNewDialog: false});
    }

    closeDialog= async () => {
      this.setState( {showDialog: false,showErrorDialog: false,showBulkUploadNonTemplateFileErrorDialog:false,showBulkUploadTemplateFileErrorDialog:false} );
      //this.props.submitModalPopUpFunc(false); 
    }


    methodInvoke = async(event) =>
      {  
        let errors = null;
      try 
      {   
        event.preventDefault();
        event.persist();
       
        if(this.checkErrors())
        {          
          this.props.submitModalPopUpFunc(true);
          return;
        }
        else
        {              
           const { userEmail} = this.props;
           var event = await this.submitAlert(event);  

        }
      }
      catch (e) 
      {
         errors = e;
      }
     //Catch Block
      
    }

    checkErrors = () => {
           
      var obj = {};
      var calendarType = this.state.calendarType.value;
      var fileName = this.state.file;
      const { userEmail} = this.props;
      
    
      if (calendarType === undefined || calendarType===null) {
        this.setState({
          calendarTypeError: 'Calendar Type should be selected for file upload activity .'
        })
        obj.prop = ++obj.prop || 0;
      }
      if (fileName!==undefined && fileName!==null && fileName!=='') 
      {
         // 1st check file extension .
         var extension = new RegExp('\\.xlsx$');
         if(!fileName.name.match(extension)) 
         {          
            this.setState({
            filepathError: 'Incorrect file format has been uploaded . Please refer to download template provided above for correct file format.'
            })  
            obj.prop = ++obj.prop || 0;
         }
         else // 2nd check file name convention. 
         {
           if(calendarType==="CREW Holiday Calendar")
           {
              if (!(fileName.name.includes('CREWHoliday_BulkUpload_Template')) )
              {
                  this.setState({
                  filepathError: 'Incorrect file has been uploaded . Please refer to download template provided above for correct file.'
                  })  
                  obj.prop = ++obj.prop || 0;
              }
           }
            if(calendarType==="JDM1 Production Calendar")
            {
              if (!(fileName.name.includes('JDM1Production_BulkUpload_Template')) )
              {
                  this.setState({
                  filepathError: 'Incorrect file selected for bulk upload process. Correct template file should be used for upload process.'
                  })  
                  obj.prop = ++obj.prop || 0;
              }
            }

         }
      }
      if (fileName===undefined || fileName===null|| fileName==='') {
        this.setState({
          filepathError: 'File for upload process should be selected .'
        })  
        obj.prop = ++obj.prop || 0;
      }
      
      if(obj.prop >=0)
      {
      return true;
      }
      else
      {
      return false;
      }
    } 

    confirmApproveReject = async() =>
    {      

      const newState = this;
      //var bodyvalues = newState.state.addOutage;  

      // Create an object of formData
      let formData = new FormData();
    
      // Update the formData object
      formData.append("fileobj", newState.state.file);
      formData.append("calendartype",newState.state.calendarType.value);

      newState.setState({calendarType:'',file:null});
     

      var token = await fetchToken();  
      var correlationId = uuid.v1(); 
      var bearer = 'Bearer ' + token;   
      var apimUrlBulkUpload = window.env.APIMURL + "/api/outage/bulkupload" ;  
      
      trackPromise(
      
            fetch(apimUrlBulkUpload, {
                method: 'POST',
                headers: {
                //'Accept': 'application/json',
                //'Content-Type': 'multipart/form-data',
                'CorrelationId': correlationId,
                'authorization': bearer,
               // 'Content-Type': 'application/json'
                },
               body: formData
              }).then(response =>
                {        
                  this.setState({ ReturnStatus: response.status});  
                  if(response.status===400)
                  {
                    /*var message = response.Content.ReadAsStringAsync();
                    if(message==="NonTemplateEmptyFileError")
                    {
                      this.showBulkUploadNonTemplateFileErrorDialog();
                    }
                    if(message==="TemplateEmptyFileError")
                    {*/
                      this.showBulkUploadTemplateFileErrorDialog();
                  /*  }*/

                  }    
                return response.json()
                    })              
              .then(async returnId => {
              //  await newState.showDialog(); 
                  if (this._isMounted) {
                        this.setState({
                  result:returnId,
                  addevent : true,
                 // calendarType: '',
                 // file:null                  
                });                         
                     
              }
             })
             .catch( async error =>
                    {
                      //alert("Error Occured");  
                      await this.showErrorDialog();
                      var err = 
                      {
                          "message": "Exception occured while performing bulk upload of calendar events .",
                          "ComponentId" : COMPONENT_ID,
                          "ComponentName" : COMPONENT_NAME,
                          "EnvironmentName" : window.env.ENVIRONMENT,
                          "ServiceLineName" : SERVICE_LINE,
                          "ServiceName" : SERVICE_NAME,
                          "ServiceOfferingName" : SERVICE_OFFERING,
                          "Component" : "Calendar Bulk Upload Page",
                          "exception": error.message,
                          "Correlation Id": correlationId
                      }               
                     appInsights.trackException({exception: error,properties : err});  
                                      
        })
                
           );           
  }

    handleFilePathChange(e)
    {     
        this.setState({ file: e.target.files[0] ,filepathError:''});   
    }

    handleCalendarTypeChange = async(calendarTypeVal) => 
    {
        await this.setState
        (
          { calendarType:calendarTypeVal,calendarTypeError:''},
          () => console.log(`Option selected:`, this.state.calendarType)          
        );           
      };

      renderError(name) {
        const { calendarTypeError,filepathError} = this.state;
        switch(name) {
                 
          case  'calendarType' :
            if (calendarTypeError) {
              return <div style ={{backgroundColor:'#f8d7da'}}>{calendarTypeError}</div>;
            }
            break; 
          case  'upload' :
              if (filepathError) {
                return <div style ={{backgroundColor:'#f8d7da'}}>{filepathError}</div>;
              }
              break;         
          
          default: 
          break;       
            
          }    
        
      }       
     

    render()
    {
        let validEmail = this.props.userEmail;
        const calendarTypeArray=[
            { value: 'CREW Holiday Calendar', label: 'CREW Holiday Calendar' },
            { value: 'JDM1 Production Calendar', label: 'JDM1 Production Calendar' }
          ];

        return(

            <div> 
            <AlertDialog title= {'Confirmation Dialog'} message= {'Are you sure you want to submit?'} open={this.state.showNewDialog}
             okButtonLabel = {'Yes'} okButtonType = {ButtonType.Danger} cancelButtonLabel={'No'}
            cancelButtonType={ButtonType.Danger} onClickCancel={this.cancelDialog} onClickOk={this.approveDialog}/>
            <AlertDialog  title= {'Information Dialog'} cancelButtonLabel={'Ok'}
                            message= {'Excel data upload will be done in async mode . Once the activity is completed an email will be sent to you with details'} open={this.state.showDialog} onClickCancel={this.closeDialog}/>  
            <AlertDialog  title= {'Error'} cancelButtonLabel={'Ok'}
                            message= {'Error occured in bulk upload activity.Please try uploading the same excel file after sometime.'} open={this.state.showErrorDialog} onClickCancel={this.closeDialog}/>    
            <AlertDialog  title= {'UploadError'} cancelButtonLabel={'Ok'}
                            message= {'Error occured in bulk upload activity.Not uploaded in the desired format/template. Please upload the excel in the required format.'} open={this.state.showBulkUploadNonTemplateFileErrorDialog} onClickCancel={this.closeDialog}/>  
            <AlertDialog  title= {'UploadError'} cancelButtonLabel={'Ok'}
                            message= {'Error occured in bulk upload activity.No Data found to be Uploaded Or one or more dates exceed the 72 hours limit between start date and end date. Please upload the excel in the required format.'} open={this.state.showBulkUploadTemplateFileErrorDialog} onClickCancel={this.closeDialog}/>  


            <form id = "bulkupload" onSubmit={this.methodInvoke.bind(this)} >
            <Modal.Body style={{'maxHeight': 'calc(100vh - 210px)',  'overflowY': 'auto'}}>             
              { (validEmail) ?
              <div>  
                     <div>
                     <label htmlFor="header" class="control-label">Bulk Upload Calendar Events</label>
                     &nbsp;
                     &nbsp;
                     </div>
                     <div className="form-group required">
                       <table width="50%" role="presentation">
                         
                         <tr>
                           <td>
                        <label htmlFor="calendarType" class="control-label">Calendar Type</label>
                        <Select  required aria-label="CalendarType" name="calendartype" title="Calendar Type" 
                         isMulti = {false}
                         value={this.state.calendarType}
                         onChange={this.handleCalendarTypeChange} 
                         options={calendarTypeArray}>                                  
                        </Select>  
                        <span style={{color: '#E22335'}}>{this.renderError('calendarType')}</span>  
                        </td>
                        </tr>
                        {(this.state.calendarType.value==='CREW Holiday Calendar')?
                        <tr>
                        <td>                        
                        <a href={CREWHolidayExcelTemplate} download="CREWHoliday_BulkUpload_Template.xlsx">Download Template</a>
                        </td>
                        </tr> 
                        : (this.state.calendarType.value==='JDM1 Production Calendar') ?
                         <tr>
                         <td>                        
                         <a href={JDM1ProductionExcelTemplate} download="JDM1Production_BulkUpload_Template.xlsx">Download Template</a>
                         </td>
                         </tr>
                         : null
                         }
                        <tr>
                          <td>
                            &nbsp;
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="upload" class="control-label">Upload Excel </label>
                            <input required aria-label="upload" type="file" placeholder="File path" className="form-control" name="upload"  onChange={(e) => this.handleFilePathChange(e)}/>
                            <span style={{color: '#E22335'}}>{this.renderError('upload')}</span>  
                          </td>
                        </tr>
                        <tr>
                          <td>
                            &nbsp;
                          </td>
                        </tr>
                        <tr>
                          <td>
                          <Button aria-label="Submit Excel" form = "bulkupload" type="submit" variant="primary" disabled={(this.state.calendarType.value==='' || this.state.calendarType.value===undefined || this.state.calendarType.value===null)?"true":""}>
                             Upload
                          </Button>
                          </td>
                        </tr>
                        </table>
                     </div>              
                           
            </div>
            :null }
             </Modal.Body>
           </form> 
           
            </div>  

        )
    }

        
}