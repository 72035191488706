import React, { Component} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import '../../App.css';

class CREWHolidayCalendarFilter extends React.Component
{
    constructor(props){
        super(props);
        this.state = {
            regionfilter: "",
            value : 'All'
                  
        }

       // this.onRegionChange = this.onRegionChange.bind(this);
    }
    
    onRegionChange=async(event) =>{
        this.setState({ regionfilter:event.target.value });
        console.log(this.state.regionfilter );
        await this.props.selectedRegionData(this.state.regionfilter);
        await this.props.newevents(this.props.selectedCalendar,this.state.regionfilter,this.props.timezonefilter);
      }
     
   

    componentDidMount() {
         //some API call
        
    }
    render()
    {

     const { filter, data,selectedValue } = this.state;
        
        return (
            
            <div  className="col-12 col-md-6">
                <label> Region : </label>
                &nbsp;
            <input placeholder="APOC" type="radio" value="APOC" name="radio-group-region"  checked={this.state.regionfilter === "APOC"} onChange={this.onRegionChange}/> APOC 
            &nbsp;
            <input placeholder="AOC" type="radio" value="AOC" name="radio-group-region" checked={this.state.regionfilter === "AOC"} onChange={this.onRegionChange}/> AOC 
            &nbsp;
            <input placeholder="EOC" type="radio" value="EOC" name="radio-group-region" checked={this.state.regionfilter === "EOC"} onChange={this.onRegionChange}/> EOC 
            &nbsp;
            <input placeholder="All" type="radio" value="All" name="radio-group-region" defaultChecked onChange={this.onRegionChange}/> All 
            &nbsp;
          </div>   
         
        )
    }    
  }
export default CREWHolidayCalendarFilter;
  