import React, { Component } from "react";
import {fetchToken,fetchRoles} from '../../Util.js';
import uuid from 'react-native-uuid';
import {appInsights} from '../../common/AppInsights/AppInsights';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";
import Select from 'react-select';

export class PlantRegionDropDown extends Component{

    constructor(props){
        super(props)        
        this.state={plantName:[],region:[],regionError:'',selectedPlantName:[],selectedRegion:[],
        country:[],countryError:'',selectedCountry:[]
    
      }
    }

    handlePlantNameChange = async(selectedPlantName) => {
      await this.setState(
        { selectedPlantName},
        () => console.log(`Option selected:`, this.state.selectedPlantName)
      );
      await this.props.plantData(selectedPlantName);
            
    };

    handleRegionChange = async(selectedRegion) => {
      await this.setState(
        { selectedRegion,selectedCountry:null,regionError:''},
        () => console.log(`Option selected:`, this.state.selectedRegion)
      );
      await this.props.regionData(selectedRegion);
      await this.props.setRegionErrorValue(this.state.regionError);
      
    };
   
    handleCountryChange = async(selectedCountry) => {
      await this.setState(
        { selectedCountry,countryError:''},
        () => console.log(`Option selected:`, this.state.selectedCountry)
      );
      await this.props.countryData(selectedCountry);
      await this.props.setCountryErrorValue(this.state.countryError);
      
    };
    
   componentDidMount()
   {       
       this.GetUserPlantData();
       this.GetRegionCountryData();
   }

    async GetUserPlantData() {
       
        appInsights.trackTrace({ message: 'DropDown Component Loaded.' },
                     {
                         userName: this.props.email,
                         "ComponentId" : COMPONENT_ID,
                         "ComponentName" : COMPONENT_NAME,
                         "EnvironmentName" : window.env.ENVIRONMENT,
                         "ServiceLineName" : SERVICE_LINE,
                         "ServiceName" : SERVICE_NAME,
                         "ServiceOfferingName" : SERVICE_OFFERING,
                         "Correlation Id": uuid.v1(),
                         "Component": 'Dropdown Page',
                     });
        var token = await fetchToken();
        var correlationId = uuid.v1();
        var bearer = 'Bearer ' + token;
        var apimUrl = window.env.APIMURL + "/api/outage/getplantsbyuser"; 
        
        fetch(apimUrl,{
            method: 'GET',
            headers: {
                'authorization': bearer,
                'Accept' : 'application/json',
                'CorrelationId': correlationId,
                'Content-Type': 'application/json'
            }})
          .then(response => {
            return response.json();
          })        
          .then(data => {   
                      
                this.setState({plantName:data});
            })
            .catch(error => {
              var err =
              {
                "message": "Exception occured in Dropdown page while getting the Plant,Region,Country Mappings from the DB",
                "ComponentId" : COMPONENT_ID,
                "ComponentName" : COMPONENT_NAME,
                "EnvironmentName" : window.env.ENVIRONMENT,
                "ServiceLineName" : SERVICE_LINE,
                "ServiceName" : SERVICE_NAME,
                "ServiceOfferingName" : SERVICE_OFFERING,
                "Component": 'Dropdown Page',
                 "userName": this.props.email,
                 //roles: this.props.role != null || '' ? this.props.role : "",
                 "exception": error.message,
                 "Correlation Id": correlationId
              }
             appInsights.trackException({exception: error,properties : err});
            })
        
    }

    async GetRegionCountryData() {
       
      appInsights.trackTrace({ message: 'DropDown Component Loaded.' },
                   {
                       userName: this.props.email,
                       "ComponentId" : COMPONENT_ID,
                       "ComponentName" : COMPONENT_NAME,
                       "EnvironmentName" : window.env.ENVIRONMENT,
                       "ServiceLineName" : SERVICE_LINE,
                       "ServiceName" : SERVICE_NAME,
                       "ServiceOfferingName" : SERVICE_OFFERING,
                       "Correlation Id": uuid.v1(),
                       "Component": 'Dropdown Page',
                   });
      var token = await fetchToken();
      var correlationId = uuid.v1();
      var bearer = 'Bearer ' + token;
      var apimUrl = window.env.APIMURL + "/api/outage/getplantsregioncountry"; 
      
      fetch(apimUrl,{
          method: 'GET',
          headers: {
              'authorization': bearer,
              'Accept' : 'application/json',
              'CorrelationId': correlationId,
              'Content-Type': 'application/json'
          }})
        .then(response => {
          return response.json();
        })        
        .then(data => {   
                    
              this.setState({region:data});
          })
          .catch(error => {
            var err =
            {
              "message": "Exception occured in Dropdown page while getting the Region,Country Mappings from the DB",
              "ComponentId" : COMPONENT_ID,
              "ComponentName" : COMPONENT_NAME,
              "EnvironmentName" : window.env.ENVIRONMENT,
              "ServiceLineName" : SERVICE_LINE,
              "ServiceName" : SERVICE_NAME,
              "ServiceOfferingName" : SERVICE_OFFERING,
              "Component": 'Dropdown Page',
               "userName": this.props.email,
               //roles: this.props.role != null || '' ? this.props.role : "",
               "exception": error.message,
               "Correlation Id": correlationId
            }
           appInsights.trackException({exception: error,properties : err});
          })
      
  }

    returnValues(array)
{
  const options = array.map(v => ({
    label: v,
    value: v
  }));
  return options;
}

render(){   
    const { selectedPlantName,selectedRegion,selectedCountry} = this.state;
    //region,country dropdown data . 
    const region = [...new Set(this.state.plantName.map((p)=>p.region))];
    // const country = [...new Set(this.state.plantName.filter((p)=>{ return (( this.state.selectedPlantName.value!='' && this.state.selectedPlantName.value==p.plantName) ? this.state.selectedPlantName.value : "")}).map(x=>x.country))];
     const country=[...new Set(this.state.region.filter( (location)=>{ return (this.state.selectedRegion!=null ? (this.state.selectedRegion.value==location.region ? this.state.selectedRegion.value : ""):'')}).map(y=>y.country))];
  
     //plant dropdown data .
    const plant = [...new Set(this.state.plantName.map((p)=>p.plantName))];
    
    const plantArray = this.returnValues(plant);
    const regionArray = this.returnValues(region);      
    const countryArray = this.returnValues(country);   
    
return(    
  
            <div>
            
            <div className="form-group required">    
            <label htmlFor="region" class="control-label">Region</label>
            <div>
            <Select required aria-label="Region" name="region" title="Select Region" 
              isMulti = {false}
              value={selectedRegion}
              onChange={this.handleRegionChange}  
              options={regionArray}        
            >
            </Select>
            <span style={{color: '#E22335'}}>{this.props.regionError}</span>
            </div>
            </div>
            
            <div className="form-group">    
            <label htmlFor="plant" class="control-label">PlantName</label>
            <div>
            <Select aria-label="PlantName" name="plant" title="Select Plant" 
              isMulti = {false}
              value={selectedPlantName}
              onChange={this.handlePlantNameChange}  
              options={plantArray}        
            >
            </Select>
           
            </div>
            </div>

            <div className="form-group required">    
            <label htmlFor="country" class="control-label">Country</label>
            <div>
            <Select required aria-label="Country" name="country" title="Select Country" 
              isMulti = {false}
              value={(selectedRegion && selectedRegion.value!='')  ? selectedCountry : null}
              onChange={this.handleCountryChange}  
              options={countryArray}
            >
            </Select>       
            <span style={{color: '#E22335'}}>{this.props.countryError}</span>
            </div>
            </div>  
       
            <div>
              &nbsp;
            </div>
        </div>  
        )      
    }
}
export default PlantRegionDropDown;