import React, { Component } from 'react';
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../../App.css';
import uuid from 'react-native-uuid';
import { fetchToken } from '../../Util.js';
import { reactAI } from "react-appinsights";
import { SERVICE_NAME, SERVICE_OFFERING, COMPONENT_NAME, SERVICE_LINE, COMPONENT_ID } from "../../common/Constants/Constants";
import { appInsights } from '../../common/AppInsights/AppInsights';
import { createScope, partnerCenterTheme, menu, menuItem, dropdown, button, select, option, accordion, accordionItem, datePicker } from '@harmony/enablers/react';



// Create a Harmony Enabler scope
const scope = createScope({
  //rootElement: document.querySelector('#external-events'),
  
  rootElement: document.getElementById('root'),
  basePath: '/OutageCalendar/public/enablers',
  
  theme: partnerCenterTheme,
  //styles: [partnerCenterTheme],
  reactInstance: React,
  });


// Generate React components

const Menu = scope.forReact(menu);
const MenuItem = scope.forReact(menuItem);
const Dropdown = scope.forReact(dropdown);
const Button = scope.forReact(button);
const Select = scope.forReact(select);
const Option = scope.forReact(option);
const Accordion = scope.forReact(accordion);
const AccordionItem = scope.forReact(accordionItem);
const DatePicker = scope.forReact(datePicker);
const url = scope.makePath('icons/chevronright.svg');

// sample hard coded values for date picker
let minDate = '01/01/2023'
let maxDate = '07/01/2023'

// filter functionality
let filter = '';
let newfilter ='';
let filtersApplied = []
let  filterEventType = ''

// sets default filter to week
let getDateRange = ['month']
//let getDateRange = []
let getEventType = []

let val1 = ''
let val2 = ''


class Filters extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    //const defaultCalendar = this.state.calendars.find(calendar => calendar.calendarName === 'SCE Calendars');
    this.state = {
      date: new Date(),
      //checkboxes: [],
      events: this.props.events,
      filteredevents: this.props.filteredevents,
      newfilteredevents: [],
      newfilteredevents: this.props.newfilteredevents,
      eventsfilter: this.props.events,
      calendars:this.props.calendars,
      calendartypes: this.props.calendartypes,
      calendarfulllist: [],
      filter: "",
      userOrgName:this.props.userOrgName,
      //selectedValue: '',
      //checked: "0",
      //checked: defaultCalendar.id - 1,
      //selectedValue: `${defaultCalendar.id}-${defaultCalendar.calendarName}`,
      otherChecked: false,
      role: this.props.role,
      userEmail: '',
      data: JSON.parse(props.filters),
      // filter functionality
      filterEventType:Dropdown,
      filterDateRange:Dropdown,
      selectedFilter: MenuItem
    };
    this.close = this.close.bind(this);

    // filter functionality
    this.filterEventTypeRef = React.createRef();
    this.filterDateRangeRef = React.createRef();
    this.filterEventType = document.querySelector('#filterEventType');
    this.filterDateRange = document.querySelector('#filterDateRange');
  };
  appInsight = reactAI.appInsights;
  close = () => this.setState({ isOpen: false });
  async componentDidMount() {
    this._isMounted = true;  // set the flag to true
    const defaultCalendar = this.state.calendars.find(calendar => calendar.calendarName === 'SCE Calendars');
    this.onNewFilterChange(defaultCalendar.id, defaultCalendar.calendarName);
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidCatch(error, info) {

    this.setState({ hasError: true });
  }

  onCalendarChange = async (str1, str2) => {
    this.setState({
      checked: str1, selectedValue: str2.target.value
    });
    console.log(str1 + " - " + str2.target.value)
   
    await this.props.newevents(str2.target.value, this.props.regionfilter, this.props.timezonefilter,);

  }
  onNewFilterChange = async (str1, str2) => {
    //const defaultCalendar = this.state.calendars.find(calendar => calendar.calendarName === 'SCE Calendars');
    
    this.setState({
      checked: parseInt(str1) - 1, selectedValue: str2
      //expanded: parseInt(str1) - 1, selectedValue: str2
      
    });
    //console.log("Event happening")
    //console.log('this is the calendar change')
    //console.log(str1 + " - " + str2)
    console.log('this is the onNewFilterChange in filter.js')
    console.log('STR2: '+str2)
    console.log('REGION FILTER: '+this.props.timezonefilter,)
    console.log('TIMEZONE FILTER: '+this.props.timezonefilter,)
    console.log('GETSELECTEDDATE: '+getSelectedDateFilter())

    val1 = str1
    val2 = str2
    
    await this.props.newevents(str2, this.props.timezonefilter, this.props.timezonefilter, getSelectedDateFilter(), getSelectedEventFilter());

    
  }

  handleChange = event => {
    this.setState({ filter: event.target.value });
  };

  toggleCheckbox(index) {
    debugger;
    const { checkboxes } = this.state;
    checkboxes[index].checked = !checkboxes[index].checked;
    this.setState({
      checkboxes
    });
  }


  changeDate = async (newdate) => {
    this.setState({ date: newdate });
    await this.props.newdate(newdate);
  }

  // filter functionality
  filterPaneSearch(){
    var eventTypes =[];
    filtersApplied.length=0;
    console.log('filterpaneseach call working')
    console.log('array: '+ eventTypes);

    this.filterEventType.querySelectorAll('menuET').forEach(item => {
      this.selectedFilter = <MenuItem>{item}</MenuItem>;
      // this.selectedFilter = <MenuItem></MenuItem>item;
      if(this.selectedFilter.checked)
      eventTypes.push(this.selectedFilter.attributes.getNamedItem('value').nodeValue);
      console.log('events type array: '+ eventTypes);
      
    });
  }

  // process flow for filtering
  // filter date method is triggering when filter button is clicked -- done
  // get the filter selected from the dropdown options -- done
  // filter based on option selected -- done
  // filters in place for all options

  getSelectedDateFilter(){
    var daterange = []
    filtersApplied.length = 0
    console.log('this is filterPaneDateRange')
    console.log('this is daterange array: '+daterange)
    console.log('this is daterange array: '+daterange.length)

    this.filterDateRangeRef.current.querySelectorAll('.datefilteroption').forEach(item => {
      if (item.checked)
      //daterange = []
      daterange.push(item.attributes.getNamedItem('value').nodeValue);
    });
    getDateRange = daterange
    //return daterange
    console.log('this is updated daterange array: '+daterange)
    console.log('this is updated daterange array: '+daterange.length)

    // Add event listener to menu items
this.filterDateRangeRef.current.querySelectorAll('.datefilteroption').forEach(item => {
  item.addEventListener('click', () => {
    // Loop through all menu items
    this.filterDateRangeRef.current.querySelectorAll('.datefilteroption').forEach(otherItem => {
      // Uncheck any that are currently checked, except for the one that was just clicked
      if (otherItem !== item) {
        otherItem.checked = false;
      }
    });
  });
});


  }

  getSelectedEventFilter(){
    var eventType = []
    filtersApplied.length = 0
    console.log('this is getSelectedEventFilter function')
    console.log('this is eventType array: '+eventType)
    console.log('this is eventType array length: '+eventType.length)

    this.filterEventTypeRef.current.querySelectorAll('.eventfilteroption').forEach(item => {
      if (item.checked)
        // eventType.push(item.attributes.getNamedItem('value').nodeValue);
        eventType.push(parseInt(item.attributes.getNamedItem('value').nodeValue));
    });
    getEventType = eventType

    console.log('this is updated eventType array: '+eventType)
    console.log('this is updated eventType array length: '+eventType.length)

    this.filterEventTypeRef.current.querySelectorAll('.eventfilteroption').forEach(item => {
      item.addEventListener('click', () => {
        // Loop through all menu items
        this.filterEventTypeRef.current.querySelectorAll('.eventfilteroption').forEach(otherItem => {
          // Uncheck any that are currently checked, except for the one that was just clicked
          if (otherItem !== item) {
            otherItem.checked = false;
          }
        });
      });
    });



  }

  async filterPaneDateRange(){
    var daterange = []
    filtersApplied.length = 0
    console.log('this is filterPaneDateRange')
    console.log('this is daterange array: '+daterange)
    console.log('this is daterange array: '+daterange.length)

    this.filterDateRangeRef.current.querySelectorAll('.datefilteroption').forEach(item => {
      if (item.checked)
      daterange.push(item.attributes.getNamedItem('value').nodeValue);
    });

    console.log('this is updated daterange array: '+daterange)
    console.log('this is updated daterange array: '+daterange.length)

    if (daterange.length > 0) {
      filtersApplied.push("Date Range");
      const today = new Date();
      const startOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
      const endOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - today.getDay()));
      this.state.newfilteredevents = this.state.filteredevents.filter(event => {
        const startDate = new Date(event.start);
        if (daterange.indexOf('day') !== -1) {
          return startDate.getFullYear() === today.getFullYear() &&
            startDate.getMonth() === today.getMonth() &&
            startDate.getDate() === today.getDate();
        } else if (daterange.indexOf('week') !== -1) {
          return startDate >= startOfWeek && startDate <= endOfWeek;
        } else if (daterange.indexOf('month') !== -1) {
          return startDate.getFullYear() === today.getFullYear() &&
            startDate.getMonth() === today.getMonth();;
        }
      });
    }
    console.log('this is updated new filtered events array: '+this.state.newfilteredevents.length)
    this.state.newfilteredevents.filter(event => {console.log(event.title)})
    // await this.props.newevents(str2, this.props.regionfilter, this.props.timezonefilter);
  }

  filterPaneSearch2() {
    var eventTypes = [];
    var filterevents = [];
    console.log('filterpaneseach call working2')
    console.log('array2: ' + eventTypes);

    this.filterEventTypeRef.current.querySelectorAll('.menuET').forEach(item => {
      if (item.checked)
        eventTypes.push(item.attributes.getNamedItem('value').nodeValue);
    });

    console.log('events type array2: ' + eventTypes);

      // filter by event type
      if(eventTypes.length > 0) {
        filtersApplied.push("Event Type")
        console.log("filters applied array: "+filtersApplied)
        console.log("events lenth: "+ this.state.filteredevents.length)
        console.log("this is eventTypes array: "+eventTypes)
        this.state.filteredevents = this.state.filteredevents.filter(row => eventTypes.includes(row.status)); console.log(this.state.filteredevents)
        // this.state.events = this.state.events.filter(row => {
        //   console.log("row status: "+ row.status);
        //   return eventTypes.includes(row.status);
        // });
        // console.log(this.state.events);
        // this.state.events.forEach(event => {
        //   console.log(event.status);
        // });
      }
  }

  clearFilters() {
    //event tyoe
    this.filterEventTypeRef.current.querySelectorAll('.eventfilteroption').forEach(item => {
      if (item.checked)
        item.checked = false
      this.getSelectedEventFilter()
        //this.eventTypes = []
         // sets default filter to week
        getDateRange.push('month')
        console.log('item checked removed: ')
    });

    this.filterDateRangeRef.current.querySelectorAll('.datefilteroption').forEach(item => {
      if (item.checked)
      item.checked = false
      this.getSelectedDateFilter()
      // sets default filter to week
      getDateRange.push('month')
      //getDateRange = []

      console.log('item checked removed - date')
    });


  }




  render() {

    var isinternalUsers = (this.props.userEmail.toLowerCase().includes("microsoft.com") ? true : false); 
    var partnerOrgName="";
    if(isinternalUsers===false)
    {
      partnerOrgName=this.props.userOrgName;
    }

    const { filter, data, selectedValue } = this.state;
    const lowercasedFilter = filter.toLowerCase();
    const calendarData = data["MyCalendar"].filter(item => {
      return Object.keys(item).some(key =>
        item[key].toLowerCase().includes(lowercasedFilter)
      );
    });
    const otherCalendarData = data["OtherCalendar"].filter(item => {
      return Object.keys(item).some(key =>
        item[key].toLowerCase().includes(lowercasedFilter)
      );
    });
    const holidayCalendarData = data["HolidayCalendar"].filter(item => {
      return Object.keys(item).some(key =>
        item[key].toLowerCase().includes(lowercasedFilter)
      );
    });
    const factoryproductionCalendarData = data["FactoryProductionCalendar"].filter(item => {
      return Object.keys(item).some(key =>
        item[key].toLowerCase().includes(lowercasedFilter)
      );
    });
    return (

      

      // <div id='external-events'>
    
      <div class="external-events-container" id="external-events">

        {/* OLD DATE PICKER IN CORNER */}
        {/* <DatePicker
          selected={this.state.date}
          onChange={this.changeDate}
          value={this.state.date}
          adjustDateOnChange
          monthsShown={1}
          showYearDropdown
          dropdownMode="select"
          inline /> */}

  

<div class="filters">
  <Accordion>
    <AccordionItem> 
      <h4 slot="heading" >    Filters <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 2048 2048">
  <path d="M0 320q0-40 15-75t41-61 61-41 75-15h1664q40 0 75 15t61 41 41 61 15 75q0 82-60 139l-648 618q-14 14-25 30t-19 33q-16 35-16 76v768q0 26-19 45t-45 19q-19 0-35-11l-384-256q-29-19-29-53v-512q0-40-15-76-8-18-19-33t-26-30L60 459Q0 402 0 320zm1920-1q0-26-19-44t-45-19H192q-26 0-45 18t-19 45q0 29 20 47l649 618q47 45 73 106t26 126v478l256 170v-648q0-65 26-126t73-106l649-618q20-18 20-47z" />
</svg></h4>
     
      <Dropdown id="filterDateRange" ref={this.filterDateRangeRef}>
                <Button slot="trigger" style={{ width: '200px' }}  caret>Date Range</Button>
                <Menu style={{ width: '200px' }} >
                    <MenuItem class="datefilteroption" role="menuitemcheckbox" value="day">Events today</MenuItem>
                    <MenuItem class="datefilteroption" role="menuitemcheckbox" value="week">Events this week</MenuItem>
                    <MenuItem class="datefilteroption" role="menuitemcheckbox" value="month">Events this month</MenuItem>
                    <MenuItem class="datefilteroption" role="menuitemcheckbox" value="next-week">Events next week</MenuItem>
                    <MenuItem class="datefilteroption" role="menuitemcheckbox" value="next-month">Events next month</MenuItem>
                    <MenuItem class="datefilteroption" role="menuitemcheckbox" value="previous-month">Events previous month</MenuItem>
                </Menu>
            </Dropdown>
            <br/>
            <br/>
            <Dropdown id="filterEventType" ref={this.filterEventTypeRef}>
                <Button slot="trigger" style={{ width: '200px' }}  caret>Event Type</Button>
                <Menu style={{ width: '200px' }} >
                    <MenuItem class="eventfilteroption" role="menuitemcheckbox" value="3">Approved</MenuItem>
                    <MenuItem class="eventfilteroption" role="menuitemcheckbox" value="1">Pending Review</MenuItem>
                    {/* <MenuItem role="menuitemcheckbox" value="next-week">Events next week</MenuItem>
                    <MenuItem role="menuitemcheckbox" value="next-month">Events next month</MenuItem> */}
                </Menu>
            </Dropdown>
            <div style={{ display: 'inline-block', marginRight: '10px' }}>
  <div className="filterfooter" style={{ marginTop: '30px' }}>
    <Button appearance="primary" onClick={()=>{this.getSelectedDateFilter(); this.getSelectedEventFilter(); this.onNewFilterChange(val1,val2)}}>Filter</Button>
  </div>
</div>

<div style={{ display: 'inline-block' }}>
  <div className="filterfooter" style={{ marginTop: '30px' }}>
    <Button appearance="primary" onClick={()=>{this.clearFilters(); this.onNewFilterChange(val1,val2)}}>Clear Filters</Button>
  </div>
</div>  
    </AccordionItem>

</Accordion>
</div>


     



  

        <div className="calendar-filters" style={{ marginTop: '20px', marginLeft: '35px' }}>
          <h4 style={{ marginBottom: '20px' }}>SAMP Calendars </h4>
          </div>
          {this.state.calendartypes.map((types) => (
  <div>
<Accordion>
        <AccordionItem>
        <span slot="heading"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 2048 2048">
  <path d="M256 704q0-26 19-45t45-19q26 0 45 19l659 658 659-658q19-19 45-19t45 19 19 45q0 26-19 45l-704 704q-19 19-45 19t-45-19L275 749q-19-19-19-45z" />
  </svg>     {types.calendarTypeName} </span>

          {this.state.calendars.filter((calendar) => {
            if (types.id === calendar.calendarTypeId) {

              if (this.props.userEmail.toLowerCase().includes("microsoft.com")) {
                // Internal user, grant access to all calendars
              
                // testing purposes
                // console.log('user email: ' + this.props.userEmail);
                // console.log('calendar organisation: '+calendar.organization)
                // console.log('user organisation: '+ this.props.userOrgName)
                // console.log("user is internal")

                return true;
              } else {
                // External user, grant access based on organization
                // console.log("user is external")
                return calendar.organization.toLowerCase() === this.props.userOrgName.toLowerCase();
              }
            }
            return false;
          }).map((calendar) => (
            // each calendar that can be selected
            <p>
              <Option  value={calendar.id} onHeSelected={this.onNewFilterChange.bind(this, calendar.id, calendar.calendarName,)} style={{marginLeft: '35px' }}>
                {calendar.calendarName}
              </Option>
            </p>
          ))}
        </AccordionItem>
      </Accordion>

  </div>
))}

          {/* <h6 className="calendar-filters"><b>My Calendars</b></h6>
          {
            calendarData.map((option, i) => {
              return <div><label key={option.id}>
                <input
                  placeholder={option.name}
                  type="radio"
                  checked={this.state.checked === option.id ? true : false}
                  key={option.name}
                  onChange={this.onCalendarChange.bind(this, option.id)}
                  value={option.name} />
              </label> {option.name} </div>
            })
          }
          <h6 className="calendar-filters"><b>Other Calendars</b></h6>
          {
            otherCalendarData.map((otheroption, i) => {
              return <div><label key={otheroption.id}>
                <input
                  placeholder={otheroption.name}
                  type="radio"
                  checked={this.state.checked === otheroption.id ? true : false}
                  key={otheroption.name}
                  onChange={this.onCalendarChange.bind(this, otheroption.id)}
                  value={otheroption.name}

                />
              </label> {otheroption.name} </div>
            })
          }

          <h6 className="calendar-filters"><b>Holiday Calendars</b></h6>
          {
            holidayCalendarData.map((holidayoption, i) => {
              return <div><label key={holidayoption.id}>
                <input
                  placeholder={holidayoption.name}
                  type="radio"
                  checked={this.state.checked === holidayoption.id ? true : false}
                  key={holidayoption.name}
                  onChange={this.onCalendarChange.bind(this, holidayoption.id)}
                  value={holidayoption.name} />
              </label> {holidayoption.name} </div>
            })
          }

          <h6 className="calendar-filters"><b>Factory Production Calendars</b></h6>
          {
            factoryproductionCalendarData.map((factoryoption, i) => {
              return <div><label key={factoryoption.id} >
                <input
                  placeholder={factoryoption.name}
                  type="radio"
                  checked={this.state.checked === factoryoption.id ? true : false}
                  key={factoryoption.name}
                  onChange={this.onCalendarChange.bind(this, factoryoption.id)}
                  value={factoryoption.name} />
              </label> {factoryoption.name} </div>
            })
          } */}
        
 

      </div>
    )
  }
}
// export function getSelectedDateFilter(){
//   var daterange = []
//   filtersApplied.length = 0
//   console.log('this is filterPaneDateRange')
//   console.log('this is daterange array: '+daterange)
//   console.log('this is daterange array: '+daterange.length)

//   this.filterDateRangeRef.current.querySelectorAll('.datefilteroption').forEach(item => {
//     if (item.checked)
//     daterange.push(item.attributes.getNamedItem('value').nodeValue);
//   });
//   console.log('this is updated daterange array: '+daterange)
//   console.log('this is updated daterange array: '+daterange.length)
//   return daterange
  
// }
function getSelectedDateFilter(){
  return getDateRange
}

function getSelectedEventFilter(){
  return getEventType
}

export{ getSelectedDateFilter, getSelectedEventFilter }
export default Filters;
