import React from 'react';
import $ from 'jquery';
import '../../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css';
import '../../../node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css';
import Yes from '../../common/Images/Yes.png';
import No from '../../common/Images/NO.png';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../OutageHub.css';
    
class ServiceHealth extends React.Component{
shouldComponentUpdate(nextProps, nextState){
     //if (nextProps.events.length > 0) {
           this.reloadTableData(nextProps.events);
           return true;
       // }
       // return false;
        }

componentWillUnmount() {
    $('#servicehealth').DataTable().destroy(true);
    }
reloadTableData = (data) => {
        const table = $('#servicehealth').DataTable();
     table.clear();
     table.rows.add(data);
     table.draw();
      }

placeContentCenter(data, type, full, meta)
{
    return '<div class="d-flex justify-content-center">' + data + '</div>';
}
      
 getImg(data, type, full, meta) {
        var acknowledge = full.isAcknowledged;
        if (acknowledge === true) {
            return '<div class="d-flex justify-content-center"><img src="' + Yes + '" alt=Yes /></div>';
        } else {
            return '<div class="d-flex justify-content-center"><img src="' + No + '" alt=No/></div>';
        }
    }

 getICM(data, type, full, meta) {
        var icm = full.incidentId;
        return '<a target="_blank" href="https://portal.microsofticm.com/imp/v3/incidents/details/' + icm + '/home">'+ icm +'</a>';

        // if (acknowledge === true) {
        //     return '<div class="d-flex justify-content-center"><img src="' + Yes + '" /></div>';
        // } else {
        //     return '<div class="d-flex justify-content-center"><img src="' + No + '" /></div>';
        // }
    }

    getBridge(data, type, full, meta) {
        var url = full.bridgeUrls_SR;
        if(url.length>5)//Array.isArray(url) && 
        {
            return '<a target="_blank" href="' + url.slice(2,-2) + '">Join Bridge</a>'; //url[0]
        }
        else
        return null;
    }

    

componentDidMount() {
    this.$el = $('#servicehealth');
    
    var table = this.$el.DataTable({
           columns : [
            {
              "className": 'details-control',
              "data": null,
              "defaultContent": "",
              width: "4%",
              "aria-labelledby":"expandandcollapsebtn",
              "role":"button"
               
            },
            { title: "Service Name", data:'serviceName', width:'15%'},
            { title: "Owning Team", data:'owningTeamName',width:'12%'},
            { title: "ICM#", data:'incidentId',render: this.getICM,width:'10%'},
            // { title: "Title", data:'title'},
            { title: "Time Reported", data: 'createdTimestamp',width:'13%'},
            { title: "Detection", data:"detection",width:'12%'},
            { title: "Severity", data:'severity', render: this.placeContentCenter,width:'9%'},
            { title: "DRI", data:'isAcknowledged', render: this.getImg,width:'6%'},
            { title: "Active Bridge", data:'bridgeUrls_SR',render: this.getBridge,width:'12%'},
            ],
           data: this.props.events,
           ordering: true,
           pageLength : 6,
           lengthMenu: [[5, 10, 20, -1], [5, 10, 20, 'Todos']],
           //bPaginate : true,
                            //"scrollY":        200,
                            //"scrollCollapse": true,
            bLengthChange: false,
            bFilter: true,
            bAutoWidth: false,
            autoWidth: false,
            columnDefs : [
                {   
                   targets : [0,1,3,4],
                   "createdCell": function (td, cellData, rowData, row, col) {
                    if (cellData) {
                      $(td).attr('title', cellData);
                    }
                  }
                }
             ]
            
            // columnDefs: [

            //      {
            //         "aTargets":[6],
            //         fnCreatedCell: function(nTd, sData, oData, iRow, iCol)
            //         {
            //             $(nTd).css('vertical-align', 'middle')
            //             // $(nTd).css('border-radius', '5px')
            //             // $(nTd).css('padding', '.25rem .5rem')
            //             // $(nTd).css('display', 'inline-block')
            //             // $(nTd).css('color', 'white')
            //             // $(nTd).css('font-size', '.875rem')
            //             // $(nTd).css('line-height', '1.5')
            //             // $(nTd).css('border-radius', '.2rem')
            //             // $(nTd).css('margin', '7px')
            //             //$(nTd).css('width', '40px')
            //         }
            //      },
                    
            // ]
        });
        $('#servicehealth tbody').on('click', 'td.details-control', function(){
          var tr = $(this).closest('tr');
          var row = table.row( tr );
          
        if ( row.child.isShown() ) {
            // This row is already open - close it
            row.child.hide();
            tr.removeClass('shown');
        }
        else {
            // Open this row
            var parentrow = row.data();
            var tabFormat = '<table class="table table-hover childservicehealth">';      
            tabFormat = tabFormat +'<tr><td width="20%">Title :</td><td className = "editColumn">'+parentrow["title_LVR"]+'' +
            // '<button type="submit" id = "pendingsave"><i class="fas fa-save"></i> Save</button></td>' +
            '</td></tr>' +'<tr><td>Last Modified Time :</td><td width="20%">'+parentrow["lastUpdateTime"]+'</td></tr>';                               
            row.child(tabFormat+'</table>').show();              
            tr.addClass('shown');               
        }
      });

      $('#servicehealth tbody').on('keydown',  'td.details-control',function(e) {
        const keyD = e.key !== undefined ? e.key : e.keyCode;
        // e.key && e.keycode have mixed support - keycode is deprecated but support is greater than e.key
        // I tested within IE11, Firefox, Chrome, Edge (latest) & all had good support for e.key
      
          if ( (keyD === 'Enter' || keyD === 13) || (['Spacebar', ' '].indexOf(keyD) >= 0 || keyD === 32)) {
          // In IE11 and lower, e.key will equal "Spacebar" instead of ' '
      
          // Default behavior is prevented to prevent the page to scroll when "space" is pressed
          e.preventDefault();
          this.click();
        }
      });
        
                        
    }
    

render(){

    return (
    <div className="row" style={{ paddingTop: "30px" }}>
    <div className ="col-md-12"> 
    <div className="inline-headers">
            <h2 className = "portal-name" style={{ fontSize: "1.1rem" }}>
              IcM Status
            </h2>
          </div>
    <table class="table table-hover servicehealth" width= "100%" 
                 id = "servicehealth" ref={(el) => (this.el = el)}>
                   <caption>IcM Status</caption>
    {/* <table class="table table-striped table-hover dt-responsive display nowrap"
                 id = "servicehealth" ref={(el) => (this.el = el)}></table> */}
      {/* <thead>
        <tr>
          <th scope="col" class="w-25">Title</th>
          <th scope="col">Start Time</th>
          <th scope="col">End Time</th>
          <th scope="col" class="w-25">Impact</th>
          <th scope="col">Status</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody> */}
{/* {this.props.events.filter(x=>x.status.toLowerCase() === "approved").map(item =>  {
return (
<tr scope="row" key = {item.eventId}>
  <td>{item.title}</td>
  <td>{item.startTime}</td>
  <td>{item.endTime}</td>
  <td>{item.impact}</td>
  <td><div className ="approval">{item.status}</div></td>
  <td><Button variant="primary" size="sm" onClick={() => this.showEditModal(item)}>View Details</Button></td>                    
</tr>
)

})}
   </tbody> */}
    </table> 
    <span className ="note"><i>(Sev4 data displayed only for last 14 days)</i></span>     
    </div>                
  </div>
) 
}
}

export default ServiceHealth;