import React from "react";
import icon from '../Images/NewSearchIcon.png'
import '../../OutageHub.css'

const AccessDenied = () => (
    <div class="offset-4 col-4">

    <img class="img-responsive center-block" src={icon} style ={{'display': 'block',
'margin-left': 'auto',
'margin-right': 'auto','margin-top':'30%'}}  alt="Forbidden"/>
    <div className ="text-center"><h2 class="text-danger">403 <br/>
Sorry! Access denied :( </h2>
 </div>
 <div>
     <h10>Please drop an email to <a class="text-primary" href = "mailto:scoutagehub@microsoft.com">SCOutagehub@microsoft.com</a>. Our team will come provide you the right access.</h10> 
 </div>
 </div>
 
);

export default AccessDenied;