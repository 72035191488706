import React, { Component } from "react";
import {Form }  from 'react-bootstrap'
import {fetchToken} from '../../Util.js';
import { reactAI } from "react-appinsights";
import uuid from 'react-native-uuid';
import {appInsights} from '../../common/AppInsights/AppInsights';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";
import Select from 'react-select';


export class ReactFactoryDropDown extends Component{

    constructor(){
        super()        
        this.state={factory:[],selectedFactory:[],factoryError:''}
    }

      
      handleFactoryChange = async(selectedFactory) => {
        await this.setState(
           { selectedFactory,factoryError:''},
           () => console.log(`Option selected:`, this.state.selectedFactory)
         );
         await this.props.factoryData(selectedFactory);
        await this.props.setfactoryError(this.state.factoryError);
       };

    componentDidMount()
    {       
          this.GetData()
    }

    async GetData() {
      
        appInsights.trackTrace({ message: 'Factory Component Loaded.' },
                     {
                         userName: this.props.email,
                         "ComponentId" : COMPONENT_ID,
                         "ComponentName" : COMPONENT_NAME,
                         "EnvironmentName" : window.env.ENVIRONMENT,
                         "ServiceLineName" : SERVICE_LINE,
                         "ServiceName" : SERVICE_NAME,
                         "ServiceOfferingName" : SERVICE_OFFERING,
                         "Correlation Id": uuid.v1(),
                         "Component": 'Factory Page',
                     });
        var token = await fetchToken();
        var correlationId = uuid.v1();
        var bearer = 'Bearer ' + token;
        var apimUrl = window.env.APIMURL + "/api/outage/getfactorymappings";
        fetch(apimUrl,{
            method: 'GET',
            headers: {
                'authorization': bearer,
                'Accept' : 'application/json',
                'CorrelationId': correlationId,
                'Content-Type': 'application/json'
            }})
          .then(response => {
            return response.json();
          })        
          .then(data => {   
                     
              this.setState({ factory:data
              });
            })
            .catch(error => {
                var err = 
                {
                 "message": "Exception occured in Factory page while getting the factory details from the DB",
                 "ComponentId" : COMPONENT_ID,
                 "ComponentName" : COMPONENT_NAME,
                 "EnvironmentName" : window.env.ENVIRONMENT,
                 "ServiceLineName" : SERVICE_LINE,
                 "ServiceName" : SERVICE_NAME,
                 "ServiceOfferingName" : SERVICE_OFFERING,
                 "Component": 'Factory Page',
                 "userName": this.props.email,
                 "exception": error.message,
                 "Correlation Id": correlationId,
                }
                appInsights.trackException({exception: error,properties : err});
            })
        
    }
    returnValues(array)
{
  const options = array.map(v => ({
    label: v,
    value: v
  }));
  return options;
}

        render(){     
            const { selectedFactory} = this.state;  
            const values = [...new Set(this.state.factory.map((factory)=>factory.factory))];
            const options = this.returnValues(values);

        return(          
            <div>
            <div className="form-group required">    
            <label htmlFor="factory" class="control-label">Factory</label>
            <div>
              <Select required aria-label="Factory" name="factory" title="Select Factory" 
                isMulti = {false}
                value={selectedFactory}
                onChange={this.handleFactoryChange}  
                options={options}        
              >
              </Select>
              <span style={{color: '#E22335'}}>{this.props.factoryError}</span>
            </div>
            </div>
            </div>
               )      
    }
}

export default ReactFactoryDropDown;