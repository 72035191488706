import React, { Fragment  } from 'react';
import { getValidAdminRole} from '../../Util.js';
import '../../OutageHub.css';
import { createScope, partnerCenterTheme} from '@harmony/enablers/react';
import { tabs, tab, tabPanel } from '@harmony/enablers/react';
import AddCalendar from '../AdminScreens/AddCalendar';
import AddCalendarType from '../AdminScreens/AddCalendarType';
import AddCalendarLegend from '../AdminScreens/AddCalendarLegend';


// Create a Harmony Enabler scope
const scope = createScope({
    theme: partnerCenterTheme,
    reactInstance: React,
});

const Tabs = scope.forReact(tabs);
const Tab = scope.forReact(tab);
const TabPanel = scope.forReact(tabPanel);

export default class CalendarManagement extends React.Component {
    constructor(props) {
        super(props);
      }
    render() {
        let validAdminRole = getValidAdminRole(this.props.role);
        return (
            <Fragment>

                {(validAdminRole === true) ?
                    <div>
                        <Tabs>
                            <Tab>Calandar Types</Tab>
                            {/* <Tab>Calendar Legends</Tab> */}
                            <Tab>Calendars</Tab>

                            <TabPanel>
                            {validAdminRole && <div className="col-md-12">
                                    <label>&nbsp;</label></div>}
                                <AddCalendarType
                                    userEmail={this.props.user.email}
                                    role={this.props.role}
                                />       
                            </TabPanel>
                            {/* <TabPanel>
                            {validAdminRole && <div className="col-md-12">
                                    <label>&nbsp;</label></div>}
                                <AddCalendarLegend
                                    userEmail={this.props.user.email}
                                    role={this.props.role}
                                />       
                            </TabPanel> */}
                            <TabPanel>
                            {validAdminRole && <div className="col-md-12">
                                    <label>&nbsp;</label></div>}
                                <AddCalendar
                                    userEmail={this.props.user.email}
                                    role={this.props.role}
                                />       
                            </TabPanel>
                        </Tabs>
                    </div>
                    : null
                }
            </Fragment>
        )
    }

}