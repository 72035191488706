import moment from "moment";
import React from "react";
import { reactAI } from "react-appinsights";
import uuid from "react-native-uuid";
import { fetchToken } from "../../Util.js";
import {
  SERVICE_NAME,
  SERVICE_OFFERING,
  COMPONENT_NAME,
  SERVICE_LINE,
  COMPONENT_ID,
} from "../../common/Constants/Constants";
import ReactRegionDropDown from "../RegionDropdown/Region";
import { appInsights } from "../../common/AppInsights/AppInsights";
import AlertDialog from "../../common/AlertDialog/AlertDialog";
import { Radio, RadioGroup } from "react-radio-group";
import ReactDropDown from "../ServiceDropdown/DropDown";
import * as Datetime from "react-datetime";
import { Button, Modal } from "react-bootstrap";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactFactoryDropDown from "../FactoryDropDown/Factory";
import { ButtonType } from "office-ui-fabric-react/lib/Button";
import CKEditor from "ckeditor4-react";
import Switch from "react-switch";
import { trackPromise } from "react-promise-tracker";
import LoadingIndicator from "../../common/PromiseTracker/PromiseTracker";
import ReactEventStatusDropDown from "../EventStatusDropDown/EventStatus";

class AddChannelManagerEvent extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    const date = Date.now();
    this.state = {
      date: "",
      enddate: "",
      clicked: false,
      result: "",
      userEmail: "",
      addevent: false,
      showModal: "",
      outageTitle: "",
      outageTitleError: "",
      description: "",
      descriptionError: "",
      impact: "",
      impactError: "",
      externalcontact: "",
      externalcontactError: "",
      engcontact: "",
      engcontactError: "",
      pmcontact: "",
      pmcontactError: "",
      dateError: "",
      enddateError: "",
      alldayevent: "",
      alldayeventError: "",
      hasError: false,
      showDialog: "",
      region: "",
      country: "",
      showErrorDialog: "",
      showNewDialog: false,
      newblocking: false,
      addOutage: [],
      factoryError: "",
      factoryData: "",
      isBusinessUser: true,
      role: this.props.role,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onEndChange = this.onEndChange.bind(this);
    this.methodInvoke = this.methodInvoke.bind(this);
    //this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.close = this.close.bind(this);
    this.cofirmApproveReject = this.cofirmApproveReject.bind(this);
    this.flip = this.flip.bind(this);
  }

  appInsight = reactAI.appInsights;
  close = () => this.setState({ isOpen: false });

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  close() {
    this.setState({ showModal: false });
  }

  convertDateToUTC(date) {
    var now_utc = date;
    now_utc.setHours(now_utc.getHours() + 5);
    now_utc.setMinutes(now_utc.getMinutes() + 30);
    return now_utc.toUTCString();
  }

  handleBusinessUserChange(checked) {
    this.setState({ isBusinessUser: checked });
  }

  checkErrors = () => {
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    const {
      outageTitle,
      description,
      impact,
      externalcontact,
      engcontact,
      pmcontact,
      date,
      enddate,
    } = this.state;
    var obj = {};
    const { userEmail } = this.props;

    if (outageTitle.length == 0) {
      this.setState({
        outageTitleError: "Title field should not be blank.",
      });
      obj.prop = ++obj.prop || 0;
    }
    if (this.state.description == null || this.state.description.length == 0) {
      this.setState({
        descriptionError: "Description field should not be blank.",
      });
      obj.prop = ++obj.prop || 0;
    }

    if (impact.length == 0) {
      this.setState({
        impactError: "Impact field should not be blank.",
      });
      obj.prop = ++obj.prop || 0;
    }
    if (date == "") {
      this.setState({
        dateError: "Start Date field should not be blank.",
      });
      obj.prop = ++obj.prop || 0;
    } else if (date) {
      var m = moment(date, "YYYY-MM-DD");

      if (m.isValid() === false) {
        this.setState({
          dateError: "Start Date format is (mm/dd/yyyy hh(12 hour):mm am/pm)",
        });
        obj.prop = ++obj.prop || 0;
      }
      if (
        Date.parse(new Date(new Date().toUTCString()).toUTCString()) >
        Date.parse(this.convertDateToUTC(new Date(date)))
      ) {
        this.setState({
          dateError: "Start Datetime is less than current UTC Datetime",
        });
        obj.prop = ++obj.prop || 0;
      }
    }

    if (enddate == "") {
      this.setState({
        enddateError: "End Date field should not be blank.",
      });
      obj.prop = ++obj.prop || 0;
    } else if (moment(enddate, "YYYY-MM-DD").isValid() === false) {
      this.setState({
        enddateError: "End Date format is (mm/dd/yyyy hh(12 hour):mm am/pm)",
      });
      obj.prop = ++obj.prop || 0;
    } else if (date && enddate) {
      if (
        Date.parse(new Date(this.convertDateToUTC(new Date(date)))) >=
        Date.parse(new Date(this.convertDateToUTC(new Date(enddate))))
      ) {
        this.setState({
          enddateError: "End Datetime should be greater than Start Datetime.",
        });
        obj.prop = ++obj.prop || 0;
      }
      var startdatecompare = moment(date, "YYYY-MM-DD HH:mm:ss");
        var enddatecompare = moment(enddate, "YYYY-MM-DD HH:mm:ss");
        var diff = enddatecompare.diff(startdatecompare, 'h'); // Diff in hours
        if (diff > 72) {
          this.setState({
            enddateError: 'the period should not be superior to 72 hours'
          })  
          obj.prop = ++obj.prop || 0;
        }
    }

    if (engcontact.length == 0) {
      this.setState({
        engcontactError: "Point of Contact field should not be blank.",
      });
      obj.prop = ++obj.prop || 0;
    } else {
      var emailError = validEmailRegex.test(engcontact)
        ? ""
        : "Email is not valid!";
      this.setState({
        engcontactError: emailError,
      });
      if (emailError !== "") {
        obj.prop = ++obj.prop || 0;
      }
    }

    if (obj.prop >= 0) {
      return true;
    } else {
      return false;
    }
  };

  renderError(name) {
    const {
      outageTitleError,
      descriptionError,
      impactError,
      engcontactError,
      dateError,
      enddateError,
      alldayeventError,
      teamGroupError,
      teamServiceError,
      teamComponentError,
      serviceGroupError,
      divisionError,
      orgNameError,
    } = this.state;
    switch (name) {
      case "outageTitle":
        if (outageTitleError) {
          return (
            <div style={{ backgroundColor: "#f8d7da" }}>{outageTitleError}</div>
          );
        }
        break;
      case "description":
        if (descriptionError) {
          return (
            <div style={{ backgroundColor: "#f8d7da" }}>{descriptionError}</div>
          );
        }
        break;

      case "impact":
        if (impactError) {
          return (
            <div style={{ backgroundColor: "#f8d7da" }}>{impactError}</div>
          );
        }
        break;

      case "date":
        if (dateError) {
          return <div style={{ backgroundColor: "#f8d7da" }}>{dateError}</div>;
        }
        break;

      case "enddate":
        if (enddateError) {
          return (
            <div style={{ backgroundColor: "#f8d7da" }}>{enddateError}</div>
          );
        }
        break;

      case "engcontact":
        if (engcontactError) {
          return (
            <div style={{ backgroundColor: "#f8d7da" }}>{engcontactError}</div>
          );
        }
        break;
      default:
        break;
    }
  }

  showDialog = async () => {
    this.setState({ showDialog: true });
  };

  showErrorDialog = async () => {
    this.setState({ showErrorDialog: true });
  };

  flip = async () => {
    this.setState({ showNewDialog: !this.state.showNewDialog });
  };

  cofirmApproveReject = async () => {
    const newState = this;
    const bodyvalues = newState.state.addOutage;
    var token = await fetchToken();
    var correlationId = uuid.v1();
    var bearer = "Bearer " + token;
    var apimUrl = window.env.APIMURL + "/api/outage/addoutage";
    trackPromise(
      fetch(apimUrl, {
        method: "POST",
        headers: {
          //'Accept': 'application/json',
          "Content-Type": "application/json",
          CorrelationId: correlationId,
          authorization: bearer,
        },
        body: bodyvalues,
      })
        .then((response) => {
          return response.json();
        })
        .then(async (returnId) => {
          if (this._isMounted) {
            this.setState({
              result: returnId,
              addevent: true,
            });
            var values = JSON.parse(bodyvalues);

            values["outageId"] = returnId;
            values["start"] = new Date(values.StartTime);
            values["end"] = new Date(values.EndTime);
            values["outageTitle"] = values.title;
            values["outageDescription"] = values.Description;
            values["outageImpact"] = values.Impact;
            values["allDay"] = values.AllDayEvent;
            values["nextsteps"] = values.NextSteps;

            newState.props.addEvent(values);

            newState.setState({
              date: "",
              enddate: "",
              clicked: "",
              outageTitle: "",
              description: "",
              engcontact: "",
              teamGroup: "",
              teamService: [],
              teamComponent: "",
              impact: "",
              serviceGroup: [],
              factory: [],
              division: [],
              orgName: [],
              nextsteps: "",
            });
            await newState.showDialog();
          }
        })
        .catch(async (error) => {
          //alert("Error Occured");
          await this.showErrorDialog();
          var err = {
            message: "Exception occured while submitting an outage request",
            ComponentId: COMPONENT_ID,
            ComponentName: COMPONENT_NAME,
            EnvironmentName: window.env.ENVIRONMENT,
            ServiceLineName: SERVICE_LINE,
            ServiceName: SERVICE_NAME,
            ServiceOfferingName: SERVICE_OFFERING,
            Component: "Calendar Add SAP Event Page",
            exception: error.message,
            "Correlation Id": correlationId,
          };
          appInsights.trackException({ exception: error, properties: err });
        })
    );
  };

  submitAlert = async (event) => {
    this.setState({ showNewDialog: !this.state.showNewDialog });
  };

  approveDialog = async (event) => {
    this.setState({ showNewDialog: false });
    var event = await this.cofirmApproveReject(event);
  };

  cancelDialog = async () => {
    const { onClickCancel } = this.props;
    if (typeof onClickCancel === "function") {
      await onClickCancel();
    }
    this.setState({ showNewDialog: false });
  };

  methodInvoke = async (event) => {
    let errors = null;
    try {
      event.preventDefault();
      event.persist();
      this.setState({
        region: event.target.region.value,
        country: event.target.country.value,
      });
      if (this.checkErrors()) {
        this.props.submitModalPopUpFunc(true);
        return;
      } else {
        var title = event.target.outageTitle.value;
        var desc = this.state.description;
        var impact = event.target.impact.value;
        var businessUser = this.state.isBusinessUser;
        var startdate = moment(this.state.date, "YYYY-MM-DDTHH:mm:ss.fff Z");
        startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");
        var EndTime = moment(this.state.enddate, "YYYY-MM-DDTHH:mm:ss.fff Z");
        EndTime = EndTime.format("YYYY-MM-DDTHH:mm:ss");
        var org = null;
        var extcontact = null;
        var engcontact =
          event.target.engcontact == undefined
            ? null
            : event.target.engcontact.value;
        var pmcontact = null;
        var alldayevent = this.state.clicked;

        var bodyvalues = JSON.stringify({
          title: title,
          Description: desc,
          Impact: impact,
          StartTime: startdate,
          EndTime: EndTime,
          organization: org,
          ExternalContact: extcontact,
          engContact: engcontact,
          pmContact: pmcontact,
          outageType:
            businessUser === true
              ? "Informational " + this.state.role
              : "Planned",
          region: event.target.region.value=='--Select Region--'?'':event.target.region.value,
          country: event.target.country.value=='--Select Country--'?'':event.target.country.value,
          teamgroup: null,
          service: null,
          component: null,
          AllDayEvent: alldayevent,
          servicegroup: null,
          factory: null,
          division: null,
          orgName: null,
          status: null,
          NextSteps: null,
          NACategory: this.state.role,
          category:'',
          Location:'',
          Recurrence:false
        });
        this.setState({ addOutage: bodyvalues });
        var event = await this.submitAlert(event);
      }
    } catch (e) {
      errors = e;
    }
    //Catch Block
  };

  handleChange(e) {
    // if(e.target.value.match("^[a-zA-Z ]*$")!=null) {
    this.setState({
      [e.target.name]: e.target.value,
      [`${e.target.name}Error`]: "",
    });
    //}
  }

  handleOnChange(e) {
    this.setState({ clicked: e, alldayeventError: "" });
  }

  onChange(date) {
    this.setState({ date: date, dateError: "" });
  }

  onEndChange(date) {
    this.setState({ enddate: date, enddateError: "" });
  }

  closeDialog = async () => {
    this.setState({ showDialog: false, showErrorDialog: false });
    this.props.submitModalPopUpFunc(false);
  };

  // cancelErrorDialog() {
  //   this.setState( {showErrorDialog: false } );
  // }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, info);
  }

  async updateServiceGroup(value) {
    this.setState({ serviceGroup: value });
  }

  async setServiceGroupErrorValue(value) {
    this.setState({
      serviceGroupError: value,
    });
  }

  async updateTeamGroup(value) {
    this.setState({
      teamGroup: value,
    });
  }

  async setTeamErrorValue(value) {
    this.setState({
      teamGroupError: value,
    });
  }

  async updateComponent(value) {
    this.setState({
      teamComponent: value,
    });
  }

  async setComponentErrorValue(value) {
    this.setState({
      teamComponentError: value,
    });
  }

  async updateService(value) {
    this.setState({
      teamService: value,
    });
  }

  async setServiceErrorValue(value) {
    this.setState({
      teamServiceError: value,
    });
  }

  async updateFactoryName(value) {
    this.setState({
      factory: value,
    });
  }

  async setfactoryError(value) {
    this.setState({
      factoryError: value,
    });
  }

  onEditorChange(event) {
    this.setState({
      description: event.editor.getData(),
    });
  }

  onNextStepsTextEditorChange(event) {
    this.setState({
      nextsteps: event.editor.getData(),
    });
  }

  async updateDivision(value) {
    this.setState({ division: value });
  }
  async setDivisionErrorValue(value) {
    this.setState({
      divisionError: value,
    });
  }
  async updateOrgName(value) {
    this.setState({ orgName: value });
  }
  async setOrgNameErrorValue(value) {
    this.setState({
      orgNameError: value,
    });
  }

  render() {
    var valid = function (currentDate, selectedDate) {};
    const { userEmail } = this.props;

    return (
      <div>
        <AlertDialog
          title={"Confirmation Dialog"}
          message={"Are you sure you want to submit?"}
          open={this.state.showNewDialog}
          okButtonLabel={"Yes"}
          okButtonType={ButtonType.Danger}
          cancelButtonLabel={"No"}
          cancelButtonType={ButtonType.Danger}
          onClickCancel={this.cancelDialog}
          onClickOk={this.approveDialog}
        />
        <AlertDialog
          title={"Success!"}
          cancelButtonLabel={"Ok"}
          message={"Event added successfully"}
          open={this.state.showDialog}
          onClickCancel={this.closeDialog}
        />
        <AlertDialog
          title={"Error"}
          cancelButtonLabel={"Ok"}
          message={"Insufficient Access"}
          open={this.state.showErrorDialog}
          onClickCancel={this.closeDialog}
        />
        <Modal
          style={{ overflow: "visible" }}
          data-keyboard="false"
          show={this.props.show}
          size="lg"
          onHide={() => {
            this.props.submitModalPopUpFunc(false);
          }}
          backdrop="static"
          centered
          autoFocus="true"
        >
          <Modal.Header closeButton>
            <Modal.Title>Create New Event</Modal.Title>
          </Modal.Header>
          <LoadingIndicator></LoadingIndicator>
          <form id="addevent" onSubmit={this.methodInvoke.bind(this)}>
            <Modal.Body
              style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}
            >
              <div className="form-group required">
                <label htmlFor="outageTitle" class="control-label">
                  Title
                </label>
                <input
                  required
                  aria-label="Title"
                  type="text"
                  placeholder="Title"
                  className="form-control"
                  name="outageTitle"
                  value={this.state.outageTitle || ""}
                  onChange={(e) => this.handleChange(e)}
                />
                <span style={{ color: "#E22335" }}>
                  {this.renderError("outageTitle")}
                </span>
              </div>

              <div className="form-group required">
                <label htmlFor="description" class="control-label">
                  Description
                </label>
                <CKEditor
                  required
                  id="editor"
                  data={this.state.description || ""}
                  onChange={(e) => this.onEditorChange(e)}
                  config={{
                    removePlugins: "elementspath,scayt,wsc",
                    removeButtons: "Image,Source,About,Maximize,Anchor",
                  }}
                />
                <span style={{ color: "#E22335" }}>
                  {this.renderError("description")}
                </span>
              </div>

              {/*               <div className="form-group required">
                <label htmlFor="informational" class="control-label">
                  Informational
                </label>
                &nbsp;&nbsp;
                <Switch
                  name="informational"
                  onChange={(e) => this.handleBusinessUserChange(e)}
                  checked={this.state.isBusinessUser}
                />
              </div> */}

              <div className="form-group required">
                <label htmlFor="impact" class="control-label">
                  Impact
                </label>
                <textarea
                  required
                  aria-label="Impact"
                  placeholder="Ex:- Manufacturing lines unavailable"
                  className="form-control"
                  name="impact"
                  value={this.state.impact || ""}
                  onChange={(e) => this.handleChange(e)}
                />
                <span style={{ color: "#E22335" }}>
                  {this.renderError("impact")}
                </span>
              </div>

              <div className="form-group required">
                <label htmlFor="date" class="control-label">
                  StartTime(<b>UTC</b>)
                </label>

                <Datetime
                  required
                  aria-label="Start Time"
                  calendarAriaLabel="StartTimeCalender"
                  yearAriaLabel="Year"
                  monthAriaLabel="Month"
                  dayAriaLabel="Day"
                  hourAriaLabel="Hour"
                  minuteAriaLabel="Minute"
                  amPmAriaLabel="ampm"
                  name="date"
                  onChange={this.onChange}
                  value={this.state.date || ""}
                ></Datetime>
                {/* <DateTimePicker style = {{'z-index': '1600 !important;'}} className="form-control" name="date" onChange={this.onChange} value={this.state.date ||""}/>  */}
                <span style={{ color: "#E22335" }}>
                  {this.renderError("date")}
                </span>
              </div>
              <div className="form-group required">
                <label htmlFor="endtime" class="control-label">
                  EndTime(<b>UTC</b>)
                </label>
                <Datetime
                  required
                  aria-label="End Time"
                  calendarAriaLabel="EndTimeCalender"
                  yearAriaLabel="Year"
                  monthAriaLabel="Month"
                  dayAriaLabel="Day"
                  hourAriaLabel="Hour"
                  minuteAriaLabel="Minute"
                  amPmAriaLabel="ampm"
                  name="endtime"
                  onChange={this.onEndChange}
                  value={this.state.enddate || ""}
                ></Datetime>
                {/* <DateTimePicker clearAriaLabel="EndTime" calendarAriaLabel="EndTimeCalender" yearAriaLabel="Year" monthAriaLabel="Month" dayAriaLabel="Day" hourAriaLabel="Hour" minuteAriaLabel="Minute" amPmAriaLabel="ampm"
                className="form-control" name="endtime" onChange={this.onEndChange} value={this.state.enddate ||""}/>  */}
                <span style={{ color: "#E22335" }}>
                  {this.renderError("enddate")}
                </span>
              </div>

              <div className="form-group">
                <ReactRegionDropDown
                  email={userEmail}
                  country={this.state.country}
                  region={this.state.region}
                />
              </div>

              <div className="form-group required">
                <label htmlFor="engcontact" class="control-label">
                  Point of Contact
                </label>
                <input
                  required
                  aria-label="Engineering Contact"
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  name="engcontact"
                  value={this.state.engcontact || ""}
                  onChange={(e) => this.handleChange(e)}
                />
                <span style={{ color: "#E22335" }}>
                  {this.renderError("engcontact")}
                </span>
              </div>

              <div className="form-group">
                <label htmlFor="alldayevent" class="control-label">
                  AllDay Event
                </label>{" "}
                &nbsp;&nbsp;
                <Switch
                  name="alldayevent"
                  onChange={(e) => this.handleOnChange(e)}
                  checked={this.state.clicked}
                />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                aria-label="Close"
                variant="secondary"
                onClick={() => {
                  this.props.submitModalPopUpFunc(false);
                }}
              >
                Close
              </Button>
              <Button
                aria-label="Submit Outage"
                form="addevent"
                type="submit"
                variant="primary"
                onClick={() => {
                  this.props.submitModalPopUpFunc(true);
                }}
              >
                Submit
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }
}

export default AddChannelManagerEvent;
