import React from "react";
import $, { trim } from "jquery";
import "../../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css";
import "../../../node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css";
import Yes from "../../common/Images/Yes.png";
import No from "../../common/Images/NO.png";
import Red from "../../common/Images/Filled.png";
import Amber from "../../common/Images/Information.png";
import Green from "../../common/Images/Check.png";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../OutageHub.css";

class QoS extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {    
      this.reloadTableData(nextProps.events); 
      return true;   
  }

  componentWillUnmount() {
    $("#qos").DataTable().destroy(true);    
  }

  reloadTableData = (data) => {
    const table = $("#qos").DataTable();
    table.clear();
    table.rows.add(data);
    table.draw();
    table.search("").draw();
  };
 

  getTrimmed(data, type, full, meta) {
    var newValue = trim(data);
    return '<div class="d-flex justify-content-left">' + newValue + "</div>";
  }

  getImageButton(full) {
    
    var reliability = full.bridgeUrls_SR;
    var latency = full.title_LVR;
    var resultmetric = reliability - latency;
    if (resultmetric < 50) {
      return (
        '<div class="d-flex justify-content-left"><img src="' +
        Red +
        '" /></div>'
      );
    } else if (resultmetric < 98) {
      return (
        '<div class="d-flex justify-content-left"><img src="' +
        Amber +
        '" /></div>'
      );
    } else {
      return (
        '<div class="d-flex justify-content-left"><img src="' +
        Green +
        '" /></div>'
      );
    }
  }

  getUniqueServices(data, type, full, meta) {
    var acknowledge = full.serviceName;
    if (acknowledge === true) {
      return (
        '<div class="d-flex justify-content-center"><img src="' +
        Yes +
        '" /></div>'
      );
    } else {
      return (
        '<div class="d-flex justify-content-center"><img src="' +
        No +
        '" /></div>'
      );
    }
  }

  format = (d) => {
    var newEvents = this.props.events.filter(
      (x) => x.serviceName === d.serviceName
    );
    var html =
      "<table><thead><tr><th>Owning Team</th><th>Service Reliability(%)</th><th>Latency(ms)</th></tr></thead><tbody>";
    for (var i = 0; i < newEvents.length; i++) {
      html +=
        "<tr>" +
        "<td>" +
        newEvents[i].owningTeamName +
        "</td>" +
        "<td>" +
        newEvents[i].bridgeUrls +
        "</td>" +
        "<td>" +
        newEvents[i].title +
        "</td></tr>";
    }
    html += "</tbody></table>";
    return html;
  };


  componentDidUpdate() {
    $("#theSelectId").val("");
    
  }
  componentDidMount() { 
    this.$el = $("#qos");
    
    var table = this.$el.DataTable({
      columns: [
        
        { title: "Service Name", data: "serviceName", width: "15%" },
        {
          "className": 'details-control',
          "data": null,
          "defaultContent": "",
          width: "4%",
          "aria-labelledby":"expandandcollapsebtn",
          "role":"button",         
           
        },
        { title: "Component Name", data: "owningTeamName" },
        {
          title: "Status",
          data: "bridgeUrls_SR",
          render: this.getImageButton,
          width: "20%",
        },
       
        {
          title: "", //Latency violation(%)
          data: "title_LVR",
          render: this.getTrimmed,
          width: "0%",
        },
        {
          title: "Last Reported(in UTC)",
          data: "lastUpdateTime",
          render: this.getTrimmed,
        },
      ],
      dom: 'lr<"table-filter-container">tip',
      
      initComplete: function (settings) {
        var api = new $.fn.dataTable.Api(settings);
        $(".table-filter-container", api.table().container()).append(
          $("#table-filter").detach().show()
        );

        $("#table-filter select").on("change", function () {
          table.search(this.value).draw();
        });
      },
      data: this.props.events,
      ordering: true,
      pageLength: 10,
      lengthMenu: [
        [5, 10, 20, -1],
        [5, 10, 20, "Todos"],
      ],
      bLengthChange: false,
      border: false,
      bAutoWidth: false,
      autoWidth: false,
      columnDefs: [
        { "orderable": false, "targets": 1 },
        {
          targets: [0, 2, 4],
          createdCell: function (td, cellData, rowData, row, col) {
            if (cellData) {
              $(td).attr("title", cellData);
            }
          },
        },
      ],
    });
    // to get the service API endpoints data . 
    var childData = this.props.child;

    // Add event listener for opening and closing rows.
    $('#qos tbody').on('click', 'td.details-control', function(){
      var tr = $(this).closest('tr');
      var row = table.row( tr );
      
    if ( row.child.isShown() ) {
        // This row is already open - close it
        row.child.hide();
        tr.removeClass('shown');
    }
    else {
        // Open this row
        var parentrow = row.data();
        var childrows = childData.filter(item => item.owningTeamName === parentrow.owningTeamName && item.teamGroupName === parentrow.teamGroupName);
        var tabFormat = '<table class="table table-hover qos" >';
        if(childrows.length > 0)
        {
          for(var i=0;i<childrows.length;i++)
          {
            //calculating status -
            var reliability = childrows[i].bridgeUrls_SR;
            var latency = childrows[i].title_LVR;
            var resultmetric = reliability - latency;
            var status='';
            if (resultmetric < 50) {
              status=
                '<div class="d-flex justify-content-left"><img src="' +
                Red +
                '" /></div>';
              
            } else if (resultmetric < 98) {
              status=
                '<div class="d-flex justify-content-left"><img src="' +
                Amber +
                '" /></div>';
            } else {
              status=
                '<div class="d-flex justify-content-left"><img src="' +
                Green +
                '" /></div>';
            }

            tabFormat = tabFormat +'<tr><td width="18%"></td><td width="31%">'+childrows[i].detection+'</td>' +'<td width="20%">'+status  +'</td>'+ '<td class="d-flex justify-content-left">'+childrows[i].lastUpdateTime+'</td>'+'</tr>'
            
          }
          row.child(tabFormat+'</table>').show();
        }
        tr.addClass('shown');
               
        
    }
  });

  
  


    
  }

  buildOptions() {
    var arr = [];
    arr[0] = <option value="">All</option>;
    var owningService = this.props.events.map((x) => x.owningTeamName);
    for (let i = 0; i < owningService.length; i++) {
      var newValue = owningService[i];
      arr.push(
        <option key={newValue} value={newValue}>
          {newValue}
        </option>
      );
    }

    return arr;
  }

  render() {
    return (
      <div className="row" style={{ paddingTop: "30px" }}>
        <div className="col-md-12">
          <div className = "row">
          <div className="inline-headers col-8">
            <h2 className = "portal-name" style={{ fontSize: "1.1rem" }}>
              Health Status
            </h2>
          </div> 
          <div class="col-4">
            <span className ="note"><img alt = "green" src={Green}/><b>&nbsp;[&gt;98%]&nbsp;&nbsp;</b></span> 
            <span className ="note"><img alt = "amber" src={Amber}/><b>&nbsp;[&gt;=50% and &lt;98%]&nbsp;&nbsp;</b></span>
            <span className ="note"><img alt = "red" src={Red}/><b>&nbsp;[&lt;50%]&nbsp;</b></span>      
            </div>
          </div>        
          <p id="table-filter" style={{ display: "none" }}>
            Search:&nbsp;
            <select id="theSelectId" defaultValue={""} aria-label="search">
              {this.buildOptions()}
            </select>
          </p>
          <table
            class="table table-hover qos"
            width="100%"
            id="qos"
            ref={(el) => (this.el = el)}
          >
          <caption>Service Health Status</caption>  
          </table>
         <span className ="note"><i>(Aggregation Refreshed every 5 minutes.Status displayed for apis having "Last Reported time" for last 7 days)</i></span>
        </div>
      </div>
    );
  }
}

export default QoS;
