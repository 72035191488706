
import React, { Component } from "react";
import {Form }  from 'react-bootstrap'
import {fetchToken} from '../../Util.js';
import { reactAI } from "react-appinsights";
import uuid from 'react-native-uuid';
import {appInsights} from '../../common/AppInsights/AppInsights';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";
import Select from 'react-select';

export class ReactEditFactoryDropDown extends Component{

    constructor(props)
    {
       
        super(props)        
        this.state={factory:[],selectedFactory:null,factoryError:'',readOnly:true}
    }

    handleFactoryChange = async(selectedFactory) => {
        await this.setState(
          { selectedFactory:(selectedFactory  == null ? [] : selectedFactory)},
          () => console.log(`Option selected:`, this.state.selectedFactory)
        );
        await this.props.factoryData(selectedFactory);
      };

    async componentDidMount() {


        appInsights.trackTrace({ message: 'EditFactory Component Loaded.' },
                     {
                         "userName": this.props.email,
                         "ComponentId" : COMPONENT_ID,
                         "ComponentName" : COMPONENT_NAME,
                         "EnvironmentName" : window.env.ENVIRONMENT,
                         "ServiceLineName" : SERVICE_LINE,
                         "ServiceName" : SERVICE_NAME,
                         "ServiceOfferingName" : SERVICE_OFFERING,
                         "Correlation Id": uuid.v1(),
                         "Component": 'Factory Page',
                     });
        var token = await fetchToken();
        var bearer = 'Bearer ' + token;
        var correlationId = uuid.v1();
        var apimUrl = window.env.APIMURL + "/api/outage/getfactorymappings"; 
        fetch(apimUrl,{
            method: 'GET',
            headers: {
                'authorization': bearer,
                'Accept' : 'application/json',
                'CorrelationId': correlationId,
                'Content-Type': 'application/json'
            }})
          .then(response => {
            return response.json();
          })        
          .then(data => {   
                   
              this.setState({ factory:data
              });
            })
            .catch(error => {
                var err = 
                {
                 "message": "Exception occured in EditFactory page while getting the factory list from the DB",
                 "ComponentId" : COMPONENT_ID,
                 "ComponentName" : COMPONENT_NAME,
                 "EnvironmentName" : window.env.ENVIRONMENT,
                 "ServiceLineName" : SERVICE_LINE,
                 "ServiceName" : SERVICE_NAME,
                 "ServiceOfferingName" : SERVICE_OFFERING,
                 "Component": 'EditFactory Page',
                 "userName": this.props.email,
                 "exception": error.message,
                 "Correlation Id": correlationId
                }
             appInsights.trackException({exception: error,properties : err});
            })
        
    }

    returnValues(array)
    {
      const options = array.map(v => ({
        label: v,
        value: v
      }));
      return options;
    }
    

        render(){  
            const { selectedFactory} = this.state; 
            const values = [...new Set(this.state.factory.map((factory)=>factory.factory))];
            const options = this.returnValues(values);    
        return(          
            <div>
            <div className="form-group required">    
            <label htmlFor="factory" class="control-label">Factory</label>
            <Select required aria-label="Factory" name = "factory"
            title="Select Factory"
            isDisabled={this.props.readOnly}
            isMulti = {false}
            value={((selectedFactory && selectedFactory.length >= 0) ? selectedFactory :
                ((this.props.event.factory != undefined  && this.props.event.factory != "") ? this.returnValues(this.props.event.factory.split(","))
                : selectedFactory)) || ''}
            onChange={this.handleFactoryChange}
            options={options}
            onBlurResetsInput={false}
            isClearable={true}
           >
          </Select>
            <span style={{color: '#E22335'}}></span>
            </div>
            </div>
        )      
    }
}
export default ReactEditFactoryDropDown;