import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {CalendarTemplate} from "../../components/FactoryCalendar/Calendar"
import EditCalendar from "../../components/FactoryCalendar/EditCalendar"
import "react-big-calendar/lib/css/react-big-calendar.css";

class LoadCalendar extends Component 
{
    constructor(props)
    {
        super(props);
        this.state = {showModal:props.show,event:{},showAddEventModal:false,events:{},eventAdded:null,childWasClicked:false}; 
    }
   
    closeModalPopUp = (show) => {this.setState({showModal:show}); }
  
    handleModalPopUp = (show,currentevent) => {this.setState({showModal:show,event:currentevent}); } 
   render()
   {        
    return (
        <div className="rbc-calendar">
        <CalendarTemplate
              handleModalPopUpFunc ={this.handleModalPopUp}
              closeModalPopUpFunc = {this.closeModalPopUp}
              userEmail = {this.props.userEmail}
              calendarType = {this.props.calendarType}
              /> 
        <EditCalendar
            show= {this.state.showModal}
            event = {this.state.event}
            closeModalPopUpFunc = {this.closeModalPopUp} 
            userEmail = {this.props.userEmail}       
          />
      </div> 
      );
   }   
}
  
  LoadCalendar.propTypes = {
    show: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    userEmail: PropTypes.string.isRequired,
    calendarType : PropTypes.string.isRequired,
};

LoadCalendar.defaultProps = {
    show: false,
    role : "",
    userEmail : "",
    calendarType : "Outage"
};

export default LoadCalendar;