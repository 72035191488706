import React, { Fragment} from 'react';
import { BrowserRouter as Router, Route,Redirect,Switch} from 'react-router-dom';
import { UserAgentApplication } from 'msal';
//import * as msal from "@azure/msal-browser";
import './App.css';
import './components/Footer/Footer.css';
import NavBarHeader from './components/Header/header';
import Welcome from './components/Welcome/Welcome'
import { Row,Container } from 'react-bootstrap';
import Footer from './components/Footer/Footer';
import config from './config';
import {getUserDetails} from './Service'
import BigCalendar from './components/Calendar/BigCalendar'
import LoadCalendar from './common/Calendar/LoadCalendar'
import jwt_decode from "jwt-decode";
import {ApprovalScreen} from './components/Approvals/Approvals';
import LogOut from './Logout';
import { isNullOrUndefined } from 'util';
import CustomSpinner from "./common/Spinner/Spinner";
import {fetchToken,fetchRoles, getDomainName,getValidApproverRole,getValidAdminRole} from './Util.js';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "./common/Constants/Constants";
import { reactAI } from "react-appinsights";
import uuid from 'react-native-uuid';
import AccessDenied from "./common/AccessDenied/AccessDenied";
import {appInsights} from './common/AppInsights/AppInsights';
import history from "./common/History/History";
import AlertDialog, { AlertDialogButtonType as ButtonType } from "./common/AlertDialog/AlertDialog";
import NoMatch from './components/NotFoundPage/NotFoundPage';
import ErrorBoundary from "./common/ErrorBoundary/ErrorBoundary";
import SideBar from './components/Sidebar/Sidebar';
import Contactus from './components/Contact Us/Contactus';
import MyContext from "./common/MyContext/MyContext.js";
import { parse } from 'path';
import UserManagement from './components/AdminScreens/UserManagement';
import BulkUpload from './components/BulkUploadScreen/BulkUpload';
import CalendarManagement from './components/AdminScreens/CalendarManagement';
import OnboardFactory from './components/AdminScreens/OnboardFactory';

class App extends React.Component {

  constructor(props){
    
    super(props);
     // this.userAgentApplication = new msal.PublicClientApplication({
      this.userAgentApplication = new UserAgentApplication({
        auth: {
          clientId: `${window.env.CLIENTID}`,
          authority: "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
          redirectUri: `${window.location.origin}`,
          navigateToLoginRequestUrl: false,
          postLogoutRedirectUri : `${window.location.origin}/Logout`,
          tokenRefreshUri: window.location.origin + '/auth.html'
      },
      cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: true
      }
    });
    
    

//     this.userAgentApplication.handleRedirectCallback((error, response) => {
//       var account = this.userAgentApplication.getAccount(); 
//       if(error!= null)
//       {
//         var err = {
//           "message": "Exception occured and login is not successful",
//           "ComponentId" : COMPONENT_ID,
//           "ComponentName" : COMPONENT_NAME,
//           "EnvironmentName" : window.env.ENVIRONMENT,
//           "ServiceLineName" : SERVICE_LINE,
//           "ServiceName" : SERVICE_NAME,
//           "ServiceOfferingName" : SERVICE_OFFERING,
//           "userName": this.props.userEmail,
//           "roles": this.props.role != null || '' ? this.props.role : "",
//           "exception": error.message,
//           "Correlation Id": uuid.v1(),
//         }
//         appInsights.trackException({exception: error,properties : err});
//       }
//  // if error is not null, something went wrong
//  // if not, response is a successful login response
//      });
  var username = "";
  var loggedUser = "";
  if(!this.userAgentApplication.getAccount())
  {
    var scopesArray = [window.env.SCOPE];
    // Need to replace the scopes from keyvault at runtime
   return this.userAgentApplication.loginRedirect(scopesArray);
    
  }
  else
  {
    loggedUser = this.userAgentApplication.getAccount();    
  }
  this.state = {
    isAuthenticated: (loggedUser !== null || undefined)? true : false,
    isFetching: true,
    name : '',
    user : {},
    userName : (loggedUser !== null)? loggedUser.userName : '',
    error: {},
    token:'',
    statusCode : 200,
    role:'default',
    filters:'',
    upcomingEventsInfo: [],
    approvalInfo: [],
    serviceHealth : [],
    errorMessage:null,
    rejectionInfo:[],
    userOrgName:''
  };

  
  if (loggedUser) {
    this.getUserProfile();   
   }
   
 }

 componentDidMount = () => {
 this.fetchRolesData();
}

async fetchRolesData()
{
  var token = await fetchToken();
  var correlationId = uuid.v1();
  var bearer = 'Bearer ' + token;
  //var getRoles = window.env.APIMURL + "/api/outage/getroles";
  var upcomingEvents = window.env.APIMURL + "/api/outage/getupcomingevents";
  //var serviceHealth = window.env.APIMURL + "/api/outage/getactiveicmincidents";
  Promise.all([
        fetch(upcomingEvents,{
          method: 'GET',
          headers: {
              'authorization': bearer,
              'CorrelationId': correlationId,
              'Accept' : 'application/json',
              'Content-Type': 'application/json'
          }})])
      .then(result => Promise.all(
        result.map(v => v.json()),
        result.map(v =>this.setState({statusCode :  v.status}))
        ))
      .then(result => 
      {

      this.setState({
        role: result[0].role,        
        upcomingEventsInfo: result[0].upcomingEvents!=null?result[0].upcomingEvents:result[0].upcomingSAPReleaseEvents,
        //upcomingEventsInfo: result[0].upcomingEvents,
        approvalInfo:result[0].pendingEvents,
        rejectionInfo:result[0].rejectedEvents,
        serviceHealth: result[0].activeIcmIncidents,
        filters:result[0].filters,
        isFetching: false,
        isL1Approver:result[0].l1Approvers,
        userOrgName:result[0].organization,
        eventapprovaldetails:result[0].eventAudit,
        eventapproverdetails:result[0].eventApprover
           })})     
        .catch(error => {
          
          var err = {
                "message": "Exception occured while fetching the roles from database",
                "ComponentId" : COMPONENT_ID,
                "ComponentName" : COMPONENT_NAME,
                "EnvironmentName" : window.env.ENVIRONMENT,
                "ServiceLineName" : SERVICE_LINE,
                "ServiceName" : SERVICE_NAME,
                "ServiceOfferingName" : SERVICE_OFFERING,
                "userName": this.props.userEmail,
                "roles": this.props.role != null || '' ? this.props.role : "",
                "exception": error.message,
                "CorrelationId": correlationId
               }
            appInsights.trackException({exception: error,properties : err});
            this.setState({errorMessage: error});
        })
}

    async login() {
    try {
      var scopesArray = [window.env.SCOPE];
      await this.userAgentApplication.loginPopup(
          {
            scopes: scopesArray,
            prompt: "select_account"
        });
      await this.getUserProfile();
    }
    catch(err) {
      var error = {};
      var scopesArray = [window.env.SCOPE];
      if (err.name === "InteractionRequiredAuthError" || "ClientAuthError") {
        return this.userAgentApplication.acquireTokenPopup({
          scopes: scopesArray,
          redirectUri: window.location.origin + '/auth.html'
          //extraQueryParameters: {domain_hint: 'microsoft.onmicrosoft.com'}
        })
            .then(response => {
                // get access token from response
                // response.accessToken
            })
            .catch(error => {
             
              var err = 
              {
                "message": "Exception occured in login popup component while getting an access token",
                "ComponentId" : COMPONENT_ID,
                "ComponentName" : COMPONENT_NAME,
                "EnvironmentName" : window.env.ENVIRONMENT,
                "ServiceLineName" : SERVICE_LINE,
                "ServiceName" : SERVICE_NAME,
                "ServiceOfferingName" : SERVICE_OFFERING,
                "userName": this.props.userEmail,
                 "roles": this.props.role != null || '' ? this.props.role : "",
                "exception": error.message,
                "Correlation Id": uuid.v1(),              
              }
              appInsights.trackException({exception: error,properties : err});
            });
    }
    }
  }

  logout() {
    this.userAgentApplication.logout();
  }

 requiresInteraction(errorMessage) 
 {
  if (!errorMessage || !errorMessage.length)
   return false;
   console.log("requiresinteraction is:" + errorMessage );
   return errorMessage.indexOf("consent_required") !== -1 ||
    errorMessage.indexOf("interaction_required") !== -1 ||
    errorMessage.indexOf("token_renewal_error") !== -1 ||
    errorMessage.indexOf("login_required") !== -1 ;
 } 


  acquireToken = () => {
  var scopesArray = [window.env.SCOPE]; 
  const tokenRequest = {
    scopes: scopesArray,
    redirectUri: window.location.origin + '/auth.html'
  };
  const tokenRedirectRequest = {
    scopes: scopesArray,
    redirectUri: window.location.origin
  };
  return this.userAgentApplication.acquireTokenSilent(tokenRequest).catch(error => {
    var err = {
                  "message": "Exception occured in acquireTokenSilent method while fetching an access token and acquireTokenSilent failed", 
                  "ComponentId" : COMPONENT_ID,
                  "ComponentName" : COMPONENT_NAME,
                  "EnvironmentName" : window.env.ENVIRONMENT,
                  "ServiceLineName" : SERVICE_LINE,
                  "ServiceName" : SERVICE_NAME,
                  "ServiceOfferingName" : SERVICE_OFFERING,
                  "userName": this.props.userEmail,
                   "roles": this.props.role != null || '' ? this.props.role : "",
                  "exception": error.message,
                  "Correlation Id": uuid.v1(),
                 }               
                 appInsights.trackException({exception: error,properties : err});
    
    if (this.requiresInteraction(error.errorCode)) {
      console.error('redirecting to login from inside acquireTokenSilent');
      return this.userAgentApplication.acquireTokenRedirect(tokenRedirectRequest);      
    }
  });
};

  async getUserProfile(tokenRequest) 
  {
    try {         
      var accessToken = await this.acquireToken();
      const DecodeJWT= (token) => {
        try {
          return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
          return null;
        }
      };
  
      if (accessToken!= null || accessToken != undefined) {
        // Get the user's profile from Graph
        var details = DecodeJWT(accessToken.accessToken);       
        let name = null; 
        let emailAddress = null;
        try {
        name = JSON.parse(JSON.stringify(details.name));
        emailAddress = JSON.parse(JSON.stringify(details.preferred_username));
        }
        catch (e) {
        }
        this.setState({
          isAuthenticated: true,
          user: {
            displayName: name,
            email: emailAddress
              },
          error: null
        });
      }
    }
    catch(error) {

      var err = 
      {
        "message": "Exception occured in DecodeJWT component while decoding the access token request",
        "ComponentId" : COMPONENT_ID,
        "ComponentName" : COMPONENT_NAME,
        "EnvironmentName" : window.env.ENVIRONMENT,
        "ServiceLineName" : SERVICE_LINE,
        "ServiceName" : SERVICE_NAME,
        "ServiceOfferingName" : SERVICE_OFFERING,
        "userName": this.props.userEmail,
        "roles": this.props.role != null || '' ? this.props.role : "",
        "exception": error.message,
        "Correlation Id": uuid.v1(),
      }
      appInsights.trackException({exception: error,properties : err});
      var error = {};
      if (typeof(err) === 'string') {
        var errParts = err.split('|');
        error = errParts.length > 1 ?
          { message: errParts[1], debug: errParts[0] } :
          { message: err };
      } else {
        error = {
          message: err.message,
          debug: JSON.stringify(err)
          
        };
      }
    }
  }
render(){
    
    const{role,errorMessage,isFetching,upcomingEventsInfo,approvalInfo,serviceHealth,statusCode,filters,rejectionInfo,isL1Approver,userOrgName,eventapprovaldetails,eventapproverdetails} = this.state
    if(statusCode === 403 || statusCode === 401)
    {
      return <AccessDenied/>; 
    } 
    if (!this.state.isAuthenticated || isFetching)
    return <CustomSpinner class="ms-Spinner ms-Spinner--large" label="Loading" />;  
    let factoryUsers = getDomainName(this.state.userName);
    let validRole = getValidApproverRole(fetchRoles(role));
    let userEmail = this.state.userName;
    let internalUsers = (userEmail.toLowerCase().includes("microsoft.com") ? true : false); 
    let validAdminRole=getValidAdminRole(fetchRoles(role));
   
      return (
        <MyContext.Provider value={{isAuthenticated:this.state.isAuthenticated,factoryUser:factoryUsers,isApprover:validRole,isInternalUser:internalUsers,isAdmin:validAdminRole}}> 
        <Router history={history}>
          <NavBarHeader
             isAuthenticated={this.state.isAuthenticated}
             authButtonMethod={this.state.isAuthenticated ? this.logout.bind(this) : this.login.bind(this)}
             user={this.state.user}
             validFactoryUsers = {factoryUsers}
             role = {validRole}
          />
           <div className="row" id="body-row">            
            <SideBar {...this.props} user={this.state.user}
             isAuthenticated={this.state.isAuthenticated}
             validFactoryUsers = {factoryUsers}
             role = {validRole}/>
            <div className="col p-4">
            <Switch>
            <Route exact path={["/",'/events/:id?']}
              render={(props) =>
                <Welcome
                  {...props}
                  isAuthenticated={this.state.isAuthenticated}
                  user={this.state.user}
                  authButtonMethod={this.login.bind(this)}
                  role = {fetchRoles(role)}
                  events = {upcomingEventsInfo}
                  approvals = {approvalInfo}
                  serviceHealth = {serviceHealth}
                  filters = {JSON.parse(filters)}
                  rejections={rejectionInfo}
                  qos = {serviceHealth.filter(x=>x.recordType === 'METRIC')}
                  qosChild={serviceHealth.filter(x=>x.recordType === 'METRICChild')}
                  internalUser = {internalUsers}
                  l1approvers={isL1Approver}
                  userOrgName = {userOrgName}
                  eventapprovaldetails={eventapprovaldetails}
                  eventapproverdetails={eventapproverdetails}
                />                  
              }            
            />              
             <Route  exact path="/approvals"
                render = { (props) =>  
                  (this.state.isAuthenticated && validRole) ? (
                    <ApprovalScreen
                    {...props}
                    role = {fetchRoles(role)}
                    user={this.state.user}
                    l1approvers={isL1Approver}
                    />
                  ) : (
                    <Redirect to={{ pathname: '/'}} />
                  )                    
                }
            /> 
            <Route  exact path="/usermanagement"
                render = { (props) =>  
                  (this.state.isAuthenticated && validAdminRole) ? (
                    <div>
                    <UserManagement
                     {...props}
                     role={fetchRoles(role)}
                    user={this.state.user}
                    />
                    </div>
                  ) : (
                    <Redirect to={{ pathname: '/'}} />
                  )                    
                }
            />  
            <Route  exact path="/calendarmanagement"
                render = { (props) =>  
                  (this.state.isAuthenticated && validAdminRole) ? (
                    <div>
                    <CalendarManagement
                     {...props}
                     role={fetchRoles(role)}
                    user={this.state.user}
                    />
                    </div>
                  ) : (
                    <Redirect to={{ pathname: '/'}} />
                  )                    
                }
            />  
            <Route  exact path="/onboardfactory"
                render = { (props) =>  
                  (this.state.isAuthenticated && validAdminRole) ? (
                    <div>
                    <OnboardFactory
                     {...props}
                     role={fetchRoles(role)}
                    user={this.state.user}
                    />
                    </div>
                  ) : (
                    <Redirect to={{ pathname: '/'}} />
                  )                    
                }
            />  
                 <Route  exact path="/bulkupload"
                 render = { (props) =>  
                  
                  this.state.isAuthenticated ? (
                    <div>
                    <BulkUpload
                    {...props}
                    role = {fetchRoles(role)}
                    userEmail = {this.state.user.email}
                    /></div>
                  ) : (
                    <Redirect to={{ pathname: '/'}} />
                  )
                  
                }
            />  

                    
             
            <Route exact path="/calendar" 
            render={(props) => 
            <BigCalendar 
            {...props}
            role = {fetchRoles(role)}
            userEmail = {this.state.user.email}
            internalUser = {internalUsers}
            filters = {filters}
            userOrgName = {userOrgName}
            />
            }
            />
            
            <Route exact path="/contact"  component={Contactus}/>          
           <Route exact path="/factory"
                render = { (props) =>  
                  
                  this.state.isAuthenticated && factoryUsers ? (
                    <LoadCalendar
                    role = {fetchRoles(role)}
                    userEmail = {this.state.user.email}
                    calendarType = {'Factory'}
                    />
                  ) : (
                    <Redirect to={{ pathname: '/'}} />
                  )
  
                  
                }
            />

            <Route exact path = "/logout">
            render = { (props) =>                    
                    <LogOut  {...props}/>                  
                }
            </Route>
             <div>            
            <Route component = {NoMatch}/>         
            </div>                  
          </Switch>
          </div>
        </div> 
        { <Footer/> }
        </Router>
        </MyContext.Provider>
      );
    }
}

export default App;
