import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';
import '../../../node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css'; 
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import MyContext from  "../../common/MyContext/MyContext.js";

class SideBar extends React.Component{  
     constructor(props)
     {
       super(props);
     }
    
    componentDidMount(){
        // Hide submenus
        $('#body-row .collapse').collapse('hide'); 
        // Collapse/Expand icon
        $('#collapse-icon').addClass('fa-angle-double-left'); 
       
        //$("#collapse").width(188);
        // Collapse click
        $('[data-toggle=sidebar-collapse]').click(function() {
            SidebarCollapse();
        });
       

        function SidebarCollapse () {
           var width = $('#collapse').width();
           if(Math.round(width) === 230)
           {
            $('#collapse').width(60);
           }
           else{
            $('#collapse').width(230);
           }
            $('.menu-collapsed').toggleClass('d-none');
            $('.sidebar-submenu').toggleClass('d-none');
            $('.submenu-icon').toggleClass('d-none');
            $('#sidebar-container').toggleClass('sidebar-expanded sidebar-collapsed');
            
            // Treating d-flex/d-none on separators with title
            var SeparatorTitle = $('.sidebar-separator-title');
            if ( SeparatorTitle.hasClass('d-flex') ) {
                SeparatorTitle.removeClass('d-flex');
            } else {
                SeparatorTitle.addClass('d-flex');
            }
            
           
            // Collapse/Expand icon
            $('#collapse-icon').toggleClass('fa-angle-double-left fa-angle-double-right');
            
            
        }    
    }

    // OnClickOfCollapse(){
    //     this.ToggleMenuCollapsed();
    // }

    // ToggleMenuCollapsed(){
    //     debugger;
    //     var elements = document.getElementsByClassName("menu-collapsed");
    //     for (var i = 0, len = elements.length; i < len; i++) {
    //         elements[i].toggleAttribute('d-none');
    //     }
    // }

    

    render(){
        return(  
      <MyContext.Consumer> 
         {value => (       
        <div id="sidebar-container" className="sidebar-expanded d-block" role="navigation" aria-labelledby="leftnavigationmenu">
          <ul className="list-group sticky-top sticky-offset">
              <li className="list-group-item sidebar-separator-title text-muted d-flex align-items-center menu-collapsed">
                  <small style ={{'color':'black'}}>MAIN MENU</small>
              </li> 
              <li>  
              <Link to = "/" aria-label = "Home" className="bg-dark list-group-item list-group-item-action">
                  <div className="d-flex w-100 justify-content-start align-items-center">
                      <span className="fa fa-home fa-fw mr-3 text-dark"></span>
                      <span className="menu-collapsed">Home</span>
                  </div>
              </Link> 
              </li>
              <li>
              <a href="#submenu1" aria-label = "Calendar" data-toggle="collapse" aria-expanded="false" className="bg-dark list-group-item list-group-item-action flex-column align-items-start">
                <div className="d-flex w-100 justify-content-start align-items-center">
                    <span className="fas fa-calendar-check fa-fw mr-3 text-dark"></span>
                    <span className="menu-collapsed">Calendar</span>
                    <span className="submenu-icon ml-auto"></span>
                </div>
              </a>  
              <div id="submenu1" className="collapse sidebar-submenu">                     
              <Link to = "/calendar" aria-label = "Calendar Screen" className="list-group-item list-group-item-action bg-dark text-black">                 
                 <span className="menu-collapsed">Calendar Screen</span>                  
              </Link>
              </div>
              {(value.isAuthenticated && value.isApprover) && 
              <div id="submenu1" className="collapse sidebar-submenu">
                <Link to = "/bulkupload" aria-label = "Bulk Upload" className="list-group-item list-group-item-action bg-dark text-black">
                    <span className="menu-collapsed">Bulk Upload</span>
                </Link>              
              </div>
              }
              </li>
              <li>
            {(value.isAuthenticated && value.isApprover) && (
            <a href="#submenu2" aria-label = "Admin" data-toggle="collapse" aria-expanded="false" className="bg-dark list-group-item list-group-item-action flex-column align-items-start">
                <div className="d-flex w-100 justify-content-start align-items-center">
                    <span className="fas fa-users-cog fa-fw mr-3 text-dark"></span>
                    <span className="menu-collapsed">Admin</span>
                    <span className="submenu-icon ml-auto"></span>
                </div>
            </a>
             )}
            <div id="submenu2" className="collapse sidebar-submenu">
                <Link to = "/approvals" aria-label = "Approval" className="list-group-item list-group-item-action bg-dark text-black">
                    <span className="menu-collapsed">Approvals</span>
                </Link>
                {/* <a href="#" class="list-group-item list-group-item-action bg-dark text-white">
                    <span class="menu-collapsed">Comms Update</span>
                </a> */}
            </div>
            {(value.isAuthenticated && value.isAdmin && (value.isInternalUser===true)) && 
            <div id="submenu2" className="collapse sidebar-submenu">
                <Link to = "/usermanagement" aria-label = "User Management" className="list-group-item list-group-item-action bg-dark text-black">
                    <span className="menu-collapsed">User Management</span>
                </Link>              
            </div>
            }
            {(value.isAuthenticated && value.isAdmin && (value.isInternalUser===true)) && 
            <div id="submenu2" className="collapse sidebar-submenu">
                <Link to = "/calendarmanagement" aria-label = "Calendar Management" className="list-group-item list-group-item-action bg-dark text-black">
                    <span className="menu-collapsed">Calendar Management</span>
                </Link>              
            </div>
            }
            </li>
            <li>            
              <Link to = "/contact" aria-label = "Contact Us" className="bg-dark list-group-item list-group-item-action">
                  <div className="d-flex w-100 justify-content-start align-items-center">
                      <span className="fa fa fa-envelope fa-fw mr-3 text-dark"></span>
                      <span className="menu-collapsed">Contact Us</span>
                  </div>
              </Link>
             </li> 
             <li>
              <div id = "collapse">
              <a data-toggle="sidebar-collapse" aria-label="LeftMenuCollapse" href="#sidebar-container" className="bg-dark list-group-item list-group-item-action d-flex align-items-center">
                  <div className="d-flex w-100 justify-content-start align-items-center" >
                      <span id="collapse-icon" className="fa fa-angle-double-left fa-fw mr-3" tabIndex="-1"></span>
                      <span id="collapse-text" className="menu-collapsed">Collapse</span>
                  </div>
              </a>
              </div>
              </li>
          </ul>
     </div>
      )} 
     </MyContext.Consumer>    
        )
    }
}

export default SideBar;