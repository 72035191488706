  import React,{Component} from 'react';
  import { reactAI } from "react-appinsights";
  import uuid from 'react-native-uuid';
  import {fetchToken,getValidAdminRole} from '../../Util.js';
  import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";
  import {appInsights} from '../../common/AppInsights/AppInsights';
  import { trackPromise } from 'react-promise-tracker'
  import 'react-confirm-alert/src/react-confirm-alert.css';
  import './ReactPaginationTable.css';
  import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
  import 'bootstrap/dist/css/bootstrap.min.css';
  import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
  import BootstrapTable from "react-bootstrap-table-next";
  import paginationFactory from 'react-bootstrap-table2-paginator';
  import { createScope, partnerCenterTheme, button, dialog, icon,textField,messageBar } from '@harmony/enablers/react';



// Create a Harmony Enabler scope
const scope = createScope({
  theme: partnerCenterTheme,
  reactInstance: React,
});


// Generate React components
const Icon = scope.forReact(icon);
const Button = scope.forReact(button);
const Dialog = scope.forReact(dialog);
const TextField = scope.forReact(textField);
const MessageBar = scope.forReact(messageBar);



  class AddCalendarType extends Component{
      _isMounted = false;
      constructor(props) {
      
        super(props);
        
          this.state = {show: false ,result:'',userEmail : '',showModal: '', calendartypes: [],
          id:'',
          calendarTypeName: '',
          calendarTypeDescription: '',
          calendarTypeOwner: '',
          calendarTypeStatus: '',
          showSuccess:false,
          showError:false,
          showUpdate:false,
          showDelete:false,
          addcalendartype:false,
          showDialog:false,showErrorDialog : false,factory:[],showNewDialog:false,newblocking:false, role : this.props.role,
          columns: [
            {
              dataField: 'id',
              text: 'Id',
              editable:false,
              sort:true,
              headerStyle: { backgroundColor: '#006ee5', color:'#ffffff'}
            },
            {
            dataField: 'calendarTypeName',
            text: 'Calendar Type Name',
            editable:true,
            sort:true,
            headerStyle: { backgroundColor: '#006ee5', color:'#ffffff'}
          },
          {
            dataField: 'calendarTypeDescription',
            text: 'Description',
            editable:true,
            sort:true,
            headerStyle: { backgroundColor: '#006ee5', color:'#ffffff'}
          },
          {
            dataField: 'calendarTypeOwner',
            text: 'Owner Alias',
            editable:true,
            sort:true,
            headerStyle: { backgroundColor: '#006ee5', color:'#ffffff'}
          },
          {
            dataField: 'calendarTypeStatus',
            text: 'Status',
            editable:true,
            headerStyle: { backgroundColor: '#006ee5', color:'#ffffff'}
          }
        ]
        
        };
        this.close = this.close.bind(this);
      }
    
      appInsight = reactAI.appInsights;
      close = () => this.setState({isOpen: false}) 
      async componentDidMount() {
        this._isMounted = true;  // set the flag to true
        var token = await fetchToken();
        var correlationId = uuid.v1();
        var bearer = 'Bearer ' + token;
        var apimUrl = window.env.APIMURL + "/api/outage/getcalendartypes";
        this.setState({ isLoading: true})
        fetch(apimUrl,{
            method: 'GET',
            headers: {
                'authorization': bearer,
                'Accept' : 'application/json',
                'CorrelationId': correlationId,
                'Content-Type': 'application/json'
            }})
            .then(response => {
              const statusCode = response.status;
              const data = response.json();
              console.log(data);
              return Promise.all([statusCode, data]);          
            })       
            .then((res) => {
              const statusCode = res[0];
              const calendartypeslist = res[1];              
              if (this._isMounted) { 
                this.setState({ calendartypes: calendartypeslist,ReturnStatus: statusCode
                });
              }
            })
            .catch(error => {
      
              var err = {
                "message": "Exception occured calendar page while getting the calendartypes  list.",
                "ComponentId" : COMPONENT_ID,
                             "ComponentName" : COMPONENT_NAME,
                             "EnvironmentName" : window.env.ENVIRONMENT,
                             "ServiceLineName" : SERVICE_LINE,
                             "ServiceName" : SERVICE_NAME,
                             "ServiceOfferingName" : SERVICE_OFFERING,
                "Component": 'Calendars'
              }
              appInsights.trackException({exception: new Error(JSON.stringify(err))});
              this.setState({ showErrorDialog: true,  errorMessage: "An error occurred while getting calendartypes list. Please try again later."});
            });
      }
      async updatecalendartypetable() {
        var token = await fetchToken();
        var correlationId = uuid.v1();
        var bearer = 'Bearer ' + token;
        var apimUrl = window.env.APIMURL + "/api/outage/getcalendartypes";
        this.setState({ isLoading: true})
        fetch(apimUrl,{
            method: 'GET',
            headers: {
                'authorization': bearer,
                'Accept' : 'application/json',
                'CorrelationId': correlationId,
                'Content-Type': 'application/json'
            }})
            .then(response => {
              const statusCode = response.status;
              const data = response.json();
              console.log(data);
              return Promise.all([statusCode, data]);          
            })       
            .then((res) => {
              const statusCode = res[0];
              const calendartypeslist = res[1];              
                this.setState({ calendartypes: calendartypeslist,ReturnStatus: statusCode
                });
            })
            .catch(error => {
      
              var err = {
                "message": "Exception occured calendar page while getting the calendartypes  list.",
                "ComponentId" : COMPONENT_ID,
                             "ComponentName" : COMPONENT_NAME,
                             "EnvironmentName" : window.env.ENVIRONMENT,
                             "ServiceLineName" : SERVICE_LINE,
                             "ServiceName" : SERVICE_NAME,
                             "ServiceOfferingName" : SERVICE_OFFERING,
                "Component": 'Calendars'
              }
              appInsights.trackException({exception: new Error(JSON.stringify(err))});
              this.setState({ showErrorDialog: true,  errorMessage: "An error occurred while getting calendartypes list. Please try again later."});
            });
      }
      componentWillUnmount() {
        this._isMounted = false;
      }
      componentDidCatch(error, info) {
        
        this.setState({ hasError: true });      
      }    
      handleClick = () => {
        this.setState({
          showModal: true,
          id: '',
          calendarTypeName: '',
          calendarTypeDescription: '',
          calendarTypeOwner: '',
          calendarTypeStatus: ''
        });
        this.setState(prevState => ({ show: !prevState.show }));
      }; 
      handleClickUpdate = () => {
        this.setState({
          showModal: true,
          id: '',
          calendarTypeName: '',
          calendarTypeDescription: '',
          calendarTypeOwner: '',
          calendarTypeStatus: ''
        });
        this.setState(prevState => ({ showUpdate: !prevState.showUpdate }));
      }; 
      handleClickDelete = () => {
        this.setState({
          showModal: true,
          id: '',
          calendarTypeName: '',
          calendarTypeDescription: '',
          calendarTypeOwner: '',
          calendarTypeStatus: ''
        });
        this.setState(prevState => ({ showDelete: !prevState.showDelete }));
      }; 
      handleSubmit = (event) => {
        event.preventDefault();
      }
    
      handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
      }
      addnewcalendartype = async(event) =>
      { 

        event.preventDefault();
        var bodyvalues = JSON.stringify({
          Id: 0,
          CalendarTypeName: this.state.calendarTypeName,
          CalendarTypeDescription: this.state.calendarTypeDescription,
          CalendarTypeOwner: this.state.calendarTypeOwner,
          CalendarTypeStatus: Boolean(this.state.calendarTypeStatus)
           })         
          this.setState({addcalendartype:bodyvalues});    
          var token = await fetchToken();
          var correlationId = uuid.v1();
          var bearer = 'Bearer ' + token;
          var apimUrl = window.env.APIMURL + "/api/outage/addcalendartype";
          trackPromise(
            fetch(apimUrl, {
              method: 'POST',
              headers: {
                //'Accept': 'application/json',
                'Content-Type': 'application/json',
                'CorrelationId': correlationId,
                'authorization': bearer,
              },
              body: bodyvalues
            }).then(response => {
              this.setState({ ReturnStatus: response.status });
              if (response.status !== 400) {
                this.setState({
                  result: response.status,
                  addeventstakeholder: true
                }
                );
                this.setState(prevState => ({ showSuccess: !prevState.showSuccess }))
      
                this.updatecalendartypetable();
      
      
              }
              else {
                console.log(response)
                this.setState(prevState => ({ showError: !prevState.showError }));
      
              }
      
            })
              .catch(async error => {
                this.setState(prevState => ({ showError: !prevState.showError }));
      
                var err =
                {
                  "message": "Exception occured while submitting an a new calendar legend",
                  "ComponentId": COMPONENT_ID,
                  "ComponentName": COMPONENT_NAME,
                  "EnvironmentName": window.env.ENVIRONMENT,
                  "ServiceLineName": SERVICE_LINE,
                  "ServiceName": SERVICE_NAME,
                  "ServiceOfferingName": SERVICE_OFFERING,
                  "Component": "Admin Calendar Add Legend Page",
                  "exception": error.message,
                  "Correlation Id": correlationId
                }
                appInsights.trackException({ exception: error, properties: err });
              }));
          this.setState(prevState => ({ show: !prevState.show }));
          this.setState({
            showModal: true,
            id:'',
            calendarTypeName: '',
            calendarTypeDescription: '',
            calendarTypeOwner: '',
            calendarTypeStatus: ''
          });
         
      }
      updatecalendartype = async(event) =>
      { 

        event.preventDefault();
        var bodyvalues = JSON.stringify({
          Id: parseInt(this.state.id),
          CalendarTypeName: this.state.calendarTypeName,
          CalendarTypeDescription: this.state.calendarTypeDescription,
          CalendarTypeOwner: this.state.calendarTypeOwner,
          CalendarTypeStatus: Boolean(this.state.calendarTypeStatus)
           })         
          this.setState({addcalendartype:bodyvalues});    
          this.setState(prevState => ({ showUpdate: !prevState.showUpdate }));
          var token = await fetchToken();
          var correlationId = uuid.v1();
          var bearer = 'Bearer ' + token;
          var apimUrl = window.env.APIMURL + "/api/outage/updatecalendartype";
          trackPromise(
            fetch(apimUrl, {
              method: 'PUT',
              headers: {
                //'Accept': 'application/json',
                'Content-Type': 'application/json',
                'CorrelationId': correlationId,
                'authorization': bearer,
              },
              body: bodyvalues
            }).then(response => {
              this.setState({ ReturnStatus: response.status });
              if (response.status !== 400) {
                this.setState({
                  result: response.status,
                  addeventstakeholder: true
                }
                );
                this.setState(prevState => ({ showSuccess: !prevState.showSuccess }));
                this.updatecalendartypetable();
              }
              else {
                this.setState(prevState => ({ showError: !prevState.showError }));
              }
      
            })
              .catch(async error => {
                //alert("Error Occured");  
                this.setState(prevState => ({ showError: !prevState.showError }));
                var err =
                {
                  "message": "Exception occured while submitting an a new calendar legend",
                  "ComponentId": COMPONENT_ID,
                  "ComponentName": COMPONENT_NAME,
                  "EnvironmentName": window.env.ENVIRONMENT,
                  "ServiceLineName": SERVICE_LINE,
                  "ServiceName": SERVICE_NAME,
                  "ServiceOfferingName": SERVICE_OFFERING,
                  "Component": "Admin Calendar Add Legend Page",
                  "exception": error.message,
                  "Correlation Id": correlationId
                }
                appInsights.trackException({ exception: error, properties: err });
              }));
          this.setState({
            showModal: true,
            id:'',
            calendarTypeName: '',
            calendarTypeDescription: '',
            calendarTypeOwner: '',
            calendarTypeStatus: ''
          });
      
          
      } 
      deletecalendartype = async(event) =>
      { 
        event.preventDefault();
        /* event.preventDefault();
        var bodyvalues = JSON.stringify({
          Id: parseInt(this.state.id),
          CalendarTypeName: this.state.calendarTypeName,
          CalendarTypeDescription: this.state.calendarTypeDescription,
          CalendarTypeOwner: this.state.calendarTypeOwner,
          CalendarTypeStatus: Boolean(this.state.calendarTypeStatus)
           })         
          this.setState({addcalendartype:bodyvalues});    
          this.setState(prevState => ({ showUpdate: !prevState.showUpdate }));
          var token = await fetchToken();
          var correlationId = uuid.v1();
          var bearer = 'Bearer ' + token;
          var apimUrl = window.env.APIMURL + "/api/outage/updatecalendartype";
          trackPromise(
            fetch(apimUrl, {
              method: 'PUT',
              headers: {
                //'Accept': 'application/json',
                'Content-Type': 'application/json',
                'CorrelationId': correlationId,
                'authorization': bearer,
              },
              body: bodyvalues
            }).then(response => {
              this.setState({ ReturnStatus: response.status });
              if (response.status !== 400) {
                this.setState({
                  result: response.status,
                  addeventstakeholder: true
                }
                );
                this.setState(prevState => ({ showSuccess: !prevState.showSuccess }));
                this.updatecalendartypetable();
              }
              else {
                this.setState(prevState => ({ showError: !prevState.showError }));
              }
      
            })
              .catch(async error => {
                //alert("Error Occured");  
                this.setState(prevState => ({ showError: !prevState.showError }));
                var err =
                {
                  "message": "Exception occured while submitting an a new calendar legend",
                  "ComponentId": COMPONENT_ID,
                  "ComponentName": COMPONENT_NAME,
                  "EnvironmentName": window.env.ENVIRONMENT,
                  "ServiceLineName": SERVICE_LINE,
                  "ServiceName": SERVICE_NAME,
                  "ServiceOfferingName": SERVICE_OFFERING,
                  "Component": "Admin Calendar Add Legend Page",
                  "exception": error.message,
                  "Correlation Id": correlationId
                }
                appInsights.trackException({ exception: error, properties: err });
              }));
          this.setState({
            showModal: true,
            id:'',
            calendarTypeName: '',
            calendarTypeDescription: '',
            calendarTypeOwner: '',
            calendarTypeStatus: ''
          });
      
           */
          this.setState(prevState => ({ showDelete: !prevState.showDelete }));
          this.setState(prevState => ({ showSuccess: !prevState.showSuccess }));
      } 
     
      handleEdit = (row) => {
        this.setState(prevState => ({ showUpdate: !prevState.showUpdate }));
        this.setState({
          showModal: true,
          id: row.id,
          calendarTypeName: row.calendarTypeName,
          calendarTypeDescription: row.calendarTypeDescription,
          calendarTypeOwner: row.calendarTypeOwner,
          calendarTypeStatus: row.calendarTypeStatus,
        });
      };
      handleDelete = (row) => {
        this.setState(prevState => ({ showDelete: !prevState.showDelete }));
        this.setState({
          showModal: true,
          id: row.id,
          calendarTypeName: row.calendarTypeName,
          calendarTypeDescription: row.calendarTypeDescription,
          calendarTypeOwner: row.calendarTypeOwner,
          calendarTypeStatus: row.calendarTypeStatus,
        });
      };
          
    render()
    {
      let validEmail =  this.props.userEmail;
      let validAdminRole=getValidAdminRole(this.props.role);     
      const expandRow = {
        renderer: row => (
          <div>
            <Button variant="primary" onClick={() => this.handleEdit(row)}>
              Edit
            </Button>
            <Button variant="primary" onClick={() => this.handleDelete(row)}>
            Delete
          </Button>
          </div>
        )
      };
      
      const options = {
        paginationSize: 4,
        sizePerPage: 5, 
        pageStartIndex: 1,
        hideSizePerPage: true, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
    };

        return (      
        
       
            <div>
              <div>
              <MessageBar appearance="success" open={this.state.showSuccess} onHeHide={(event) => this.setState(prevState => ({ showSuccess: !prevState.showSuccess }))}>
                        Your changes have been saved!
                    </MessageBar>
                    <MessageBar appearance="error" open={this.state.showError} onHeHide={(event) => this.setState(prevState => ({ showError: !prevState.showError }))}>
                        Your request could not be completed at this time.
                    </MessageBar>
                    <br />
              <Button onClick={this.handleClick}><Icon slot="start" name="add"></Icon>
                New Calendar Type</Button>
              <Dialog
                heading="New Calendar Type"
                open={this.state.show}
                onHeAfterHide={
                  ({ target, currentTarget }) => (target === currentTarget ? this.handleClick : null)
                }
              >
                <form onSubmit={ this.addnewcalendartype.bind(this)}>
              <TextField label="Calendar Type:" 
                name="calendarTypeName"
                required 
                value={this.state.calendarTypeName}
                onHeChange={(event) => this.setState({calendarTypeName: event.target.value})} />

              <br />

              <TextField
                label="Description:"
                required 
                name="calendarTypeDescription"
                value={this.state.calendarTypeDescription}
                onHeChange={(event) => this.setState({calendarTypeDescription: event.target.value})}
              />
              <br />

              <TextField
                label="Owner Alias:"
                required 
                name="calendarTypeOwner"
                value={this.state.calendarTypeOwner}
                onHeChange={(event) => this.setState({calendarTypeOwner: event.target.value})}
              />
              <br />

              <TextField
                label="Status:"
                required 
                name="calendarTypeStatus"
                value={this.state.calendarTypeStatus}
                onHeChange={(event) => this.setState({calendarTypeStatus: event.target.value})}
              />
              <br />



              <Button slot="footer" type="submit" appearance="primary">
                Add
              </Button>
              <Button slot="footer" onClick={this.handleClick}>
                Close
              </Button>
            </form>
              </Dialog>
              <Dialog
                heading="Update Calendar Type"
                open={this.state.showUpdate}
                onHeAfterHide={
                  ({ target, currentTarget }) => (target === currentTarget ? this.handleClickUpdate : null)
                }
              >
                <form onSubmit={ this.updatecalendartype.bind(this)}>
              <TextField label="Calendar Type:" 
                name="calendarTypeName"
                required 
                value={this.state.calendarTypeName}
                onHeChange={(event) => this.setState({calendarTypeName: event.target.value})} />

              <br />

              <TextField
                label="Description:"
                required 
                name="calendarTypeDescription"
                value={this.state.calendarTypeDescription}
                onHeChange={(event) => this.setState({calendarTypeDescription: event.target.value})}
              />
              <br />

              <TextField
                label="Owner Alias:"
                required 
                name="calendarTypeOwner"
                value={this.state.calendarTypeOwner}
                onHeChange={(event) => this.setState({calendarTypeOwner: event.target.value})}
              />
              <br />

              <TextField
                label="Status:"
                required 
                name="calendarTypeStatus"
                value={this.state.calendarTypeStatus}
                onHeChange={(event) => this.setState({calendarTypeStatus: event.target.value})}
              />
              <br />



              <Button slot="footer" type="submit" appearance="primary">
                Update
              </Button>
              <Button slot="footer" onClick={this.handleClickUpdate}>
                Close
              </Button>
            </form>
              </Dialog>
              <Dialog
                heading="Delete Calendar Type"
                open={this.state.showDelete}
                onHeAfterHide={
                  ({ target, currentTarget }) => (target === currentTarget ? this.handleClickDelete : null)
                }
              >
                <form onSubmit={ this.deletecalendartype.bind(this)}>
              
                 Are you sure to delete the Calendar Type: <b>{this.state.calendarTypeName}</b>?
                 <br></br>
                 <br></br>
                 <br></br>
                <Button slot="footer" type="submit" appearance="primary">
                  Delete
                </Button>
                <Button slot="footer" onClick={this.handleClickDelete}>
                  Cancel
                </Button>
              </form>
              </Dialog>

              </div>
              <br></br>
              <div>
                <BootstrapTable striped 
                  bordered 
                  hover 
                  condensed
                  wrapperClasses="table-responsive"
                  className = "Table"           
                  keyField='id' 
                  data={ this.state.calendartypes } 
                  columns={ this.state.columns }
                  options={options}
                  pagination={ paginationFactory(options) } 
                  expandRow={expandRow}
                  noDataIndication="Loading..."/> 
              </div>
  
            </div>
      );
      }
    }

  export default AddCalendarType;