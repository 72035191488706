import moment from 'moment';
import React from 'react';
import { reactAI } from "react-appinsights";
import uuid from 'react-native-uuid';
import {fetchToken} from '../../Util.js';
import {
    SERVICE_NAME
  } from "../../common/Constants/Constants";
import ReactRegionDropDown from '../RegionDropdown/Region';
import ReactEditDropDown from '../ServiceDropdown/EditDropDown';
import {appInsights} from '../../common/AppInsights/AppInsights';
import AlertDialog from "../../common/AlertDialog/AlertDialog";
import { Radio, RadioGroup} from 'react-radio-group';
import ReactDropDown from '../ServiceDropdown/DropDown';
import ReactEditRegionDropDown from '../RegionDropdown/EditRegion';
import {ButtonType,DefaultButton} from 'office-ui-fabric-react/lib/Button'  
import * as Datetime from 'react-datetime';
import { Button,Modal,Navbar,Form,FormGroup,FormLabel,NavDropdown,Nav,FormControl, Container, Row,Col,ButtonGroup } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

class EditCalendar extends React.Component{

    constructor(props){     
      super(props);    
      this.state = {readOnly:true,isdisabled:true,clicked:'',date: null,
      enddate:null,showDialog:false,outageId : '',showDeleteDialog:false,
      teamGroup :null, teamComponent:null,teamService:null,updateCompleted:false,
}
      this.handleChange = this.handleChange.bind(this);
      this.handleOnChange = this.handleOnChange.bind(this);
      this.returnArrayValues = this.returnArrayValues.bind(this);
      this.onChange = this.onChange.bind(this);
      this.onEndChange = this.onEndChange.bind(this);
      this.flip = this.flip.bind(this);
    };    
  
    ToggleReadonly = () => {
      
      this.setState({readOnly:!this.state.readOnly})
      //this.setState({isdisabled:false})
    }
  
    flip = async() => 
    {
      this.setState({showDialog:!this.state.showDialog})
    }
  
    handleOnChange(e){
     
      this.setState({ clicked:e})
      }
  
    
    handleChange(e){
        this.setState({[e.target.name]: e.target.value, [`${e.target.name}Error`]:  '' })
      }
  
    onChange(date)
    {      //this.preventDefault(); 
      //this.setState({ date })
      this.setState({ date:date, dateError :''})
    }
  
    closeDialog() {
      this.setState( {showDialog: false});
    }
  
  
    // handleRadioChange(date)
    // {      //this.preventDefault(); 
    //   this.setState({ clicked:date})
    // }
  
    onEndChange(date)
    {
      
        //this.preventDefault(); 
      //this.setState({enddate:date })
      this.setState({enddate:date,enddateError:''})
    }
  
    returnArrayValues(event)
    {
      let opts = [], opt;
      for (let i = 0, len = event.length; i < len; i++) {
        opt = event[i].value;
        opts.push(opt);
       }
      return opts.join().length > 0 ? opts.join() : event.value;
    }

    render(){
      let subText,display;
      let checked = (this.state.clicked == "") ? (((this.props.event.allDay)==true) ? true : false):((this.state.clicked)=="Yes"?true:false);
      const { userEmail} = this.props;
      let validEmail = (userEmail != undefined  ? userEmail.toLowerCase().includes("microsoft.com") : null);
      return (
          <div>
            <Modal style = {{'overflow': 'visible'}} role="dialog" data-keyboard="false" show={this.props.show} size="lg" onHide ={()=> {this.props.closeModalPopUpFunc(false)}} backdrop="static" centered autoFocus="true">
              <Modal.Header closeButton>
                <Modal.Title>View Factory Events</Modal.Title>
              </Modal.Header>
              <form id = "updateevent">
                <Modal.Body style={{'maxHeight': 'calc(100vh - 210px)', 'overflowY': 'auto'}}>
                  <div className="form-group required">
                    <label for="outageTitle" class="control-label">Title</label>
                    <input type="text" className="form-control" name="outageTitle" readOnly={this.state.readOnly} defaultValue={this.props.event.outageTitle} onChange={(e) => this.handleChange(e)} />
                  </div>
                  <div className="form-group required">
                    <label class="control-label" for="outageDescription">Description</label>
                    <textarea aria-label="OutageDescription" type="text" placeholder="Enter your description" 
                    data-toggle = "tooltip" title="Input Message Subject between 50 to 500"
                    class="form-control" name="description"
                    readOnly={this.state.readOnly} defaultValue={this.props.event.outageDescription} onChange={(e) => this.handleChange(e)}/>
                   
                  </div>
                  <div className="form-group required">
                    <label for="outageImpact" class="control-label">Impact</label>
                    <textarea aria-label="outageImpact" type="text" class="form-control" name="impact" readOnly={this.state.readOnly} defaultValue={this.props.event.outageImpact} onChange={(e) => this.handleChange(e)}/>
                    
                  </div>
  
                  <div className="form-group required">              
                <label htmlFor="startDate" class="control-label">StartTime</label>
                <div className="form-group">
                <Datetime  aria-label="Start Time" calendarAriaLabel="StartTimeCalender" yearAriaLabel="Year" monthAriaLabel="Month" dayAriaLabel="Day" hourAriaLabel="Hour" minuteAriaLabel="Minute" amPmAriaLabel="ampm"
                  name="startDate"  inputProps={{readOnly:this.state.readOnly}} onChange={this.onChange} value={this.state.date == null ? this.props.event.start : this.state.date}></Datetime>    
                 
                </div>            
              </div>
              <div className="form-group required">
                <label htmlFor="endtime" class="control-label">EndTime</label>
                <Datetime  aria-label="End Time" calendarAriaLabel="EndTimeCalender" yearAriaLabel="Year" monthAriaLabel="Month" dayAriaLabel="Day" hourAriaLabel="Hour" minuteAriaLabel="Minute" amPmAriaLabel="ampm"
                  name="endtime"  inputProps={{readOnly:this.state.readOnly}} onChange={this.onEndChange} value={this.state.enddate == null ? this.props.event.end : this.state.enddate}></Datetime>
                
              </div>
              { validEmail ?  
                  <div className="form-group required">
                    <label for="pointOfContact" class="control-label">PM Contact</label>
                    <input aria-label="PM Contact" type="email" class="form-control" id="pmContact" name="pmcontact" readOnly={this.state.readOnly} defaultValue={this.props.event.pmContact} onChange={(e) => this.handleChange(e)}/>
                   
                 </div>
                  : null }
                  
                  { !validEmail ?
                  <div className="form-group required">
                <label htmlFor="externalcontact" class="control-label">External Contact</label>
                <input aria-label="External Contact" type="email" className="form-control" name="externalcontact" id="externalcontact" readOnly={this.state.readOnly} defaultValue={this.props.event.extContact}  onChange={(e) => this.handleChange(e)}  />
                
              </div>
              : null}
             
              { validEmail ?  
              <div className="form-group required">
                <label htmlFor="engcontact" class="control-label">Engineering Contact</label>
                <input aria-label="Engineering Contact" type="email" className="form-control" id="engcontact" name="engcontact" readOnly={this.state.readOnly} defaultValue={this.props.event.engContact}  onChange={(e) => this.handleChange(e)}  />
                
              </div> 
               : null }        
              <div className="form-group required">
                <label htmlFor="alldayevent" class="control-label">AllDayEvent</label>
                {/* <RadioButtons name ="alldayevent" value={this.state.alldayevent||""} onChange={(e) => this.handleChange(e)}></RadioButtons> */}
                <RadioGroup name="alldayevent"  onChange={(e) => this.handleOnChange(e)}>
                  
                 <div className="radio-button-background">
                  <Radio aria-label="yes" disabled={this.state.readOnly} checked= {(this.state.clicked == "") ? (((this.props.event.allDay)==true) ? true : false):((this.state.clicked)=="Yes"?true:false)} value = "Yes" className="radio-button" />Yes
                  <Radio aria-label="no" disabled={this.state.readOnly} checked= {(this.state.clicked == "") ? (((this.props.event.allDay)==false) ? true : false):((this.state.clicked)=="No"?true:false)} value = "No" style={{ marginLeft: "10px" }} className="radio-button" />No
                 </div>
                 {/* <div className="radio-button-background">
                    <Radio value="Orange" style={{ marginLeft: "5px" }} className="radio-button" />Orange
                  </div> */}
                </RadioGroup>
                </div>
                
                { validEmail ?                
                  <div className="form-group">
                   <ReactEditDropDown email = {userEmail} 
                   event = {this.props.event} readOnly={this.state.readOnly}/>
                  </div>
                  : null
                }
                 
                  <div className="form-group">
                  <ReactEditRegionDropDown email = {userEmail} event = {this.props.event} readOnly={this.state.readOnly}></ReactEditRegionDropDown>
                  </div>
                 </Modal.Body>              
                <Modal.Footer>
                  <Button variant="secondary" onClick={()=> {this.setState({readOnly:true});this.props.closeModalPopUpFunc(false)}}>
                    Close
                  </Button>                
                </Modal.Footer>
              </form>
            </Modal>
          </div>
      );
    }
  }

export default EditCalendar;