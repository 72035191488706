import React from "react";
import { Icon } from "office-ui-fabric-react";

const InternalServerError = () => (
    <div className="mh-3">
        <h2 className="mb-1 pb-n">
            <span className="ms-fontSize-xxl">Internal Server Error&nbsp;</span><Icon className="ms-IconColor-red ms-fontSize-xl" iconName="Blocked" />
        </h2>
        <p>The server encountered an internal error and was unable to complete your request.</p>
        <p>Please contact the server administrator at scoutagehub@microsoft.com to inform about the error and the actions you performed just before this error.</p>
    </div>
);

export default InternalServerError;