import '../../../node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css'; 
import $ from 'jquery';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import React from 'react';
//import icon from '../Images/Search-Icon.png'
import icon from '../Images/NewSearchIcon.png'
import AddEventModalPopUp from "../../components/AddEvent/AddEvent";
import CalendarApp from "../../components/Calendar/CalendarApp";
import '../../OutageHub.css'

class Search extends React.Component{
    constructor(props){
        super(props)
        this.state = {
          showAddEventModal: false
        }
        this.showModal = this.showModal.bind(this);
    }
    showModal() {
        this.setState( {showAddEventModal:true});
      }

      handleAddEvent = (event) =>{
        
      }
  
      handleAddEventModalPopUp = (show) => {this.setState({showAddEventModal:show});}
  render()
  {
    const { showAddEventModal } = this.state;
    if(showAddEventModal)
    {
     return <AddEventModalPopUp
          show={this.state.showAddEventModal}
          submitModalPopUpFunc = {this.handleAddEventModalPopUp} 
          userEmail = {this.props.user.email}
          role = {this.props.role} 
          addEvent = {this.handleAddEvent}          
      />
    }
  return (
    <div class="offset-4 col-4">

    <img src={icon} style ={{'display': 'block',
'margin-left': 'auto',
'margin-right': 'auto'}}  alt="Cinque Terre"/>
    <div className ="text-center">No upcoming downtime<br/>Click 
    <a class="text-primary" href="#" onClick={this.showModal}> Here</a> to submit new request</div>

   </div>
    );
}
}
export default Search