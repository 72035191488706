import React,{ Component,useState} from 'react';
import { Calendar, momentLocalizer} from "react-big-calendar";
import moment from 'moment';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import * as Datetime from 'react-datetime';
import '../../App.css'
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import {Modal,Navbar,Form,NavDropdown,Nav,FormControl, Container, Row,Col,ButtonGroup} from 'react-bootstrap';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import format from "date-fns/format";
import RadioButtons from '../RadioButton/RadioButtons';
import { UserAgentApplication } from 'msal';
import config from '../../config';
import { AccessTokenValue } from 'msal/lib-commonjs/cache/AccessTokenValue';
import { th } from 'date-fns/locale';
import Dropdown from '../ServiceDropdown/DropDown';
import ReactDropDown from '../ServiceDropdown/DropDown';
import {fetchToken} from '../../Util.js';
import ReactRegionDropDown from '../RegionDropdown/Region';
import ReactEditDropDown from '../ServiceDropdown/EditDropDown';
import { Radio, RadioGroup} from 'react-radio-group';
import ReactEditRegionDropDown from '../RegionDropdown/EditRegion';
import CustomSpinner from "../../common/Spinner/Spinner";
import AlertDialog from "../../common/AlertDialog/AlertDialog";
import AddEventModalPopUp from "../../components/AddEvent/AddEvent";
import EditModalPopUp from "../../components/EditEvent/EditEvent";
import CalendarApp from "../../components/Calendar/CalendarApp";
import ErrorBoundary from "../../common/ErrorBoundary/ErrorBoundary";
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";
import { reactAI } from "react-appinsights";
import uuid from 'react-native-uuid';
import AccessDenied from "../../common/AccessDenied/AccessDenied";
import {appInsights} from '../../common/AppInsights/AppInsights';
import alertDialog from '../../common/AlertDialog/AlertDialog';
import {Fabric} from 'office-ui-fabric-react/lib/Fabric'  
import {ButtonType,DefaultButton} from 'office-ui-fabric-react/lib/Button'  
import {Dialog, DialogType, DialogFooter} from 'office-ui-fabric-react/lib/Dialog' 
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import InternalServerError from "../../common/ErrorPages/InternalServerError";
import ReactFactoryDropDown from '../FactoryDropDown/Factory';
import {Children} from 'react';
import AddSAPEventModalPopUp from "../../components/AddEvent/AddSAPEvent";
import EditSAPEventModalPopUp from "../../components/EditEvent/EditSAPEvent";
import Filters from './Filters';
import {GetPersonaLegend} from './Legends';
import CREWHolidayCalendarFilter from './CREWHolidayCalendarFilter';
import TimezoneCalendarFilter from './TimezoneCalendarFilter';
import AddFactoryProductionEvent from "../../components/AddEvent/AddFactoryProductionEvent";
import EditFactoryProductionEvent from "../../components/EditEvent/EditFactoryProductionEvent";
import { createScope, partnerCenterTheme, button, icon, menu, menuItem, commandBar, pageTitle, dataGrid, text, badge } from '@harmony/enablers/react'; 
import { event } from 'jquery';
import { getSelectedDateFilter } from './Filters';

// Create a Harmony Enabler scope
const scope = createScope({
  //rootElement: document.querySelector('#external-events'),
  
  //rootElement: document.getElementById('root'),
  //basePath: 'Source/OutageHubUI/OutageCalendar/node_modules/@harmony/enablers',
  theme: partnerCenterTheme,
  //styles: [partnerCenterTheme],
  reactInstance: React,
});

const Button = scope.forReact(button);
const Icon = scope.forReact(icon);
const Menu = scope.forReact(menu);
const MenuItem = scope.forReact(menuItem);
const CommandBar = scope.forReact(commandBar);
const PageTitle = scope.forReact(pageTitle);
const DataGrid = scope.forReact(dataGrid);
const Text = scope.forReact(text);
const Badge = scope.forReact(badge);

moment.locale("en-GB");
const localizer = momentLocalizer(moment);
const OutageCalendar = withDragAndDrop(Calendar);
//const OutageCalendar = ''
let testparvalue = 'placeholder'

const wrapperStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: 'var(--he-spacing-medium)',
};

const commandBarStyle = {
  flex: '1 1 200px',
};

const searchStyle = {
  flex: '0 1 160px',
};


var ReturnStatus = 200;


class NewToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role: props.currrole
    };
  }

  render() {
    return (
      (this.state.role !== "Reader") ?
        <button type="button" aria-labelledby="newrequest" class="btn btn-primary" onClick={() => { this.props.handleAddEventModal(true) }}>New Request</button>
        : null
    );
  }
}

export class ReactCalendar extends React.Component
{
  
  constructor(props){
    super(props);
    this.state = {events:[],filteredevents:[],displayedDateRage: {},calendarevents:[], newfilteredevents:[],
     emptyArray:true,current_view: 'month',current_date: moment.utc().toDate().toUTCString(),
     myDate : (this.props.date === undefined) ? new Date() : this.props.date.myProp,
     start : (this.props.date === undefined) ? new Date() : this.props.date.start,
     filters : this.props.filters,
     role:this.props.role,
     calendars:this.props.calendars,
     calendartypes: this.props.calendartypes,
     event:{},
     //calendars: [],
      //calendartypes: [],
      //mcalendars: this.props.calendars,
     selectedRadioButton:'',
     selectedRegionData:'',
     selectedCalendar:'',
     selectedTimezoneData:"",
     userOrgName:this.props.userOrgName,
     data:[],
     end : (this.props.date === undefined) ? new Date() : this.props.date.end}
    this.bindScopes([
      'onView',
      'onNavigate',
      'updateTimes',
      'handleNavigate',
      'timesToEvents'
    ]);

    this.updateTimes();
  }

  async updateSmallCalendar(value){
    this.setState({myDate:value});
    //this.handleNavigate(value,'day','NEXT');  
  }

  bindScopes(keys){
    for(let key of keys){
      this[key] = this[key].bind(this);
    }
  }

  handleNavigate(date, view, action) {
    this.setState({myDate: moment(date).toDate()});
}

  timesToEvents(times){
    const events = times.map(time => {
      const start_date = moment(time.Date);
      const end_date = start_date.clone().add(time.Minutes, 'minutes');
      return {
        title: time.Incident.lookupName,
        start: start_date.toDate(),
        end:   end_date.toDate()
      };
    })
    // console.log(events);
    this.setState({
      events: events
    })
  }
  
  onView(view){
    this.setState({
      current_view: view
    });

    this.updateTimes(this.state.current_date, 'day');
  }
  onNavigate(date, view){
    const new_date = moment(date);
    this.setState({
      current_date: new_date
    });

    this.updateTimes(new_date, view);
  }

  updateTimes(date = this.state.current_date, view = this.state.current_view){
    let start, end;
    // if view is day: from moment(date).startOf('day') to moment(date).endOf('day');
    if(view === 'day'){
      start = moment(date).startOf('day');
      end   = moment(date).endOf('day');
    }
    else if(view === 'week'){
      start = moment(date).startOf('week');
      end   = moment(date).endOf('week');
    }
    
    else if(view === 'month'){
      end   = moment(date).endOf('month');
      //.add(7, 'days');
      start = moment(date).startOf('month');
      //.subtract(7, 'days');
      
    }
    // if view is agenda: from moment(date).startOf('day') to moment(date).endOf('day').add(1, 'month');
    else if(view === 'agenda'){
      start = moment(date).startOf('day');
      end   = moment(date).endOf('day').add(1, 'month');
    }
    this.setState({start:start.toString(), end:end.toString()})
 }

  RemoveEvent = (outageId) => {
   
    let prevEvents = this.state.events;
    let currentEvents = prevEvents.filter((event)=>{return event.outageId != outageId}).map((event)=> event)
    this.setState({events:currentEvents});
    this.props.closeModalPopUpFunc(false);
  }

  AddEvent = (event) => {
   
    let prevEvents = this.state.events;
    prevEvents.push(event)
    this.setState({events:prevEvents});
    this.props.handleAddEventModalPopUpFunc(false);
  }

  UpdateEvent = (event) => {
   
    let prevEvents = this.state.events;
    let currentEvents = prevEvents.findIndex((newevent)=>{return newevent.outageId == event.EventId})
    prevEvents[currentEvents] = event;
    this.setState({events:prevEvents});
    this.props.closeModalPopUpFunc(false);
  }

  

  
  childWasClicked = () => 
  {
  }

  eventPropGetter = (event, start, end, isSelected) => 
  {
 
    // EVENT COLOURS ON CALENDAR
    let newStyle = {
      borderRadius: "0px",
      border: "none",
      role:"button"
    };

    if(event.outageType=="Informational BusinessUser SAP ReleaseEvent") 
    {
      if(event.category=="Finance Blackout")
    {
      newStyle.backgroundColor = "#de2710"
    }
    else if (event.category=='Public Holiday')
    {
      newStyle.backgroundColor = "#af5f00"
    }
    else if (event.category=='Month/Quarter End Restriction')
    {
      newStyle.backgroundColor = "darkgreen"
    }
    else if (event.category=='Public Holiday')
    {
      newStyle.backgroundColor = "#af5f00"
    }
  }
    else if (event.category=='Public Holiday')
    {
      newStyle.backgroundColor = "#af5f00"
    }
    else if(event.category=='Plant Holiday')
    {
      newStyle.backgroundColor = "darkblue"
    }
    else if(event.category=='OT')
    {
      newStyle.backgroundColor = "#007ba6"
    }
    else if(event.category=='Holiday')
    {
      newStyle.backgroundColor = "black"
    }
    else if(event.category=='Physical Inventory Count')
    {
      newStyle.backgroundColor = "#af5f00"
    }
    else if(event.category=='Production')
    {
      newStyle.backgroundColor = "green"
    }
    else if(event.category=='Partial Production')
    {
      newStyle.backgroundColor = "purple"
    }
    else
    {
      if (event.status == '1' || event.status == '2'){
        newStyle.backgroundColor = "#49498D"
      }
      else if(event.status == '3'){
        newStyle.backgroundColor = "green"
      }
    }

    return {
      style: newStyle
    };
}

  
  eventStyleGetter = (event, start, end, isSelected) => {
    // console.log(event);
    var backgroundColor = '#' + event.hexColor;
    var style = {
        backgroundColor: '#de2710',
        borderRadius: '0px',
        opacity: 0.8,
        color: 'black',
        border: '0px',
        display: 'block'        
    };
    
    return {
        style: style
    };
  }

 
 

  async componentDidMount()
  {
    await this.GetData();  
    // this.updateEvents(); 
  }
  
  async GetData() {
      
    appInsights.trackTrace({ message: 'Calendar Component Loaded and trying to fetch events.' },
    {
        "userName": this.props.userEmail,
        "ComponentId" : COMPONENT_ID,
        "ComponentName" : COMPONENT_NAME,
        "EnvironmentName" : window.env.ENVIRONMENT,
        "ServiceLineName" : SERVICE_LINE,
        "ServiceName" : SERVICE_NAME,
        "ServiceOfferingName" : SERVICE_OFFERING,
        "Correlation Id": uuid.v1(),
        "Component": 'Calendar Page',
    });
    var token = await fetchToken();
    var correlationId = uuid.v1();
    var bearer = 'Bearer ' + token;
    var apimUrl = window.env.APIMURL + "/api/outage/getoutages";
   fetch(apimUrl,{
    method: 'GET',
    headers: {
        'authorization': bearer,
        'CorrelationId': correlationId,
        'Accept' : 'application/json',
        'Content-Type': 'application/json'
    }})
    .then(response => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
      
    })
    .then((res) => {
      //const { statusCode, data } = res;
      const statusCode = res[0];
      const data = res[1];
      this.setState({ ReturnStatus: statusCode});
      //console.log(data)

      // let results = this.state.data;
      // results.push(data)
      

     
      let result = data.map((event)=>{
        if(event.outageType=="Informational BusinessUser SAP ReleaseEvent")
        {
        return {
         
          outageId:event.eventId,
          outageTitle:event.title,
          organization:event.organization,
          outageDescription:event.description,
          category:event.category,
          outageType:event.outageType,
          location:event.location,
          allDay: event.allDayEvent,
          recurrence: event.recurrence,
          end: new Date(event.endTime),
          start: new Date(event.startTime),
          nacategory:event.naCategory,
          title:event.title,
          Timezone:event.timezone
          }
        }
        else
        {
          if(event.naCategory==='CREW Holiday Calendar')
          {
            return{
              outageId:event.eventId,
              holidayName:event.title,
              organization:event.organization,
              engContact:event.engContact,
              extContact:event.externalContact,
              outageType:event.outageType,
              region:event.region,
              country:event.country,
              end: new Date(event.endTime),
              start: new Date(event.startTime),
              title:event.title + " - " + event.region + " - " + event.country,
              status:event.approvalStatus,
              plantName:event.factoryName,
              holidayType:event.category,
              category:event.category,
              nacategory:event.naCategory,
              Timezone:event.timezone
            }
          }
          //COMMENTING BLOCK BELOW AS TITLE & DESCRIPTION FIELDS FOR THIS CALENDAR NOT DISPLAYING
          // else if(event.naCategory==='JDM1 Production Calendar')
          // {
          //   return{
          //     outageId:event.eventId,
          //     title:event.title,
          //     organization:event.organization,
          //     outageType:event.outageType,
          //     pmContact:event.pmcontact,
          //     engContact:event.engContact,
          //     extContact:event.externalContact,
          //     region:event.region,
          //     country:event.country,
          //     end: new Date(event.endTime),
          //     start: new Date(event.startTime),
          //     //title:event.title,
          //     status:event.approvalStatus,
          //     factory:event.factoryName,
          //     category:event.category,
          //     nacategory:event.naCategory,
          //     Timezone:event.timezone
          //   }
          // }

          //POTENTIAL ALTERNATIVE? IN DB THERE IS NO FIELD TO DETERMINE IT IS A FACTORY EVENT LIKE A TYPE
          //   else if(event.naCategory.includes('Production Calendar'))
          // {
          //   return{
          //     outageId:event.eventId,
          //     title:event.title,
          //     organization:event.organization,
          //     outageType:event.outageType,
          //     pmContact:event.pmcontact,
          //     engContact:event.engContact,
          //     extContact:event.externalContact,
          //     region:event.region,
          //     country:event.country,
          //     end: new Date(event.endTime),
          //     start: new Date(event.startTime),
          //     //title:event.title,
          //     status:event.approvalStatus,
          //     factory:event.factoryName,
          //     category:event.category,
          //     nacategory:event.naCategory,
          //     Timezone:event.timezone
          //   }
          // }
          else
          {
            // console.log("THIS GET DISPLAY EVENTS: "+ result)
          return{
          outageId:event.eventId,
          outageTitle:event.title,
          organization:event.organization,
          outageDescription:event.description,
          outageImpact:event.impact,
          pmContact:event.pmcontact,
          engContact:event.engContact,
          extContact:event.externalContact,
          outageType:event.outageType,
          region:event.region,
          country:event.country,
          teamgroup:event.teamGroup,
          service:event.service,
          component:event.component,
          allDay: event.allDayEvent,
          end: new Date(event.endTime),
          start: new Date(event.startTime),
          title:event.title,
          status:event.approvalStatus,
          servicegroup:event.serviceGroup,
          factory:event.factoryName,
          division:event.division,
          orgName:event.orgName,
          eventstatus:event.eventStatus,
          nextsteps:event.nextSteps,
          nacategory:event.naCategory,
          incidentNumber:event.incidentNumber,
          Timezone:event.timezone,
          severity:event.severity,
          bridgeurls:event.bridgeUrls,
          updatedata:event.updateData,
          resolutiondata:event.resolutionData,
          timelinedata:event.timelineData,
          category:event.category,
            
          
         
          //title:event.title,
          }
         
         
        }
        
        }
        
      })
      
      var updtdata=this.setEventDataUTCTimezone(result);
      var defaultEvents = (this.props.role === "BusinessUser SAP") ? updtdata.filter(x=>x.nacategory  === "BusinessUser SAP") : updtdata.filter(x=>(x.nacategory !== "BusinessUser SAP" && x.nacategory!=="CREW Holiday Calendar" && x.nacategory!=="JDM1 Production Calendar" ));
      this.setState({ events: defaultEvents,filteredevents:updtdata,calendarevents:result,emptyArray:false})
    })
    .catch( error =>
      {
        var err = 
        {
          "message": "Exception occured while getting the calendar events from DB",
          "ComponentId" : COMPONENT_ID,
          "ComponentName" : COMPONENT_NAME,
          "EnvironmentName" : window.env.ENVIRONMENT,
          "ServiceLineName" : SERVICE_LINE,
          "ServiceName" : SERVICE_NAME,
          "ServiceOfferingName" : SERVICE_OFFERING,
          "Component" : "Calendar Page",
          "userName": this.props.userEmail,
          "roles": this.props.role != null || '' ? this.props.role : "",
          "exception": error.message,
          "Correlation Id": correlationId
        }
        appInsights.trackException({exception: error,properties : err});  
      })

      this._isMounted = true;  // set the flag to true
      this.setState({ isLoading: true })
      var apimUrl = window.env.APIMURL + "/api/outage/getcalendars";
      //loading Calendars
      const calendarsPromise = fetch(apimUrl, {
        method: 'GET',
        headers: {
          'authorization': bearer,
          'Accept': 'application/json',
          'CorrelationId': correlationId,
          'Content-Type': 'application/json'
        }
      }).then(response => {
          const statusCode = response.status;
          const data = response.json();
          return Promise.all([statusCode, data]);
        }).then((res) => {
          const statusCode = res[0];
          const calendarlist = res[1];
          
          if (this._isMounted) {
            this.setState({
              calendars: calendarlist, ReturnStatus: statusCode
            });
          }
        }).catch(error => {
  
          var err = {
            "message": "Exception occured calendar page while getting the calendar  list.",
            "ComponentId": COMPONENT_ID,
            "ComponentName": COMPONENT_NAME,
            "EnvironmentName": window.env.ENVIRONMENT,
            "ServiceLineName": SERVICE_LINE,
            "ServiceName": SERVICE_NAME,
            "ServiceOfferingName": SERVICE_OFFERING,
            "Component": 'Calendars'
          }
          appInsights.trackException({ exception: new Error(JSON.stringify(err)) });
          this.setState({ showErrorDialog: true, errorMessage: "An error occurred while getting calendar list. Please try again later." });
        });
  
      //loading Calendars Types
      apimUrl = window.env.APIMURL + "/api/outage/getcalendartypes";
      const calendartypesPromise = fetch(apimUrl, {
        method: 'GET',
        headers: {
          'authorization': bearer,
          'Accept': 'application/json',
          'CorrelationId': correlationId,
          'Content-Type': 'application/json'
        }
      }).then(response => {
          const statusCode = response.status;
          const data = response.json();
          return Promise.all([statusCode, data]);
        }).then((res) => {
          const statusCode = res[0];
          const calendarlist = res[1];
        
          if (this._isMounted) {
            this.setState({
              calendartypes: calendarlist, ReturnStatus: statusCode
            });
          }
        }).catch(error => {
  
          var err = {
            "message": "Exception occured calendar page while getting the calendar type list.",
            "ComponentId": COMPONENT_ID,
            "ComponentName": COMPONENT_NAME,
            "EnvironmentName": window.env.ENVIRONMENT,
            "ServiceLineName": SERVICE_LINE,
            "ServiceName": SERVICE_NAME,
            "ServiceOfferingName": SERVICE_OFFERING,
            "Component": 'Calendars'
          }
          appInsights.trackException({ exception: new Error(JSON.stringify(err)) });
          this.setState({ showErrorDialog: true, errorMessage: "An error occurred while getting calendar type list. Please try again later." });
        });
     
      
    this.props.setClick(this.RemoveEvent);
    this.props.AddsetClick(this.AddEvent);
    this.props.UpdatesetClick(this.UpdateEvent);  
 
    
  }
  
 


  onEventResize = (type, { event, start, end, allDay }) => {
    this.setState(state => {
      state.events[0].start = start;
      state.events[0].end = end;
      return { events: state.events };
    });
  };

  setCurrentEvent = (event) => {
    this.setState({ event: event });
    console.log('this is the set current event function')
  }

 


  onEventDrop = ({ event, start, end, allDay }) => {
    alert(start)
  };

 convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  //const testvar = 
  async updateEvents(value,selectedregion,selectedTimezone, selectedDateFilter, selectedEventFilter)
  {
    //selectedDateFilter = getSelectedDateFilter()  
    //console.log("SELECTED DATE FILTER: "+selectedDateFilter)
    let startdate,enddate;
    selectedregion=selectedregion===''||selectedregion===undefined||selectedregion===null?this.state.selectedRegionData:selectedregion;
    selectedTimezone=selectedTimezone===''||selectedTimezone===undefined||selectedTimezone===null?this.state.selectedTimezoneData:selectedTimezone;
    var prevselectedTimezone=this.state.selectedTimezoneData;
    var isinternalUsers = (this.props.userEmail.toLowerCase().includes("microsoft.com") ? true : false); 
    var partnerOrgName="";

    this.setState({filteredevents:this.state.calendarevents}); 
    //var calevents,updtcalevents;


    // Create a dictionary of events by category
const eventsByCategory = {};
this.state.filteredevents.forEach(event => {
  if (event.nacategory === value) {
    event.nacategory = value;
    testparvalue = event.nacategory;
  }
  if (!eventsByCategory[event.nacategory]) {
    eventsByCategory[event.nacategory] = [];
  }
  eventsByCategory[event.nacategory].push(event);
});

// Get the events for the given value
const calevents = eventsByCategory[value] || [];

// Update the calendar events and state
const updtcalevents = this.selectedtimezoneCalendarData(calevents, selectedTimezone, prevselectedTimezone);
this.setState({
  events: updtcalevents,
  selectedRadioButton: value,
  selectedRegionData: selectedregion,
  selectedTimezoneData: selectedTimezone,
  selectedCalendar: value,
  testparvalue: value
  
});

// Filter the new events array based on selected date filter
// * TODO: add additional filters - next week & next month
// ! events this week & next week logic needs to be reviewed
if (selectedDateFilter.length > 0) {
  const today = new Date();
  const startOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
  const endOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - today.getDay()));
  const filteredEvents = calevents.filter(event => {
    const startDate = new Date(event.start);
    if (selectedDateFilter.indexOf('day') !== -1) {
      return startDate.getFullYear() === today.getFullYear() &&
        startDate.getMonth() === today.getMonth() &&
        startDate.getDate() === today.getDate();
    } else if (selectedDateFilter.indexOf('week') !== -1) {
      // return startDate >= startOfWeek && startDate <= endOfWeek;
      const thisWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      const thisWeekEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
      return startDate >= thisWeekStart && startDate <= thisWeekEnd;
    } else if (selectedDateFilter.indexOf('month') !== -1) {
      return startDate.getFullYear() === today.getFullYear() &&
        startDate.getMonth() === today.getMonth();
    } else if (selectedDateFilter.indexOf('next-week') !== -1) {
      // const nextWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
      // const nextWeekEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 14);
      // return startDate >= nextWeekStart && startDate <= nextWeekEnd;
      const nextWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
      const nextWeekEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 14);
      return startDate >= nextWeekStart && startDate <= nextWeekEnd;
    } else if (selectedDateFilter.indexOf('next-month') !== -1) {
      const nextMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      nextMonthStart.setMonth(nextMonthStart.getMonth() + 1);
      const nextMonthEnd = new Date(nextMonthStart.getFullYear(), nextMonthStart.getMonth() + 1, 0);
      const startOfMonth = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
      return startOfMonth >= nextMonthStart && startOfMonth <= nextMonthEnd;
      
    }// previous month filter
      else if (selectedDateFilter.indexOf('previous-month') !== -1 ){
      const today = new Date();
      const firstDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      const lastDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth(), 0);
      return startDate >= firstDayOfPreviousMonth && startDate <= lastDayOfPreviousMonth;
    }
  });
  console.log('this is updated new filtered events array: ' + filteredEvents.length);
  filteredEvents.filter(event => { console.log(event.title, event.nacategory) });
  this.setState({ events: filteredEvents });
}

if(selectedEventFilter.length > 0){
  console.log('this is the IF for event filter inside BigCalendar.js')
  const currentDate = new Date();
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(currentDate.getMonth() - 3);
  const filteredEvents = calevents.filter(event => selectedEventFilter.includes(event.status) && 
  new Date(event.start) >= threeMonthsAgo);
  console.log(filteredEvents);
  this.setState({ events: filteredEvents });
  
  
 
  // this.setState({ events: filteredEvents });
}
//console.log('this is updated new filtered events array: ' + this.newfilteredevents.length);
//this.newfilteredevents.filter(event => { console.log(event.title) });

//console.log("THIS IS LOOP: " + value);
// console.log("THIS IS RESULTS ARRAY: " + this.state.data);
// this.GetData()
testparvalue = value
console.log('this is selected calendar: '+ value)
// console.log('this is calevents var: '+calevents)
// console.log('this is updatcalevents: '+updtcalevents)

//methodInvoke(value);
//console.log("THIS IS CALEVENTS: " + calevents)

    //current implementation 12-6-23
    // for (let i = 0; i < value.length; i++) {
    //   calevents=this.state.filteredevents.filter(x=>x.nacategory=== value);
    //   updtcalevents=this.selectedtimezoneCalendarData(calevents,selectedTimezone,prevselectedTimezone);
    //   this.setState({events:updtcalevents,selectedRadioButton:value,selectedRegionData:selectedregion,selectedTimezoneData:selectedTimezone});
    //   console.log("THIS IS LOOP: "+value)
    // }

    // value is not an iterable object
    // value.forEach(async (event) => {
      
    //   calevents=this.state.filteredevents.filter(x=>x.nacategory=== value);
    //   updtcalevents=this.selectedtimezoneCalendarData(calevents,selectedTimezone,prevselectedTimezone);
    //   this.setState({events:updtcalevents,selectedRadioButton:value,selectedRegionData:selectedregion,selectedTimezoneData:selectedTimezone});
    //   console.log("THIS IS LOOP: "+value, event)
    // });


    // if(isinternalUsers===false)
    // {
    //   partnerOrgName=this.props.userOrgName;
    // }

    // resetting the calendar eventlist before displaying it based on selected timezone .
    
  //  if(value.trim().toLowerCase() === "sap release calendar" && isinternalUsers)
  //  {
  //       calevents=this.state.filteredevents.filter(x=>x.nacategory=== "BusinessUser SAP");
  //       updtcalevents=this.selectedtimezoneCalendarData(calevents,selectedTimezone,prevselectedTimezone);
  //       this.setState({events:updtcalevents,selectedRadioButton:value,selectedRegionData:selectedregion,selectedTimezoneData:selectedTimezone});

  //  }
  //  else if(value.trim().toLowerCase()==="crew holiday calendar")
  //  {
  //   if(selectedregion==='' || selectedregion===null || selectedregion===undefined || selectedregion==="All")
  //   {
  //       calevents=this.state.filteredevents.filter(x=>x.nacategory=== "CREW Holiday Calendar");
  //       updtcalevents=this.selectedtimezoneCalendarData(calevents,selectedTimezone,prevselectedTimezone);
  //       this.setState({events:updtcalevents,selectedRadioButton:value,selectedRegionData:selectedregion,selectedTimezoneData:selectedTimezone});
  //   }
  //   else
  //   {
  //       calevents=this.state.filteredevents.filter(x=>x.nacategory=== "CREW Holiday Calendar" && x.region===selectedregion);
  //       updtcalevents=this.selectedtimezoneCalendarData(calevents,selectedTimezone,prevselectedTimezone);
  //       this.setState({events:updtcalevents,selectedRadioButton:value,selectedRegionData:selectedregion,selectedTimezoneData:selectedTimezone});
  //   }
  // }
  // else if(value.trim().toLowerCase()==="jdm1 production calendar") // allow only sce-pegatron .
  // {
  //   if((partnerOrgName.toLowerCase()==="pegatron" && isinternalUsers===false) ||(isinternalUsers))
  //   {
  //       calevents=this.state.filteredevents.filter(x=>x.nacategory=== "JDM1 Production Calendar");
  //       updtcalevents=this.selectedtimezoneCalendarData(calevents,selectedTimezone,prevselectedTimezone);
  //       this.setState({events:updtcalevents,selectedRadioButton:value,selectedRegionData:selectedregion,selectedTimezoneData:selectedTimezone});
  //       console.log("THIS IS JDM1 CALL: "+value)
  //   }
  //   else
  //   {
  //       calevents=this.state.filteredevents.filter(x=>x.nacategory !== "BusinessUser SAP" && x.nacategory !== "CREW Holiday Calendar"  && x.nacategory !== "JDM1 Production Calendar");
  //       updtcalevents=this.selectedtimezoneCalendarData(calevents,selectedTimezone,prevselectedTimezone);
  //       this.setState({events:updtcalevents,selectedRadioButton:value,selectedRegionData:selectedregion,selectedTimezoneData:selectedTimezone});

  //   }    
  // }
  // else if(value.trim().toLowerCase()==="lcm1 production calendar")
  // {
  //   calevents=this.state.filteredevents.filter(x=>x.nacategory=== "LCM1 Production Calendar");
  //   updtcalevents=this.selectedtimezoneCalendarData(calevents,selectedTimezone,prevselectedTimezone);
  //   this.setState({events:updtcalevents,selectedRadioButton:value,selectedRegionData:selectedregion,selectedTimezoneData:selectedTimezone});
  //   console.log("THIS IS LCM1 CALL: "+value)
  // }
  // else
  // {     
  //   console.log("Calendar test test: "+value)
    //console.log(`calendar name test: ${Calendar.calendarName}`)
   // console.log(`additional tests: ${this.state.calendars}`)
       // calevents=this.state.filteredevents.filter(x=>x.nacategory !== "BusinessUser SAP" && x.nacategory !== "CREW Holiday Calendar"  && x.nacategory !== "JDM1 Production Calendar");
       // updtcalevents=this.selectedtimezoneCalendarData(calevents,selectedTimezone,prevselectedTimezone);
       // this.setState({events:updtcalevents,selectedRadioButton:value,selectedRegionData:selectedregion,selectedTimezoneData:selectedTimezone});
  
   
   await this.props.setCalendar(value); 
   
  } 

 

  ColoredDateCellWrapper = ({children, value}) =>
    React.cloneElement(Children.only(children), {
        style: {
            ...children.style,
            //JSON.stringify(value).slice(1,11)
            //toISOString().split('T')[0]
            backgroundColor: this.convert(value) === this.convert(this.state.myDate) ? '#17a2b8' : null, 
        },
    });

     // convert UTC date to CST date .
     convertUTCDateToCST(date)
     {
       var now_utc =  date;
       now_utc.setHours(now_utc.getHours()+8);
       //return now_utc.toUTCString();
       return now_utc;
     }
     // convert UTC date to PST date . 
     convertUTCDateToPST(date)
     {
       var now_utc =  date;
       var isDST=this.checkDateForDaylightSaving(date);
       if (isDST===true)
       {
         now_utc.setHours(now_utc.getHours()-7); //during daylight saving months .
       }
       else
       {
         now_utc.setHours(now_utc.getHours()-8);
       } 
       //return now_utc.toUTCString();
       return now_utc;
     }
     // convert CST date to UTC date .
    convertCSTDateToUTC(date)
    {
      var now_cst =  date;
      now_cst.setHours(now_cst.getHours()-8);
      //return now_cst.toUTCString();
      return now_cst;
    }
    convertCSTDateToPST(date)
    {
      var now_cst =  date;
      var isDST=this.checkDateForDaylightSaving(date);

      if (isDST===true)
      {
      now_cst.setHours(now_cst.getHours()-8);
      now_cst.setHours(now_cst.getHours()-7);
      }
      else
      {
        now_cst.setHours(now_cst.getHours()-8);
        now_cst.setHours(now_cst.getHours()-8);
      }
      //return now_cst.toUTCString();
      return now_cst;
    }
    // convert PST date to UTC date .
    convertPSTDateToUTC(date)
    {
      var now_pst =  date;
      var isDST=this.checkDateForDaylightSaving(date);
      if (isDST===true)
      {
        now_pst.setHours(now_pst.getHours()+7); //during daylight saving months .
      }
      else
      {
        now_pst.setHours(now_pst.getHours()+8);
      } 
      //return now_pst.toUTCString();
      return now_pst;
    }
    convertPSTDateToCST(date)
    {
      var now_pst =  date;
      var isDST=this.checkDateForDaylightSaving(date);

      if (isDST===true)
      {
         now_pst.setHours(now_pst.getHours()+7);
         now_pst.setHours(now_pst.getHours()+8);
      }
      else
      {
        now_pst.setHours(now_pst.getHours()+8);
        now_pst.setHours(now_pst.getHours()+8);
      }
      //return now_cst.toUTCString();
      return now_pst;
    }

    //calculate daylight saving start date , end date  in a year . 
  
    calculateDSTStartDate()
    {
      const days = {
        Mon: 1,
        Tue: 2,
        Wed: 3,
        Thu: 4,
        Fri: 5,
        Sat: 6,
        Sun: 0
      }
     
      var weekNumber,day,monthMoment;
      var year=new Date().getFullYear();
      var marchdate=year+'-03-01';
      var dststartdate;

      // 2nd Sunday of March . 
      weekNumber=2;
      day=days.Sun;
      monthMoment=moment(marchdate);
     
      let m = monthMoment.clone()
         .startOf('month')                     
         .day(day)
        if (m.month() !== monthMoment.month()) 
        {
          m.add(7, 'd');
        }
        //dststartdate= m.add(7 * (weekNumber - 1), 'd').format('YYYY-MM-DDTHH:mm:ss.fff Z');
        dststartdate= m.add(7 * (weekNumber - 1), 'd');
        dststartdate=moment(dststartdate).add(2, 'hours');
        return dststartdate;            
       
    }

    calculateDSTEndDate()
    {
      const days = {
        Mon: 1,
        Tue: 2,
        Wed: 3,
        Thu: 4,
        Fri: 5,
        Sat: 6,
        Sun: 0
      }
     
      var weekNumber,day,monthMoment;
      var year=new Date().getFullYear();
      var novdate=year+'-11-01';
      var dstenddate;

      // 1st Sunday of November .  
      weekNumber=1;
      day=days.Sun;
      monthMoment=moment(novdate);

      let m = monthMoment.clone()
      .startOf('month')                     
      .day(day)
       if (m.month() !== monthMoment.month()) 
      {
         m.add(7, 'd');
      }
      //dstenddate= m.add(7 * (weekNumber - 1), 'd').format('YYYY-MM-DDTHH:mm:ss.fff Z');
      dstenddate= m.add(7 * (weekNumber - 1), 'd');
      dstenddate=moment(dstenddate).add(2, 'hours');
      return dstenddate;        
       
    }
    //check daylight saving is active or not . 
    checkDateForDaylightSaving(date)
    {
       var DaylightStartDate= this.calculateDSTStartDate();
       var DaylightEndDate=this.calculateDSTEndDate();
       if(date >= DaylightStartDate && date <=DaylightEndDate)
       {
         return true;
       }
       else
       {
         return false;
       }
    }

     selectedtimezoneCalendarData(origevents,selectedtimezone,prevselectedtimezone)
     {
        var updtevents=origevents;
        if(prevselectedtimezone==="UTC" || prevselectedtimezone===null || prevselectedtimezone==='' || prevselectedtimezone===undefined)
        {
             if(selectedtimezone==="CST")
             {
                updtevents.forEach((eventlist) => {          
                eventlist.start=this.convertUTCDateToCST(eventlist.start);
                eventlist.end=this.convertUTCDateToCST(eventlist.end);
                eventlist.Timezone=selectedtimezone;
          
             });
            
             }
             else if(selectedtimezone==="PST")
             {
                updtevents.forEach((eventlist) => {          
                  eventlist.start=this.convertUTCDateToPST(eventlist.start);
                  eventlist.end=this.convertUTCDateToPST(eventlist.end);
                  eventlist.Timezone=selectedtimezone;
      
                });
             }
                   
        }
        else if(prevselectedtimezone==="CST")
        {
             if(selectedtimezone==="UTC")
             {
                updtevents.forEach((eventlist) => {          
                eventlist.start=this.convertCSTDateToUTC(eventlist.start);
                eventlist.end=this.convertCSTDateToUTC(eventlist.end);
                eventlist.Timezone=selectedtimezone;
          
             });
            
             }
             else if(selectedtimezone==="PST")
             {
                updtevents.forEach((eventlist) => {          
                  eventlist.start=this.convertCSTDateToPST(eventlist.start);
                  eventlist.end=this.convertCSTDateToPST(eventlist.end);
                  eventlist.Timezone=selectedtimezone;
      
                });
             }
        }
        else if(prevselectedtimezone==="PST")
        {
             if(selectedtimezone==="UTC")
             {
                updtevents.forEach((eventlist) => {          
                eventlist.start=this.convertPSTDateToUTC(eventlist.start);
                eventlist.end=this.convertPSTDateToUTC(eventlist.end);
                eventlist.Timezone=selectedtimezone;
             });
            
             }
             else if(selectedtimezone==="CST")
             {
                updtevents.forEach((eventlist) => {          
                  eventlist.start=this.convertPSTDateToCST(eventlist.start);
                  eventlist.end=this.convertPSTDateToCST(eventlist.end);
                  eventlist.Timezone=selectedtimezone;
                });
             }
        }
        
          return updtevents;

     }

     // this is function that is setting timezone to UTC
     setEventDataUTCTimezone(origevents)
     {         

              origevents.forEach((eventlist) => {  
                if(eventlist.Timezone==="PST" || eventlist.Timezone==="CST") 
                {       
                   //eventlist.Timezone="UTC";
                   //console.log('inside IF in setEventDataUTCTimezone method')
                }
                  
              });

              return origevents;
             
     }

    async onSelectRegionData(value){
      
      this.setState({
          selectedRegionData: value
         });
    } 

    async onSelectTimezoneData(value){
      
      this.setState({
          selectedTimezoneData: value
         });
    } 

    formatShortDate(str1){
      const shortdate = moment(new Date(str1))
      return shortdate.format("YYYY-MM-DDTHH:mm:ss").toString();; 
    }

    formatDate(event) {
      let startdate = event.start;
      let enddate = event.end;
    
      if (event.Timezone === "PST") {
        startdate = Date.parse(new Date(this.convertUTCDateToPST(new Date(event.start))));
        startdate = moment(new Date(startdate), "YYYY-MM-DDTHH:mm:ss.fff Z");
        startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");
        enddate = Date.parse(new Date(this.convertUTCDateToPST(new Date(event.end))));
        enddate = moment(new Date(enddate), "YYYY-MM-DDTHH:mm:ss.fff Z");
        enddate = enddate.format("YYYY-MM-DDTHH:mm:ss");
      } else if (event.Timezone === "CST") {
        startdate = Date.parse(new Date(this.convertUTCDateToCST(new Date(event.start))));
        startdate = moment(new Date(startdate), "YYYY-MM-DDTHH:mm:ss.fff Z");
        startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");
        enddate = Date.parse(new Date(this.convertUTCDateToCST(new Date(event.end))));
        enddate = moment(new Date(enddate), "YYYY-MM-DDTHH:mm:ss.fff Z");
        enddate = enddate.format("YYYY-MM-DDTHH:mm:ss");
      }
      else if(event.Timezone === "UTC") {
        startdate = moment(new Date(startdate), "YYYY-MM-DDTHH:mm:ss.fff Z");
        startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");
        enddate = moment(new Date(enddate), "YYYY-MM-DDTHH:mm:ss.fff Z");
        enddate = enddate.format("YYYY-MM-DDTHH:mm:ss");
      }

      return { startdate, enddate };
    }

  render(){ 
    const {currentDate, currentView,displayedDateRage,filteredevents} = this.state;
    
    if(this.state.ReturnStatus === 500)
    {
      return <InternalServerError/>;
    }
    if(this.state.emptyArray){   
    
    return <CustomSpinner label="Loading" />;
  } 
  
    return (
      
      <div className="render-container">

<Filters 
        // passing events array to filters.js which is called in constructor
        events={this.state.events}
        filteredevents={this.state.filteredevents}
        newfilteredevents={this.state.newfilteredevents}
        newdate={this.updateSmallCalendar.bind(this)} 
        filters = {this.props.filters} 
        internalUser = {this.props.internalUser} 
        selectedTimezoneData={this.state.selectedTimezoneData}
        newevents={this.updateEvents.bind(this)}  
        role = {this.props.role}
          userEmail = {this.props.userEmail}  
          calendars={this.state.calendars}
          calendartypes={this.state.calendartypes} 
          selectedCalendar={this.state.selectedCalendar}
          userOrgName={this.props.userOrgName}       
        />

<GetPersonaLegend 
          Persona = {this.props.role} 
          internalUser = {this.props.internalUser}
          selectedCalendar={this.state.selectedRadioButton}
          calendartypes={this.state.calendartypes} >
        </GetPersonaLegend>
       
      

  {/* <div className="container" style={{ marginLeft: '300px' }}> */}
  {/* <div class="render-container"  > */}
        {/* LEGEND AT TOP - PENDING REVIEW, APPROVED, ADHOC */}
       

      {/* COMMENTING TIMEZONE FILTER ON TOOLBAR, WILL CREATE AS FILTER */}
        {/* { (this.state.selectedRadioButton==="CREW Holiday Calendar") ?
        // TIMEZONE CALENDAR FILTER IS THE TIMEZONE SELECTOR RADIO BUTTONS
        <div class="row"> */}
          
        {/* <TimezoneCalendarFilter
        Persona = {this.props.role} 
        internalUser = {this.props.internalUser}
        selectedCalendar={this.state.selectedRadioButton}
        selectedTimezoneData={this.onSelectTimezoneData.bind(this)}
        newevents={this.updateEvents.bind(this)} 
        >
      </TimezoneCalendarFilter> */}
        {/* <CREWHolidayCalendarFilter
          Persona = {this.props.role} 
          internalUser = {this.props.internalUser}
          selectedCalendar={this.state.selectedRadioButton}
          selectedRegionData={this.onSelectRegionData.bind(this)}
          newevents={this.updateEvents.bind(this)} 
          >
        </CREWHolidayCalendarFilter>
        </div>
        :null
        }
        { (this.state.selectedRadioButton!=="CREW Holiday Calendar") ?
          <div class="row">
          <TimezoneCalendarFilter
          Persona = {this.props.role} 
          internalUser = {this.props.internalUser}
          selectedCalendar={this.state.selectedRadioButton}
          selectedTimezoneData={this.onSelectTimezoneData.bind(this)}
          newevents={this.updateEvents.bind(this)} 
          >
        </TimezoneCalendarFilter> 

   

  </div>

        : null} */}

{/* <div class="dg-container"> */}
{/* <div class="dg-container" style={{ width: '60%' }}> */}
<div class="dg-container-test" style={{ width: '100%' }}>

<EditModalPopUp
           show= {this.state.showModal}
           event = {this.state.event}
           closeModalPopUpFunc = {this.closeModalPopUp} 
           userEmail = {this.props.userEmail}
           role = {this.props.role}
           removeEvent = {this.handleRemoveEvent}  
           updateEvent = {this.handleUpdateEvent}  
                 
         />
         <AddEventModalPopUp 
           show={this.state.showAddEventModal}  
           event = {this.state.event}
           submitModalPopUpFunc = {this.handleAddEventModalPopUp}
           userEmail = {this.props.userEmail}
           role = {this.props.role}
           addEvent = {this.handleAddEvent} 
         /> 
           <AddFactoryProductionEvent
       show={this.state.showAddEventModal}
       submitModalPopUpFunc = {this.handleAddEventModalPopUp} 
       userEmail = {this.props.userEmail}
       role = {this.props.role}  
       addEvent = {this.handleAddEvent}        
       />
         <EditFactoryProductionEvent
          show= {this.state.showModal}
          event = {this.state.event}
          closeModalPopUpFunc = {this.closeModalPopUp} 
          userEmail = {this.props.userEmail}
          role = {this.props.role}
          removeEvent = {this.handleRemoveEvent}  
          updateEvent = {this.handleUpdateEvent}        
        />
  <div class="title">    
 <PageTitle>
      SAMP Calendar
      <span slot="active-title">Events | {testparvalue}  </span>
      
    </PageTitle>
    
    <div class="toolbar" style={{  }}>
      <NewToolbar handleAddEventModal={this.props.handleAddEventModalPopUpFunc} currrole={this.props.role} />
      </div>
   
      </div> 
    <div class="calendar-container"> 
    <div class="event-table">
    <table class="he-table he-table--fixed-heading" >
  
      <tr>
        <th>ID</th>
        <th >Title</th>
        {/* <th >Description</th> */}
        <th>Start Date</th>
        <th>End Date</th>
        <th>Timezone</th>
        <th>Type</th>
        {/* <th>Category</th> */}
        <th>Legend</th>
        {/* <th>Status</th> */}
      </tr>
      {/* {this.state.events.map((event) =>  (  */}
      {this.state.events.map((event) =>  ( 
        
  <tr>
   
   {/* <td><Button appearance="link" onClick={this.props.closeModalPopUpFunc}>{event.outageId}</Button></td>  */}
   {/* <td><Button appearance="link" onClick= {(event)=>{this.props.closeModalPopUpFunc(true,event)}}>{event.outageId}</Button></td> */}
    {/* <td><Button appearance="link" onClick= {(event)=> {this.UpdateEvent(event)}}>{event.outageId}</Button></td> */}
    {/* <td><Button appearance="link" onClick={() => this.UpdateEvent(event)} >{event.outageId}</Button></td> */}
    {/* <td><Button appearance="link"  onClick={(event) => {this.props.handleModalPopUpFunc(true, event); console.log('this is onclick')}}  >{event.outageId}</Button></td> */}
    {/* <td><Button appearance="link"  onClick={(event)=>{ this.setCurrentEvent(event); this.props.handleModalPopUpFunc(true,event); }}  >{event.outageId}</Button></td> */}
    
    

    <td>{event.outageId}</td>
    {/* <td>{event.outageTitle}+{event.title}</td> */}
    <td>{event.title}</td>
    
    {/* <td>{event.outageDescription}</td> */}
    {/* <td>{event.start.toString()}</td> */}
    <td>{this.formatShortDate(this.formatDate(event).startdate.toString())}</td>
    <td>{this.formatShortDate(this.formatDate(event).enddate.toString())}</td>
    {/* <td>{this.formatDate(event.start)}</td>
    <td>{this.formatDate(event.end)}</td> */}
    <td>{event.Timezone}</td>
    <td>{event.outageType}</td>
    {/* <td>{event.category}</td> */}
    <td>
   
    {/* <Badge class="black-badge">Black</Badge> */}
              <div style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                backgroundColor: this.eventPropGetter(event).style.backgroundColor // Use the background color returned by the eventPropGetter function
              }}></div>
            </td>
    {/* <td>hardcoded value</td> */}
  </tr>
))}
      </table> 
      <div>
      <div>
         
         </div>
       {/* <AddFactoryProductionEvent
       show={this.state.showAddEventModal}
       submitModalPopUpFunc = {this.handleAddEventModalPopUp} 
       userEmail = {this.props.userEmail}
       role = {this.props.role}  
       addEvent = {this.handleAddEvent}        
       />
         <EditFactoryProductionEvent
          show= {this.state.showModal}
          event = {this.state.event}
          closeModalPopUpFunc = {this.closeModalPopUp} 
          userEmail = {this.props.userEmail}
          role = {this.props.role}
          removeEvent = {this.handleRemoveEvent}  
          updateEvent = {this.handleUpdateEvent}        
        /> */}
       </div>
      </div> 
      </div>
    {/* </div> */}
    {/* PLACEHOLDER DATA GRID FOR DESIGN UI PURPOSES */}
{/* <DataGrid rows={rows} columns={columns} label="HTML Content Example">
      {rows.map(row => (
        <span slot={`field1-${row.id}`} key={`field1-${row.id}`}>
          <Button appearance="link">Link Button {row.id}</Button>
          <br />
          <Text appearance="small">Secondary string</Text>
        </span>
      ))} 
    </DataGrid> */}
     

        {/* THIS IS MAIN LARGE CALENDAR ON CALENDAR SCREEN */}
        {/* COMMENTING THIS OUT REMOVES REACT CALENDAR ON SCREEN WITH TOOLBAR */}
        {/* <OutageCalendar
        selectable
        //step={60}        // duration of the slot       
        //timeslots={24}    // number of slots within an hour
        defaultView="month"
        startAccessor="start"
        endAccessor="end"
        localizer={localizer}
        defaultDate = {new Date()}
        date = {this.state.myDate}
        onNavigate={date => {
          // but here I still get the current date
          if(date)
          {
            this.setState({ myDate: new Date(date)});
          }
          else
          {
            this.setState({ myDate: new Date(this.state.myDate)});
          }          
      }}
        onView={this.onView}
        events={this.state.events}
        onEventDrop={this.onEventDrop}
        onEventResize={this.onEventResize}
        onSelectEvent ={(event)=>{this.props.handleModalPopUpFunc(true,event)}}
        resizable
        eventStyleGetter={this.eventStyleGetter}
        eventPropGetter={(this.eventPropGetter)}
        
        components={{
          toolbar: CustomToolbar(this.props.handleAddEventModalPopUpFunc,this.props.role),
          dateCellWrapper: this.ColoredDateCellWrapper,          
        }}       
        onKeyPressEvent={(event)=>{this.props.handleModalPopUpFunc(true,event)}}
      /> */}
    
      {/* <table>
  <thead>
    <tr>
      <th>Title</th>
      <th>Description</th>
      <th>Start Date</th>
      <th>View Event</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Event 1</td>
      <td>Description of Event 1</td>
      <td>2022-01-01</td>
      <td><button>View</button></td>
    </tr>
    <tr>
      <td>Event 2</td>
      <td>Description of Event 2</td>
      <td>2022-02-01</td>
      <td><button>View</button></td>
    </tr>
    <tr>
      <td>Event 3</td>
      <td>Description of Event 3</td>
      <td>2022-03-01</td>
      <td><button>View</button></td>
    </tr>
  </tbody>
</table> */}

      </div>
      </div>
    );
  }  
}


//export let val = testparvalue;
function getTestparvalue() {
  return testparvalue
}

export { getTestparvalue}

export default class BigCalendar extends React.Component{
  constructor(props){
    super(props); 
      
  }    
  render(){
  return (
    <div className="rbc-calendar">
    <CalendarApp
          show={false}
          role = {this.props.role}
          userEmail = {this.props.userEmail}
          filters = {this.props.filters}
          internalUser = {this.props.internalUser}  
          userOrgName={this.props.userOrgName}          
          {...this.props}          
    />
  </div> 
  )
}
}