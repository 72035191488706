import React,{ Component,useState} from 'react';
import '../../App.css'
import { types } from 'util';

export function GetPersonaLegend(props)
  {
    const Persona = props.Persona;
    const selectedCalendar=props.selectedCalendar;
    const calendartypes = props.calendartypes
    
    // calendartypes.map((caltype) => {
    //   console.log('this is the selected calendar var'+selectedCalendar)
    //   console.log(caltype.calendarTypeName)
    //   if(caltype.calendarTypeName === "Factory Production Calendars"){
    //     console.log("true factory proudction calendar")
    //   }
    //   else{
    //     console.log("false factory proudction calendar")
    //   }
    //   //console.log('these are cals: '+caltype.calendarTypeName)
    // }  )


    // this.calendartypes.map((caltype) => {
    //   if (caltype === 'Factory Production Calendars') {
    //     return (
    //       <div class="row">
    //         <div class="col-12 col-md-10">
    //           <ul class="legend">
    //             <li><span class="production"></span> Production</li>
    //             <li><span class="partialproduction"></span> Partial Production</li>   
    //             <li><span class="ot"></span> OT</li> 
    //             <li><span class="physicalinventorycount"></span> Physical Inventory Count</li> 
    //             <li><span class="holiday"></span> Holiday</li>        
    //           </ul>
    //         </div>
    //         <div class="col-12 col-md-2"></div>         
    //       </div>
    //     );
    //   }
    // })

    if(selectedCalendar!=='' && selectedCalendar.toLowerCase() === "sce calendars")
    {   
      // this conditional displays the SCE calendar legend   
      // return(<p>this is sce calendar div</p>)

        return (<div class="row">
            
        <div class="col-12 col-md-8">        
        <ul class="legend">
          <li><span class="review"></span> Pending Review</li>
          <li><span class="approved"></span> Approved</li>
          <li><span class="adhoc"></span> Adhoc</li>
        </ul>
        </div>
        <div class="col-12 col-md-4"/>        
        </div>);
        
    }
    else if(selectedCalendar!=='' && selectedCalendar.trim().toLowerCase() === "sap release calendar")
    {
     return (<div class="row">
       
        <div class="col-12 col-md-10">
        <ul class="legend">
          <li><span class="financefreeze"></span> Finance Freeze</li>
          <li><span class="publicholiday"></span> Public Holiday</li>
          <li><span class="monthquarterendrestriction"></span> Month/Quarter End Restrictions</li>   
        </ul>
        </div> 
        <div class="col-12 col-md-2"></div>       
        </div>);  
    }
    else if(selectedCalendar!=='' && selectedCalendar.trim().toLowerCase()==='crew holiday calendar')
    {
      return (<div class="row">
      <div class="col-12 col-md-8">
      <ul class="legend">
        <li><span class="plantholiday"></span> Plant Holiday</li>
        <li><span class="publicholiday"></span> Public Holiday</li>          
      </ul>
      </div>
      <div class="col-12 col-md-4"></div>        
      </div>);
    }
    else if(selectedCalendar!=='' && selectedCalendar.trim().toLowerCase()==='jdm1 production calendar')
    {
      return (<div class="row">
      <div class="col-12 col-md-10">
      <ul class="legend">
        <li><span class="production"></span> Production</li>
        <li><span class="partialproduction"></span> Partial Production</li>   
        <li><span class="ot"></span> OT</li> 
        <li><span class="physicalinventorycount"></span> Physical Inventory Count</li> 
        <li><span class="holiday"></span> Holiday</li>        
      </ul>
      </div>
      <div class="col-12 col-md-2"></div>         
      </div>);
    }

    else 
    {
      // onboarded calendars will have the below legend
      return (
      <div class="row">
      <div class="col-12 col-md-10">
      <ul class="legend">
        <li><span class="production"></span> Production</li>
        <li><span class="partialproduction"></span> Partial Production</li>   
        <li><span class="ot"></span> OT</li> 
        <li><span class="physicalinventorycount"></span> Physical Inventory Count</li> 
        <li><span class="holiday"></span> Holiday</li>        
      </ul>
      </div>
      <div class="col-12 col-md-2"></div>         
      </div>
      );
      }
  }