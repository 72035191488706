import React, { Component } from "react";
import {Form }  from 'react-bootstrap'
import {fetchToken} from '../../Util.js';
import { reactAI } from "react-appinsights";
import uuid from 'react-native-uuid';
import {appInsights} from '../../common/AppInsights/AppInsights';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";

export class ReactCategoryDropDown extends Component{

    constructor(){
        super()        
        this.state={category:[],selectedCategory:null}
    }

    async componentDidMount() {
      
        appInsights.trackTrace({ message: 'Category Component Loaded.' },
                     {
                         userName: this.props.email,
                         "ComponentId" : COMPONENT_ID,
                         "ComponentName" : COMPONENT_NAME,
                         "EnvironmentName" : window.env.ENVIRONMENT,
                         "ServiceLineName" : SERVICE_LINE,
                         "ServiceName" : SERVICE_NAME,
                         "ServiceOfferingName" : SERVICE_OFFERING,
                         "Correlation Id": uuid.v1(),
                         "Component": 'Category Page',
                     });
        var token = await fetchToken();
        var correlationId = uuid.v1();
        var bearer = 'Bearer ' + token;
        var apimUrl = window.env.APIMURL + "/api/outage/getcategorymappings";
        fetch(apimUrl,{
            method: 'GET',
            headers: {
                'authorization': bearer,
                'Accept' : 'application/json',
                'CorrelationId': correlationId,
                'Content-Type': 'application/json'
            }})
          .then(response => {
            return response.json();
          })        
          .then(data => {   
                     
              this.setState({ category:data
              });
            })
            .catch(error => {
                var err = 
                {
                 "message": "Exception occured in Category page while getting the Category Mappings from the DB",
                 "ComponentId" : COMPONENT_ID,
                 "ComponentName" : COMPONENT_NAME,
                 "EnvironmentName" : window.env.ENVIRONMENT,
                 "ServiceLineName" : SERVICE_LINE,
                 "ServiceName" : SERVICE_NAME,
                 "ServiceOfferingName" : SERVICE_OFFERING,
                 "Component": 'Category Page',
                 "userName": this.props.email,                   
                 "exception": error.message,
                 "Correlation Id": correlationId,
                }
                appInsights.trackException({exception: error,properties : err});
            })
        
    }

        render(){       
        return(          
            <div>
            <label htmlFor="outageTitle">Category</label>
            <Form.Control id="category" title="Select Group" as="select" value = {this.state.selectedCategory == null || "" ? this.props.category :this.state.selectedCategory} onChange={(event)=>{ this.setState({ selectedCategory:event.target.value})}}>                   
                <option value={null}>--Select Category--</option>
                {                    
                    [...new Set(this.state.category.map((x)=>x.category))].map((todo, index) => <option value={todo} as="select" >{todo}</option> )
                }
            </Form.Control>
            
        </div>  
        )      
    }
}

export default ReactCategoryDropDown;