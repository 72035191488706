import React from 'react';
import { usePromiseTracker } from "react-promise-tracker";
import Loader from 'react-loader-spinner'

const LoadingIndicator = props => {
      const { promiseInProgress } = usePromiseTracker();
    
       return (
        promiseInProgress && 
     
        <button class="btn btn-primary" type="button" disabled>
  <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>&nbsp;
  Processing...
</button>
      );  
     }
export default LoadingIndicator;