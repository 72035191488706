import React,{ Component,useState} from 'react';
import { Calendar, momentLocalizer} from "react-big-calendar";
import moment from 'moment';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import * as Datetime from 'react-datetime';
import '../../App.css'
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { Button,Modal,Navbar,Form,NavDropdown,Nav,FormControl, Container, Row,Col,ButtonGroup} from 'react-bootstrap';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import format from "date-fns/format";
import RadioButtons from '../RadioButton/RadioButtons';
import { UserAgentApplication } from 'msal';
import config from '../../config';
import { AccessTokenValue } from 'msal/lib-commonjs/cache/AccessTokenValue';
import { th } from 'date-fns/locale';
import Dropdown from '../ServiceDropdown/DropDown';
import ReactDropDown from '../ServiceDropdown/DropDown';
import {fetchToken} from '../../Util.js';
import ReactRegionDropDown from '../RegionDropdown/Region';
import ReactEditDropDown from '../ServiceDropdown/EditDropDown';
import { Radio, RadioGroup} from 'react-radio-group';
import ReactEditRegionDropDown from '../RegionDropdown/EditRegion';
import CustomSpinner from "../../common/Spinner/Spinner";
import AlertDialog from "../../common/AlertDialog/AlertDialog";
import AddEventModalPopUp from "../../components/AddEvent/AddEvent";
import EditModalPopUp from "../../components/EditEvent/EditEvent";
import {ReactCalendar} from "../../components/Calendar/BigCalendar";
import {ChannelCalendar} from "../../components/Calendar/ChannelCalendar";
import ErrorBoundary from "../../common/ErrorBoundary/ErrorBoundary";
import {
  SERVICE_NAME
} from "../../common/Constants/Constants";
import { reactAI } from "react-appinsights";
import uuid from 'react-native-uuid';
import AccessDenied from "../../common/AccessDenied/AccessDenied";
import {appInsights} from '../../common/AppInsights/AppInsights';
import alertDialog from '../../common/AlertDialog/AlertDialog';
import {Fabric} from 'office-ui-fabric-react/lib/Fabric'  
import {ButtonType,DefaultButton} from 'office-ui-fabric-react/lib/Button'  
import {Dialog, DialogType, DialogFooter} from 'office-ui-fabric-react/lib/Dialog' 
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import AddSAPReleaseEventModalPopUp from "../../components/AddEvent/AddSAPReleaseEvent";
import EditSAPReleaseEventModalPopUp from "../../components/EditEvent/EditSAPReleaseEvent";
import AddChannelEventModalPopUp from "../../components/AddEvent/AddChannelManagerEvent";
import EditChannelEventModalPopUp from "../../components/EditEvent/EditChannelManagerEvent";
import AddCREWHolidayEvent from "../../components/AddEvent/AddCREWHolidayEvent";
import EditCREWHolidayEvent from "../../components/EditEvent/EditCREWHolidayEvent";
import AddFactoryProductionEvent from "../../components/AddEvent/AddFactoryProductionEvent";
import EditFactoryProductionEvent from "../../components/EditEvent/EditFactoryProductionEvent";

class CalendarApp extends React.Component{
    constructor(props){ 
      super(props);
      this.state = {
        showModal:props.show,
        filters:props.filters,
        //getSelectedDateFilter: props.getSelectedDateFilter,
        event:{},
        showAddEventModal:false, 
        role:props.role,
        events:{},
        eventAdded:null,
        selectedCalendar:'',
        userOrgName:props.userOrgName,
        childWasClicked:false}; 
      this.onChildClicked = this.onChildClicked.bind(this);   
    }
  
    onChildClicked() {
      this.setState({childWasClicked : true });
    }
  
    closeModalPopUp = (show) => {this.setState({showModal:show}); }
  
    handleModalPopUp = (show,currentevent) => {this.setState({showModal:show,event:currentevent}); }
  
    handleAddEventModalPopUp = (show) => {this.setState({showAddEventModal:show});}

     
    handleRemoveEvent = (outageId) =>{
        
    }
  
    handleAddEvent = (event) =>{
        
    }
  
    handleUpdateEvent = (event) =>{
    }

   async selectCalendar(value)
   {
       this.setState({selectedCalendar:value})

   }

   
  
    // myCallback = (addedevent) => {
    //   debugger;
    //   //  [...we will use the dataFromChild here...]
    //   this.setState({eventAdded:addedevent});
    //  }
  
  
    render(){
      let { role } = this.state;
      let AddEditView;
      if ((role==='BusinessUser SAP')||(role==='BusinessUser SAPReader')) {
      /*  AddEditView = <div>
          <ReactCalendar
              handleModalPopUpFunc ={this.handleModalPopUp}
              handleAddEventModalPopUpFunc = {this.handleAddEventModalPopUp}
              AddsetClick={click => this.handleAddEvent = click}
              UpdatesetClick= {click => this.handleUpdateEvent = click}
              closeModalPopUpFunc = {this.closeModalPopUp}
              setClick={click => this.handleRemoveEvent = click}
              userEmail = {this.props.userEmail}
              date = {this.props.location.state}
              />          
          <AddSAPEventModalPopUp 
        show={this.state.showAddEventModal}  
        event = {this.state.event}
        submitModalPopUpFunc = {this.handleAddEventModalPopUp}
        userEmail = {this.props.userEmail}
        role = {this.props.role}
        addEvent = {this.handleAddEvent} 
      />
      <EditSAPEventModalPopUp
        show= {this.state.showModal}
        event = {this.state.event}
        closeModalPopUpFunc = {this.closeModalPopUp} 
        userEmail = {this.props.userEmail}
        role = {this.props.role}
        removeEvent = {this.handleRemoveEvent}  
        updateEvent = {this.handleUpdateEvent}        
      /> 
      </div> */
      AddEditView=<div>
       <ReactCalendar
              handleModalPopUpFunc ={this.handleModalPopUp}
              handleAddEventModalPopUpFunc = {this.handleAddEventModalPopUp}
              AddsetClick={click => this.handleAddEvent = click}
              UpdatesetClick= {click => this.handleUpdateEvent = click}
              closeModalPopUpFunc = {this.closeModalPopUp}
              setClick={click => this.handleRemoveEvent = click}
              userEmail = {this.props.userEmail}
              date = {this.props.location.state}
              role = {this.props.role}
              filters = {this.props.filters}
              internalUser = {this.props.internalUser} 
              userOrgName={this.props.userOrgName}  
              setCalendar={this.selectCalendar.bind(this)}  
                       
              /> 
      <AddSAPReleaseEventModalPopUp 
        show={this.state.showAddEventModal}  
        event = {this.state.event}
        submitModalPopUpFunc = {this.handleAddEventModalPopUp}
        userEmail = {this.props.userEmail}
        role = {this.props.role}
        addEvent = {this.handleAddEvent} 
      />
      <EditSAPReleaseEventModalPopUp
        show= {this.state.showModal}
        event = {this.state.event}
        closeModalPopUpFunc = {this.closeModalPopUp} 
        userEmail = {this.props.userEmail}
        role = {this.props.role}
        removeEvent = {this.handleRemoveEvent}  
        updateEvent = {this.handleUpdateEvent}        
      />  

      </div>
      } 
      else if (role==='BusinessUser ChannelManagers') {
        AddEditView = <div>
          <ChannelCalendar
              handleModalPopUpFunc ={this.handleModalPopUp}
              handleAddEventModalPopUpFunc = {this.handleAddEventModalPopUp}
              AddsetClick={click => this.handleAddEvent = click}
              UpdatesetClick= {click => this.handleUpdateEvent = click}
              closeModalPopUpFunc = {this.closeModalPopUp}
              setClick={click => this.handleRemoveEvent = click}
              userEmail = {this.props.userEmail}
              date = {this.props.location.state}
              />          
          <AddChannelEventModalPopUp 
        show={this.state.showAddEventModal}  
        event = {this.state.event}
        submitModalPopUpFunc = {this.handleAddEventModalPopUp}
        userEmail = {this.props.userEmail}
        role = {this.props.role}
        addEvent = {this.handleAddEvent} 
      />
      <EditChannelEventModalPopUp
        show= {this.state.showModal}
        event = {this.state.event}
        closeModalPopUpFunc = {this.closeModalPopUp} 
        userEmail = {this.props.userEmail}
        role = {this.props.role}
        removeEvent = {this.handleRemoveEvent}  
        updateEvent = {this.handleUpdateEvent}        
      /> 
      </div>
      }      
      else {

        AddEditView = <div>
        <ReactCalendar
             handleModalPopUpFunc ={this.handleModalPopUp}
             handleAddEventModalPopUpFunc = {this.handleAddEventModalPopUp}
             AddsetClick={click => this.handleAddEvent = click}
             UpdatesetClick= {click => this.handleUpdateEvent = click}
             closeModalPopUpFunc = {this.closeModalPopUp}
             setClick={click => this.handleRemoveEvent = click}
             userEmail = {this.props.userEmail}
             role = {this.props.role}
             filters = {this.props.filters}
             internalUser = {this.props.internalUser}
             date = {this.props.location.state} 
             userOrgName={this.props.userOrgName}    
             setCalendar={this.selectCalendar.bind(this)}  
             
             /> 
                   
        {(this.state.selectedCalendar==='' || this.state.selectedCalendar.trim().toLowerCase()==='sce calendars' )  ?  //SCE event UI form .
         <div>
         <EditModalPopUp
           show= {this.state.showModal}
           event = {this.state.event}
           closeModalPopUpFunc = {this.closeModalPopUp} 
           userEmail = {this.props.userEmail}
           role = {this.props.role}
           removeEvent = {this.handleRemoveEvent}  
           updateEvent = {this.handleUpdateEvent}  
                 
         />
         <AddEventModalPopUp 
           show={this.state.showAddEventModal}  
           event = {this.state.event}
           submitModalPopUpFunc = {this.handleAddEventModalPopUp}
           userEmail = {this.props.userEmail}
           role = {this.props.role}
           addEvent = {this.handleAddEvent} 
         /> 
         </div>
        
        :   
        (this.state.selectedCalendar.trim().toLowerCase()==='crew holiday calendar') ?     
         <div>
        <AddCREWHolidayEvent
        show={this.state.showAddEventModal}
        submitModalPopUpFunc = {this.handleAddEventModalPopUp} 
        userEmail = {this.props.userEmail}
        role = {this.props.role}  
        addEvent = {this.handleAddEvent}        
        />
          <EditCREWHolidayEvent
           show= {this.state.showModal}
           event = {this.state.event}
           closeModalPopUpFunc = {this.closeModalPopUp} 
           userEmail = {this.props.userEmail}
           role = {this.props.role}
           removeEvent = {this.handleRemoveEvent}  
           updateEvent = {this.handleUpdateEvent}        
         />
        </div>
        :
        (this.state.selectedCalendar.trim().toLowerCase()==='jdm1 production calendar' || 'lcm1 production calendar' || 'tpk production calendar' || 'gis production calendar') ?     
        <div>
       <AddFactoryProductionEvent
       show={this.state.showAddEventModal}
       submitModalPopUpFunc = {this.handleAddEventModalPopUp} 
       userEmail = {this.props.userEmail}
       role = {this.props.role}  
       addEvent = {this.handleAddEvent}        
       />
         <EditFactoryProductionEvent
          show= {this.state.showModal}
          event = {this.state.event}
          closeModalPopUpFunc = {this.closeModalPopUp} 
          userEmail = {this.props.userEmail}
          role = {this.props.role}
          removeEvent = {this.handleRemoveEvent}  
          updateEvent = {this.handleUpdateEvent}        
        />
       </div>
       :null
        
      }
      </div>
          
      }


      return (                
          <div>          
            {AddEditView}                       
          </div>     
      )
    }
  }

 export default CalendarApp; 