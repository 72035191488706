import React,{Fragment,Component} from 'react';
import { Jumbotron,Button,Table} from 'react-bootstrap';
import $ from 'jquery';
import '../../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css';
import DataTable from 'datatables.net';
import '../../../node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css';
import { Link } from 'react-router-dom';
import {fetchToken,getValidApproverRole} from '../../Util.js';
import AlertDialog from '../../common/AlertDialog/AlertDialog.js';
import {ButtonType} from 'office-ui-fabric-react/lib/Button';
import Yes from '../../common/Images/Yes.png';
import No from '../../common/Images/NO.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../OutageHub.css';
class FCM extends React.Component{
constructor(props)
{
    super(props);
}

shouldComponentUpdate(nextProps, nextState){
     //if (nextProps.events.length > 0) {
           this.reloadTableData(nextProps.events);
           return true;
       // }
       // return false;
        }

componentWillUnmount() {
    $('#fcm').DataTable().destroy(true);
    }
reloadTableData = (data) => {
        const table = $('#fcm').DataTable();
     table.clear();
     table.rows.add(data);
     table.draw();
      }

placeContentCenter(data, type, full, meta)
{
    return '<div class="d-flex justify-content-center">' + data + '</div>';
}
      
 getImg(data, type, full, meta) {
        var acknowledge = full.isAcknowledged;
        if (acknowledge === true) {
            return '<div class="d-flex justify-content-center"><img src="' + Yes + '" /></div>';
        } else {
            return '<div class="d-flex justify-content-center"><img src="' + No + '" /></div>';
        }
    }

 getADO(data, type, full, meta) {
        var icm = full.incidentId;
        return '<div class="d-flex justify-content-center"><a target="_blank" href="https://microsoftit.visualstudio.com/Supply%20Chain%20Staging/_releaseProgress?_a=release-pipeline-progress&releaseId=' + icm + '">'+ icm +'</a></div>';

        // if (acknowledge === true) {
        //     return '<div class="d-flex justify-content-center"><img src="' + Yes + '" /></div>';
        // } else {
        //     return '<div class="d-flex justify-content-center"><img src="' + No + '" /></div>';
        // }
    }

    getBridge(data, type, full, meta) {
        var url = full.bridgeUrls_SR;
        if(url.length>5)//Array.isArray(url) && 
        {
            return '<a target="_blank" href="' + url.slice(2,-2) + '">Join Bridge</a>'; //url[0]
        }
        else
        return null;
    }

    

componentDidMount() {
    this.$el = $('#fcm');
    this.$el.DataTable({
           columns : [
            { title: "Title", data:'title_LVR',width:'30%'},
            { title: "Service Name", data:'serviceName'},
            { title: "Component Name", data:'owningTeamName'},
            { title: "Release ID", data:'incidentId',render: this.getADO,width:'12%'},
            { title: "Deployment Time", data: 'lastUpdateTime'}        
            ],
           data: this.props.events,
           ordering: true,
           pageLength : 6,
           lengthMenu: [[5, 10, 20, -1], [5, 10, 20, 'Todos']],
           //bPaginate : true,
                            //"scrollY":        200,
                            //"scrollCollapse": true,
            bLengthChange: false,
            bFilter: true,
            bAutoWidth: false,
            autoWidth: false,
            columnDefs : [
                {   
                   targets : [0,1,2],
                   "createdCell": function (td, cellData, rowData, row, col) {
                    if (cellData) {
                      $(td).attr('title', cellData);
                    }
                  }
                }
             ]
            
            
        });                       
    }
    

render(){

    return (
    <div className="row" style={{ paddingTop: "30px" }}>
    <div className ="col-md-12"> 
    <div className="inline-headers">
            <h2 className = "portal-name" style={{ fontSize: "1.1rem" }}>
              ADO Releases
            </h2>
          </div>
    <table class="table table-hover fcm" width= "100%"
                 id = "fcm" ref={(el) => (this.el = el)}>
                     <caption>ADO Releases</caption>
    </table>   
    <span className ="note"><i>(ADO Releases displayed only for last 7 days)</i></span>    
    </div>                
  </div>
) 
}
}

export default FCM;