import React, { Component } from "react";
import {fetchToken,fetchRoles} from '../../Util.js';
import uuid from 'react-native-uuid';
import {appInsights} from '../../common/AppInsights/AppInsights';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";
import Select from 'react-select';

export class ReactDropDown extends Component{

    constructor(props){
        super(props)        
        this.state={teams:[],selectedGroup:[],selectedTemp:[],selectedService:[],teamgroupError:'',
        teamcomponentError:'',teamserviceError:'',selectedServiceGroup:[],servicegroupError:'',servicegroup:[],
        division:[],divisionError:'',organizationName:[],orgNameError:'',selectedDivision:[],selectedOrgName:[]
      
      }
    }

    handleDivisionChange = async(selectedDivision) => {
      await this.setState(
        { selectedDivision,selectedOrganization:null,divisionError:''},
        () => console.log(`Option selected:`, this.state.selectedDivision)
      );
      await this.props.divisionData(selectedDivision);
      await this.props.setDivisionErrorValue(this.state.divisionError);
      
    };

    handleOrgNameChange = async(selectedOrgName) => {
      await this.setState(
        { selectedOrgName,selectedServiceGroup:null,orgNameError:''},
        () => console.log(`Option selected:`, this.state.selectedOrgName)
      );
      await this.props.orgData(selectedOrgName);
      await this.props.setOrgNameErrorValue(this.state.orgNameError);
      
    };
   
    handleServiceGroupChange = async(selectedServiceGroup) => {
      await this.setState(
        { selectedServiceGroup,selectedGroup:null,serviceGroupError:''},
        () => console.log(`Option selected:`, this.state.selectedServiceGroup)
      );
      await this.props.servicegroupData(selectedServiceGroup);
      await this.props.setServiceGroupErrorValue(this.state.serviceGroupError);
      
    };
    
    handleTeamChange = async(selectedGroup) => {
        await this.setState(
          { selectedGroup,selectedService:null,teamGroupError:''},
          () => console.log(`Option selected:`, this.state.selectedGroup)
        );
        await this.props.teamData(selectedGroup);
        await this.props.setTeamErrorValue(this.state.teamGroupError);
      };
    
      handleServiceChange = async(selectedService) => {
       await this.setState(
          { selectedService,selectedComponent:null},
          () => console.log(`Option selected:`, this.state.selectedService)
        );
        await this.props.teamService(selectedService);
        await this.props.setServiceErrorValue(this.state.teamServiceError);
      };
    
      handleComponentChange = async(selectedComponent) => {
        await this.setState(
          { selectedComponent,teamcomponentError:''},
          () => console.log(`Option selected:`, this.state.selectedComponent)
        );
        await this.props.teamComponent(selectedComponent);
        await this.props.setComponentErrorValue(this.state.teamComponentError);
      };


   componentDidMount()
   {       
       this.GetData()
   }

    async GetData() {
       
        appInsights.trackTrace({ message: 'DropDown Component Loaded.' },
                     {
                         userName: this.props.email,
                         "ComponentId" : COMPONENT_ID,
                         "ComponentName" : COMPONENT_NAME,
                         "EnvironmentName" : window.env.ENVIRONMENT,
                         "ServiceLineName" : SERVICE_LINE,
                         "ServiceName" : SERVICE_NAME,
                         "ServiceOfferingName" : SERVICE_OFFERING,
                         "Correlation Id": uuid.v1(),
                         "Component": 'Dropdown Page',
                     });
        var token = await fetchToken();
        var correlationId = uuid.v1();
        var bearer = 'Bearer ' + token;
        var apimUrl = window.env.APIMURL + "/api/outage/getservicemappings"; 
        
        fetch(apimUrl,{
            method: 'GET',
            headers: {
                'authorization': bearer,
                'Accept' : 'application/json',
                'CorrelationId': correlationId,
                'Content-Type': 'application/json'
            }})
          .then(response => {
            return response.json();
          })        
          .then(data => {   
                      
             /* this.setState({ division:data.filter(x=>{if((x.serviceGroupName=="SCE Factory Systems") ||(x.serviceGroupName=="Supply Chain") ||(x.serviceGroupName=="COSINE Devices and Gaming Security")){ return x}})
              }); */
              this.setState({division:data});
            })
            .catch(error => {
              var err =
              {
                "message": "Exception occured in Dropdown page while getting the Division , Organization,Service Group,Team Group, Service and Component Mappings from the DB",
                "ComponentId" : COMPONENT_ID,
                "ComponentName" : COMPONENT_NAME,
                "EnvironmentName" : window.env.ENVIRONMENT,
                "ServiceLineName" : SERVICE_LINE,
                "ServiceName" : SERVICE_NAME,
                "ServiceOfferingName" : SERVICE_OFFERING,
                "Component": 'Dropdown Page',
                 "userName": this.props.email,
                 //roles: this.props.role != null || '' ? this.props.role : "",
                 "exception": error.message,
                 "Correlation Id": correlationId
              }
             appInsights.trackException({exception: error,properties : err});
            })
        
    }

    returnValues(array)
{
  const options = array.map(v => ({
    label: v,
    value: v
  }));
  return options;
}

render(){   
    const { selectedDivision,selectedOrgName,selectedServiceGroup,selectedGroup,selectedService,selectedComponent} = this.state;
    const division = [...new Set(this.state.division.map((d)=>d.divisionName))];
    const org = [...new Set(this.state.division.filter((div)=>{ return (( this.state.selectedDivision.value!='' && this.state.selectedDivision.value==div.divisionName) ? this.state.selectedDivision.value : "")}).map(x=>x.organizationName))];
    const servicegrp = [...new Set(this.state.division.filter((o)=>{ return (( this.state.selectedOrgName.value!='' && this.state.selectedOrgName.value==o.organizationName) ? this.state.selectedOrgName.value : "")}).map(x=>x.serviceGroupName))];
    const team = [...new Set(this.state.division.filter((servicegroup)=>{ return ((this.state.selectedServiceGroup) != null ? (this.state.selectedServiceGroup.map(x=> x.label).includes(servicegroup.serviceGroupName)) : "")}).map(x=>x.teamGroupName))];
    const service = [...new Set(this.state.division.filter((team)=>{ return ((this.state.selectedGroup) != null ? (this.state.selectedGroup.map(x=> x.label).includes(team.teamGroupName)) : "")}).map(x=>x.serviceName))];
    const component = [...new 
      Set(
      this.state.division.filter(                        
          (team)=>{ 
              return ((this.state.selectedGroup != null ? (this.state.selectedGroup.map(x=> x.label).includes(team.teamGroupName)) : null) && (this.state.selectedService != null ? (this.state.selectedService.map(x=> x.label).includes(team.serviceName)) : ""))
          })
          .map(y=>y.componentName)
          )]
          
    const divisionArray = this.returnValues(division);
    const orgNameArray = this.returnValues(org);      
    const serviceGroupArray = this.returnValues(servicegrp);
    const teamArray=this.returnValues(team);
    const serviceArray = this.returnValues(service);
    const componentArray = this.returnValues(component); 
   
    
return(    
  
            <div>
            <div className="form-group required">    
            <label htmlFor="division" class="control-label">Division</label>
            <div>
            <Select required aria-label="Division" name="division" title="Select Division" 
              isMulti = {false}
              value={selectedDivision}
              onChange={this.handleDivisionChange}  
              options={divisionArray}        
            >
            </Select>
            <span style={{color: '#E22335'}}>{this.props.divisionError}</span>
            </div>
            </div>

            <div className="form-group required">    
            <label htmlFor="organizationname" class="control-label">OrganizationName</label>
            <div>
            <Select required aria-label="OrganizationName" name="organizationname" title="Select OrganizationName" 
              isMulti = {false}
              value={(selectedDivision && selectedDivision.value!='')  ? selectedOrgName : null}
              onChange={this.handleOrgNameChange}  
              options={this.state.selectedDivision!= null ? orgNameArray : null}        
            >
            </Select>
            <span style={{color: '#E22335'}}>{this.props.orgNameError}</span>
            </div>
            </div>
            
            <div className="form-group required">    
            <label htmlFor="servicegroup" class="control-label">ServiceGroup</label>
            <div>
            <Select required aria-label="ServiceGroup" name="servicegroup" title="Select ServiceGroup" 
              isMulti = {true}
              value={(selectedOrgName && selectedOrgName.value!='')  ? selectedServiceGroup : null}
              onChange={this.handleServiceGroupChange}  
              options={this.state.selectedOrgName!= null ? serviceGroupArray : null}        
            >
            </Select>       
            <span style={{color: '#E22335'}}>{this.props.serviceGroupError}</span>
            </div>
            </div>

            <div className="form-group required">    
            <label htmlFor="teamgroup" class="control-label">TeamGroup</label>
            <div>
            <Select required aria-label="TeamGroup" name="teamgroup" title="Select TeamGroup"
              isMulti = {true}
              value = { (selectedServiceGroup && selectedServiceGroup.length > 0) ? selectedGroup : null}
              onChange={this.handleTeamChange}
              options={this.state.selectedServiceGroup!= null ? teamArray : null}
            >
            </Select>
            <span style={{color: '#E22335'}}>{this.props.teamGroupError}</span>
            </div>
            </div>
           
            <div className="form-group required">   
            <label htmlFor="teamservice" class="control-label">Service</label>
            <Select required aria-label="Service" name="teamservice" title="Select Service"
              isMulti = {true}
              value = { (selectedGroup && selectedGroup.length > 0) ? selectedService : null}
              onChange={this.handleServiceChange}
              options={this.state.selectedGroup!= null ? serviceArray : null}
            >
            </Select>
            <span style={{color: '#E22335'}}>{this.props.serviceError}</span>
            </div>

            <div className="form-group required">   
            <label htmlFor="teamcomponent" class="control-label">Component</label>
            <Select required aria-label="Component" name="teamcomponent" title="Select Component"
              isMulti = {true}
              value = { ((selectedGroup && selectedGroup.length > 0) && ( selectedService && selectedService.length > 0)) ? selectedComponent : null}
              onChange={this.handleComponentChange}
              options={componentArray}
            >
            </Select>
            { <span style={{color: '#E22335'}}>{this.props.componentError}</span> }
            </div>
        </div>  
        )      
    }
}
export default ReactDropDown;