import React, { Component, Fragment } from "react";
import '../../App.css'

function LoadLegend(props) { 
    if(props.calendarType === 'Factory')  
    {   
      return (
        <div className="container">
        <div class="row">
        <div class="col-12 col-md-8"></div>
        <div >
        <ul class="legend">
        <li><span class="review"></span>Production</li>
      <li><span class="approved"></span>Partial Production</li>
      <li><span class="shutdown"></span>Shut Down</li>
      <li><span class="inventory"></span>Physical Inventory</li>
      </ul>
      </div>       
      </div>
      </div>
      );  
    }
    else
    return null;   
}

export default LoadLegend;