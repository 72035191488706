import React, { Component } from "react";


export class LogOut extends Component{

    constructor(){
        super()        
        //this.state={regions:[],selectedRegion:null,selectedDatacenter:null,selectedCountry:null}
    }

    
        render(){       
        return(          
           <div>
             Logout 
        </div>  
        )      
    }
}

export default LogOut;