import React,{Component} from 'react';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../OutageHub.css';
import {Button,Modal} from 'react-bootstrap';
import uuid from 'react-native-uuid';
import {appInsights} from '../../common/AppInsights/AppInsights';
import $ from 'jquery';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";
import AddEventModalPopUp from "../../components/AddEvent/AddEvent";
import { Link } from 'react-router-dom';
import {fetchToken} from '../../Util.js';
import '../../common/AlertDialog/AlertDialog.scss'
import AlertDialog from '../../common/AlertDialog/AlertDialog.js';
import {ButtonType} from 'office-ui-fabric-react/lib/Button';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactFlow from 'react-flow-renderer';
import './ApprovalFlowStyle.css';


const flowStyles = { height: '200px',width:'1000px' };


export default class JDM1FactoryFlow extends Component
{
    constructor(props){
        super(props)        
        this.state={steps: [
          {
              id: 'horizontal-1',
              sourcePosition: 'right',
              data: { label: 'Create' },
              position: { x: 0, y: 60 },
              style:{'background-color':this.setNodeColor('Create')},
              type: 'selectorNode'
            },
            {
              id: 'horizontal-2',
              sourcePosition: 'right',
              targetPosition: 'left',
              data: { label: 'L1-A Approve' },
              position: { x: 200, y: 60 },
              style:{'background-color':this.setNodeColor('L1-A Approve')},
              type: 'selectorNode'
            },
            {
              id: 'horizontal-3',
              sourcePosition: 'right',
              targetPosition: 'left',
              data: { label: 'L1-B Approve' },
              position: { x: 400, y: 60 },
              style:{'background-color':this.setNodeColor('L1-B Approve')},
              type: 'selectorNode'
            },
            {
              id: 'horizontal-4',
              sourcePosition: 'right',
              targetPosition: 'left',
              data: { label: 'L2 Approve' },
              position: { x: 600, y: 60 },
              style:{'background-color':this.setNodeColor('L2 Approve')},
              type:'output'
            },
            {
              id: 'horizontal-5',
              sourcePosition: 'right',
              targetPosition: 'left',
              data: { label: 'Reject' },
              position: { x: 600, y: 150 },
              style:{'background-color':this.setNodeColor('L2 Reject')},
              type:'output'
            },
            {
              id: 'horizontal-6',
              sourcePosition: 'right',
              targetPosition: 'left',
              data: { label: 'Reject' },
              position: { x: 400, y: 150 },
              style:{'background-color':this.setNodeColor('L1-B Reject')},
              type:'output'
            },
            {
              id: 'horizontal-7',
              sourcePosition: 'right',
              targetPosition: 'left',
              data: { label: 'Reject' },
              position: { x: 200, y: 150 },
              style:{'background-color':this.setNodeColor('L1-A Reject')},
              type:'output'
            },
            {
              id: 'horizontal-e1-2',
              source: 'horizontal-1',
              type: 'step',
              target: 'horizontal-2', 
              animated:'true'           
            },
            {
              id: 'horizontal-e2-3',
              source: 'horizontal-2',
              type: 'step',
              target: 'horizontal-3',  
              animated:'true'            
            },
            {
              id: 'horizontal-e1-7',
              source: 'horizontal-1',
              type: 'step',
              target: 'horizontal-7', 
              animated:'true'              
            },
            {
              id: 'horizontal-e3-4',
              source: 'horizontal-3',
              type: 'step',
              target: 'horizontal-4',  
              animated:'true'            
            },
            {
              id: 'horizontal-e2-6',
              source: 'horizontal-2',
              type: 'step',
              target: 'horizontal-6', 
              animated:'true'             
            },
            {
              id: 'horizontal-e3-5',
              source: 'horizontal-3',
              type: 'step',
              target: 'horizontal-5', 
              animated:'true'  
                          
            },
            
      ],
          
          showApprovalModalWindow:'',
          eventcreate:props.eventcreatedata,
          eventl1aapprove:props.eventl1aapprovedata,
          eventl1bapprove:props.eventl1bapprovedata,
          eventl2approve:props.eventl2approvedata,
          eventreject:props.eventrejectdata,
          newsteps:props.updatedSteps,
          eventl1assignedapprover:props.eventl1approverdata,
          eventl2assignedapprover:props.eventl2approverdata          
        }
        
        this.close = this.close.bind(this);
    }

    close = () => this.setState({isOpen: false})    
      
    close(){
      this.setState({ showApprovalModalWindow: false });
    }

    setNodeColor(nodename)
    {
      if(nodename==="Create")
      {
          if(this.props.eventcreatedata.length>0 && this.props.eventcreatedata[0].username!==null)
          {
              return '#00a100'
          }
          else
          {
              return '#999999'
          }
      }
      else if(nodename==='L1-A Approve')
      {

          if(this.props.eventl1aapprovedata.length>0 && this.props.eventl1aapprovedata[0].username!==null)
          {
             return '#00a100'
          }
          else
          {
             return '#999999'
          }
      }
      else if(nodename==='L1-B Approve')
      {

          if(this.props.eventl1bapprovedata.length>0 && this.props.eventl1bapprovedata[0].username!==null)
          {
             return '#00a100'
          }
          else
          {
             return '#999999'
          }
      }
      else if(nodename==='L2 Approve')
      {

          if(this.props.eventl2approvedata.length>0 && this.props.eventl2approvedata[0].username!==null)
          {
              return '#00a100'
          }
          else
          {
              return '#999999'
          }
      }
      else if(nodename==='L1-A Reject')
      {

          if(this.props.eventrejectdata.length>0 && this.props.eventl1aapprovedata.length===0)
          {
              return '#00a100'
          }
          else
          {
              return '#999999'
          }
      }
      else if(nodename==='L1-B Reject')
      {

          if(this.props.eventrejectdata.length>0 && this.props.eventl1bapprovedata.length===0)
          {
              return '#00a100'
          }
          else
          {
              return '#999999'
          }
      }
      else if(nodename==='L2 Reject')
      {

          if(this.props.eventrejectdata.length>0 && this.props.eventl2approvedata.length===0 && this.props.eventl1approvedata.length>0)
          {
              return '#00a100'
          }
          else
          {
              return '#999999'
          }
      }

    }
  

    render()
    {
        const { steps,newsteps,currentStep } = this.state;
               

        return (
          <div>
               <Modal style = {{'overflow': 'visible'}} role="dialog" data-keyboard="false" show={this.props.show} onHide ={()=> {this.props.closeModalPopUpFunc(false)}} size="lg"  backdrop="static" centered autoFocus="true" >
              <Modal.Header closeButton>
                <Modal.Title>Event Approval Workflow</Modal.Title>
              </Modal.Header>
              <form id = "eventapproval">
              <Modal.Body >
                  <div style={flowStyles}>
                 <ReactFlow
                    elements={this.state.steps}                                     
                 >
                 </ReactFlow>
                  </div>
                  <div></div>
            <div style={{'font-size':'10px'}}>
              <ul>
              <li>
            <label htmlFor="legend_completestep" class="control-label" style={{'background-color':'#00a900'}}>Completed Steps</label>
            </li>
            <li>
            <label htmlFor="legend_pendingstep" class="control-label" style={{'background-color':'#999999'}}>UnAttempted Steps</label>
            </li>
            </ul>
            </div>
            
            <div></div>
            {(this.state.eventcreate!==null && this.state.eventcreate!==undefined && this.state.eventcreate.length>0) ?
                 <div style={{'font-size':'14px'}}><label htmlFor="create" class="control-label">Event Created By : {this.state.eventcreate[0].username}</label></div>
            :null   }
             {(this.state.eventl1assignedapprover!==null && this.state.eventl1assignedapprover!==undefined && this.state.eventl1assignedapprover.length>0) ?
                <div style={{'font-size':'14px'}}><label htmlFor="l1aassignedapprover" class="control-label">L1-A/L1-B Assigned Approvers : {this.state.eventl1assignedapprover[0].mailToList}</label></div>
            : null  }
            {(this.state.eventl1aapprove!==null && this.state.eventl1aapprove!==undefined && this.state.eventl1aapprove.length>0) ?
                <div style={{'font-size':'14px'}}><label htmlFor="l1aapprove" class="control-label">L1-A Approved By : {this.state.eventl1aapprove[0].username}</label></div>
            : null  }
             {(this.state.eventl1bapprove!==null && this.state.eventl1bapprove!==undefined && this.state.eventl1bapprove.length>0) ?
                <div style={{'font-size':'14px'}}><label htmlFor="l1bapprove" class="control-label">L1-B Approved By : {this.state.eventl1bapprove[0].username}</label></div>
            : null  }
             {(this.state.eventl2assignedapprover!==null && this.state.eventl2assignedapprover!==undefined && this.state.eventl2assignedapprover.length>0) ?
                <div style={{'font-size':'14px'}}><label htmlFor="l2assignedapprover" class="control-label">L2 Assigned Approver : {this.state.eventl2assignedapprover[0].mailToList}</label></div>
            : null  }
             {(this.state.eventl2approve!==null && this.state.eventl2approve!==undefined && this.state.eventl2approve.length>0) ?
                <div style={{'font-size':'14px'}}><label htmlFor="l2approve" class="control-label">L2 Approved By : {this.state.eventl2approve[0].username}</label></div>
            : null  }
            { (this.state.eventreject!==null && this.state.eventreject!==undefined && this.state.eventreject.length>0) ?
                <div style={{'font-size':'14px'}}><label htmlFor="reject" class="control-label">Event Rejected By : {this.state.eventreject[0].username}</label></div>
            : null       
            }     
            
            
           
            
            </Modal.Body> 
            </form>
            </Modal> 

          </div>
        );
    }
}