import React,{ Component,useState} from 'react';
import { Calendar, momentLocalizer} from "react-big-calendar";
import moment from 'moment';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import * as Datetime from 'react-datetime';
import '../../App.css'
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { Button,Modal,Navbar,Form,NavDropdown,Nav,FormControl, Container, Row,Col,ButtonGroup} from 'react-bootstrap';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import format from "date-fns/format";
import RadioButtons from '../RadioButton/RadioButtons';
import { UserAgentApplication } from 'msal';
import config from '../../config';
import { AccessTokenValue } from 'msal/lib-commonjs/cache/AccessTokenValue';
import { th } from 'date-fns/locale';
import Dropdown from '../ServiceDropdown/DropDown';
import ReactDropDown from '../ServiceDropdown/DropDown';
import {fetchToken} from '../../Util.js';
import ReactRegionDropDown from '../RegionDropdown/Region';
import ReactEditDropDown from '../ServiceDropdown/EditDropDown';
import { Radio, RadioGroup} from 'react-radio-group';
import ReactEditRegionDropDown from '../RegionDropdown/EditRegion';
import CustomSpinner from "../../common/Spinner/Spinner";
import AlertDialog from "../../common/AlertDialog/AlertDialog";
import AddEventModalPopUp from "../../components/AddEvent/AddEvent";
import EditModalPopUp from "../../components/EditEvent/EditEvent";
import CalendarApp from "../../components/Calendar/CalendarApp";
import ErrorBoundary from "../../common/ErrorBoundary/ErrorBoundary";
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";
import { reactAI } from "react-appinsights";
import uuid from 'react-native-uuid';
import AccessDenied from "../../common/AccessDenied/AccessDenied";
import {appInsights} from '../../common/AppInsights/AppInsights';
import alertDialog from '../../common/AlertDialog/AlertDialog';
import {Fabric} from 'office-ui-fabric-react/lib/Fabric'  
import {ButtonType,DefaultButton} from 'office-ui-fabric-react/lib/Button'  
import {Dialog, DialogType, DialogFooter} from 'office-ui-fabric-react/lib/Dialog' 
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import InternalServerError from "../../common/ErrorPages/InternalServerError";
import ReactFactoryDropDown from '../FactoryDropDown/Factory';
import {Children} from 'react';
import AddSAPEventModalPopUp from "../../components/AddEvent/AddSAPEvent";
import EditSAPEventModalPopUp from "../../components/EditEvent/EditSAPEvent";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);
const OutageCalendar = withDragAndDrop(Calendar);

var ReturnStatus = 200;

const CustomToolbar = (handleAddEventModal)=>{  

    return class CustomBigCalendarToolBar extends Toolbar {
    constructor(props){
      super(props)
    }

    render() {
      return (
        
        <div className='rbc-toolbar'>
          
          <span className="rbc-btn-group">
            <Button variant="info" onClick={() => this.navigate('TODAY')} >Today</Button>
            <Button variant="info" onClick={() => this.navigate('PREV')}>Back</Button>
            <Button variant="info"  onClick={() => this.navigate('NEXT')}>Next</Button>
           
            <Button variant="info" onClick={()=>{handleAddEventModal(true)}}><b>Add Event</b></Button>
           
          </span>
          <span className="rbc-toolbar-label"><b>UTC TimeZone -</b> {this.props.label}</span>
          <div className="rbc-btn-group">
            <Button variant="info" onClick={this.view.bind(null, 'month')}>Month</Button>
            <Button variant="info" onClick={this.view.bind(null, 'week')}>Week</Button>
            <Button variant="info" onClick={this.view.bind(null, 'day')}>Day</Button>          
            {/* <Button variant="info" onClick={this.view.bind(null, 'agenda')}>Agenda</Button> */}
          </div>         
        </div>
       
      );
    }

    navigate = action => {
       
      this.props.onNavigate(action)
    }  
  }
} 

export class ChannelCalendar extends React.Component
{
  
  constructor(props){
    super(props);
    this.state = {events:[],displayedDateRage: {},
     emptyArray:true,current_view: 'month',current_date: moment.utc().toDate().toUTCString(),
     myDate : (this.props.date === undefined) ? new Date() : this.props.date.myProp,
     start : (this.props.date === undefined) ? new Date() : this.props.date.start,
     end : (this.props.date === undefined) ? new Date() : this.props.date.end}
    this.bindScopes([
      'onView',
      'onNavigate',
      'updateTimes',
      'timesToEvents'
    ]);

    this.updateTimes();
  }

 

  bindScopes(keys){
    for(let key of keys){
      this[key] = this[key].bind(this);
    }
  }

  timesToEvents(times){
    const events = times.map(time => {
      const start_date = moment(time.Date);
      const end_date = start_date.clone().add(time.Minutes, 'minutes');
      return {
        title: time.Incident.lookupName,
        start: start_date.toDate(),
        end:   end_date.toDate()
      };
    })
    console.log(events);
    this.setState({
      events: events
    })
  }
  
  onView(view){
    this.setState({
      current_view: view
    });

    this.updateTimes(this.state.current_date, view);
  }
  onNavigate(date, view){
    const new_date = moment(date);
    this.setState({
      current_date: new_date
    });

    this.updateTimes(new_date, view);
  }

  updateTimes(date = this.state.current_date, view = this.state.current_view){
    let start, end;
    // if view is day: from moment(date).startOf('day') to moment(date).endOf('day');
    if(view === 'day'){
      start = moment(date).startOf('day');
      end   = moment(date).endOf('day');
    }
    else if(view === 'week'){
      start = moment(date).startOf('week');
      end   = moment(date).endOf('week');
    }
    
    else if(view === 'month'){
      end   = moment(date).endOf('month');
      //.add(7, 'days');
      start = moment(date).startOf('month');
      //.subtract(7, 'days');
      
    }
    // if view is agenda: from moment(date).startOf('day') to moment(date).endOf('day').add(1, 'month');
    else if(view === 'agenda'){
      start = moment(date).startOf('day');
      end   = moment(date).endOf('day').add(1, 'month');
    }
    this.setState({displayedDateRage:{start:start.toString(), end:end.toString()}})
    //services.times.getFrom(start.format(API_GET_DATE_FORMAT), end.format(API_GET_DATE_FORMAT)).then(this.timesToEvents);
  }

  RemoveEvent = (outageId) => {
   
    let prevEvents = this.state.events;
    let currentEvents = prevEvents.filter((event)=>{return event.outageId != outageId}).map((event)=> event)
    this.setState({events:currentEvents});
    this.props.closeModalPopUpFunc(false);
  }

  AddEvent = (event) => {
   
    let prevEvents = this.state.events;
    prevEvents.push(event)
    //let currentEvents = prevEvents.filter((event)=>{return event.outageId != outageId}).map((event)=> event)
    this.setState({events:prevEvents});
    this.props.handleAddEventModalPopUpFunc(false);
    //this.props.closeModalPopUpFunc(false);
  }

  UpdateEvent = (event) => {
   
    let prevEvents = this.state.events;
    // prevEvents.push(event)
    let currentEvents = prevEvents.findIndex((newevent)=>{return newevent.outageId == event.EventId})
    prevEvents[currentEvents] = event;
    //.map((newevent)=> event)
    this.setState({events:prevEvents});
    // this.props.handleAddEventModalPopUpFunc(false)
    this.props.closeModalPopUpFunc(false);
  }

  

  
  childWasClicked = () => 
  {
  }

  eventPropGetter = (event, start, end, isSelected) => 
  {
 
    
    let newStyle = {
      //backgroundColor: "lightgrey",
      //color: 'black',
      borderRadius: "0px",
      border: "none",
      role:"button"
    };

    if (event.status == '1' || event.status == '2'){
      newStyle.backgroundColor = "#49498D"
    }
    else if(event.status == '3'){
      newStyle.backgroundColor = "green"
    }
    // else if(event.status == '4'){
    //   newStyle.backgroundColor = "Red"
    // }

    return {
      className: "test-sarath",
      style: newStyle
    };
}

  eventStyleGetter = (event, start, end, isSelected) => {
    console.log(event);
    var backgroundColor = '#' + event.hexColor;
    var style = {
        backgroundColor: 'Red',
        borderRadius: '0px',
        opacity: 0.8,
        color: 'black',
        border: '0px',
        display: 'block'
    };
    
    return {
        style: style
    };
  }

 
 

  async componentDidMount()
  {
    //this.computeDisplayedDateRange();
    await this.GetData();    
  }
  
  async GetData() {
      
    appInsights.trackTrace({ message: 'Calendar Component Loaded and trying to fetch events.' },
    {
        "userName": this.props.userEmail,
        "ComponentId" : COMPONENT_ID,
        "ComponentName" : COMPONENT_NAME,
        "EnvironmentName" : window.env.ENVIRONMENT,
        "ServiceLineName" : SERVICE_LINE,
        "ServiceName" : SERVICE_NAME,
        "ServiceOfferingName" : SERVICE_OFFERING,
        "Correlation Id": uuid.v1(),
        "Component": 'Calendar Page',
    });
    var token = await fetchToken();
    var correlationId = uuid.v1();
    var bearer = 'Bearer ' + token;
    var apimUrl = window.env.APIMURL + "/api/outage/getoutages";
   fetch(apimUrl,{
    method: 'GET',
    headers: {
        'authorization': bearer,
        'CorrelationId': correlationId,
        'Accept' : 'application/json',
        'Content-Type': 'application/json'
    }})
    .then(response => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
      
    })
    .then((res) => {
      //const { statusCode, data } = res;
      const statusCode = res[0];
      const data = res[1];
      this.setState({ ReturnStatus: statusCode});
      //console.log(data)
      var filteredData = data.filter(x=>x.naCategory==='BusinessUser ChannelManagers');
      let result = filteredData.map((event)=>{
        
        return {
          outageId:event.eventId,
          outageTitle:event.title,
          organization:event.organization,
          outageDescription:event.description,
          outageImpact:event.impact,
          pmContact:event.pmcontact,
          engContact:event.engContact,
          extContact:event.externalContact,
          outageType:event.outageType,
          region:event.region,
          country:event.country,
          teamgroup:event.teamGroup,
          service:event.service,
          component:event.component,
          allDay: event.allDayEvent,
          end: new Date(event.endTime),
          start: new Date(event.startTime),
          title:event.title,
          status:event.approvalStatus,
          servicegroup:event.serviceGroup,
          factory:event.factoryName,
          division:event.division,
          orgName:event.orgName,
          eventstatus:event.eventStatus,
          nextsteps:event.nextSteps,
          nacategory:event.NACategory

          //title:event.title,
      }
      })
      this.setState({ events: result,emptyArray:false})
    })
    .catch( error =>
      {
        var err = 
        {
          "message": "Exception occured while getting the calendar events from DB",
          "ComponentId" : COMPONENT_ID,
          "ComponentName" : COMPONENT_NAME,
          "EnvironmentName" : window.env.ENVIRONMENT,
          "ServiceLineName" : SERVICE_LINE,
          "ServiceName" : SERVICE_NAME,
          "ServiceOfferingName" : SERVICE_OFFERING,
          "Component" : "Calendar Page",
          "userName": this.props.userEmail,
          "roles": this.props.role != null || '' ? this.props.role : "",
          "exception": error.message,
          "Correlation Id": correlationId
        }
        appInsights.trackException({exception: error,properties : err});  
      })
      
    this.props.setClick(this.RemoveEvent);
    this.props.AddsetClick(this.AddEvent);
    this.props.UpdatesetClick(this.UpdateEvent);
    
    
    
  }
  

  onEventResize = (type, { event, start, end, allDay }) => {
    this.setState(state => {
      state.events[0].start = start;
      state.events[0].end = end;
      return { events: state.events };
    });
  };



  onEventDrop = ({ event, start, end, allDay }) => {
    alert(start)
  };

 convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  ColoredDateCellWrapper = ({children, value}) =>
    React.cloneElement(Children.only(children), {
        style: {
            ...children.style,
            //JSON.stringify(value).slice(1,11)
            //toISOString().split('T')[0]
            backgroundColor: this.convert(value) === this.convert(this.state.myDate) ? 'orange' : null,
        },
    });

  render(){ 
    const {currentDate, currentView,displayedDateRage} = this.state;

    if(this.state.ReturnStatus === 500)
    {
      return <InternalServerError/>;
    }

    if(this.state.emptyArray){   
    
    return <CustomSpinner label="Loading" />;
  } 
  
    return (
      
      <div className="container">
        <div class="row">
        {/* <div class="col-12 col-md-6"></div> */}
        <div class = "col-12 col-md-6">
        <ul class="legend">
      <li><span class="review"></span> Pending Review</li>
      <li><span class="approved"></span> Approved</li>
      <li><span class="adhoc"></span> Adhoc</li>
      </ul>
      </div>       
      </div>
      {/* <div class="pull-left"><label for="review">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;<b>In Review:</b></label>&nbsp;&nbsp;
         <input type="color" id="review" name="review" value="#49498D" disabled/><br/></div>
       
         <div class="text-center"><label for="approved"><b>Approved:</b></label>&nbsp;&nbsp;
         <input type="color" id="approved" name="approved" value="#28a745" disabled/><br/></div> */}
        <OutageCalendar
        localizer={localizer}
        defaultDate = {this.state.myDate}
        min={this.state.start}
        max={this.state.end}
        onView={this.onView}
        onNavigate={this.onNavigate}
        events={this.state.events}
        startAccessor="start"
        endAccessor="end"
        onEventDrop={this.onEventDrop}
        onEventResize={this.onEventResize}
        onSelectEvent ={(event)=>{this.props.handleModalPopUpFunc(true,event)}}
        resizable
        eventStyleGetter={this.eventStyleGetter}
        eventPropGetter={(this.eventPropGetter)}
        components={{
          toolbar: CustomToolbar(this.props.handleAddEventModalPopUpFunc),
          dateCellWrapper: this.ColoredDateCellWrapper,
        }}       
      />
      </div>
    );
  }  
}
