import React, { Component} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import '../../App.css';
import { createScope, partnerCenterTheme, radioGroup, radio} from '@harmony/enablers/react'; 

// Create a Harmony Enabler scope
const scope = createScope({
    theme: partnerCenterTheme,
    reactInstance: React,
  });

  const RadioGroup = scope.forReact(radioGroup);
  const Radio = scope.forReact(radio);

class TimezoneCalendarFilter extends React.Component
{
    constructor(props){
        super(props);
        this.state = {
            timezonefilter: "",
            value : 'All'
                  
        }      
    }
    
    onTimezoneChange=async(event) =>{
        this.setState({ timezonefilter:event.target.value });
        console.log(this.state.timezonefilter );
        await this.props.selectedTimezoneData(this.state.timezonefilter);
        await this.props.newevents(this.props.selectedCalendar,this.props.regionfilter,this.state.timezonefilter);
      }
     
   

    componentDidMount() {
         //some API call
        
    }
    render()
    {

     const { filter, data,selectedValue } = this.state;
        
        return (
           
            <div  className="col-12 col-md-6">
              
                {/* <label> Timezone : </label>
                &nbsp;
                <input placeholder="PST" type="radio" value="PST" name="radio-group-timezone"  checked={this.state.timezonefilter === "PST"} onChange={this.onTimezoneChange}/> PST
                &nbsp;
                <input placeholder="CST" type="radio" value="CST" name="radio-group-timezone" checked={this.state.timezonefilter === "CST"} onChange={this.onTimezoneChange}/> CST
                &nbsp;
                <input placeholder="UTC" type="radio" value="UTC" name="radio-group-timezone" defaultChecked onChange={this.onTimezoneChange}/> UTC
                &nbsp; */}

                {/* <RadioGroup label="Timezone"> &nbsp;
            <Radio value="PST" name="radio-group-timezone" checked={this.state.timezonefilter === "PST"} onHeSelected={this.onTimezoneChange}> PST</Radio> &nbsp;
            <Radio value="CST" name="radio-group-timezone"  checked={this.state.timezonefilter === "CST"} onHeSelected={this.onTimezoneChange}> CST</Radio> &nbsp;
            <Radio  value="UTC" name="radio-group-timezone" defaultChecked onHeSelected={this.onTimezoneChange}> UTC </Radio> &nbsp;
          </RadioGroup> */}

{/* <RadioGroup label="Timezone">
  <Radio value="PST" name="radio-group-timezone" checked={this.state.timezonefilter === "PST"} onSelected={this.onTimezoneChange}> PST</Radio>
  <Radio value="CST" name="radio-group-timezone" checked={this.state.timezonefilter === "CST"} onSelected={this.onTimezoneChange}> CST</Radio>
  <Radio value="UTC" name="radio-group-timezone" checked={this.state.timezonefilter === "UTC"} onSelected={this.onTimezoneChange}> UTC </Radio>
</RadioGroup> */}
            </div>   

            
            
        )
    }    
  }
export default TimezoneCalendarFilter;
  