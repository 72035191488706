import moment from 'moment';
import React from 'react';
import uuid from 'react-native-uuid';
import {fetchToken} from '../../Util.js';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";
import ReactEditDropDown from '../ServiceDropdown/EditDropDown';
import {appInsights} from '../../common/AppInsights/AppInsights';
import AlertDialog from "../../common/AlertDialog/AlertDialog";
import { Radio, RadioGroup} from 'react-radio-group';
import ReactEditRegionDropDown from '../RegionDropdown/EditRegion';
import {ButtonType} from 'office-ui-fabric-react/lib/Button'  
import * as Datetime from 'react-datetime';
import { Button,Modal } from 'react-bootstrap';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactEditFactoryDropDown from '../FactoryDropDown/EditFactory';
import CKEditor from 'ckeditor4-react';
import Switch from "react-switch";
import { trackPromise } from 'react-promise-tracker'
import LoadingIndicator from '../../common/PromiseTracker/PromiseTracker';
import ReactEditEventStatusDropDown from '../EventStatusDropDown/EditEventStatus';


class EditSAPEventModalPopUp extends React.Component{

    constructor(props){     
      super(props);    
      this.state = {readOnly:true,isdisabled:false,clicked:'',date: null,
      enddate:null,showDialog:false,outageId : '',showDeleteDialog:false,
      teamGroup :null, teamComponent:null,teamService:null,updateCompleted:false,
      outageTitle:'valid',outageTitleError: '',description: '',descriptionError: '',
      impact: 'valid',impactError: '',dateError:'', enddateError:'',
      externalcontact: 'valid',externalcontactError:'',engcontact :'valid',engcontactError :'',pmcontact:'valid',pmcontactError:''
      ,serviceGroup:null,servicegroupData:null,region:null,country:null,factory:null,factoryData:null,
      division:null,orgName:null,divisionData:null,orgNameData:null,nextsteps:'',status:null
}
      this.handleChange = this.handleChange.bind(this);
      this.checkErrors = this.checkErrors.bind(this);
      this.methodInvoke = this.methodInvoke.bind(this);
      this.handleOnChange = this.handleOnChange.bind(this);
      this.returnArrayValues = this.returnArrayValues.bind(this);
      this.onChange = this.onChange.bind(this);
      this.onEndChange = this.onEndChange.bind(this);
      this.flip = this.flip.bind(this);
    }  
  
    ToggleReadonly = () => {
      
      this.setState({readOnly:!this.state.readOnly});
      this.setState({isdisabled:!this.state.isdisabled});
    }
  
    flip = async() => 
    {
      this.setState({showDialog:!this.state.showDialog})
    }
  
    handleOnChange(e){
     
      this.setState({ clicked:e})
      }
  
    // handleChange(e){
      
    //   this.setState({[e.target.id]: e.target.value})    
    // }

    

    handleChange(e){
        this.setState({[e.target.name]: e.target.value, [`${e.target.name}Error`]:  '' })
      }
  
    onChange(date)
    {      //this.preventDefault(); 
      //this.setState({ date })
      this.setState({ date:date, dateError :''})
    }
  
    closeDialog() {
      this.setState( {showDialog: false});
    }
  
    closeDeleteDialog = async () => {
      this.setState( {showDeleteDialog: false,updateCompleted:false});
    }
  
    deleteDialog = async() => {
      this.setState( {showDialog: false});
      await this.deleteConfirmation(this.state.outageId);
      this.setState( {showDeleteDialog: true});
      
    }
  
    cancelDialog = async () => {
      this.setState( {showDialog: false } );
      this.props.closeModalPopUpFunc(true);
    }
  
    // handleRadioChange(date)
    // {      //this.preventDefault(); 
    //   this.setState({ clicked:date})
    // }
  
    onEndChange(date)
    {
      
        //this.preventDefault(); 
      //this.setState({enddate:date })
      this.setState({enddate:date,enddateError:''})
    }
  
    returnArrayValues(event)
    {
      let opts = [], opt;
      for (let i = 0, len = event.length; i < len; i++) {
        opt = event[i].value;
        opts.push(opt);
       }
      return opts.join().length > 0 ? opts.join() : event.value;
    }
    
    convertDateToUTC(date)
    {
      var now_utc =  date;
      now_utc.setHours(now_utc.getHours()+5);
      now_utc.setMinutes(now_utc.getMinutes()+30);
      return now_utc.toUTCString();
    }

    checkErrors = () => {
        const validEmailRegex = 
        RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        const {outageTitle,impact,externalcontact,engcontact,pmcontact} = this.state;
        var obj = {};
        var date = this.state.date;
        var enddate = this.state.enddate;
        date = (date == null) ? this.props.event.start : date;
        enddate = (enddate == null) ? this.props.event.end : enddate;
        const { userEmail} = this.props;
       
            
        if (outageTitle.length == 0) {
          this.setState({
            outageTitleError: 'Title field should not be blank.'
          })
          obj.prop = ++obj.prop || 0;
        }
        if ((this.state.description == null || this.state.description.length == 0)&&(this.props.event.outageDescription==null || this.props.event.outageDescription.length==0)) {
          this.setState({
            descriptionError: 'Description field should not be blank.'
          })  
          obj.prop = ++obj.prop || 0;
        }

          if (impact.length == 0) {
            this.setState({
              impactError: 'Impact field should not be blank.'
            })  
            obj.prop = ++obj.prop || 0;
          }

        
      if (date == '') {
        this.setState({
          dateError: 'Start Date field should not be blank.'
        })  
        obj.prop = ++obj.prop || 0;
      }
        if (enddate == '') {
          this.setState({
            enddateError: 'End Date field should not be blank.'
          })
          obj.prop = ++obj.prop || 0;
        }
      else if (date && enddate) {
        if (Date.parse(new Date(this.convertDateToUTC(new Date(date)))) >= Date.parse(new Date(this.convertDateToUTC(new Date(enddate))))) {
          this.setState({
            enddateError: 'End Datetime should be greater than Start Datetime.'
          })
          obj.prop = ++obj.prop || 0;
        }
      }
      if (engcontact.length == 0) {
        
        this.setState({
          engcontactError: 'Point of Contact field should not be blank.'
        })  
        obj.prop = ++obj.prop || 0;
      }
      else if(engcontact !== 'valid')
      {
        var emailError = validEmailRegex.test(engcontact) ? '' : 'Email is not valid!';
        this.setState({
          engcontactError: emailError
        }) 
        if(emailError!=='') 
        {
          obj.prop = ++obj.prop || 0;
        }
        
      }  
      
        if(obj.prop >=0)
        {
        return true;
        }
        else
        {
        return false;
        }
    }
      
      renderError(name) {
        const { outageTitleError,descriptionError,impactError,externalcontactError,engcontactError,pmcontactError,
            dateError,enddateError,alldayeventError,teamGroupError,teamServiceError,teamComponentError,serviceGroupError,factoryError,divisionError,orgNameError} = this.state;
        switch(name) {
          case 'outageTitle':
            if (outageTitleError) {
              return <div style ={{backgroundColor:'#f8d7da'}}>{outageTitleError}</div>;
            }
            break;
          case 'servicegroup':
            if (serviceGroupError) {
              return <div style ={{backgroundColor:'#f8d7da'}}>{serviceGroupError}</div>;
            }
            break;  
          case 'teamgroup':
            if (teamGroupError) {
                return <div style ={{backgroundColor:'#f8d7da'}}>{teamGroupError}</div>;
              }
              break;
          case 'teamservice':
              if (teamServiceError) {
                    return <div style ={{backgroundColor:'#f8d7da'}}>{teamServiceError}</div>;
                }
                break;
          case 'teamcomponent':
            if (teamComponentError) {
                  return <div style ={{backgroundColor:'#f8d7da'}}>{teamComponentError}</div>;
              }
                  break;
          case  'description' :
            if (descriptionError) {
              return <div style ={{backgroundColor:'#f8d7da'}}>{descriptionError}</div>;
            }
            break;
    
            case  'impact' :
              if (impactError) {
                return <div style ={{backgroundColor:'#f8d7da'}}>{impactError}</div>;
              }
              break;
             
            case  'alldayevent' :
              if (alldayeventError) {
                return <div style ={{backgroundColor:'#f8d7da'}}>{alldayeventError}</div>;
              }
            break;  
    
    
          case  'date' :
             if (dateError) {
                return <div style ={{backgroundColor:'#f8d7da'}}>{dateError}</div>;
            }
            break;
    
            case  'enddate' :
              if (enddateError) {
                 return <div style ={{backgroundColor:'#f8d7da'}}>{enddateError}</div>;
             }
             break;
    
          
          case  'engcontact' :
            if (engcontactError) {
                return <div style ={{backgroundColor:'#f8d7da'}}>{engcontactError}</div>;
              }
              break;   
         
          
          case 'division':
              if (divisionError) {
                    return <div style ={{backgroundColor:'#f8d7da'}}>{divisionError}</div>;
                }
              break;
          case 'organization':
              if (orgNameError) {
                    return <div style ={{backgroundColor:'#f8d7da'}}>{orgNameError}</div>;
                  }
                break;        
          default: 
          break;
          //return null;
            
          }       
      }
  
    methodInvoke = async(event) =>
    {
      let errors = null;
      let prop = this;
      event.preventDefault();
      event.persist();
      try {                        
      if(this.checkErrors())
      {
          this.props.closeModalPopUpFunc(true);
          return;
      }
      const title = event.target.outageTitle.value;
      var desc=(prop.state.description!= "" ? prop.state.description: (prop.props.event.outageDescription!=""?prop.props.event.outageDescription:''));
      var impact = event.target.impact.value;
      var start = prop.state.date == null ? new Date(prop.props.event.start) : prop.state.date;
      var end = prop.state.enddate == null ? new Date(prop.props.event.end) : prop.state.enddate;
      var startdate = moment(start, 'YYYY-MM-DDTHH:mm:ss.fff Z');
      startdate = startdate.format('YYYY-MM-DDTHH:mm:ss');
      var EndTime= moment(end, 'YYYY-MM-DDTHH:mm:ss.fff Z');
      EndTime = EndTime.format('YYYY-MM-DDTHH:mm:ss');
      var org = event.target.organization == undefined ? null : '';
      var extcontact = event.target.externalcontact == undefined ? null : event.target.externalcontact.value ;
      var engcontact = event.target.engcontact == undefined ? null : event.target.engcontact.value;
      var pmcontact = event.target.pmContact == undefined ? null : event.target.pmContact.value;
      var alldayevent = prop.state.clicked == "" || prop.state.clicked == undefined ?  prop.props.event.allDay : (prop.state.clicked);
      var nxtsteps=(prop.state.nextsteps!= "" ? prop.state.nextsteps: (prop.props.event.nextsteps!=""?prop.props.event.nextsteps:''));

      var bodyvalues = JSON.stringify({
        EventId : prop.props.event.outageId,
        title: title,
        Description: desc,
        Impact: impact,
        StartTime: startdate,
        EndTime: EndTime,
        organization: org,
        ExternalContact: extcontact,
        engContact: engcontact,
        pmContact: pmcontact,        
        outageType: prop.props.event.outageType,
        region: event.target.region.value=='--Select Region--'?'':event.target.region.value,
        country: event.target.country.value=='--Select Country--'?'':event.target.country.value,
        teamgroup: event.target.teamgroup  == undefined ? null : (prop.state.teamGroup == null ? this.returnArrayValues(event.target.teamgroup) : prop.state.teamGroup.map(x=> x.value).toString()) ,
        service: event.target.teamservice == undefined ? null : (prop.state.teamService == null ? this.returnArrayValues(event.target.teamservice) : prop.state.teamService.map(x=> x.value).toString()) ,
        component: event.target.teamcomponent == undefined ? null : (prop.state.teamcomponent == null ? this.returnArrayValues(event.target.teamcomponent) : prop.state.teamcomponent.map(x=> x.value).toString()),
        AllDayEvent: alldayevent,
        servicegroup: event.target.servicegroup  == undefined ? null : (prop.state.serviceGroup == null ? this.returnArrayValues(event.target.servicegroup) : prop.state.serviceGroup.map(x=> x.value).toString()), 
        factory: event.target.factory==undefined?null:(prop.state.factory == null ? this.returnArrayValues(event.target.factory) : prop.state.factory.map(x=> x.value).toString()),      
        division:event.target.division==undefined?'':event.target.division.value,
        orgName:event.target.organizationname==undefined?'':event.target.organizationname.value,
        eventStatus:event.target.status.value=='--Select Status--'?'Initial':event.target.status.value,
        NextSteps:nxtsteps,
        NACategory:prop.props.event.outageType=='Planned'?"N.A":this.props.role,
        category:'',
        Location:'',
        Recurrence:false  

      })
      
       appInsights.trackTrace({ message: 'Calendar Edit SAP Event Component Loaded.' },
      {
          "userName": this.props.userEmail,
          "ComponentId" : COMPONENT_ID,
          "ComponentName" : COMPONENT_NAME,
          "EnvironmentName" : window.env.ENVIRONMENT,
          "ServiceLineName" : SERVICE_LINE,
          "ServiceName" : SERVICE_NAME,
          "ServiceOfferingName" : SERVICE_OFFERING,
          "Correlation Id": uuid.v1(),
          "Component": 'Edit SAP Event Calendar Page',
      });
       
       var token = await fetchToken();  
       var correlationId = uuid.v1();
      var bearer = 'Bearer ' + token;
      var apimUrl = window.env.APIMURL + "/api/outage/updateoutage"; 
      trackPromise(    
      fetch(apimUrl, {
        method: 'PUT',
        headers: {
        //'Accept': 'application/json',
        //'Access-Control-Allow-Origin': '*',
        'CorrelationId': correlationId,
        'Content-Type': 'application/json',
        'authorization': bearer,
        },
       body: bodyvalues       
      }).then(response => {        
        return response.json()
      }).then(json => {
       
        // this.setState({
        //   result:json
        // });
       var values = JSON.parse(bodyvalues); 
       values["outageId"] = prop.props.event.outageId;
       values["start"] = new Date(values.StartTime);
       values["end"] = new Date(values.EndTime);
       values["outageTitle"] = values.title;
       values["outageDescription"] = values.Description;
       values["outageImpact"] = values.Impact;
       values["allDay"] = values.AllDayEvent;
       values["extContact"] = values.ExternalContact; 
       values["nextsteps"]=values.NextSteps;
           
       prop.props.updateEvent(values);
       prop.setState({
        date: null,
        enddate:null,
        clicked:'',
        readOnly:true,
        updateCompleted : true,
       })
      })
      .catch(error => 
        {
         var err =
         {
            "message": "Exception occured while updating the calendar edit sap event",
            "ComponentId" : COMPONENT_ID,
            "ComponentName" : COMPONENT_NAME,
            "EnvironmentName" : window.env.ENVIRONMENT,
            "ServiceLineName" : SERVICE_LINE,
            "ServiceName" : SERVICE_NAME,
            "ServiceOfferingName" : SERVICE_OFFERING,
            "Component" : "Calendar Edit SAP Event Page",
            "userName": this.props.userEmail,
            "roles": this.props.role != null || '' ? this.props.role : "",
            "exception": error.message,
            "Correlation Id": correlationId
         }
         appInsights.trackException({exception: error,properties : err}); 
        })
      );
      }
      catch (e) {
        errors = e;
    }
    }
  
    deleteConfirmation = async(outageID) =>
    {
      var apimUrl = window.env.APIMURL + "/api/outage/deleteoutage/?outageid="; 
      var correlationId = uuid.v1();
      let url = apimUrl + outageID;
      var token = await fetchToken();  
      var bearer = 'Bearer ' + token;
      var bodyvalues = JSON.stringify({
        outageid : outageID,
       }) 
       
       appInsights.trackTrace({ message: 'Calendar Component Loaded and user clicked on Delete event.' },
      {
          userName: this.props.userEmail,
          "ComponentId" : COMPONENT_ID,
          "ComponentName" : COMPONENT_NAME,
          "EnvironmentName" : window.env.ENVIRONMENT,
          "ServiceLineName" : SERVICE_LINE,
          "ServiceName" : SERVICE_NAME,
          "ServiceOfferingName" : SERVICE_OFFERING,
          "Correlation Id": correlationId,
          "Component": 'Calendar Delete Event Operation',
      });
      fetch(url, {
        method: 'DELETE',
        headers: {
        //'Accept': 'application/json',
        //'Access-Control-Allow-Origin': '*',
        'CorrelationId': correlationId,
        'Content-Type': 'application/json',
        'authorization': bearer,
        },
      }).then(response => {
       
        return response.json()
      }).then(json => {
        
        this.setState({
          result:json
        });
      }).catch(error =>
        {
        
         var err = 
         {
            "message": "Exception occured in Calendar Delete component while submitting the delete request",
            "ComponentId" : COMPONENT_ID,
            "ComponentName" : COMPONENT_NAME,
            "EnvironmentName" : window.env.ENVIRONMENT,
            "ServiceLineName" : SERVICE_LINE,
            "ServiceName" : SERVICE_NAME,
            "ServiceOfferingName" : SERVICE_OFFERING,
            "Component": "Calendar Delete Event",
            "userName": this.props.userEmail,
            "roles": this.props.role != null || '' ? this.props.role : "",
            "exception": error.message,
            "Correlation Id": correlationId
         }
         appInsights.trackException({exception: error,properties : err}); 
        })
      this.props.removeEvent(outageID);
  
    }
  
    DeleteEvent = async()=>{
      
      let outageID = this.props.event.outageId;
      this.setState({outageId:outageID});
      var shouldDelete = await this.flip();
      
      //await this.Sample(outageID);
      // Service Delete Operetion
      // 
       }
  
    async updateServiceGroup(value){
      this.setState({
          serviceGroup: value
          });
    }
     
    async updateTeamGroup(value){
      this.setState({
          teamGroup: value
         });
    }
  
    async updateComponent(value){
      this.setState({
          teamComponent: value
         });
    }
  
    async updateService(value){
      this.setState({
          teamService: value
         });
    }
    
    async updateFactoryName(value){
      
      this.setState({
          factory: value
         });
    }

    async updateRegion(value){
      
      this.setState({
          region: value
         });
    }

    async updateCountry(value){
      
      this.setState({
          country: value
         });
    }

    onEditorChange( event ) {
      this.setState( {
          description: event.editor.getData(),
          descriptionError:''
      } );
    }

/*    updateDivision(event){
      
      this.setState({
          division: this.props.event.division
         });
    }

    updateOrgName(event){
      
      this.setState({
          orgName: this.props.event.orgName
         });
    } */

  async updateDivision(value)
  {
    this.setState({
      division: value
     });
  } 
  
  async updateOrgName(value)
  {
    this.setState({
      orgName: value
     });
  } 

  async updateEventStatus(value){
      
    this.setState({
        status: value
       });
  }

  onNextStepsTextEditorChange( event ) {
    this.setState( {
        nextsteps: event.editor.getData()        
    } );
  }

  /*  showDescriptionData()
    {
      return(<div>{ dangerouslySetInnerHTML={{ __html: this.props.data }} }</div>)
    } */

  
    returnValues(array)
    {
      const options = array.map(v => ({
        label: v,
        value: v
      }));
      return options;
    }



    render(){
      
      let subText,display;
      let checked = (this.state.clicked == "") ? (((this.props.event.allDay)==true) ? true : false):((this.state.clicked)=="Yes"?true:false);
      const { userEmail} = this.props;
                
      if(this.state.showDeleteDialog)
      {
        subText = 'We have successfully processed your request';
        display = true;
      }
      if(this.state.updateCompleted)
      {
        subText = 'Updated successfully';
        display = true;
      }
      return (
          <div>
            
            <AlertDialog  title= {'Success!'} cancelButtonLabel={'Ok'}
                          message= {subText} open={display} onClickCancel={this.closeDeleteDialog}/> 
            <AlertDialog okButtonLabel = {'Delete'} okButtonType = {ButtonType.Danger} onClickOk={this.deleteDialog} title= {'Confirm Dialog'} cancelButtonLabel={'Cancel'}
                          message= {'Are you sure you want to delete this event?'} open={this.state.showDialog} onClickCancel={this.cancelDialog}/> 
            <Modal style = {{'overflow': 'visible'}} role="dialog" data-keyboard="false" show={this.props.show} size="lg" onHide ={()=> {this.props.closeModalPopUpFunc(false)}} backdrop="static" centered autoFocus="true">
              <Modal.Header closeButton>
                <Modal.Title>Edit Event</Modal.Title>
              </Modal.Header>
              <LoadingIndicator></LoadingIndicator>
              <form id = "updateevent" onSubmit = {this.methodInvoke.bind(this)}>
                <Modal.Body style={{'maxHeight': 'calc(100vh - 210px)', 'overflowY': 'auto'}}>
                  <div className="form-group required">
                    <label for="outageTitle" class="control-label">Title</label>
                    <input required type="text" className="form-control" name="outageTitle" readOnly={this.state.readOnly} defaultValue={this.props.event.outageTitle} onChange={(e) => this.handleChange(e)} />
                    <span style={{color: '#E22335'}}>{this.renderError('outageTitle')}</span>
                  </div>
                  {/* <div className="form-group">
                    <label for="organization">Organization</label>
                    <input type="text" class="form-control" id="organization" readOnly={this.state.readOnly} defaultValue={this.props.event.organization} onChange={(e) => this.handleChange(e)} />
                  </div> */}
                              
               <div className="form-group">
                  <ReactEditEventStatusDropDown 
                        email = {userEmail} 
                        event = {this.props.event} 
                        readOnly={this.state.readOnly}
                        statusData={this.updateEventStatus.bind(this)} 
                  />            
               </div>            

              <div className="form-group required">
                <label htmlFor="description" class="control-label">Description</label>
                <CKEditor required id='editor'
                  onChange={(e) => this.onEditorChange(e)}
                  readOnly={this.state.readOnly}
                  data={this.props.event.outageDescription}
                  config={{removePlugins:'elementspath,scayt,wsc' ,  removeButtons:'Image,Source,About,Maximize,Anchor'}}
                />
                 <span style={{color: '#E22335'}}>{this.renderError('description')}</span>
              </div>

               
                 <div className="form-group required">
                 <label htmlFor="informational" class="control-label">Informational</label> &nbsp;&nbsp;
                 {/* <BootstrapSwitchButton checked = {this.props.event.outageType === 'Planned' ? false : true} disabled = {this.state.readOnly}   offlabel='No' onlabel='Yes' className="form-control" name="informational"
                 onstyle="outline-success" offstyle="outline-info" size="sm" />        */}
               
               <Switch required name="informational" disabled ={true} checked={this.props.event.outageType === 'Planned' ? false : true} />
               
               </div>
              

                  <div className="form-group required">
                    <label for="outageImpact" class="control-label">Impact</label>
                    <textarea required aria-label="outageImpact" type="text" class="form-control" name="impact" readOnly={this.state.readOnly} defaultValue={this.props.event.outageImpact} onChange={(e) => this.handleChange(e)}/>
                    <span style={{color: '#E22335'}}>{this.renderError('impact')}</span>
                  </div>
  
                  <div className="form-group required">              
                <label htmlFor="startDate" class="control-label">StartTime(<b>UTC</b>)</label>
                <div className="form-group">
                <Datetime required aria-label="Start Time"  calendarAriaLabel="StartTimeCalender" yearAriaLabel="Year" monthAriaLabel="Month" dayAriaLabel="Day" hourAriaLabel="Hour" minuteAriaLabel="Minute" amPmAriaLabel="ampm"
                  name="startDate"  inputProps={{readOnly: true,disabled : this.state.readOnly}} onChange={this.onChange} value={this.state.date == null ? this.props.event.start : this.state.date}></Datetime>    
                 <span style={{color: '#E22335'}}>{this.renderError('date')}</span>
                </div>            
              </div>

                  <div className="form-group required">
                    <label htmlFor="endtime" class="control-label">EndTime</label>
                    <Datetime required calendarAriaLabel="EndTimeCalender" yearAriaLabel="Year" monthAriaLabel="Month" dayAriaLabel="Day" hourAriaLabel="Hour" minuteAriaLabel="Minute" amPmAriaLabel="ampm"
                      name="endtime" inputProps={{readOnly: true,disabled : this.state.readOnly}} onChange={this.onEndChange} value={this.state.enddate === null ? this.props.event.end : this.state.enddate}></Datetime>
                    <span style={{ color: '#E22335' }}>{this.renderError('enddate')}</span>
                  </div>

              <div className="form-group">
                <label htmlFor="nextsteps" class="control-label">Next Steps</label>
                <CKEditor required id='nextsteps_editor'
                  onChange={(e) => this.onNextStepsTextEditorChange(e)}
                  readOnly={this.state.readOnly}
                  data={this.props.event.nextsteps}
                  config={{removePlugins:'elementspath,scayt,wsc' ,  removeButtons:'Image,Source,About,Maximize,Anchor'}}
                />               
              </div>
              
              
              <div className="form-group required">
                <label htmlFor="engcontact" class="control-label">Point of Contact</label>
                <input required aria-label="Engineering Contact" type="email" className="form-control" id="engcontact" name="engcontact" readOnly={this.state.readOnly} defaultValue={this.state.engcontact === 'valid' ? this.props.event.engContact : this.state.engcontact}  onChange={(e) => this.handleChange(e)}  />
                <span style={{color: '#E22335'}}>{this.renderError('engcontact')}</span>
              </div> 
                   
              
              <div className="form-group">
                <label htmlFor="alldayevent" class="control-label">AllDayEvent</label> &nbsp;&nbsp;              
                <Switch  name="alldayevent" disabled={this.state.readOnly} onChange={(e) => this.handleOnChange(e)} checked={(this.state.clicked === "") ? (this.props.event.allDay):((this.state.clicked))} />

                </div>
                
                               
                  <div className="form-group">
                   <ReactEditDropDown 
                   email = {userEmail} 
                   divisionData={this.updateDivision.bind(this)}
                   orgNameData={this.updateOrgName.bind(this)}
                   servicegroupData={this.updateServiceGroup.bind(this)}
                   teamData={this.updateTeamGroup.bind(this)} 
                   teamComponent={this.updateComponent.bind(this)} 
                   teamService={this.updateService.bind(this)}
                   event = {this.props.event} 
                   readOnly={this.state.readOnly}
                   
                   />
                  </div>
                 
                               
                  <div className="form-group">
                  <ReactEditRegionDropDown 
                        email = {userEmail} 
                        event = {this.props.event} 
                        readOnly={this.state.readOnly}
                        regionData={this.updateRegion.bind(this)} 
                        countryData={this.updateCountry.bind(this)}
                  />            
                  
                  </div>                  
                  
                
                  </Modal.Body>              
                <Modal.Footer>
                   
                  <Button aria-label="Delete" variant="secondary" onClick={()=> {
                    this.DeleteEvent()
                    }}>
                    Delete
                  </Button>
                  
                  <Button aria-label="Close" variant="secondary" onClick={()=> {this.setState({readOnly:true});this.props.closeModalPopUpFunc(false)}}>
                    Close
                  </Button>
                  {this.props.role !== 'Reader' && this.props.role !== 'BusinessUser ChannelManagers' ?
                  <Button aria-label="Toggle" variant="secondary" onClick={()=> {this.ToggleReadonly()}}>
                    {
                      (this.state.readOnly)? "Edit":"Back"
                    }
                  </Button>
                  : null }
                  <Button aria-label="Edit Event" variant="primary" form = "updateevent" type="Submit" hidden={Boolean(this.state.readOnly)} onClick={()=> {
                      
                      this.props.closeModalPopUpFunc(true);                   
                      
                      }}>
                    Save Changes
                  </Button>
                </Modal.Footer>
              </form>
            </Modal>
          </div>
      );
    }
  }

export default EditSAPEventModalPopUp;