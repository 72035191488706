import moment from 'moment';
import React,{ReactDOM,Component} from 'react';
import { reactAI } from "react-appinsights";
import uuid from 'react-native-uuid';
import {fetchToken,getValidAdminRole,fetchRoles} from '../../Util.js';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";
import {appInsights} from '../../common/AppInsights/AppInsights';
import AlertDialog from "../../common/AlertDialog/AlertDialog";
import ReactDropDown from '../ServiceDropdown/SvcDropDown';
import { Button,Modal } from 'react-bootstrap';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {ButtonType} from 'office-ui-fabric-react/lib/Button'; 
import { trackPromise } from 'react-promise-tracker'
import LoadingIndicator from '../../common/PromiseTracker/PromiseTracker';
import EventCategoryDropDown from '../FactoryDropDown/EventCategoryDropdown';
import './ReactPaginationTable.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import BootstrapTable,{TableHeaderColumn} from "react-bootstrap-table-next";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';

class AddEventStakeHolder extends Component{
    _isMounted = false;
    constructor(props) {
    
      super(props);
      
      const date = Date.now();
      this.state = {result:'',userEmail : '',addeventstakeholder : false,showModal: '',
        showDialog:false,teamGroup :[],teamGroupError :'', teamComponent:[],teamComponentError :'',teamService:[],teamServiceError :'',
        showErrorDialog :false,serviceGroup:[],serviceGroupError:'',factory:[],servicegroupData:'',showNewDialog:false,newblocking:false, addOutage:[]
        ,factoryError:'',factoryData:'', division:[],divisionError:'',orgName:[],orgNameError:'',orgData:'', role : this.props.role
        ,tenantId:'',tenantIdError:'',mailTo:'',mailToError:'',mailCC:'',mailCCError:'',mailBCC:'',mailBCCError:'',eventstakeholderlist:[],stakeholdertype:'ServiceTree',
        columns: [
          {
            dataField: 'teamGroup',
            text: 'TeamGroup',
            editable:false,
            sort: true,
            headerStyle: { backgroundColor: '#006ee5', color:'#ffffff'}
          },
          {
          dataField: 'category',
          text: 'Category( eg:FactoryName/EventType )',
          editable:false,
          sort: true,
          headerStyle: { backgroundColor: '#006ee5', color:'#ffffff'}
        },
        {
          dataField: 'mailTo',
          text: 'MailTo',
          editable:false,
          headerStyle: { backgroundColor: '#006ee5', color:'#ffffff'}
          
        },
        {
          dataField: 'mailCc',
          text: 'MailCC',
          editable:false,
          headerStyle: { backgroundColor: '#006ee5', color:'#ffffff'}
        }
           
      ] 
      };

 
      this.handleChange = this.handleChange.bind(this);
     // this.onChange = this.onChange.bind(this);
     // this.onEndChange = this.onEndChange.bind(this);
      this.methodInvoke = this.methodInvoke.bind(this);
    //  this.handleOnChange = this.handleOnChange.bind(this);
      this.close = this.close.bind(this);
      this.cofirmAddStakeHolder = this.cofirmAddStakeHolder.bind(this);
      this.flip = this.flip.bind(this);
      
    }

 
  
    appInsight = reactAI.appInsights;
    close = () => this.setState({isOpen: false}) 
  
   
  
    componentWillUnmount() {
      this._isMounted = false;
    }
  
    close(){
      this.setState({ showModal: false });
    }

      
    checkErrors = () => {
      const validEmailRegex = 
      RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
      const {} = this.state;
      var obj = {};
      const { userEmail} = this.props;
      let validEmail = (userEmail != undefined ? userEmail.toLowerCase().includes("microsoft.com") : null);
          
      if(validEmail && this.state.stakeholdertype==='ServiceTree') 
      {
        
        if ( this.state.division == null || this.state.division.length == 0) {
          this.setState({
            divisionError: 'Division should be selected.'
          })  
          obj.prop = ++obj.prop || 0;
        }

        if ( this.state.orgName == null || this.state.orgName.length == 0) {
          this.setState({
            orgNameError: 'Organization name  should be selected.'
          })  
          obj.prop = ++obj.prop || 0;
        }

        if ( this.state.serviceGroup == null || this.state.serviceGroup.length == 0) {
          this.setState({
            serviceGroupError: 'ServiceGroup should be selected.'
          })  
          obj.prop = ++obj.prop || 0;
        }

        if ( this.state.teamGroup == null || this.state.teamGroup.length == 0) {
          this.setState({
            teamGroupError: 'TeamGroup should be selected.'
          })  
          obj.prop = ++obj.prop || 0;
        }

        if (this.state.mailTo == null || this.state.mailTo.length == 0) {
            this.setState({
              mailToError: 'Mail To list should be mentioned.'
            })  
            obj.prop = ++obj.prop || 0;
          }  


    }    
    else if(validEmail && this.state.stakeholdertype==='Custom') 
    {
      
      if ((this.state.factory == null || this.state.factory.length == 0)) {
        this.setState({
          factoryError: 'Factory should be selected.'
        })  
        obj.prop = ++obj.prop || 0;
      }

      if (this.state.mailTo == null || this.state.mailTo.length == 0) {
          this.setState({
            mailToError: 'Mail To list should be mentioned.'
          })  
          obj.prop = ++obj.prop || 0;
        }
    }
    
      if(obj.prop >=0)
      {
      return true;
      }
      else
      {
      return false;
      }
    } 
  
    renderError(name) {
      const { teamGroupError,serviceGroupError,factoryError,divisionError,orgNameError,mailToError} = this.state;
      switch(name) {
       
        case 'teamgroup':
          if (teamGroupError) {
              return <div style ={{backgroundColor:'#f8d7da'}}>{teamGroupError}</div>;
            }
            break;
              
        case 'servicegroup':
            if (serviceGroupError) {
                  return <div style ={{backgroundColor:'#f8d7da'}}>{serviceGroupError}</div>;
              }
            break;   
        case 'factory':
            if (factoryError) {
                  return <div style ={{backgroundColor:'#f8d7da'}}>{factoryError}</div>;
              }
            break; 
        case 'division':
            if (divisionError) {
                  return <div style ={{backgroundColor:'#f8d7da'}}>{divisionError}</div>;
              }
            break; 
        case 'orgName':
            if (orgNameError) {
                  return <div style ={{backgroundColor:'#f8d7da'}}>{orgNameError}</div>;
                }
            break;
            case 'mailTo':
                if (mailToError) {
                      return <div style ={{backgroundColor:'#f8d7da'}}>{mailToError}</div>;
                    }
                break; 
             
        default: 
        break;
        
      
        }
}
    
refreshStakeHolderList = async(event) => {
  //this._isMounted = true;
  appInsights.trackTrace({ message: 'Event StakeHolder Screen Loaded.' },
                 {
                     userName: '',
                     roles: this.props.role != null || '' ? this.props.role : "",
                     "ComponentId" : COMPONENT_ID,
                     "ComponentName" : COMPONENT_NAME,
                     "EnvironmentName" : window.env.ENVIRONMENT,
                     "ServiceLineName" : SERVICE_LINE,
                     "ServiceName" : SERVICE_NAME,
                     "ServiceOfferingName" : SERVICE_OFFERING,
                     "Correlation Id": uuid.v1(),
                     "Component": 'Admin Screen - Add StakeHolder Page',
                 });

var token = await fetchToken();
var correlationId = uuid.v1();
var bearer = 'Bearer ' + token;
var apimUrl = window.env.APIMURL + "/api/outage/getstakeholders";
this.setState({ isLoading: true})
fetch(apimUrl,{
    method: 'GET',
    headers: {
        'authorization': bearer,
        'Accept' : 'application/json',
        'CorrelationId': correlationId,
        'Content-Type': 'application/json'
    }})
    .then(response => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);          
    })       
    .then((res) => {
      //const { statusCode, data } = res;
      const statusCode = res[0];
      const stakeholderlist = res[1];                  
      this.setState({ eventstakeholderlist: stakeholderlist,ReturnStatus: statusCode
      });          
    })
    .catch(error => {

      var err = {
        "message": "Exception occured StakeHolder page while getting the list.",
        "ComponentId" : COMPONENT_ID,
                     "ComponentName" : COMPONENT_NAME,
                     "EnvironmentName" : window.env.ENVIRONMENT,
                     "ServiceLineName" : SERVICE_LINE,
                     "ServiceName" : SERVICE_NAME,
                     "ServiceOfferingName" : SERVICE_OFFERING,
        "Component": 'Admin Screen - Add L1 Approver Page',
         "userName": this.props.userEmail,
         "roles": this.props.role != null || '' ? this.props.role : "",
         "exception": error.message,
         "Correlation Id": correlationId
      }
        appInsights.trackException({exception: error,properties : err});  
    })
 
}

    successDialog()
    {
      this.setState({showDialog : true});
    };

     errorDialog()
     {
        this.setState({showErrorDialog : true});
     };

      flip = async() => 
     {
      this.setState({showNewDialog:!this.state.showNewDialog})
     }

      cofirmAddStakeHolder = async() =>
      {      

        const newState = this;
        const bodyvalues = newState.state.addeventstakeholder;
        var token = await fetchToken();  
         var correlationId = uuid.v1(); 
        var bearer = 'Bearer ' + token;  
        var apimUrl = window.env.APIMURL + "/api/outage/addeventstakeholder";  
        trackPromise(
        fetch(apimUrl, {
          method: 'POST',
          headers: {
          //'Accept': 'application/json',
          'Content-Type': 'application/json',
          'CorrelationId': correlationId,
          'authorization': bearer,
          },
         body: bodyvalues
        }).then(response => {     
          this.setState({ ReturnStatus: response.status});  
          if(response.status !== 400)
          {           
            this.setState({
              result:response.status,
              addeventstakeholder : true 
            },
            this.successDialog());          
          }
          else
          {
              this.errorDialog();
          }
          
        })
       .catch( async error =>
              {
                //alert("Error Occured");  
                this.errorDialog();
                var err = 
                {
                    "message": "Exception occured while submitting an outage request",
                    "ComponentId" : COMPONENT_ID,
                    "ComponentName" : COMPONENT_NAME,
                    "EnvironmentName" : window.env.ENVIRONMENT,
                    "ServiceLineName" : SERVICE_LINE,
                    "ServiceName" : SERVICE_NAME,
                    "ServiceOfferingName" : SERVICE_OFFERING,
                    "Component" : "Calendar Add Event Page",
                    "exception": error.message,
                    "Correlation Id": correlationId
                }               
               appInsights.trackException({exception: error,properties : err});  
             }));
          
      }  
  
    submitAlert = async(event) =>{
      this.setState({showNewDialog:!this.state.showNewDialog})
      
    }

    approveDialog = async(event) => {
      this.setState( {showNewDialog: false});  
      var event = await this.cofirmAddStakeHolder(event);    
    }

    cancelDialog = async () => {
      const { onClickCancel } = this.props;
      if (typeof onClickCancel === "function") { await onClickCancel(); }
      this.setState( {showNewDialog: false});
    }

   /* closeDialog = async () => {
      const { onClickCancel } = this.props;
      if (typeof onClickCancel === "function") { await onClickCancel(); }
      this.setState( {showDialog: false});
    } */

    closeDialog= async () => {
      this.setState( {showDialog: false,showErrorDialog: false} );
     // this.props.submitModalPopUpFunc(false); 
    }
    
    
      methodInvoke = async(event) =>
      {  
        let errors = null; 
      try {   
        event.preventDefault();
        event.persist();
         
        if(this.checkErrors())
        {         
          //this.props.submitModalPopUpFunc(true);
          return;
        }
        else
        {              
       
        const { userEmail} = this.props;
        let validEmail = (userEmail != undefined ? userEmail.toLowerCase().includes("microsoft.com") : null);
                       
        var bodyvalues = JSON.stringify({
          teamgroup: event.target.teamgroup === undefined ? '' : event.target.teamgroup.value,
          servicegroup: event.target.servicegroup === undefined ? '' : event.target.servicegroup.value,
          othercategory: event.target.factory === undefined ? '' : event.target.factory.value,
          division:event.target.division===undefined?'':event.target.division.value,
          organization:event.target.organizationname===undefined?'':event.target.organizationname.value,
          mailTo:event.target.mailTo===undefined?'':event.target.mailTo.value,
          mailCC:event.target.mailCC===undefined?'':event.target.mailCC.value,
          mailBCC:event.target.mailBCC===undefined?'':event.target.mailBCC.value        
        })         
        this.setState({addeventstakeholder:bodyvalues});
        var event = await this.submitAlert(event);       
      }
    }
    catch (e) {
      errors = e;
  }
     //Catch Block
  }
       
     
      handleChange(e){
        // if(e.target.value.match("^[a-zA-Z ]*$")!=null) {
         this.setState({[e.target.name]: e.target.value, [`${e.target.name}Error`]:  '' })
        //}
      }     
  
    
  
    componentDidCatch(error, info) {
      
      this.setState({ hasError: true });      
    }    

    async componentDidMount()
    {
      //this._isMounted = true;
      appInsights.trackTrace({ message: 'StakeHolder Screen Loaded.' },
                     {
                         userName: '',
                         roles: this.props.role != null || '' ? this.props.role : "",
                         "ComponentId" : COMPONENT_ID,
                         "ComponentName" : COMPONENT_NAME,
                         "EnvironmentName" : window.env.ENVIRONMENT,
                         "ServiceLineName" : SERVICE_LINE,
                         "ServiceName" : SERVICE_NAME,
                         "ServiceOfferingName" : SERVICE_OFFERING,
                         "Correlation Id": uuid.v1(),
                         "Component": 'Admin Screen - Add StakeHolder Page',
                     });
   
    var token = await fetchToken();
    var correlationId = uuid.v1();
    var bearer = 'Bearer ' + token;
    var apimUrl = window.env.APIMURL + "/api/outage/getstakeholders";
    this.setState({ isLoading: true})
    fetch(apimUrl,{
        method: 'GET',
        headers: {
            'authorization': bearer,
            'Accept' : 'application/json',
            'CorrelationId': correlationId,
            'Content-Type': 'application/json'
        }})
        .then(response => {
          const statusCode = response.status;
          const data = response.json();
          return Promise.all([statusCode, data]);          
        })       
        .then((res) => {
          //const { statusCode, data } = res;
          const statusCode = res[0];
          const stakeholderlist = res[1];                  
          this.setState({ eventstakeholderlist: stakeholderlist,ReturnStatus: statusCode
          });          
        })
        .catch(error => {

          var err = {
            "message": "Exception occured StakeHolder page while getting the list.",
            "ComponentId" : COMPONENT_ID,
                         "ComponentName" : COMPONENT_NAME,
                         "EnvironmentName" : window.env.ENVIRONMENT,
                         "ServiceLineName" : SERVICE_LINE,
                         "ServiceName" : SERVICE_NAME,
                         "ServiceOfferingName" : SERVICE_OFFERING,
            "Component": 'Admin Screen - Add L1 Approver Page',
             "userName": this.props.userEmail,
             "roles": this.props.role != null || '' ? this.props.role : "",
             "exception": error.message,
             "Correlation Id": correlationId
          }
            appInsights.trackException({exception: error,properties : err});  
        })
     
    }

    async updateServiceGroup(value){
      
      this.setState({serviceGroup: value });      
    }

    async setServiceGroupErrorValue(value){
      
      this.setState({
          serviceGroupError: value
         });
    }
  
    async updateTeamGroup(value){
      
      this.setState({
          teamGroup: value
         });
    }
  
    async setTeamErrorValue(value){
      
      this.setState({
          teamGroupError: value
         });
    }
  
    async updateService(value){
     
      this.setState({
          teamService: value
         });
    }

    async setServiceErrorValue(value){
    
      this.setState({
          teamServiceError: value
         });
    }

    async updateFactoryName(value){
      
      this.setState({
          factory: value
         });
    }

    async setfactoryError(value){
      
      this.setState({
          factoryError: value
         });
    }

    async updateDivision(value){
      
      this.setState({division: value });
      
    }
    async setDivisionErrorValue(value){
      
      this.setState({
          divisionError: value
         });
    }
    async updateOrgName(value){
      
      this.setState({orgName: value });
      
    }
    async setOrgNameErrorValue(value){
      
      this.setState({
          orgNameError: value
         });
    }

    setStakeHolderType(event) {
      console.log(event.target.value);
      this.setState({stakeholdertype:event.target.value});
    }
       
   

   render(){     
   
    
    let validEmail = this.props.userEmail;
    let validAdminRole=getValidAdminRole(this.props.role);
    
    const borderRadiusStyle = { borderRadius: 2 };
    const options = {
      paginationSize: 4,
      sizePerPage: 5, 
      pageStartIndex: 1,
      //alwaysShowAllBtns: true, // Always show next and previous button
      //withFirstAndLast: false, // Hide the going to First and Last page button
      hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
  };


     
      return (      
        <div> 
          <AlertDialog title= {'Confirmation Dialog'} message= {'Are you sure you want to submit?'} open={this.state.showNewDialog}
           okButtonLabel = {'Yes'} okButtonType = {ButtonType.Danger} cancelButtonLabel={'No'}
          cancelButtonType={ButtonType.Danger} onClickCancel={this.cancelDialog} onClickOk={this.approveDialog}/>
          <AlertDialog  title= {'Success!'} cancelButtonLabel={'Ok'}
                          message= {'StakeHolder added successfully'} open={this.state.showDialog} onClickCancel={this.closeDialog}/>  
          <AlertDialog  title= {'Error'} cancelButtonLabel={'Ok'}
                          message= {'Insufficient Access'} open={this.state.showErrorDialog} onClickCancel={this.closeDialog}/>    
           
           
            <form id = "addeventstakeholder" onSubmit={this.methodInvoke.bind(this)}>
                         
              { (validEmail && validAdminRole) ?
              <div>  
                     
                     <div className="form-group required">
                       <label>Select Mapping Type : </label>
                       &nbsp;
                       &nbsp;
                       <fieldset onChange={this.setStakeHolderType.bind(this)}>
                       <label>
                            <input type="radio" value="ServiceTree"  name="stakeholder type" checked={this.state.stakeholdertype==='ServiceTree'}/> ServiceTree Mapping
                       </label>
                       &nbsp;
                       &nbsp;
                       <label>
                        <input type="radio" value="Custom" name="stakeholder type" checked={this.state.stakeholdertype==='Custom'}/> Custom Mapping
                        </label>
                        </fieldset>
                      </div>

                    {(this.state.stakeholdertype==="ServiceTree") ?   

                    <div className="form-group"><ReactDropDown
                      servicegroupData={this.updateServiceGroup.bind(this)} 
                      teamData={this.updateTeamGroup.bind(this)} 
                      email = {this.props.userEmail}
                      setServiceGroupErrorValue={this.setServiceGroupErrorValue.bind(this)}
                      setTeamErrorValue = {this.setTeamErrorValue.bind(this)}
                      serviceGroupError={this.renderError('servicegroup')}
                      teamGroupError = {this.renderError('teamgroup')}
                      divisionError={this.renderError('division')}
                      setDivisionErrorValue = {this.setDivisionErrorValue.bind(this)}
                      divisionData={this.updateDivision.bind(this)} 
                      orgNameError={this.renderError('orgName')}                      
                      setOrgNameErrorValue = {this.setOrgNameErrorValue.bind(this)}                      
                      orgData={this.updateOrgName.bind(this)} 
                      
                    />
                      
                    </div>
                    : (this.state.stakeholdertype==="Custom") ?
                    <div className="form-group">
                    <EventCategoryDropDown                          
                            factoryData={this.updateFactoryName.bind(this)}
                            email = {this.props.userEmail}      
                            factoryError = {this.renderError('factory')}
                            setfactoryError={this.setfactoryError.bind(this)}                                        
                    />
                    </div>
                    : null
                  }                  
                 
               <div className="form-group required">
                <label htmlFor="mailto" class="control-label">MailTo List </label>
                <input required aria-label="MailTo" type="text" placeholder="MailTo" className="form-control" name="mailTo" value={this.state.mailTo ||""} onChange={(e) => this.handleChange(e)}/>
               
                <span style={{color: '#E22335'}}>{this.renderError('mailTo')}</span>
              </div> 

              <div className="form-group">
                <label htmlFor="mailcc" class="control-label">MailCC List </label>
                <input  aria-label="MailCC" type="text" placeholder="MailCC" className="form-control" name="mailCC" value={this.state.mailCC ||""} onChange={(e) => this.handleChange(e)}/>
              </div> 

              <div className="form-group">
                <label htmlFor="mailbcc" class="control-label">MailBCC List </label>
                <input  aria-label="MailBCC" type="text" placeholder="MailBCC" className="form-control" name="mailBCC" value={this.state.mailBCC ||""} onChange={(e) => this.handleChange(e)}/>
              </div> 
               
            
              <Button aria-label="Submit Outage" form = "addeventstakeholder" type="submit" variant="primary">
                Submit
              </Button>
              <div>&nbsp;</div>
              <div>
              <Button aria-label="Refresh Data" variant="primary" onClick={this.refreshStakeHolderList} >
                Refresh StakeHolder List
              </Button>
              </div>
              <div>&nbsp;</div>
              <div>
                <BootstrapTable 
                striped 
                bordered 
                hover 
                condensed
                wrapperClasses="table-responsive"
                className = "Table"           
                keyField='id' 
                data={ this.state.eventstakeholderlist } 
                columns={ this.state.columns }
                options={options}
                pagination={ paginationFactory(options) }
                >  
               
              </BootstrapTable>
            </div>
            </div>
            :null }
           </form> 
          
        </div>
    );
    }
  }

export default AddEventStakeHolder;