import React,{ReactDOM,Component} from 'react';
import CKEditor from 'ckeditor4-react';
import { Button } from 'react-bootstrap';
import {appInsights} from '../../common/AppInsights/AppInsights';
import uuid from 'react-native-uuid';
import {fetchToken} from '../../Util.js';
import { trackPromise } from 'react-promise-tracker';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";
import AlertDialog from '../../common/AlertDialog/AlertDialog';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {ButtonType} from 'office-ui-fabric-react/lib/Button';

class SAPReleaseSchedule extends Component
{
  _isMounted = false;
    constructor(props){
        super(props)        
        this.state={
        sapquarterlydata:'',
        sapsupportpackdata:'',
        sapmonthlydata:'',
        sapdualstackdata:'',
        selectedyear:'',
        sapreleaseschedule:[],
        editorname:'',
        showDialog:false,
        sapdualstacklabel:'',
        sapmonthlyreleaselabel:'',
        sapquarterlyreleaselabel:'',
        sapsupportpacklabel:'',
        addsapschedule:false,
        showErrorDialog :false,
        showNewDialog:false

    }
    this.handleYearChange=this.handleYearChange.bind(this);
    this.SaveCalendarEvent = this.SaveCalendarEvent.bind(this);
    this.flip = this.flip.bind(this);  
    
    } 
    
    submitAlert = async(event) =>{
      this.setState({showNewDialog:!this.state.showNewDialog})
      
    }

    approveDialog = async(event) => {
      this.setState( {showNewDialog: false});  
      var event = await this.SaveCalendarEvent(event);    
    }

    cancelDialog = async () => {
      const { onClickCancel } = this.props;
      if (typeof onClickCancel === "function") { await onClickCancel(); }
      this.setState( {showNewDialog: false});
    }

    onEditorChange(event) {
        this.setState({[event.editor.config.bodyId] : event.editor.getData(),[`${event.editor.config.bodyId}Error`]:'',editorname:event.editor.config.bodyId});
      }

    SaveCalendarEvent=async()=>
    {
            
      const newState = this;
      const bodyvalues = newState.state.addsapschedule;    
       var token = await fetchToken();  
       var correlationId = uuid.v1();
       
      var bearer = 'Bearer ' + token;
      var apimUrl = window.env.APIMURL + "/api/outage/addsapreleaseschedule"; 
      trackPromise( 
      fetch(apimUrl, {
        method: 'POST',
        headers: {
        'CorrelationId': correlationId,
        'Content-Type': 'application/json',
        'authorization': bearer,
        },
       body: bodyvalues       
      })
      .then(response => {     
        this.setState({ ReturnStatus: response.status});  
        if(response.status !== 400)
        {           
          this.setState({
            result:response.status,
            addsapschedule  : true 
          },
          this.successDialog()); 
        }
        else
        {
            this.errorDialog();
        }
        
      })
        .catch(error => 
        {
          this.errorDialog();
         var err =
         {
            "message": "Exception occured while upserting  the sap release schedule views",
            "EnvironmentName" : window.env.ENVIRONMENT,
            "Component" : "SAP Release Schedule Page",
            "userName": this.props.userEmail,
            "roles": this.props.role != null || '' ? this.props.role : "",
            "exception": error.message,
            "Correlation Id": correlationId
         }
         appInsights.trackException({exception: error,properties : err}); 
        })
      );
      }

    handleYearChange=async(e)=>
    {       
          this.setState({
          
            selectedyear: e.target.value,
            
          });
          await this.reloadData(e.target.value);
    }
     
    async componentDidMount() 
    {
        var quarterlydata='',monthlydata='',supportpackdata='',dualstackdata='',year='',dualstacklabel='',quarterlylabel='',monthlylabel='',supportpacklabel='';
        
        //derive MS financial year . 
        var month=new Date().getMonth() + 1;
        if(month>=7 && month<=12) // new financial year 
        {
           year=new Date().getFullYear() + 1 ;
        }
        else // current financial year which is same as current year 
        {
           year=new Date().getFullYear();
        }

        var token = await fetchToken();
        var correlationId = uuid.v1();
        var bearer = 'Bearer ' + token;
        var sapschedule=window.env.APIMURL + "/api/outage/getsapreleaseschedule?year=" + year;
        
        fetch(sapschedule,{
              method: 'GET',
              headers: {
                  'authorization': bearer,
                  'CorrelationId': correlationId,
                  'Accept' : 'application/json',
                  'Content-Type': 'application/json'
              }})
              .then(response => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);          
              })      
              .then(res => {   
                         
                  this.setState({ sapreleaseschedule:res[1]
                  });

                  for(var i=0;i<(this.state.sapreleaseschedule.length);i++)
                  {
                       switch (this.state.sapreleaseschedule[i]['scheduleName'])
                       {
                         case "sapquarterlydata":
                         quarterlydata=this.state.sapreleaseschedule[i]['scheduleDetail'];
                         quarterlylabel=this.state.sapreleaseschedule[i]['scheduleLabel'];
                        break;
                        case "sapsupportpackdata":
                        supportpackdata=this.state.sapreleaseschedule[i]['scheduleDetail'];
                        supportpacklabel=this.state.sapreleaseschedule[i]['scheduleLabel'];
                        break;
                        case "sapmonthlydata" :
                        monthlydata=this.state.sapreleaseschedule[i]['scheduleDetail'];
                        monthlylabel=this.state.sapreleaseschedule[i]['scheduleLabel'];
                        break;
                        case "sapdualstackdata":
                        dualstackdata=this.state.sapreleaseschedule[i]['scheduleDetail'];
                        dualstacklabel=this.state.sapreleaseschedule[i]['scheduleLabel'];
                        break;
                
                        default:
                        break;
                      }
                  }

            
                   this.setState({
          
          sapquarterlydata:quarterlydata,
          sapsupportpackdata:supportpackdata,
          sapmonthlydata:monthlydata,
          sapdualstackdata:dualstackdata,
          sapdualstacklabel:dualstacklabel,
          sapmonthlyreleaselabel:monthlylabel,
          sapquarterlyreleaselabel:quarterlylabel,
          sapsupportpacklabel:supportpacklabel,
          sapreleaseschedule:''
                   });


                })     
            .catch(error => {
              
              var err = {
                    "message": "Exception occured while fetching the SAP Release Schedule from database",
                    "ComponentId" : COMPONENT_ID,
                    "ComponentName" : COMPONENT_NAME,
                    "EnvironmentName" : window.env.ENVIRONMENT,
                    "ServiceLineName" : SERVICE_LINE,
                    "ServiceName" : SERVICE_NAME,
                    "ServiceOfferingName" : SERVICE_OFFERING,
                    "userName": this.props.userEmail,
                    "roles": this.props.role != null || '' ? this.props.role : "",
                    "exception": error.message,
                    "CorrelationId": correlationId
                   }
                appInsights.trackException({exception: error,properties : err});
                this.setState({errorMessage: error});
            }) 

      
              
    } 

    async reloadData(selectedYear) 
    {
        var quarterlydata='',monthlydata='',supportpackdata='',dualstackdata='',dualstacklabel='',quarterlylabel='',monthlylabel='',supportpacklabel='';
        
        var token = await fetchToken();
        var correlationId = uuid.v1();
        var bearer = 'Bearer ' + token;
        var sapschedule=window.env.APIMURL + "/api/outage/getsapreleaseschedule?year=" + selectedYear;
        
        fetch(sapschedule,{
              method: 'GET',
              headers: {
                  'authorization': bearer,
                  'CorrelationId': correlationId,
                  'Accept' : 'application/json',
                  'Content-Type': 'application/json'
              }})
              .then(response => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);          
              })      
              .then(res => {   
                         
                  this.setState({ sapreleaseschedule:res[1]
                  });

                  for(var i=0;i<(this.state.sapreleaseschedule.length);i++)
                  {
                       switch (this.state.sapreleaseschedule[i]['scheduleName'])
                       {
                         case "sapquarterlydata":
                         quarterlydata=this.state.sapreleaseschedule[i]['scheduleDetail'];
                         quarterlylabel=this.state.sapreleaseschedule[i]['scheduleLabel'];
                        break;
                        case "sapsupportpackdata":
                        supportpackdata=this.state.sapreleaseschedule[i]['scheduleDetail'];
                        supportpacklabel=this.state.sapreleaseschedule[i]['scheduleLabel'];
                        break;
                        case "sapmonthlydata" :
                        monthlydata=this.state.sapreleaseschedule[i]['scheduleDetail'];
                        monthlylabel=this.state.sapreleaseschedule[i]['scheduleLabel'];
                        break;
                        case "sapdualstackdata":
                        dualstackdata=this.state.sapreleaseschedule[i]['scheduleDetail'];
                        dualstacklabel=this.state.sapreleaseschedule[i]['scheduleLabel'];
                        break;
                
                        default:
                        break;
                      }
                  }

            
                   this.setState({
          
          sapquarterlydata:quarterlydata,
          sapsupportpackdata:supportpackdata,
          sapmonthlydata:monthlydata,
          sapdualstackdata:dualstackdata,
          sapdualstacklabel:dualstacklabel,
          sapmonthlyreleaselabel:monthlylabel,
          sapquarterlyreleaselabel:quarterlylabel,
          sapsupportpacklabel:supportpacklabel,
          sapreleaseschedule:''
                   });


                })     
            .catch(error => {
              
              var err = {
                    "message": "Exception occured while fetching the SAP Release Schedule from database",
                    "ComponentId" : COMPONENT_ID,
                    "ComponentName" : COMPONENT_NAME,
                    "EnvironmentName" : window.env.ENVIRONMENT,
                    "ServiceLineName" : SERVICE_LINE,
                    "ServiceName" : SERVICE_NAME,
                    "ServiceOfferingName" : SERVICE_OFFERING,
                    "userName": this.props.userEmail,
                    "roles": this.props.role != null || '' ? this.props.role : "",
                    "exception": error.message,
                    "CorrelationId": correlationId
                   }
                appInsights.trackException({exception: error,properties : err});
                this.setState({errorMessage: error});
            }) 

      
              
    }  

  closeDialog = async () => {
  const { onClickCancel } = this.props;
  if (typeof onClickCancel === "function") { await onClickCancel(); }
  this.setState( {showDialog: false});
}

    componentWillUnmount() {
      this._isMounted = false;
    }

    successDialog()
    {
      this.setState({showDialog : true});
    };

     errorDialog()
     {
        this.setState({showErrorDialog : true});
     };

      flip = async() => 
     {
      this.setState({showNewDialog:!this.state.showNewDialog})
     }
    
     
     methodInvoke = async(event) =>
     {  
      let errors = null; 
     try 
     {   
       event.preventDefault();
       event.persist();
       var editordata='',tablename='',labeltext='';
       var fyear=(this.state.selectedyear!==""?this.state.selectedyear:this.props.event.selectedyear);
           
      if(this.state.editorname==="sapquarterlydata" || event.target.id==="sapquarterlyreleasesave")
      {
          editordata=this.state.sapquarterlydata;
          tablename="sapquarterlydata";
          labeltext=this.state.sapquarterlyreleaselabel;
          //this.state.sapquarterlydata='';
      }
      else if(this.state.editorname==="sapsupportpackdata" || event.target.id==="sapsupportpacksave")
      {
        editordata=this.state.sapsupportpackdata;
        tablename="sapsupportpackdata";
        labeltext=this.state.sapsupportpacklabel;
        //this.state.sapsupportpackdata='';
      }
      else if(this.state.editorname==="sapmonthlydata" || event.target.id==="sapmonthlysave") 
      {
        editordata=this.state.sapmonthlydata;
        tablename="sapmonthlydata";
        labeltext=this.state.sapmonthlyreleaselabel;
        //this.state.sapmonthlydata='';
      }
      else if(this.state.editorname==="sapdualstackdata" || event.target.id==="sapdualstacksave")
      {
        editordata=this.state.sapdualstackdata;
        tablename="sapdualstackdata";
        labeltext=this.state.sapdualstacklabel;
        //this.state.sapdualstackdata='';
      }          
     
      var bodyvalues = JSON.stringify({
        
        scheduledetail: editordata,
        year:parseInt(fyear),
        schedulename: tablename,
        schedulelabel:labeltext
         
      })     
              
       this.setState({addsapschedule:bodyvalues});
       var event = await this.submitAlert(event);       
     
   }
   catch (e) {
     errors = e;
 }
    //Catch Block
 }

     
    handleTextChange(e)
    {
        this.setState({[e.target.name]: e.target.value });    
    }

     render()
     {
       const{sapdualstacklabel,sapmonthlyreleaselabel,sapquarterlyreleaselabel,sapsupportpacklabel}=this.state;
        let maxOffset = 10;
        let thisYear =2020;
        let allYears = [];
        for(let x = 1; x <= maxOffset; x++) {
            allYears.push(thisYear + x)
        }
    
        const yearList = allYears.map((x) => {return(<option key={x} value={x}>{x}</option>)});
        
         //derive MS financial year . 
         var year='';
         var month=new Date().getMonth() + 1;
         if(month>=7 && month<=12) // new financial year 
         {
            year=new Date().getFullYear() + 1 ;
         }
         else // current financial year which is same as current year 
         {
            year=new Date().getFullYear();
         }
         let curryear=year; 
         if (this.state.selectedyear==='')
         {
          this.setState({selectedyear:curryear});
         }
         
         year=year.toString().substring(2);

        return (
        
          <div>

          <AlertDialog title= {'Confirmation Dialog'} message= {'Are you sure you want to submit?'} open={this.state.showNewDialog}
           okButtonLabel = {'Yes'} okButtonType = {ButtonType.Danger} cancelButtonLabel={'No'}
          cancelButtonType={ButtonType.Danger} onClickCancel={this.cancelDialog} onClickOk={this.approveDialog}/>

          <AlertDialog title= {'Success'} cancelButtonLabel={'Ok'}
              message= {'SAP Release Schedule have been sucessfully saved.'} 
              open={this.state.showDialog} onClickCancel={this.closeDialog}
          />
             <form id = "addsapschedule" onSubmit={this.methodInvoke.bind(this)}>  
            
            <div>
                                <label>Select Financial Year : </label>
                                <select value={this.state.selectedyear||curryear} onChange={this.handleYearChange}>  
                                <option value="Select Year" key="Select Year">Select Year</option>                               
                                {yearList}
                                </select> 
                          <div>        
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          </div>
                         <div>
                           {(this.props.role==='BusinessUser SAPReader') ?                           
                            <input   type="text" className="form-control" name="sapquarterlyreleaselabel" readOnly={this.state.readOnly} defaultValue={this.state.sapquarterlyreleaselabel} />
                            :
                            <input   aria-label="sapquarterlyreleaselabel" type="text"  className="form-control" name="sapquarterlyreleaselabel" defaultValue={this.state.sapquarterlyreleaselabel} value={this.state.sapquarterlyreleaselabel ||""} onChange={(e) => this.handleTextChange(e)}/>
                           
                            
                           }
                           <div>        
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          </div>
                      
                              {(this.props.role==='BusinessUser SAP') &&
                                <Button id="sapquarterlyreleasesave" variant="primary"  form = "addsapschedule" type="submit">
                                   Save
                                </Button> 
                             
                              }
                          
                            
                            {(this.props.role==='BusinessUser SAP') ?
                                <CKEditor id='SAPQuarterlyCalendarEditor'
                                 data={this.state.sapquarterlydata!==null|''?this.state.sapquarterlydata:''}    
                                 onChange={(e) => this.onEditorChange(e)}
                                 config={{bodyId:'sapquarterlydata',
                                          removePlugins:'elementspath,scayt,wsc' ,
                                          removeButtons:'Image,Source,About,Maximize,Anchor,Link,Paste,PasteText,PasteFromWord,Cut,Copy' , 
                                          extraPlugins: 'autogrow,easyimage,colordialog,tableresize,colorbutton,font,justify,liststyle',
                                          autogrow_onstartup:true,
                                          autoGrow_maxHeight: 1300
                                                                                                                            
                                        }}   
                                                        
                                />
                                :
                                <CKEditor id='SAPQuarterlyCalendarEditor'
                                data={this.state.sapquarterlydata!==null|''?this.state.sapquarterlydata:''}    
                                onChange={(e) => this.onEditorChange(e)}
                                config={{bodyId:'sapquarterlydata',
                                         removePlugins:'elementspath,scayt,wsc' ,
                                         removeButtons:'Image,Source,About,Maximize,Anchor,Link,Paste,PasteText,PasteFromWord,Cut,Copy' , 
                                         extraPlugins: 'autogrow,easyimage,colordialog,tableresize,colorbutton,font,justify,liststyle',
                                         autogrow_onstartup:true,
                                         autoGrow_maxHeight: 1300
                                                                                                                           
                                       }}
                                readOnly='true'                           
                               /> 
                                }
                            </div>
                          
                          <div>        
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          </div> 
                          {(this.props.role==='BusinessUser SAPReader') ?
                             <input  type="text" className="form-control" name="sapsupportpacklabel" readOnly={this.state.readOnly} defaultValue={this.state.sapsupportpacklabel} />
                             :
                             <input   aria-label="sapsupportpacklabel" type="text"  className="form-control" name="sapsupportpacklabel"  defaultValue={this.state.sapsupportpacklabel} value={this.state.sapsupportpacklabel ||""} onChange={(e) => this.handleTextChange(e)}/>
                            
                            
                          }
                          <div>        
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          </div>
                       
                         {(this.props.role==='BusinessUser SAP') &&
                       <Button  id="sapsupportpacksave"  variant="primary" form = "addsapschedule" type="submit">
                         Save
                       </Button>
                         }
                     
                        <div>
                              {(this.props.role==='BusinessUser SAP') ?
                                <CKEditor id='SAPSupportPackEditor'
                                 data={this.state.sapsupportpackdata!==null|''?this.state.sapsupportpackdata:''}    
                                 onChange={(e) => this.onEditorChange(e)}
                                 config={{bodyId:'sapsupportpackdata',
                                 removePlugins:'elementspath,scayt,wsc' , 
                                 removeButtons:'Image,Source,About,Maximize,Anchor,Link,Paste,PasteText,PasteFromWord,Cut,Copy' ,  
                                 extraPlugins: 'autogrow,easyimage,colordialog,tableresize,colorbutton,font,justify,liststyle',
                                 autogrow_onstartup:true,
                                 autoGrow_maxHeight: 1300
                                 }}
                                />
                                :
                                <CKEditor id='SAPSupportPackEditor'
                                data={this.state.sapsupportpackdata!==null|''?this.state.sapsupportpackdata:''}    
                                onChange={(e) => this.onEditorChange(e)}
                                config={{bodyId:'sapsupportpackdata',
                                removePlugins:'elementspath,scayt,wsc' , 
                                removeButtons:'Image,Source,About,Maximize,Anchor,Link,Paste,PasteText,PasteFromWord,Cut,Copy' ,  
                                extraPlugins: 'autogrow,easyimage,colordialog,tableresize,colorbutton,font,justify,liststyle',
                                autogrow_onstartup:true,
                                autoGrow_maxHeight: 1300
                                }}
                                readOnly='true'
                               />
                              }
                            </div>
                            <div>        
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          </div>
                           {(this.props.role==='BusinessUser SAPReader')?
                            <input  type="text" className="form-control" name="sapmonthlyreleaselabel" readOnly={this.state.readOnly} defaultValue={this.state.sapmonthlyreleaselabel} />
                            :
                            <input  aria-label="sapmonthlyreleaselabel" type="text"  className="form-control" name="sapmonthlyreleaselabel" defaultValue={this.state.sapmonthlyreleaselabel} value={this.state.sapmonthlyreleaselabel ||""} onChange={(e) => this.handleTextChange(e)}/>
                           
                              }
                       <div>        
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          </div>
                         {(this.props.role==='BusinessUser SAP') &&
                       <Button id="sapmonthlyreleasesave" variant="primary" form = "addsapschedule" type="submit">
                         Save
                       </Button>
                         }
                     
                        <div>
                        {(this.props.role==='BusinessUser SAP') ?
                                <CKEditor id='SAPMonthlyCalendarEditor'
                                 data={this.state.sapmonthlydata!==null|''?this.state.sapmonthlydata:''}    
                                 onChange={(e) => this.onEditorChange(e)}
                                 config={{bodyId:'sapmonthlydata',
                                          removePlugins:'elementspath,scayt,wsc' , 
                                          removeButtons:'Image,Source,About,Maximize,Anchor,Link,Paste,PasteText,PasteFromWord,Cut,Copy' ,  
                                          extraPlugins: 'autogrow,easyimage,colordialog,tableresize,colorbutton,font,justify,liststyle',
                                          autogrow_onstartup:true,
                                          autoGrow_maxHeight: 1300 
                                        }}
                                 
                            />
                            :
                            <CKEditor id='SAPMonthlyCalendarEditor'
                            data={this.state.sapmonthlydata!==null|''?this.state.sapmonthlydata:''}    
                            onChange={(e) => this.onEditorChange(e)}
                            config={{bodyId:'sapmonthlydata',
                            removePlugins:'elementspath,scayt,wsc' ,
                            removeButtons:'Image,Source,About,Maximize,Anchor,Link,Paste,PasteText,PasteFromWord,Cut,Copy' ,  
                            extraPlugins: 'autogrow,easyimage,colordialog,tableresize,colorbutton,font,justify,liststyle',
                            autogrow_onstartup:true,
                            autoGrow_maxHeight: 1300   
                          }}
                            readOnly='true'
                            />
                        }
                            </div>
                            <div>        
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          </div>
                           {(this.props.role==='BusinessUser SAPReader') ?
                            <input  type="text" className="form-control" name="sapdualstacklabel" readOnly={this.state.readOnly} defaultValue={this.state.sapdualstacklabel}  />
                            :
                            <input  aria-label="sapdualstacklabel" type="text"  className="form-control" name="sapdualstacklabel"  defaultValue={this.state.sapdualstacklabel} value={this.state.sapdualstacklabel ||""} onChange={(e) => this.handleTextChange(e)}/>
                              }
                       <div>        
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          </div>
                         {(this.props.role==='BusinessUser SAP') &&
                       <Button id="sapdualstacksave"  variant="primary" form = "addsapschedule" type="submit">
                         Save
                       </Button>
                         }
                     
                            <div>
                            {(this.props.role==='BusinessUser SAP') ?
                                <CKEditor id='SAPDualStackCalendarEditor'
                                 data={this.state.sapdualstackdata!==null|''?this.state.sapdualstackdata:''}    
                                 onChange={(e) => this.onEditorChange(e)}
                                 config={{bodyId:'sapdualstackdata',
                                 removePlugins:'elementspath,scayt,wsc' , 
                                 removeButtons:'Image,Source,About,Maximize,Anchor,Link,Paste,PasteText,PasteFromWord,Cut,Copy' , 
                                 extraPlugins: 'autogrow,easyimage,colordialog,tableresize,colorbutton,font,justify,liststyle',
                                 autogrow_onstartup:true,
                                 autoGrow_maxHeight: 1300 
                                }}
                                />
                            :
                            <CKEditor id='SAPDualStackCalendarEditor'
                                 data={this.state.sapdualstackdata!==null|''?this.state.sapdualstackdata:''}    
                                 onChange={(e) => this.onEditorChange(e)}
                                 config={{bodyId:'sapdualstackdata',
                                 removePlugins:'elementspath,scayt,wsc' , 
                                 removeButtons:'Image,Source,About,Maximize,Anchor,Link,Paste,PasteText,PasteFromWord,Cut,Copy' ,  
                                 extraPlugins: 'autogrow,easyimage,colordialog,tableresize,colorbutton,font,justify,liststyle',
                                 autogrow_onstartup:true,
                                 autoGrow_maxHeight: 1300
                                }}
                                 readOnly='true'
                                />
                            }
                            </div>
                               
          </div>
          
            </form>
            </div>
        )
    }
}
export default SAPReleaseSchedule;