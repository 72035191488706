import React, { Component } from "react";
import {fetchToken,fetchRoles} from '../../Util.js';
import uuid from 'react-native-uuid';
import {appInsights} from '../../common/AppInsights/AppInsights';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";
import Select from 'react-select';


export class EditPlantRegionDropDown extends Component{

    constructor(props){
        
        super(props)       
        this.state={readOnly:true,selectedCountryName:null,country:[],
        selectedPlantName:null , selectedRegionName:null,plantNames:[],region:[]
        }
        
    }

    handlePlantNameChange = async(selectedPlantName) => {
      await this.setState(
        { 
            selectedPlantName:(selectedPlantName  == null ? [] : selectedPlantName),
        },
        () => console.log(`Option selected:`, this.state.selectedPlantName)
      );
      await this.props.plantData(selectedPlantName);
    };

    handleRegionChange = async(selectedRegionName) => {
      await this.setState(
        {  
            selectedRegionName:(selectedRegionName  == null ? [] : selectedRegionName),
            selectedCountryName:null,
          },
        () => console.log(`Option selected:`, this.state.selectedRegionName)
      );
      await this.props.regionData(selectedRegionName);
    };


    handleCountryChange = async(selectedCountryName) => {
      await this.setState(
        { selectedCountryName:(selectedCountryName  == null ? [] : selectedCountryName)},
        () => console.log(`Option selected:`, this.state.selectedCountryName)
      );
      await this.props.countryData(selectedCountryName);
    };

    componentDidMount() 
    {
 
      this.GetUserPlantData();
      this.GetRegionCountryData();
    }

    async GetUserPlantData() 
    {
        appInsights.trackTrace({ message: 'EditPlantRegionDropDown Component Loaded.' },
                     {
                         userName: this.props.email,
                         "ComponentId" : COMPONENT_ID,
                         "ComponentName" : COMPONENT_NAME,
                         "EnvironmentName" : window.env.ENVIRONMENT,
                         "ServiceLineName" : SERVICE_LINE,
                         "ServiceName" : SERVICE_NAME,
                         "ServiceOfferingName" : SERVICE_OFFERING,
                         "Correlation Id": uuid.v1(),
                         "Component": 'EditPlantRegionDropdown Page',
                     });
        var token = await fetchToken();
        var bearer = 'Bearer ' + token;
        var correlationId = uuid.v1();
        var apimUrl = window.env.APIMURL + "/api/outage/getplantsbyuser";
        
        fetch(apimUrl,{
            method: 'GET',
            headers: {
                'authorization': bearer,
                'Accept' : 'application/json',
                'CorrelationId': correlationId,
                'Content-Type': 'application/json'
            }})
          .then(response => {
            return response.json();
          })        
          .then(data => {   
            this.setState({plantNames:data});
            })
            .catch(error => {
              var err =
              {
                "message": "Exception occured in EditPlantRegionDropdown page while getting the plant,region,country Mappings from the DB",
                "ComponentId" : COMPONENT_ID,
                "ComponentName" : COMPONENT_NAME,
                "EnvironmentName" : window.env.ENVIRONMENT,
                "ServiceLineName" : SERVICE_LINE,
                "ServiceName" : SERVICE_NAME,
                "ServiceOfferingName" : SERVICE_OFFERING,
                "Component": 'Dropdown Page',
                 "userName": this.props.email,
                 //roles: this.props.role != null || '' ? this.props.role : "",
                 "exception": error.message,
                 "Correlation Id": correlationId
              }
             appInsights.trackException({exception: error,properties : err});
                
            })

      }

      async GetRegionCountryData() 
      {
            appInsights.trackTrace({ message: 'EditPlantRegionDropDown Component Loaded.' },
                     {
                         userName: this.props.email,
                         "ComponentId" : COMPONENT_ID,
                         "ComponentName" : COMPONENT_NAME,
                         "EnvironmentName" : window.env.ENVIRONMENT,
                         "ServiceLineName" : SERVICE_LINE,
                         "ServiceName" : SERVICE_NAME,
                         "ServiceOfferingName" : SERVICE_OFFERING,
                         "Correlation Id": uuid.v1(),
                         "Component": 'EditPlantRegionDropdown Page',
                     });
        var token = await fetchToken();
        var bearer = 'Bearer ' + token;
        var correlationId = uuid.v1();
        var apimUrl = window.env.APIMURL + "/api/outage/getplantsregioncountry";
        
        fetch(apimUrl,{
            method: 'GET',
            headers: {
                'authorization': bearer,
                'Accept' : 'application/json',
                'CorrelationId': correlationId,
                'Content-Type': 'application/json'
            }})
          .then(response => {
            return response.json();
          })        
          .then(data => {   
            this.setState({region:data});
            })
            .catch(error => {
              var err =
              {
                "message": "Exception occured in EditPlantRegionDropdown page while getting the region,country Mappings from the DB",
                "ComponentId" : COMPONENT_ID,
                "ComponentName" : COMPONENT_NAME,
                "EnvironmentName" : window.env.ENVIRONMENT,
                "ServiceLineName" : SERVICE_LINE,
                "ServiceName" : SERVICE_NAME,
                "ServiceOfferingName" : SERVICE_OFFERING,
                "Component": 'Dropdown Page',
                 "userName": this.props.email,
                 //roles: this.props.role != null || '' ? this.props.role : "",
                 "exception": error.message,
                 "Correlation Id": correlationId
              }
             appInsights.trackException({exception: error,properties : err});
                
            })
       }

    returnValues(array)
    {
      const options = array.map(v => ({
        label: v,
        value: v
      }));
      return options;
    }
        render(){  //code change from here .
            const { selectedPlantName,selectedRegionName,selectedCountryName} = this.state;

        /*  const plant = [...new Set(this.state.plantNames.map((p)=>p.plantName))];
            const region = [...new Set(this.state.plantNames.filter( (p)=>{ return (( this.state.selectedPlantName!=null ?((this.state.selectedPlantName.value!=='' && this.state.selectedPlantName.value==p.plantName) ? this.state.selectedPlantName.value : ""):"") )} ).map(x=>x.region))];
            const country = [...new Set(this.state.plantNames.filter((o)=>{ return (( this.state.selectedPlantName!=null?(( this.state.selectedPlantName.value!=='' && this.state.selectedPlantName.value==o.plantName) ? this.state.selectedPlantName.value : ""):"") && ( this.state.selectedRegionName!=null?(( this.state.selectedRegionName.value!=='' && this.state.selectedRegionName.value==o.region) ? this.state.selectedRegionName.value : ""):""))}).map(x=>x.country))];
        */
            //region,country dropdown data . 
            const region = [...new Set(this.state.plantNames.map((p)=>p.region))];
            const country=[...new Set(this.state.region.filter( (location)=>{ return (this.state.selectedRegionName!=null ? (this.state.selectedRegionName.value==location.region ? this.state.selectedRegionName.value : ""):(this.props.event.region!=null?(this.props.event.region==location.region ? this.props.event.region : ""):''))}).map(y=>y.country))];
            
            //plant dropdown data .
            const plant = [...new Set(this.state.plantNames.map((p)=>p.plantName))];
            
            const plantArray = this.returnValues(plant);
            const regionArray = this.returnValues(region);     
            const countryArray = this.returnValues(country);
              
            let mapPlant;
            let mapRegion;
            let mapCountry;
                  
            if(selectedPlantName!=null)
            {    
              mapPlant = selectedPlantName;
              
            } else if(this.props.event.plantName !== undefined && this.props.event.plantName !== null)
            {
                mapPlant = this.returnValues(this.props.event.plantName.split(","));
            }
            else if(this.props.event.factory !== undefined && this.props.event.factory !== null)
            {
                mapPlant = this.returnValues(this.props.event.factory.split(","));
            }

            if(selectedRegionName!=null)
            {    
              mapRegion = selectedRegionName;
              
            } else if(this.props.event.region != undefined && this.props.event.region != null)
            {
                mapRegion = this.returnValues(this.props.event.region.split(","));
            }

            if((selectedRegionName!=null || this.props.event.region!=null) && selectedCountryName!= null )
            {    
               mapCountry = selectedCountryName;
            } else if(this.props.event.country != undefined && this.props.event.country != null)
            {
                mapCountry = this.returnValues(this.props.event.country.split(","));
            }          
            
        
 return(          
    <div>

          <div className="form-group required">
            <label htmlFor="region" class="control-label">Region</label>
              <Select required aria-label="Region" name = "region"
              title="Select Region"
              isDisabled={this.props.readOnly}
              isMulti = {false}              
              value={mapRegion||""}
              onChange={this.handleRegionChange}
              options={ regionArray}
              onBlurResetsInput={false}
              isClearable={true}
              >
              </Select>
              <span style={{color: '#E22335'}}></span>
          </div>

          <div className="form-group">
            <label htmlFor="plant" class="control-label">Plant Name</label>
              <Select aria-label="PlantName" name = "plant"
              title="Select Plant"
              isDisabled={this.props.readOnly}
              isMulti = {false}              
              value={mapPlant||""}
              onChange={this.handlePlantNameChange}
              options={plantArray}
              onBlurResetsInput={false}
              isClearable={true}
              >
              </Select>             
          </div>

          <div className="form-group required">
            <label htmlFor="country" class="control-label">Country</label>
              <Select required aria-label="Country" name = "country"
              title="Select Country"
              isDisabled={this.props.readOnly}
              isMulti = {false}
              value={mapCountry || ""}
              onChange={this.handleCountryChange}
             // options={this.state.selectedRegionName!= null ? countryArray : []}
              options={countryArray}
              onBlurResetsInput={false}
              isClearable={true}
              >
              </Select>
              <span style={{color: '#E22335'}}></span>
          </div>

           
          
            </div>
        )      
    }
}
export default EditPlantRegionDropDown;