import React from 'react';
import icon from '../../common/Images/Search-Icon.png';

const Contact = function()
{
  return (
    <section class="mb-4">

    <div>
    <h2 class="h1-responsive font-weight-bold text-center my-4">Contact us</h2>   
    </div>
    
   
    <p class="text-center w-responsive mx-auto mb-5">Do you have any questions? Please do not hesitate to contact us directly <a  href = "mailto:scoutagehub@microsoft.com">SCOutagehub@microsoft.com</a>. Our team will come back to you within
        a matter of hours to help you.</p>
     </section>   
    );
}
export default Contact