import React, { Component } from "react";
import {Form }  from 'react-bootstrap'
import {fetchToken} from '../../Util.js';
import { reactAI } from "react-appinsights";
import uuid from 'react-native-uuid';
import {appInsights} from '../../common/AppInsights/AppInsights';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";

export class ReactEditRegionDropDown extends Component{

    constructor(props)
    {
       
        super(props)        
        this.state={regions:[],selectedRegion:null,selectedDatacenter:null,selectedCountry:null,readOnly:true,default:null,}
        //this.onChange = this.onChange.bind(this);
    }

    async componentDidMount() {


        appInsights.trackTrace({ message: 'EditRegion Component Loaded.' },
                     {
                         "userName": this.props.email,
                         "ComponentId" : COMPONENT_ID,
                         "ComponentName" : COMPONENT_NAME,
                         "EnvironmentName" : window.env.ENVIRONMENT,
                         "ServiceLineName" : SERVICE_LINE,
                         "ServiceName" : SERVICE_NAME,
                         "ServiceOfferingName" : SERVICE_OFFERING,
                         "Correlation Id": uuid.v1(),
                         "Component": 'Region Page',
                     });
        var token = await fetchToken();
        var bearer = 'Bearer ' + token;
        var correlationId = uuid.v1();
        var apimUrl = window.env.APIMURL + "/api/outage/getregionmappings"; 
        fetch(apimUrl,{
            method: 'GET',
            headers: {
                'authorization': bearer,
                'Accept' : 'application/json',
                'CorrelationId': correlationId,
                'Content-Type': 'application/json'
            }})
          .then(response => {
            return response.json();
          })        
          .then(data => {   
                   
              this.setState({ regions:data
              });
            })
            .catch(error => {
                var err = 
                {
                 "message": "Exception occured in EditRegion page while getting the Country, Region Mappings from the DB",
                 "ComponentId" : COMPONENT_ID,
                 "ComponentName" : COMPONENT_NAME,
                 "EnvironmentName" : window.env.ENVIRONMENT,
                 "ServiceLineName" : SERVICE_LINE,
                 "ServiceName" : SERVICE_NAME,
                 "ServiceOfferingName" : SERVICE_OFFERING,
                 "Component": 'EditRegion Page',
                 "userName": this.props.email,
                        //roles: this.props.role != null || '' ? this.props.role : "",
                 "exception": error.message,
                 "Correlation Id": correlationId
                }
             appInsights.trackException({exception: error,properties : err});
            })
        
    }

        render(){       
        return(          
            <div>
            <label htmlFor="outageTitle">Region</label>
            {/* defaultValue={this.props.event.region} */}
            <Form.Control id="region" title="Select Group" as="select" disabled={this.props.readOnly} value = {this.state.selectedRegion == null | "" ? this.props.event.region :this.state.selectedRegion}  onChange={(event)=>{ this.setState({ selectedRegion:event.target.value,selectedDatacenter:null,selectedCountry:null})}}>                   
                <option value={null}>--Select Region--</option> 
                {                    
                    [...new Set(this.state.regions.map((team)=>team.region))].map((todo, index) => <option key={index} value={todo} as="select" >{todo}</option> )
                }
            </Form.Control>
            <br/>
            <label htmlFor="outageTitle">Country</label>
            <Form.Control id="country" title="Select Service" as="select" disabled={this.props.readOnly} value={this.state.selectedCountry == null | "" ? this.props.event.country : this.state.selectedCountry} onChange={(event)=>{this.setState({selectedCountry:event.target.value,selectedDatacenter:null})}}>
                 <option>--Select Country--</option> 
                {                  
                    [...new Set(this.state.regions.filter((team)=>{ return  team.region == ((this.state.selectedRegion) == null | "" ? this.props.event.region : this.state.selectedRegion)}).map(x=>x.country))].map((todo, index) => <option key={index}  value={todo} as="select" >{todo}</option> )
                }
            </Form.Control>
            <br/>
{/*            
            <label htmlFor="outageTitle">Contract Manufacturer Sites</label>
            <Form.Control id="factory"  as="select"  disabled={this.props.readOnly} value ={this.state.selectedDatacenter == null | "" ? 'JDM1, Suzhou (JDM)' : this.state.selectedDatacenter} onChange={(event)=>{this.setState({selectedDatacenter:event.target.value})}}>
                <option>--Select Factory--</option>
                {                    
                    [...new 
                        Set(
                        this.state.regions.filter(                        
                            (team)=>{ 
                                return ((team.region=='Asia') && (team.country == 'China'))
                            })
                            .map(y=>y.datacenter)
                            )].map((todo, index) => <option value={todo} as="select" >{todo}</option> )
                }
            </Form.Control> */}

        </div>  
        )      
    }
}
export default ReactEditRegionDropDown;