import moment from 'moment';
import React from 'react';
import uuid from 'react-native-uuid';
import { fetchToken } from '../../Util.js';
import { SERVICE_NAME, SERVICE_OFFERING, COMPONENT_NAME, SERVICE_LINE, COMPONENT_ID, SHIPROOM, HighTide } from "../../common/Constants/Constants";
import ReactEditDropDown from '../ServiceDropdown/EditDropDown';
import { appInsights } from '../../common/AppInsights/AppInsights';
import AlertDialog from "../../common/AlertDialog/AlertDialog";
import { Radio, RadioGroup } from 'react-radio-group';
import ReactEditRegionDropDown from '../RegionDropdown/EditRegion';
import { ButtonType } from 'office-ui-fabric-react/lib/Button'
import * as Datetime from 'react-datetime';
import { Button, Modal } from 'react-bootstrap';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactEditFactoryDropDown from '../FactoryDropDown/EditFactory';
import  CKEditor  from 'ckeditor4-react';
import { trackPromise } from 'react-promise-tracker'
import LoadingIndicator from '../../common/PromiseTracker/PromiseTracker';
import Switch from "react-switch";
import ReactEditEventStatusDropDown from '../EventStatusDropDown/EditEventStatus';
import EditApproverDropDown from '../ApproverDropdown/EditApproverDropdown';
import { now } from 'lodash';


class EditModalPopUp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      readOnly: true, isdisabled: false, clicked: '', date: null,
      enddate: null, showDialog: false, outageId: '', showDeleteDialog: false,
      teamGroup: null, teamComponent: null, teamService: null, updateCompleted: false,
      outageTitle: 'valid', outageTitleError: '', description: '', descriptionError: '',
      impact: '', impactError: '', dateError: '', enddateError: '',
      externalcontact: 'valid', externalcontactError: '', engcontact: 'valid', engcontactError: '', pmcontact: 'valid', pmcontactError: ''
      , serviceGroup: null, servicegroupData: null, region: null, country: null, factory: null, factoryData: null,
      division: null, orgName: null, divisionData: null, orgNameData: null, nextsteps: '', nextstepsError: '', status: null, outagetypeclicked: '',
      incidentnumber: '', incidentnumberError: '', timezone: "", severity: '', severityError: '', bridgeurls: '', bridgeurlsError: '', updatedata: '', updatedataError: '', resolutiondata: '', resolutiondataError: '',
      timelinedata: '', timelinedataError: '', istimezoneselectchange: false, localstartdate: null, localenddate: null
    }
    this.handleChange = this.handleChange.bind(this);
    this.checkErrors = this.checkErrors.bind(this);
    this.methodInvoke = this.methodInvoke.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.returnArrayValues = this.returnArrayValues.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onEndChange = this.onEndChange.bind(this);
    this.flip = this.flip.bind(this);
    this.handleOutageTypeChange = this.handleOutageTypeChange.bind(this);
  }

  ToggleReadonly = () => {

    this.setState({ readOnly: !this.state.readOnly });
    this.setState({ isdisabled: !this.state.isdisabled });
  }

  flip = async () => {
    this.setState({ showDialog: !this.state.showDialog })
  }

  handleOnChange(e) {

    this.setState({ clicked: e })
  }

  handleOutageTypeChange(e) {

    this.setState({ outagetypeclicked: e })
  }

  // handleChange(e){

  //   this.setState({[e.target.id]: e.target.value})    
  // }



  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value, [`${e.target.name}Error`]: '' })
  }

  onChange(date) {      //this.preventDefault(); 
    //this.setState({ date })
    this.setState({ date: date, dateError: '' })
  }

  closeDialog() {
    this.setState({ showDialog: false });
  }

  closeDeleteDialog = async () => {
    this.setState({ showDeleteDialog: false, updateCompleted: false, });
    this.props.closeModalPopUpFunc(false);
  }

  deleteDialog = async () => {
    this.setState({ showDialog: false });
    await this.deleteConfirmation(this.state.outageId);
    this.setState({ showDeleteDialog: true });
    //this.props.closeModalPopUpFunc(false);
  }

  cancelDialog = async () => {
    this.setState({ showDialog: false });
    this.props.closeModalPopUpFunc(true);
  }

  onEndChange(date) {
    this.setState({ enddate: date, enddateError: '' })
  }

  returnArrayValues(event) {
    let opts = [], opt;
    for (let i = 0, len = event.length; i < len; i++) {
      opt = event[i].value;
      opts.push(opt);
    }
    return opts.join().length > 0 ? opts.join() : event.value;
  }

  convertDateToUTC(date) {
    var now_utc = date;
    now_utc.setHours(now_utc.getHours() + 5);
    now_utc.setMinutes(now_utc.getMinutes() + 30);
    return now_utc.toUTCString();
  }
  formatUTCDate(date) {
    var now_utc = date;
    return now_utc.toUTCString('MM/DD/YYYY hh:mm:ss A');
  }

  // convert CST date to UTC date .
  convertCSTDateToUTC(date) {
    var now_cst = date;
    now_cst.setHours(now_cst.getHours() - 8);
    return now_cst;
  }
  // convert PST date to UTC date .
  convertPSTDateToUTC(date) {
    var now_pst = date;
    var isDST = this.checkDateForDaylightSaving(date);

    if (isDST === true) {
      now_pst.setHours(now_pst.getHours() + 7); //during daylight saving months .
    }
    else {
      now_pst.setHours(now_pst.getHours() + 8);
    }
    return now_pst;
  }
  // convert UTC date to CST date .
  convertUTCDateToCST(date) {
    var now_utc = date;
    now_utc.setHours(now_utc.getHours() + 8);
    return now_utc;
  }
  // convert UTC date to PST date . 
  convertUTCDateToPST(date) {
    var now_utc = date;
    var isDST = this.checkDateForDaylightSaving(date);
    if (isDST === true) {
      now_utc.setHours(now_utc.getHours() - 7);
    }
    else {
      now_utc.setHours(now_utc.getHours() - 8);
    }
    return now_utc;
  }
  convertPSTDateToCST(date) {
    var now_pst = date;
    var isDST = this.checkDateForDaylightSaving(date);
    if (isDST === true) {
      now_pst.setHours(now_pst.getHours() + 7);
      now_pst.setHours(now_pst.getHours() + 8);
    }
    else {
      now_pst.setHours(now_pst.getHours() + 8);
      now_pst.setHours(now_pst.getHours() + 8);
    }
    //return now_cst.toUTCString();
    return now_pst;
  }
  convertCSTDateToPST(date) {
    var now_cst = date;
    var isDST = this.checkDateForDaylightSaving(date);

    if (isDST === true) {
      now_cst.setHours(now_cst.getHours() - 8);
      now_cst.setHours(now_cst.getHours() - 7);
    }
    else {
      now_cst.setHours(now_cst.getHours() - 8);
      now_cst.setHours(now_cst.getHours() - 8);
    }
    //return now_cst.toUTCString();
    return now_cst;
  }

  //calculate daylight saving start date , end date  in a year . 

  calculateDSTStartDate() {
    const days = {
      Mon: 1,
      Tue: 2,
      Wed: 3,
      Thu: 4,
      Fri: 5,
      Sat: 6,
      Sun: 0
    }

    var weekNumber, day, monthMoment;
    var year = new Date().getFullYear();
    var marchdate = year + '-03-01';
    var dststartdate;

    // 2nd Sunday of March . 
    weekNumber = 2;
    day = days.Sun;
    monthMoment = moment(marchdate);

    let m = monthMoment.clone()
      .startOf('month')
      .day(day)
    if (m.month() !== monthMoment.month()) {
      m.add(7, 'd');
    }
    //dststartdate= m.add(7 * (weekNumber - 1), 'd').format('YYYY-MM-DDTHH:mm:ss.fff Z');
    dststartdate = m.add(7 * (weekNumber - 1), 'd');
    dststartdate = moment(dststartdate).add(2, 'hours');
    return dststartdate;

  }

  calculateDSTEndDate() {
    const days = {
      Mon: 1,
      Tue: 2,
      Wed: 3,
      Thu: 4,
      Fri: 5,
      Sat: 6,
      Sun: 0
    }

    var weekNumber, day, monthMoment;
    var year = new Date().getFullYear();
    var novdate = year + '-11-01';
    var dstenddate;

    // 1st Sunday of November .  
    weekNumber = 1;
    day = days.Sun;
    monthMoment = moment(novdate);

    let m = monthMoment.clone()
      .startOf('month')
      .day(day)
    if (m.month() !== monthMoment.month()) {
      m.add(7, 'd');
    }
    //dstenddate= m.add(7 * (weekNumber - 1), 'd').format('YYYY-MM-DDTHH:mm:ss.fff Z');
    dstenddate = m.add(7 * (weekNumber - 1), 'd');
    dstenddate = moment(dstenddate).add(2, 'hours');
    return dstenddate;

  }
  //check daylight saving is active or not . 
  checkDateForDaylightSaving(date) {
    var DaylightStartDate = this.calculateDSTStartDate();
    var DaylightEndDate = this.calculateDSTEndDate();
    if (date >= DaylightStartDate && date <= DaylightEndDate) {
      return true;
    }
    else {
      return false;
    }
  }

  getLocalisedDate() {

    if (this.state.istimezoneselectchange === false)  //when timezone radio button in Edit UI screen is not changed by user . 
    {
      /* if (this.props.event.Timezone==="PST")
       {
          if(this.state.localstartdate===null)
          {
          this.setState({localstartdate:Date.parse(new Date(this.convertUTCDateToPST(new Date(this.props.event.start))))});  
          this.props.event.start=new Date(this.convertUTCDateToPST(new Date(this.props.event.start))); 
          }
          if(this.state.localenddate===null)
          {
          this.setState({localenddate:Date.parse(new Date(this.convertUTCDateToPST(new Date(this.props.event.end))))});  
          this.props.event.end=new Date(this.convertUTCDateToPST(new Date(this.props.event.end)));  
          }
                   
       }
       else if(this.props.event.Timezone==="CST")
       {            
          if(this.state.localstartdate===null)
          {
            this.setState({localstartdate:Date.parse(new Date(this.convertUTCDateToCST(new Date(this.props.event.start))))});  
            this.props.event.start=new Date(this.convertUTCDateToCST(new Date(this.props.event.start)));
          }
          if(this.state.localenddate===null)
          {
            this.setState({localenddate:Date.parse(new Date(this.convertUTCDateToCST(new Date(this.props.event.end))))});  
            this.props.event.end=new Date(this.convertUTCDateToCST(new Date(this.props.event.end)));  
          }
 
       }
       else if(this.props.event.Timezone==="UTC")
       {            
          if(this.state.localstartdate===null)
          {
            this.setState({localstartdate:Date.parse(new Date(this.props.event.start))});  
            
          }
          if(this.state.localenddate===null)
          {
            this.setState({localenddate:Date.parse(new Date(this.props.event.end))});  
           
          }
 
       } */

      if (this.state.localstartdate === null || Number.isNaN(this.state.localstartdate) || this.state.localstartdate === undefined || this.state.localstartdate === '') {
        //  this.setState({localstartdate:Date.parse(new Date(this.props.event.start))});  
        this.state.localstartdate = Date.parse(new Date(this.props.event.start));

      }
      if (this.state.localenddate === null || Number.isNaN(this.state.localenddate) || this.state.localenddate === undefined || this.state.localenddate === '') {
        //this.setState({localenddate:Date.parse(new Date(this.props.event.end))});  
        this.state.localenddate = Date.parse(new Date(this.props.event.end));

      }

    }



  }

  onTimezoneChange = async (e) => {
    this.selectedtimezoneEventData(e.target.value);
    this.setState({ timezone: e.target.value, istimezoneselectchange: true });

    console.log(this.state.timezone);
  }

  selectedtimezoneEventData(selectedtimezone) {
    var prevselectedtimezone = this.props.event.Timezone;

    if (prevselectedtimezone === "UTC" || prevselectedtimezone === null || prevselectedtimezone === '' || prevselectedtimezone === undefined) {
      if (selectedtimezone === "CST") {
        if (this.state.date === null && this.state.enddate === null) {
          this.props.event.start = new Date(this.convertUTCDateToCST(new Date(this.props.event.start)));
          this.props.event.end = new Date(this.convertUTCDateToCST(new Date(this.props.event.end)));
        }
        else if (this.state.date !== null && this.state.enddate === null) {
          this.setState({ date: Date.parse(new Date(this.convertUTCDateToCST(new Date(this.state.date)))) });
          this.props.event.start = new Date(this.convertUTCDateToCST(new Date(this.state.date)));
          this.props.event.end = new Date(this.convertUTCDateToCST(new Date(this.props.event.end)));
        }
        else if (this.state.date === null && this.state.enddate !== null) {
          this.setState({ enddate: Date.parse(new Date(this.convertUTCDateToCST(new Date(this.state.enddate)))) });
          this.props.event.start = new Date(this.convertUTCDateToCST(new Date(this.props.event.start)));
          this.props.event.end = new Date(this.convertUTCDateToCST(new Date(this.state.enddate)));
        }
        else if (this.state.date !== null && this.state.enddate !== null) {
          this.setState({
            date: Date.parse(new Date(this.convertUTCDateToCST(new Date(this.state.date)))),
            enddate: Date.parse(new Date(this.convertUTCDateToCST(new Date(this.state.enddate))))
          });
          this.props.event.start = new Date(this.convertUTCDateToCST(new Date(this.state.date)));
          this.props.event.end = new Date(this.convertUTCDateToCST(new Date(this.state.enddate)));
        }

        this.props.event.Timezone = selectedtimezone;
      }
      else if (selectedtimezone === "PST") {
        if (this.state.date === null && this.state.enddate === null) {
          this.props.event.start = new Date(this.convertUTCDateToPST(new Date(this.props.event.start)));
          this.props.event.end = new Date(this.convertUTCDateToPST(new Date(this.props.event.end)));
        }
        else if (this.state.date !== null && this.state.enddate === null) {
          this.setState({ date: Date.parse(new Date(this.convertUTCDateToPST(new Date(this.state.date)))) });
          this.props.event.start = new Date(this.convertUTCDateToPST(new Date(this.state.date)));
          this.props.event.end = new Date(this.convertUTCDateToPST(new Date(this.props.event.end)));
        }
        else if (this.state.date === null && this.state.enddate !== null) {
          this.setState({ enddate: Date.parse(new Date(this.convertUTCDateToPST(new Date(this.state.enddate)))) });
          this.props.event.start = new Date(this.convertUTCDateToPST(new Date(this.props.event.start)));
          this.props.event.end = new Date(this.convertUTCDateToPST(new Date(this.state.enddate)));
        }
        else if (this.state.date !== null && this.state.enddate !== null) {
          this.setState({
            date: Date.parse(new Date(this.convertUTCDateToPST(new Date(this.state.date)))),
            enddate: Date.parse(new Date(this.convertUTCDateToPST(new Date(this.state.enddate))))
          });
          this.props.event.start = new Date(this.convertUTCDateToPST(new Date(this.state.date)));
          this.props.event.end = new Date(this.convertUTCDateToPST(new Date(this.state.enddate)));
        }

        this.props.event.Timezone = selectedtimezone;
      }
      else if (selectedtimezone === "UTC") {
        if (this.state.date !== null) {
          this.setState({ date: new Date(this.state.date) });
          this.props.event.start = new Date(this.state.date);
        }
        else if (this.state.enddate !== null) {
          this.setState({ enddate: new Date(this.state.enddate) });
          this.props.event.end = new Date(this.state.enddate);
        }
        this.props.event.Timezone = selectedtimezone;
      }
    }
    else if (prevselectedtimezone === "CST") {
      if (selectedtimezone === "UTC") {
        if (this.state.date === null && this.state.enddate === null) {
          this.props.event.start = new Date(this.convertCSTDateToUTC(new Date(this.props.event.start)));
          this.props.event.end = new Date(this.convertCSTDateToUTC(new Date(this.props.event.end)));
        }
        else if (this.state.date !== null && this.state.enddate === null) {
          this.setState({ date: Date.parse(new Date(this.convertCSTDateToUTC(new Date(this.state.date)))) });
          this.props.event.start = new Date(this.convertCSTDateToUTC(new Date(this.state.date)));
          this.props.event.end = new Date(this.convertCSTDateToUTC(new Date(this.props.event.end)));
        }
        else if (this.state.date === null && this.state.enddate !== null) {
          this.setState({ enddate: Date.parse(new Date(this.convertCSTDateToUTC(new Date(this.state.enddate)))) });
          this.props.event.start = new Date(this.convertCSTDateToUTC(new Date(this.props.event.start)));
          this.props.event.end = new Date(this.convertCSTDateToUTC(new Date(this.state.enddate)));
        }
        else if (this.state.date !== null && this.state.enddate !== null) {
          this.setState({
            date: Date.parse(new Date(this.convertCSTDateToUTC(new Date(this.state.date)))),
            enddate: Date.parse(new Date(this.convertCSTDateToUTC(new Date(this.state.enddate))))
          });
          this.props.event.start = new Date(this.convertCSTDateToUTC(new Date(this.state.date)));
          this.props.event.end = new Date(this.convertCSTDateToUTC(new Date(this.state.enddate)));
        }

        this.props.event.Timezone = selectedtimezone;

      }
      else if (selectedtimezone === "PST") {
        if (this.state.date === null && this.state.enddate === null) {
          this.props.event.start = new Date(this.convertCSTDateToPST(new Date(this.props.event.start)));
          this.props.event.end = new Date(this.convertCSTDateToPST(new Date(this.props.event.end)));
        }
        else if (this.state.date !== null && this.state.enddate === null) {
          this.setState({ date: Date.parse(new Date(this.convertCSTDateToPST(new Date(this.state.date)))) });
          this.props.event.start = new Date(this.convertCSTDateToPST(new Date(this.state.date)));
          this.props.event.end = new Date(this.convertCSTDateToPST(new Date(this.props.event.end)));
        }
        else if (this.state.date === null && this.state.enddate !== null) {
          this.setState({ enddate: Date.parse(new Date(this.convertCSTDateToPST(new Date(this.state.enddate)))) });
          this.props.event.start = new Date(this.convertCSTDateToPST(new Date(this.props.event.start)));
          this.props.event.end = new Date(this.convertCSTDateToPST(new Date(this.state.enddate)));
        }
        else if (this.state.date !== null && this.state.enddate !== null) {
          this.setState({
            date: Date.parse(new Date(this.convertCSTDateToPST(new Date(this.state.date)))),
            enddate: Date.parse(new Date(this.convertCSTDateToPST(new Date(this.state.enddate))))
          });
          this.props.event.start = new Date(this.convertCSTDateToPST(new Date(this.state.date)));
          this.props.event.end = new Date(this.convertCSTDateToPST(new Date(this.state.enddate)));
        }

        this.props.event.Timezone = selectedtimezone;

      }
      else if (selectedtimezone === "CST") {
        if (this.state.date !== null) {
          this.setState({ date: new Date(this.state.date) });
          this.props.event.start = new Date(this.state.date);
        }
        else if (this.state.enddate !== null) {
          this.setState({ enddate: new Date(this.state.enddate) });
          this.props.event.end = new Date(this.state.enddate);
        }
        this.props.event.Timezone = selectedtimezone;
      }
    }
    else if (prevselectedtimezone === "PST") {
      if (selectedtimezone === "UTC") {

        if (this.state.date === null && this.state.enddate === null) {
          this.props.event.start = new Date(this.convertPSTDateToUTC(new Date(this.props.event.start)));
          this.props.event.end = new Date(this.convertPSTDateToUTC(new Date(this.props.event.end)));
        }
        else if (this.state.date !== null && this.state.enddate === null) {
          this.setState({ date: Date.parse(new Date(this.convertPSTDateToUTC(new Date(this.state.date)))) });
          this.props.event.start = new Date(this.convertPSTDateToUTC(new Date(this.state.date)));
          this.props.event.end = new Date(this.convertPSTDateToUTC(new Date(this.props.event.end)));
        }
        else if (this.state.date === null && this.state.enddate !== null) {
          this.setState({ enddate: Date.parse(new Date(this.convertPSTDateToUTC(new Date(this.state.enddate)))) });
          this.props.event.start = new Date(this.convertPSTDateToUTC(new Date(this.props.event.start)));
          this.props.event.end = new Date(this.convertPSTDateToUTC(new Date(this.state.enddate)));
        }
        else if (this.state.date !== null && this.state.enddate !== null) {
          this.setState({
            date: Date.parse(new Date(this.convertPSTDateToUTC(new Date(this.state.date)))),
            enddate: Date.parse(new Date(this.convertPSTDateToUTC(new Date(this.state.enddate))))
          });
          this.props.event.start = new Date(this.convertPSTDateToUTC(new Date(this.state.date)));
          this.props.event.end = new Date(this.convertPSTDateToUTC(new Date(this.state.enddate)));
        }

        this.props.event.Timezone = selectedtimezone;

      }
      else if (selectedtimezone === "CST") {
        if (this.state.date === null && this.state.enddate === null) {
          this.props.event.start = new Date(this.convertPSTDateToCST(new Date(this.props.event.start)));
          this.props.event.end = new Date(this.convertPSTDateToCST(new Date(this.props.event.end)));
        }
        else if (this.state.date !== null && this.state.enddate === null) {
          this.setState({ date: Date.parse(new Date(this.convertPSTDateToCST(new Date(this.state.date)))) });
          this.props.event.start = new Date(this.convertPSTDateToCST(new Date(this.state.date)));
          this.props.event.end = new Date(this.convertPSTDateToCST(new Date(this.props.event.end)));
        }
        else if (this.state.date === null && this.state.enddate !== null) {
          this.setState({ enddate: Date.parse(new Date(this.convertPSTDateToCST(new Date(this.state.enddate)))) });
          this.props.event.start = new Date(this.convertPSTDateToCST(new Date(this.props.event.start)));
          this.props.event.end = new Date(this.convertPSTDateToCST(new Date(this.state.enddate)));
        }
        else if (this.state.date !== null && this.state.enddate !== null) {
          this.setState({
            date: Date.parse(new Date(this.convertPSTDateToCST(new Date(this.state.date)))),
            enddate: Date.parse(new Date(this.convertPSTDateToCST(new Date(this.state.enddate))))
          });
          this.props.event.start = new Date(this.convertPSTDateToCST(new Date(this.state.date)));
          this.props.event.end = new Date(this.convertPSTDateToCST(new Date(this.state.enddate)));
        }

        this.props.event.Timezone = selectedtimezone;

      }
      else if (selectedtimezone === "PST") {
        if (this.state.date !== null) {
          this.setState({ date: new Date(this.state.date) });
          this.props.event.start = new Date(this.state.date);
        }
        else if (this.state.enddate !== null) {
          this.setState({ enddate: new Date(this.state.enddate) });
          this.props.event.end = new Date(this.state.enddate);
        }
        this.props.event.Timezone = selectedtimezone;
      }
    }

  }

  checkErrors = () => {
    const validEmailRegex =
      RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const { outageTitle, externalcontact, engcontact, pmcontact, incidentnumber, timezone, severity, bridgeurls } = this.state;
    var obj = {};
    var date = this.state.date;
    var impact = this.state.impact;
    var description = this.state.description;
    var enddate = this.state.enddate;
    date = (date == null) ? this.props.event.start : date;
    impact = (impact === "" || impact === null || impact === undefined) ? this.props.event.outageImpact : impact;
    description = (description === "" || description === null || description === undefined) ? this.props.event.outageDescription : description;
    enddate = (enddate == null) ? this.props.event.end : enddate;
    var updttimezone = (timezone === "") ? this.props.event.Timezone : timezone;

    if (this.state.date === null && this.state.timezone === '') // this means that in EditEvent screen startdate value not changed .
    {
      if (updttimezone === "PST") {
        date = Date.parse(new Date(this.convertUTCDateToPST(new Date(date))));
      }
      else if (updttimezone === "CST") {
        date = Date.parse(new Date(this.convertUTCDateToCST(new Date(date))));
      }
    }

    if (this.state.enddate == null && this.state.timezone === '') // this means that in EditEvent screen enddate value not changed .
    {
      if (updttimezone === "PST") {
        enddate = Date.parse(new Date(this.convertUTCDateToPST(new Date(enddate))));
      }
      else if (updttimezone === "CST") {
        enddate = Date.parse(new Date(this.convertUTCDateToCST(new Date(enddate))));
      }

    }

    const { userEmail } = this.props;
    let validEmail = (userEmail != undefined ? userEmail.toLowerCase().includes("microsoft.com") : null);
    var pmcontactname = (this.state.pmcontact === '' | null | 'valid') ? this.props.event.pmContact : this.state.pmcontact;
    var incnum = (incidentnumber === "" | null | undefined) ? this.props.event.incidentNumber : incidentnumber;
    var sevnum = (severity === "" | null | undefined) ? this.props.event.severity : severity;
    var bridgeurl = (bridgeurls === "" | null | undefined) ? this.props.event.bridgeurls : bridgeurls;

    if (outageTitle.length === 0) {
      this.setState({
        outageTitleError: 'Title field should not be blank.'
      })
      obj.prop = ++obj.prop || 0;
    }
    if (description.length === 0) {
      this.setState({
        descriptionError: 'Description field should not be blank.'
      })
      obj.prop = ++obj.prop || 0;
    }

    if (impact.length === 0) {
      this.setState({
        impactError: 'Impact field should not be blank.'
      })
      obj.prop = ++obj.prop || 0;
    }


    if (date == '') {
      this.setState({
        dateError: 'Start Date field should not be blank.'
      })
      obj.prop = ++obj.prop || 0;
    }
      if (enddate == '') {
        this.setState({
          enddateError: 'End Date field should not be blank.'
        })
        obj.prop = ++obj.prop || 0;
      } else if (date && enddate) {
      if (Date.parse(new Date(this.convertDateToUTC(new Date(date)))) >= Date.parse(new Date(this.convertDateToUTC(new Date(enddate))))) {
        this.setState({
          enddateError: 'End Datetime should be greater than Start Datetime.'
        })
        obj.prop = ++obj.prop || 0;
      }
    }
    if (!validEmail) {
      if (externalcontact.length == 0) {

        this.setState({
          externalcontactError: 'External Contact field should not be blank.'
        })
        obj.prop = ++obj.prop || 0;
      }
      else if (externalcontact !== 'valid') {
        var emailError = validEmailRegex.test(externalcontact) ? '' : 'Email is not valid!';
        this.setState({
          externalcontactError: emailError
        })
        if (emailError !== '') {
          obj.prop = ++obj.prop || 0;
        }
      }
    }


    if (validEmail) {
      if (engcontact.length == 0) {

        this.setState({
          engcontactError: 'Point of Contact field should not be blank.'
        })
        obj.prop = ++obj.prop || 0;
      }
      else if (engcontact !== 'valid') {
        var emailError = validEmailRegex.test(engcontact) ? '' : 'Email is not valid!';
        this.setState({
          engcontactError: emailError
        })
        if (emailError !== '') {
          obj.prop = ++obj.prop || 0;
        }

      }

      if (pmcontactname.length === 0) {

        this.setState({
          pmcontactError: 'Approver Contact field should not be blank.'
        })
        obj.prop = ++obj.prop || 0;
      }
      else if (pmcontact !== 'valid') {
        var emailError = validEmailRegex.test(pmcontact.value) ? '' : 'Email is not valid!';
        this.setState({
          pmcontactError: emailError
        })
        if (emailError !== '') {
          obj.prop = ++obj.prop || 0;
        }
      }
    }

    if (this.props.event.outageType === "UnPlanned") {
      if (incnum === null || incnum === undefined || incnum === '') {
        this.setState({
          incidentnumberError: 'Incident number should be mentioned for Unplanned event.'
        })
        obj.prop = ++obj.prop || 0;
      }
      else if (incnum.length === 0) {
        this.setState({
          incidentnumberError: 'Incident number should be mentioned for Unplanned event.'
        })
        obj.prop = ++obj.prop || 0;
      }
      if (sevnum === null || sevnum === undefined || sevnum === '') {
        this.setState({
          severityError: 'Severity of the Incident number should be mentioned for Unplanned event.'
        })
        obj.prop = ++obj.prop || 0;
      }
      else if (sevnum.length === 0) {
        this.setState({
          severityError: 'Severity of the Incident number should be mentioned for Unplanned event.'
        })
        obj.prop = ++obj.prop || 0;
      }
      if (this.props.event.eventstatus.toLowerCase() !== "mitigation") {
        if (bridgeurl === null || bridgeurl === undefined || bridgeurl === '') {
          this.setState({
            bridgeurlsError: 'Bridgeurl of the Incident number should be mentioned for Unplanned event.'
          })
          obj.prop = ++obj.prop || 0;
        }
        else if (bridgeurl.length === 0) {
          this.setState({
            bridgeurlsError: 'Bridgeurl of the Incident number should be mentioned for Unplanned event.'
          })
          obj.prop = ++obj.prop || 0;
        }
      }

    }


    if (obj.prop >= 0) {
      return true;
    }
    else {
      return false;
    }
  }
  renderError(name) {
    const { outageTitleError, descriptionError, impactError, externalcontactError, engcontactError, pmcontactError,
      dateError, enddateError, alldayeventError, teamGroupError, teamServiceError, teamComponentError, serviceGroupError, factoryError, divisionError, orgNameError, outagetypetoggleError, incidentnumberError, severityError, bridgeurlsError } = this.state;
    switch (name) {
      case 'outageTitle':
        if (outageTitleError) {
          return <div style={{ backgroundColor: '#f8d7da' }}>{outageTitleError}</div>;
        }
        break;
      case 'servicegroup':
        if (serviceGroupError) {
          return <div style={{ backgroundColor: '#f8d7da' }}>{serviceGroupError}</div>;
        }
        break;
      case 'teamgroup':
        if (teamGroupError) {
          return <div style={{ backgroundColor: '#f8d7da' }}>{teamGroupError}</div>;
        }
        break;
      case 'teamservice':
        if (teamServiceError) {
          return <div style={{ backgroundColor: '#f8d7da' }}>{teamServiceError}</div>;
        }
        break;
      // case 'teamcomponent':
      //   if (teamComponentError) {
      //         return <div style ={{backgroundColor:'#f8d7da'}}>{teamComponentError}</div>;
      //     }
      //         break;
      case 'description':
        if (descriptionError) {
          return <div style={{ backgroundColor: '#f8d7da' }}>{descriptionError}</div>;
        }
        break;

      case 'impact':
        if (impactError) {
          return <div style={{ backgroundColor: '#f8d7da' }}>{impactError}</div>;
        }
        break;
      // case  'organization' :
      //     if (organizationError) {
      //       return <div style ={{backgroundColor:'#f8d7da'}}>{organizationError}</div>;
      //     }
      //   break;

      case 'alldayevent':
        if (alldayeventError) {
          return <div style={{ backgroundColor: '#f8d7da' }}>{alldayeventError}</div>;
        }
        break;


      case 'date':
        if (dateError) {
          return <div style={{ backgroundColor: '#f8d7da' }}>{dateError}</div>;
        }
        break;

      case 'enddate':
        if (enddateError) {
          return <div style={{ backgroundColor: '#f8d7da' }}>{enddateError}</div>;
        }
        break;

      case 'externalcontact':
        if (externalcontactError) {
          return <div style={{ backgroundColor: '#f8d7da' }}>{externalcontactError}</div>;
        }
        break;
      case 'engcontact':
        if (engcontactError) {
          return <div style={{ backgroundColor: '#f8d7da' }}>{engcontactError}</div>;
        }
        break;
      case 'pmcontact':
        if (pmcontactError) {
          return <div style={{ backgroundColor: '#f8d7da' }}>{pmcontactError}</div>;
        }
        break;
      case 'factory':
        if (factoryError) {
          return <div style={{ backgroundColor: '#f8d7da' }}>{factoryError}</div>;
        }
        break;
      case 'division':
        if (divisionError) {
          return <div style={{ backgroundColor: '#f8d7da' }}>{divisionError}</div>;
        }
        break;
      case 'organization':
        if (orgNameError) {
          return <div style={{ backgroundColor: '#f8d7da' }}>{orgNameError}</div>;
        }
        break;
      case 'outagetypetoggle':
        if (outagetypetoggleError) {
          return <div style={{ backgroundColor: '#f8d7da' }}>{outagetypetoggleError}</div>;
        }
        break;
      case 'incidentnumber':
        if (incidentnumberError) {
          return <div style={{ backgroundColor: '#f8d7da' }}>{incidentnumberError}</div>;
        }
        break;
      case 'severity':
        if (severityError) {
          return <div style={{ backgroundColor: '#f8d7da' }}>{severityError}</div>;
        }
        break;
      case 'bridgeurls':
        if (bridgeurlsError) {
          return <div style={{ backgroundColor: '#f8d7da' }}>{bridgeurlsError}</div>;
        }
        break;
      default:
        break;
      //return null;

    }
  }

  methodInvoke = async (event) => {
    let errors = null;
    let prop = this;
    event.preventDefault();
    event.persist();
    try {
      if (this.checkErrors()) {
        this.props.closeModalPopUpFunc(true);
        return;
      }
      const title = event.target.outageTitle.value;
      var startdate, EndTime;
      var desc = ((prop.state.description !== null && prop.state.description !== undefined) ? prop.state.description : (prop.props.event.outageDescription !== "" ? prop.props.event.outageDescription : ''));
      var impact = ((prop.state.impact !== null && prop.state.impact !== undefined) ? prop.state.impact : (prop.props.event.outageImpact !== "" ? prop.props.event.outageImpact : ''));
      var selectedtimezone = (prop.state.timezone !== "" ? prop.state.timezone : (prop.props.event.Timezone !== "" ? prop.props.event.Timezone : ''));
      var start = prop.state.date == null ? new Date(prop.props.event.start) : new Date(prop.state.date);
      var end = prop.state.enddate == null ? new Date(prop.props.event.end) : new Date(prop.state.enddate);
      startdate = moment(start, 'YYYY-MM-DDTHH:mm:ss.fff Z');
      startdate = startdate.format('YYYY-MM-DDTHH:mm:ss');
      EndTime = moment(end, 'YYYY-MM-DDTHH:mm:ss.fff Z');
      EndTime = EndTime.format('YYYY-MM-DDTHH:mm:ss');
      if(this.state.outagetypeclicked===false && this.state.status.toLowerCase()!=="mitigation")
      {
        EndTime=  moment().endOf('day');
        EndTime = EndTime.format('YYYY-MM-DDTHH:mm:ss');     
      }
      console.log("description " + desc);
      console.log(prop.state);

      /*  if(prop.state.date === null && prop.state.timezone==='') // this means that in EditEvent screen startdate value not changed .
        {
              if (selectedtimezone==="PST")
              {
                  startdate=Date.parse(new Date(this.convertUTCDateToPST(new Date(start))));
                  startdate = moment(new Date(startdate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                  startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");                
              }
              else if(selectedtimezone==="CST")
              {              
                  startdate=Date.parse(new Date(this.convertUTCDateToCST(new Date(start))));
                  startdate = moment(new Date(startdate),"YYYY-MM-DDTHH:mm:ss.fff Z");
                  startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");              
              }
              else
              {
                startdate = moment(start, 'YYYY-MM-DDTHH:mm:ss.fff Z');
                startdate = startdate.format('YYYY-MM-DDTHH:mm:ss');  
              }
        }
        else 
        {
              startdate = moment(start, 'YYYY-MM-DDTHH:mm:ss.fff Z');
              startdate = startdate.format('YYYY-MM-DDTHH:mm:ss');           
        }
  
        if(prop.state.enddate == null && prop.state.timezone==='') // this means that in EditEvent screen enddate value not changed .
        {
              if (selectedtimezone==="PST")
              {
                  EndTime=Date.parse(new Date(this.convertUTCDateToPST(new Date(end))));
                  EndTime = moment(new Date(EndTime),"YYYY-MM-DDTHH:mm:ss.fff Z");
                  EndTime = EndTime.format("YYYY-MM-DDTHH:mm:ss");
              }
              else if(selectedtimezone==="CST")
              {              
                  EndTime=Date.parse(new Date(this.convertUTCDateToCST(new Date(end))));
                  EndTime = moment(new Date(EndTime),"YYYY-MM-DDTHH:mm:ss.fff Z");
                  EndTime = EndTime.format("YYYY-MM-DDTHH:mm:ss");
              }
              else
              {
                EndTime= moment(end, 'YYYY-MM-DDTHH:mm:ss.fff Z');
                EndTime = EndTime.format('YYYY-MM-DDTHH:mm:ss');
              }
        }
        else 
        {
              EndTime= moment(end, 'YYYY-MM-DDTHH:mm:ss.fff Z');
              EndTime = EndTime.format('YYYY-MM-DDTHH:mm:ss');
        }  */

      var org = event.target.organization === undefined ? null : '';
      var extcontact = event.target.externalcontact === undefined ? null : event.target.externalcontact.value;
      var engcontact = event.target.engcontact === undefined ? prop.props.event.engContact : event.target.engcontact.value;
      var updtpmcontact = event.target.pmcontact === undefined ? prop.props.event.pmContact : event.target.pmcontact.value;
      var alldayevent = prop.state.clicked === "" || prop.state.clicked === undefined ? prop.props.event.allDay : (prop.state.clicked);
      var nxtsteps = ((prop.state.nextsteps !== null && prop.state.nextsteps !== undefined) ? prop.state.nextsteps : (prop.props.event.nextsteps !== "" ? prop.props.event.nextsteps : ''));

      var outagetypetoggle = prop.state.outagetypeclicked === "" || prop.state.outagetypeclicked === undefined || prop.state.outagetypeclicked === null ? prop.props.event.outageType : (prop.state.outagetypeclicked);
      outagetypetoggle = outagetypetoggle === "Planned" ? true : (outagetypetoggle === "UnPlanned" ? false : '');
      var incnumber = event.target.incidentnumber === undefined || event.target.incidentnumber === null || event.target.incidentnumber === '' ? prop.props.event.incidentNumber : event.target.incidentnumber.value;
      var sevnum = event.target.severity === undefined || event.target.severity === null || event.target.severity === '' ? prop.props.event.severity : event.target.severity.value;
      //sevnum=parseInt(sevnum);
      var bridgeurls = event.target.bridgeurls === undefined || event.target.bridgeurls === null || event.target.bridgeurls === '' ? prop.props.event.bridgeurls : event.target.bridgeurls.value;
      var updatedetails = ((prop.state.updatedata !== null && prop.state.updatedata !== undefined) ? prop.state.updatedata : (prop.props.event.updatedata !== "" ? prop.props.event.updatedata : ''));
      var resolutiondetails = ((prop.state.resolutiondata !== null && prop.state.resolutiondata !== undefined) ? prop.state.resolutiondata : (prop.props.event.resolutiondata !== "" ? prop.props.event.resolutiondata : ''));
      var timelinedetails = ((prop.state.timelinedata !== null && prop.state.timelinedata !== undefined) ? prop.state.timelinedata : (prop.props.event.timelinedata !== "" ? prop.props.event.timelinedata : ''));
      var nacategory = ((prop.props.event.nacategory !== undefined && prop.props.event.nacategory !== null && prop.props.event.nacategory !== '') ? prop.props.event.nacategory : prop.props.role);

      var bodyvalues = JSON.stringify({
        EventId: prop.props.event.outageId,
        title: title,
        Description: desc,
        Impact: impact,
        StartTime: startdate,
        EndTime: EndTime,
        organization: org,
        ExternalContact: extcontact,
        engContact: engcontact,
        pmContact: updtpmcontact,
        outageType: (prop.props.event.outageType === 'Planned' || prop.props.event.outageType === 'UnPlanned' ? (outagetypetoggle === true ? 'Planned' : 'UnPlanned') : prop.props.event.outageType),
        region: event.target.region.value === '--Select Region--' ? prop.props.event.region : event.target.region.value,
        country: event.target.country.value === '--Select Country--' ? prop.props.event.country : event.target.country.value,
        teamgroup: event.target.teamgroup === undefined ? (prop.props.event.teamgroup === null ? this.returnArrayValues(event.target.teamgroup) : prop.props.event.teamgroup) : this.returnArrayValues(event.target.teamgroup),
        service: event.target.teamservice === undefined ? (prop.props.event.service === null ? this.returnArrayValues(event.target.teamservice) : prop.props.event.service) : this.returnArrayValues(event.target.teamservice),
        component: event.target.teamcomponent === undefined ? (prop.props.event.component === null ? this.returnArrayValues(event.target.teamcomponent) : prop.props.event.component) : this.returnArrayValues(event.target.teamcomponent),
        AllDayEvent: alldayevent,
        servicegroup: event.target.servicegroup === undefined ? (prop.props.event.servicegroup === null ? this.returnArrayValues(event.target.servicegroup) : prop.props.event.servicegroup) : this.returnArrayValues(event.target.servicegroup),
        factoryName: event.target.factory === undefined ? (prop.props.event.factory === null ? null : prop.props.event.factory) : this.returnArrayValues(event.target.factory),
        division: event.target.division === undefined ? prop.props.event.division : event.target.division.value,
        orgName: event.target.organizationname === undefined ? prop.props.event.orgName : event.target.organizationname.value,
        eventStatus: event.target.status === undefined ? prop.props.event.eventstatus : event.target.status.value === '--Select Status--' ? 'Initial' : event.target.status.value,
        NextSteps: nxtsteps,
        category: '',
        Location: '',
        Recurrence: false,
        IncidentNumber: incnumber,
        Timezone: selectedtimezone,
        severity: sevnum,
        bridgeurls: bridgeurls,
        updatedata: updatedetails,
        resolutiondata: resolutiondetails,
        timelinedata: timelinedetails,
        //NACategory: prop.props.event.nacategory === 'Partner' ? "Partner" : (prop.props.event.outageType==='Planned' || prop.props.event.outageType==='UnPlanned'?"N.A":this.props.role) 
        NACategory: nacategory
      })
      appInsights.trackTrace({ message: 'Calendar Edit Event Component Loaded.' },
        {
          "userName": this.props.userEmail,
          "ComponentId": COMPONENT_ID,
          "ComponentName": COMPONENT_NAME,
          "EnvironmentName": window.env.ENVIRONMENT,
          "ServiceLineName": SERVICE_LINE,
          "ServiceName": SERVICE_NAME,
          "ServiceOfferingName": SERVICE_OFFERING,
          "Correlation Id": uuid.v1(),
          "Component": 'Edit Event Calendar Page',
        });
      var token = await fetchToken();
      var correlationId = uuid.v1();
      var bearer = 'Bearer ' + token;
      var apimUrl = window.env.APIMURL + "/api/outage/updateoutage";
      trackPromise(
        fetch(apimUrl, {
          method: 'PUT',
          headers: {
            //'Accept': 'application/json',
            //'Access-Control-Allow-Origin': '*',
            'CorrelationId': correlationId,
            'Content-Type': 'application/json',
            'authorization': bearer,
          },
          body: bodyvalues
        }).then(response => {
          return response.json()
        }).then(json => {
          var values = JSON.parse(bodyvalues);
          values["outageId"] = prop.props.event.outageId;
          values["start"] = new Date(values.StartTime);
          values["end"] = new Date(values.EndTime);
          values["outageTitle"] = values.title;
          values["outageDescription"] = values.Description;
          values["outageImpact"] = values.Impact;
          values["allDay"] = values.AllDayEvent;
          values["extContact"] = values.ExternalContact;
          values["nextsteps"] = values.NextSteps;
          values["outageType"] = values.OutageType;
          values["timezone"] = values.Timezone;
          // values["severity"]=values.severity;
          // values["bridgeurls"]=values.bridgeurls;  
          //  values["updatedata"]=values.updatedata; 
          // values["resolutiondata"]=values.resolutiondata; 
          // values["timelinedata"]=values.timelinedata;              
          prop.setState({
            date: null,
            enddate: null,
            clicked: '',
            readOnly: true,
            updateCompleted: true,
            outagetypeclicked: ''
          })
          prop.props.updateEvent(values);
        })
          .catch(error => {
            var err =
            {
              "message": "Exception occured while updating the calendar edit event",
              "ComponentId": COMPONENT_ID,
              "ComponentName": COMPONENT_NAME,
              "EnvironmentName": window.env.ENVIRONMENT,
              "ServiceLineName": SERVICE_LINE,
              "ServiceName": SERVICE_NAME,
              "ServiceOfferingName": SERVICE_OFFERING,
              "Component": "Calendar Edit Event Page",
              "userName": this.props.userEmail,
              "roles": this.props.role != null || '' ? this.props.role : "",
              "exception": error.message,
              "Correlation Id": correlationId
            }
            appInsights.trackException({ exception: error, properties: err });
          })
      );
    }
    catch (e) {
      errors = e;
    }
  }

  deleteConfirmation = async (outageID) => {
    let prop = this;
    var apimUrl = window.env.APIMURL + "/api/outage/deleteoutage/?outageid=";
    var correlationId = uuid.v1();
    let url = apimUrl + outageID;
    var token = await fetchToken();
    var bearer = 'Bearer ' + token;
    var bodyvalues = JSON.stringify({
      outageid: outageID,
    })

    appInsights.trackTrace({ message: 'Calendar Component Loaded and user clicked on Delete event.' },
      {
        userName: this.props.userEmail,
        "ComponentId": COMPONENT_ID,
        "ComponentName": COMPONENT_NAME,
        "EnvironmentName": window.env.ENVIRONMENT,
        "ServiceLineName": SERVICE_LINE,
        "ServiceName": SERVICE_NAME,
        "ServiceOfferingName": SERVICE_OFFERING,
        "Correlation Id": correlationId,
        "Component": 'Calendar Delete Event Operation',
      });
    fetch(url, {
      method: 'DELETE',
      headers: {
        //'Accept': 'application/json',
        //'Access-Control-Allow-Origin': '*',
        'CorrelationId': correlationId,
        'Content-Type': 'application/json',
        'authorization': bearer,
      },
    }).then(response => {
      return response.text()
    }).then(res => {
      try {
        //prop.setState({showDeleteDialog:true});
        prop.props.removeEvent(outageID);
      } catch (err) {
        // handle error
        var error =
        {
          "message": "Exception occured in Calendar Delete component while submitting the delete request",
          "ComponentId": COMPONENT_ID,
          "ComponentName": COMPONENT_NAME,
          "EnvironmentName": window.env.ENVIRONMENT,
          "ServiceLineName": SERVICE_LINE,
          "ServiceName": SERVICE_NAME,
          "ServiceOfferingName": SERVICE_OFFERING,
          "Component": "Calendar Delete Event",
          "userName": this.props.userEmail,
          "roles": this.props.role != null || '' ? this.props.role : "",
          "exception": err.message,
          "Correlation Id": correlationId
        }
        appInsights.trackException({ exception: error, properties: error });
      }
    }).catch(error => {

      var err =
      {
        "message": "Exception occured in Calendar Delete component while submitting the delete request",
        "ComponentId": COMPONENT_ID,
        "ComponentName": COMPONENT_NAME,
        "EnvironmentName": window.env.ENVIRONMENT,
        "ServiceLineName": SERVICE_LINE,
        "ServiceName": SERVICE_NAME,
        "ServiceOfferingName": SERVICE_OFFERING,
        "Component": "Calendar Delete Event",
        "userName": this.props.userEmail,
        "roles": this.props.role != null || '' ? this.props.role : "",
        "exception": error.message,
        "Correlation Id": correlationId
      }
      appInsights.trackException({ exception: error, properties: err });
    })


  }

  DeleteEvent = async () => {

    let outageID = this.props.event.outageId;
    this.setState({ outageId: outageID });
    var shouldDelete = await this.flip();

    //await this.Sample(outageID);
    // Service Delete Operetion
    // 
  }

  async updateServiceGroup(value) {
    this.setState({
      serviceGroup: value
    });
  }

  async updateTeamGroup(value) {
    this.setState({
      teamGroup: value
    });
  }

  async updateComponent(value) {
    this.setState({
      teamComponent: value
    });
  }

  async updateService(value) {
    this.setState({
      teamService: value
    });
  }

  async updateFactoryName(value) {

    this.setState({
      factory: value
    });
  }

  async updateRegion(value) {

    this.setState({
      region: value
    });
  }

  async updateCountry(value) {

    this.setState({
      country: value
    });
  }

  onEditorChange(event) {
    this.setState({ [event.editor.config.bodyId]: event.editor.getData(), [`${event.editor.config.bodyId}Error`]: '' });
  }


  async updateDivision(value) {
    this.setState({
      division: value
    });
  }

  async updateOrgName(value) {
    this.setState({
      orgName: value
    });
  }

  async updateEventStatus(value) {
    var desc = value.toLowerCase() === "shiproom" ? SHIPROOM : (value.toLowerCase() === "crew hightide" ? HighTide : this.state.description);
    var newenddate =  moment().endOf('day');
    this.setState({
      status: value, description: desc, enddate: ((value.toLowerCase() != "mitagation")? newenddate:'' )
    });
  }

  async setpmContactErrorValue(value) {

    this.setState({
      pmcontactError: value
    });
  }
  async updateApproverContact(value) {

    this.setState({ pmcontact: value });

  }

  // onNextStepsTextEditorChange( event ) {
  //   this.setState( {
  //       nextsteps: event.editor.getData()        
  //   } );
  // }

  /*  showDescriptionData()
    {
      return(<div>{ dangerouslySetInnerHTML={{ __html: this.props.data }} }</div>)
    } */


  returnValues(array) {
    const options = array.map(v => ({
      label: v,
      value: v
    }));
    return options;
  }

  async getIncidentDetails() {
    var incnum = this.state.incidentnumber !== '' ? this.state.incidentnumber : 0;
    var token = await fetchToken();
    var correlationId = uuid.v1();
    var bearer = 'Bearer ' + token;
    var apimUrl = window.env.APIMURL + "/api/outage/getincidentdetails/?incidentnumber=" + incnum;

    fetch(apimUrl, {
      method: 'GET',
      headers: {
        'authorization': bearer,
        'Accept': 'application/json',
        'CorrelationId': correlationId,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(data => {

        this.setState({
          severity: data[0].severity, bridgeurls: data[0].bridgeUrls
        });
      })
      .catch(error => {
        var err =
        {
          "message": "Exception occured while getting the incident number details - severity , bridge urls from  DB",
          "ComponentId": COMPONENT_ID,
          "ComponentName": COMPONENT_NAME,
          "EnvironmentName": window.env.ENVIRONMENT,
          "ServiceLineName": SERVICE_LINE,
          "ServiceName": SERVICE_NAME,
          "ServiceOfferingName": SERVICE_OFFERING,
          "Component": 'Region Page',
          "userName": this.props.email,
          //roles: this.props.role != null || '' ? this.props.role : "",
          "exception": error.message,
          "Correlation Id": correlationId,
        }
        appInsights.trackException({ exception: error, properties: err });
      })
  }


  render() {

    let subText, display;
    let checked = (this.state.clicked == "") ? (((this.props.event.allDay) == true) ? true : false) : ((this.state.clicked) == "Yes" ? true : false);
    const { userEmail } = this.props;
    let validEmail = (userEmail != undefined ? userEmail.toLowerCase().includes("microsoft.com") : null);
    let factoryOption = this.props.event.factory != null | undefined ? true : false;
    let servicegroupOption = (this.props.event.servicegroup != undefined | '' | null ? (this.props.event.servicegroup == "Supply Chain" ? false : true) : false) && (this.props.event.servicegroup != undefined | '' | null ? (this.props.event.servicegroup == "Sales, Partner & Supply Chain" ? false : true) : false);
    factoryOption = (factoryOption && servicegroupOption) ? true : false;
    let incnumbershow = (this.props.event.outageType === "UnPlanned" ? true : false);
    let current = new Date();
    let endTimeShow = (this.props.event.eventstatus === "Mitigation" ? true : false);

    
    //  this.getLocalisedDate();

    if (this.state.showDeleteDialog) {
      subText = 'We have successfully processed your request';
      display = true;
    }
    if (this.state.updateCompleted) {
      subText = 'Updated successfully';
      display = true;
    }

    let inputProps = {
      placeholder: "Select a Date",
      readOnly: true,
      disabled: this.state.readOnly
    };

    return (
      <div>

        <AlertDialog title={'Success!'} cancelButtonLabel={'Ok'}
          message={subText} open={display} onClickCancel={this.closeDeleteDialog} />
        <AlertDialog okButtonLabel={'Delete'} okButtonType={ButtonType.Danger} onClickOk={this.deleteDialog} title={'Confirm Dialog'} cancelButtonLabel={'Cancel'}
          message={'Are you sure you want to delete this event?'} open={this.state.showDialog} onClickCancel={this.cancelDialog} />
        <Modal style={{ 'overflow': 'visible' }} role="dialog" data-keyboard="false" show={this.props.show} size="lg" onHide={() => { this.props.closeModalPopUpFunc(false) }} backdrop="static" centered autoFocus={true}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Event</Modal.Title>
          </Modal.Header>
          <LoadingIndicator />
          <form id="updateevent" onSubmit={this.methodInvoke.bind(this)}>
            <Modal.Body style={{ 'maxHeight': 'calc(100vh - 210px)', 'overflowY': 'auto' }}>
              <div className="form-group required">
                <label htmlFor="outageTitle" class="control-label">Title</label>
                <input required type="text" className="form-control" name="outageTitle" title="outageTitle" readOnly={this.state.readOnly} defaultValue={this.props.event.outageTitle} onChange={(e) => this.handleChange(e)} />
                <span style={{ color: '#E22335' }}>{this.renderError('outageTitle')}</span>
              </div>
              {/* <div className="form-group">
                    <label htmlFor="organization">Organization</label>
                    <input type="text" class="form-control" id="organization" readOnly={this.state.readOnly} defaultValue={this.props.event.organization} onChange={(e) => this.handleChange(e)} />
                  </div> */}

              {validEmail && (this.props.event.nacategory !== 'Partner') ?
                <div className="form-group">
                  <ReactEditEventStatusDropDown
                    email={userEmail}
                    event={this.props.event}
                    readOnly={this.state.readOnly}
                    statusData={this.updateEventStatus.bind(this)}
                  />
                </div>
                : null
              }

              {validEmail ?
                <div className="form-group">
                  <label htmlFor="outagetypetoggle" class="control-label">Planned Event</label> &nbsp;&nbsp;
                  <Switch
                    name="outagetypetoggle"
                    title="outagetypetoggle"
                    disabled={this.state.readOnly}
                    onChange={(e) => this.handleOutageTypeChange(e)}
                    checked={(this.props.event.outageType === "Planned" ? true : false) || this.state.outagetypeclicked} />
                </div>
                : null}

              {(incnumbershow && validEmail) ?
                <div>
                  <div className="form-group required">
                    <label htmlFor="incidentnumber" class="control-label">Incident Number</label>
                    <input required aria-label="IncidentNumber"
                      type="text"
                      readOnly={this.state.readOnly}
                      defaultValue={this.props.event.incidentNumber}
                      className="form-control"
                      name="incidentnumber"
                      onChange={(e) => this.handleChange(e)} />
                    <span style={{ color: '#E22335' }}>{this.renderError('incidentnumber')}</span>
                    &nbsp;
                    <Button variant="secondary" onClick={() => { this.getIncidentDetails() }}>
                      Get Incident Details
                    </Button>
                  </div>

                  <div className="form-group required">
                    <label htmlFor="severity" class="control-label">Severity</label>
                    <input required aria-label="Severity" type="text" readOnly={this.state.readOnly} defaultValue={this.props.event.severity} className="form-control" name="severity" onChange={(e) => this.handleChange(e)} />
                    <span style={{ color: '#E22335' }}>{this.renderError('severity')}</span>
                  </div>

                  <div className="form-group required">
                    <label htmlFor="bridgeurls" class="control-label">BridgeUrls</label>
                    <textarea required aria-label="BridgeUrls" readOnly={this.state.readOnly} defaultValue={this.props.event.bridgeurls} className="form-control" name="bridgeurls" onChange={(e) => this.handleChange(e)} />
                    <span style={{ color: '#E22335' }}>{this.renderError('bridgeurls')}</span>
                  </div>

                </div>
                : null}

              <div className="form-group required">
                <label htmlFor="outageDescription" class="control-label">Description</label>

                <CKEditor required id='description'
                  onChange={(e) => this.onEditorChange(e)}
                  readOnly={this.state.readOnly}
                 
                  data={this.props.event.outageDescription}
                 
                  config={{ height: '20vh', bodyId: "description", removePlugins: 'elementspath,scayt,wsc', removeButtons: 'Image,Source,About,Maximize,Anchor' }}
                />
                <span style={{ color: '#E22335' }}>{this.renderError('description')}</span>
              </div>

              {this.props.role.includes('BusinessUser') &&
                <div className="form-group required">
                  <label htmlFor="informational" class="control-label">Informational</label> &nbsp;&nbsp;
                  {/* <BootstrapSwitchButton checked = {this.props.event.outageType === 'Planned' ? false : true} disabled = {this.state.readOnly}   offlabel='No' onlabel='Yes' className="form-control" name="informational"
                 onstyle="outline-success" offstyle="outline-info" size="sm" />        */}

                  <Switch required name="informational" disabled={true} checked={this.props.event.outageType === 'Planned' ? false : true} />

                </div>
              }

              <div className="form-group required">
                <label htmlFor="outageImpact" class="control-label">Impact</label>
             {/*    <textarea aria-label="outageImpact" type="text"
                     class="form-control" name="impact"
                      readOnly={this.state.readOnly} 
                      defaultValue={this.props.event.outageImpact} 
                      onChange={(e) => this.handleChange(e)}/> */}
         <CKEditor required id='impact'
                  onChange={(e) => this.onEditorChange(e)}
                  readOnly ={this.state.readOnly}
                  data={this.props.event.outageImpact}
                  config={{ height: '20vh', bodyId: "impact", removePlugins: 'elementspath,scayt,wsc', removeButtons: 'Image,Source,About,Maximize,Anchor' }}
                /> 
                <span style={{ color: '#E22335' }}>{this.renderError('impact')}</span>
              </div>

              <div className="form-group required">

                <label> Timezone : </label>
                &nbsp;
                <input type="radio" value="UTC" name="radio-group" placeholder="UTC" checked={this.props.event.Timezone === "UTC" || this.props.event.Timezone === null || this.state.timezone === "UTC"} onChange={this.onTimezoneChange} /> UTC
                &nbsp;
                <input type="radio" value="PST" name="radio-group" placeholder="PST" checked={this.props.event.Timezone === "PST" || this.state.timezone === "PST"} onChange={this.onTimezoneChange} /> PST
                &nbsp;
                <input type="radio" value="CST" name="radio-group" placeholder="CST" checked={this.props.event.Timezone === "CST" || this.state.timezone === "CST"} onChange={this.onTimezoneChange} /> CST
                &nbsp;
              </div>

              <div className="form-group required">
                <label htmlFor="startDate" class="control-label">StartTime</label>
                <div className="form-group">
                  <Datetime required calendarAriaLabel="StartTimeCalender" yearAriaLabel="Year" monthAriaLabel="Month" dayAriaLabel="Day" hourAriaLabel="Hour" minuteAriaLabel="Minute" amPmAriaLabel="ampm"
                    name="startDate" inputProps={inputProps} onChange={this.onChange} value={this.state.date === null ? this.props.event.start : this.state.date}></Datetime>
                  <span style={{ color: '#E22335' }}>{this.renderError('date')}</span>
                </div>
              </div>

            {(endTimeShow) ?
            <div className="form-group required">
            <label htmlFor="endDate" class="control-label">EndTime</label>
            <div className="form-group">
              <Datetime required calendarAriaLabel="EndTimeCalender" yearAriaLabel="Year" monthAriaLabel="Month" dayAriaLabel="Day" hourAriaLabel="Hour" minuteAriaLabel="Minute" amPmAriaLabel="ampm"
                name="endTime" inputProps={inputProps} onChange={this.onEndChange} value={this.state.enddate === null ? this.props.event.end : this.state.enddate}></Datetime>
              <span style={{ color: '#E22335' }}>{this.renderError('enddate')}</span>
            </div>
            </div>
            : 
            (this.state.status != null) ? (this.state.status.toLowerCase() === "mitigation" ?
            <div className="form-group required">
             <label htmlFor="endtime" class="control-label">EndTime</label>
              <Datetime required calendarAriaLabel="EndTimeCalender" yearAriaLabel="Year" monthAriaLabel="Month" dayAriaLabel="Day" hourAriaLabel="Hour" minuteAriaLabel="Minute" amPmAriaLabel="ampm"
                name="endtime" inputProps={inputProps} onChange={this.onEndChange} value={this.state.enddate === null ? this.props.event.enddate : this.state.enddate}></Datetime>
              <span style={{ color: '#E22335' }}>{this.renderError('enddate')}</span> 
            </div>
            :
            null
              )
                :  null
               }

              <div className="form-group">
                <label htmlFor="alldayevent" class="control-label">AllDayEvent</label> &nbsp;&nbsp;
                <Switch name="alldayevent" disabled={this.state.readOnly} title="alldayevent" onChange={(e) => this.handleOnChange(e)} checked={(this.state.clicked === "") ? (this.props.event.allDay) : ((this.state.clicked))} />
              </div>

              {validEmail && (this.props.event.nacategory !== 'Partner') ?
                <div className="form-group">
                  <label htmlFor="nextsteps" class="control-label">Next Steps</label>
                  <CKEditor required id='nextsteps'
                    onChange={(e) => this.onEditorChange(e)}
                    readOnly={this.state.readOnly}
                    data={this.props.event.nextsteps}
                    config={{ bodyId: "nextsteps", removePlugins: 'elementspath,scayt,wsc', removeButtons: 'Image,Source,About,Maximize,Anchor' }}
                  />
                </div>
                : null}

              {(incnumbershow && validEmail) ?
                <div>
                  <div className="form-group">
                    <label htmlFor="updatedata" class="control-label">Update</label>
                    <CKEditor required id='updatedata'
                      onChange={(e) => this.onEditorChange(e)}
                      readOnly={this.state.readOnly}
                      data={this.props.event.updatedata}
                      config={{ bodyId: "updatedata", removePlugins: 'elementspath,scayt,wsc', removeButtons: 'Image,Source,About,Maximize,Anchor' }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="resolutiondata" class="control-label">Resolution</label>
                    <CKEditor required id='resolutiondata'
                      onChange={(e) => this.onEditorChange(e)}
                      readOnly={this.state.readOnly}
                      data={this.props.event.resolutiondata}
                      config={{ bodyId: "resolutiondata", removePlugins: 'elementspath,scayt,wsc', removeButtons: 'Image,Source,About,Maximize,Anchor' }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="timelinedata" class="control-label">Timeline</label>
                    <CKEditor required id='timelinedata'
                      onChange={(e) => this.onEditorChange(e)}
                      readOnly={this.state.readOnly}
                      data={this.props.event.timelinedata}
                      config={{ bodyId: "timelinedata", removePlugins: 'elementspath,scayt,wsc', removeButtons: 'Image,Source,About,Maximize,Anchor' }}
                    />
                  </div>
                </div>
                : null}

              {validEmail ?
                <div className="form-group required">
                  <EditApproverDropDown
                    email={userEmail}
                    pmContactError={this.renderError('pmcontact')}
                    setpmContactErrorValue={this.setpmContactErrorValue.bind(this)}
                    pmContactData={this.updateApproverContact.bind(this)}
                    event={this.props.event}
                    readOnly={this.state.readOnly}
                  />
                </div>
                : null}

              {!validEmail || (this.props.event.nacategory === 'Partner') ?
                <div className="form-group required">
                  <label htmlFor="externalcontact" class="control-label">External Contact</label>
                  <input required aria-label="External Contact" type="email" className="form-control" name="externalcontact" id="externalcontact" readOnly={this.state.readOnly} defaultValue={this.props.event.extContact} onChange={(e) => this.handleChange(e)} />
                  <span style={{ color: '#E22335' }}>{this.renderError('externalcontact')}</span>
                </div>
                : null}

              {validEmail && (this.props.event.nacategory !== 'Partner') ?
                <div className="form-group required">
                  <label htmlFor="engcontact" class="control-label">Point of Contact</label>
                  <input required aria-label="Engineering Contact" type="email" className="form-control" id="engcontact" name="engcontact" readOnly={this.state.readOnly} defaultValue={this.state.engcontact === 'valid' ? this.props.event.engContact : this.state.engcontact} onChange={(e) => this.handleChange(e)} />
                  <span style={{ color: '#E22335' }}>{this.renderError('engcontact')}</span>
                </div>
                : null}



              {validEmail && (this.props.event.nacategory !== 'Partner') ?
                <div className="form-group">
                  <ReactEditDropDown
                    email={userEmail}
                    divisionData={this.updateDivision.bind(this)}
                    orgNameData={this.updateOrgName.bind(this)}
                    servicegroupData={this.updateServiceGroup.bind(this)}
                    teamData={this.updateTeamGroup.bind(this)}
                    teamComponent={this.updateComponent.bind(this)}
                    teamService={this.updateService.bind(this)}
                    event={this.props.event}
                    readOnly={this.state.readOnly}

                  />
                </div>
                : null
              }
              {factoryOption && validEmail && (this.props.event.nacategory !== 'Partner') ?

                <div className="form-group">
                  <ReactEditFactoryDropDown
                    email={userEmail}
                    factoryData={this.updateFactoryName.bind(this)}
                    event={this.props.event}
                    readOnly={this.state.readOnly}
                  />
                </div>

                : null}

              <div className="form-group">
                <ReactEditRegionDropDown
                  email={userEmail}
                  event={this.props.event}
                  readOnly={this.state.readOnly}
                  regionData={this.updateRegion.bind(this)}
                  countryData={this.updateCountry.bind(this)}
                />

              </div>

            </Modal.Body>
            <Modal.Footer>
              {this.props.role == 'Admin' || this.props.role == 'Writer' || this.props.role == 'Approver' ?
                <Button aria-label="Delete" variant="secondary" onClick={() => {
                  this.DeleteEvent()
                }}>
                  Delete
                </Button>
                : null}
              <Button aria-label="Close" variant="secondary" onClick={() => { this.setState({ readOnly: true, localstartdate: null, localenddate: null }); this.props.closeModalPopUpFunc(false) }}>
                Close
              </Button>
              {this.props.role !== 'Reader' && this.props.role !== 'BusinessUser ChannelManagers' ?
                <Button aria-label="Toggle" variant="secondary" onClick={() => { this.ToggleReadonly() }}>
                  {
                    (this.state.readOnly) ? "Edit" : "Back"
                  }
                </Button>
                : null}
              <Button aria-label="Edit Event" variant="primary" form="updateevent" type="Submit" hidden={Boolean(this.state.readOnly)} onClick={() => {

                this.props.closeModalPopUpFunc(true);

              }}>
                Save Changes
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }
}

export default EditModalPopUp;