
import moment from 'moment';
import React from 'react';
import { reactAI } from "react-appinsights";
import uuid from 'react-native-uuid';
import {fetchToken} from '../../Util.js';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";
import {appInsights} from '../../common/AppInsights/AppInsights';
import AlertDialog from "../../common/AlertDialog/AlertDialog";
import { Radio, RadioGroup} from 'react-radio-group';
import * as Datetime from 'react-datetime';
import { Button,Modal } from 'react-bootstrap';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {ButtonType} from 'office-ui-fabric-react/lib/Button'; 
import { trackPromise } from 'react-promise-tracker'
import LoadingIndicator from '../../common/PromiseTracker/PromiseTracker';
import ReactCategoryDropDown from '../CategoryDropDown/Category';
import { string } from 'prop-types';
import PlantRegionDropDown from '../PlantRegionDropDown/PlantRegionDropDown';
import Select from 'react-select';

class AddCREWHolidayEvent extends React.Component{
    _isMounted = false;
    constructor(props) {
    
      super(props);
      
      const date = Date.now();
      this.state = {date: '',enddate:'',clicked:false,result:'',userEmail : '',addevent : false,showModal: '',
        holidayName: '',holidayNameError: '',dateError:'',
        enddateError : '',hasError:false,
        showDialog: '',showErrorDialog : '',showNewDialog:false,newblocking:false, addOutage:[]
        , role : this.props.role,location:'',holidayType:'',holidayTypeError:'',regionError:'',
        countryError:'',region:'',country:'',plantName:'',plantError:'',rowId:'',showHolidayErrorDialog:'', timezone:"UTC"
      };
      
      this.handleChange = this.handleChange.bind(this);
      this.onChange = this.onChange.bind(this);
      this.onEndChange = this.onEndChange.bind(this);
      this.methodInvoke = this.methodInvoke.bind(this);
      this.close = this.close.bind(this);
      this.cofirmApproveReject = this.cofirmApproveReject.bind(this);
      this.flip = this.flip.bind(this);
    }
  
    appInsight = reactAI.appInsights;
    close = () => this.setState({isOpen: false}) 
  
    componentDidMount() {
      this._isMounted = true;
    }
  
    componentWillUnmount() {
      this._isMounted = false;
    }
  
    close(){
      this.setState({ showModal: false });
    }

    convertDateToUTC(date)
    {
      var now_utc =  date;
      now_utc.setHours(now_utc.getHours()+5);
      now_utc.setMinutes(now_utc.getMinutes()+30);
      return now_utc.toUTCString();
    }

    // convert CST date to UTC date .
    convertCSTDateToUTC(date)
    {
      var now_cst =  date;
      now_cst.setHours(now_cst.getHours()-8);
      return now_cst.toUTCString();
    }
    // convert PST date to UTC date .
    convertPSTDateToUTC(date)
    {
      var isDST=this.checkDateForDaylightSaving(date);
      var now_pst =  date;
      if (isDST===true)
      {
        now_pst.setHours(now_pst.getHours()+7); //during daylight saving months .
      }
      else
      {
        now_pst.setHours(now_pst.getHours()+8);
      }
      return now_pst.toUTCString();
    }
    // convert UTC date to CST date .
    convertUTCDateToCST(date)
    {
      var now_utc =  date;
      now_utc.setHours(now_utc.getHours()+8);
      return now_utc;
    }
    // convert UTC date to PST date . 
    convertUTCDateToPST(date)
    {
      var now_utc =  date;
      var isDST=this.checkDateForDaylightSaving(date);
      if (isDST===true)
      {
      now_utc.setHours(now_utc.getHours()-7);  //during daylight saving months .
      }
      else
      {
        now_utc.setHours(now_utc.getHours()-8);
      }
      return now_utc;
    }
    convertCSTDateToUTCTimezone(date)
    {
      var now_cst =  date;
      now_cst.setHours(now_cst.getHours()-8);
      return now_cst;
    }
    convertPSTDateToUTCTimezone(date)
    {
      var now_pst =  date;
      var isDST=this.checkDateForDaylightSaving(date);
      if (isDST===true)
      {
         now_pst.setHours(now_pst.getHours()+7);  //during daylight saving months .
      }
      else
      {
        now_pst.setHours(now_pst.getHours()+8);
      }
      return now_pst;
    }
    convertCSTDateToPST(date)
    {
      var now_cst =  date;
      var isDST=this.checkDateForDaylightSaving(date);
      if (isDST===true)
      {
      now_cst.setHours(now_cst.getHours()-8);
      now_cst.setHours(now_cst.getHours()-7);
      }
      else
      {
        now_cst.setHours(now_cst.getHours()-8);
        now_cst.setHours(now_cst.getHours()-8);
      }
      //return now_cst.toUTCString();
      return now_cst;
    }

    convertPSTDateToCST(date)
    {
      var now_pst =  date;
      var isDST=this.checkDateForDaylightSaving(date);

      if (isDST===true)
      {
      now_pst.setHours(now_pst.getHours()+7);
      now_pst.setHours(now_pst.getHours()+8);
      }
      else
      {
        now_pst.setHours(now_pst.getHours()+8);
        now_pst.setHours(now_pst.getHours()+8);
      }
      //return now_cst.toUTCString();
      return now_pst;
    }

    //calculate daylight saving start date , end date  in a year . 
  
    calculateDSTStartDate()
    {
      const days = {
        Mon: 1,
        Tue: 2,
        Wed: 3,
        Thu: 4,
        Fri: 5,
        Sat: 6,
        Sun: 0
      }
     
      var weekNumber,day,monthMoment;
      var year=new Date().getFullYear();
      var marchdate=year+'-03-01';
      var dststartdate;

      // 2nd Sunday of March . 
      weekNumber=2;
      day=days.Sun;
      monthMoment=moment(marchdate);
     
      let m = monthMoment.clone()
         .startOf('month')                     
         .day(day)
        if (m.month() !== monthMoment.month()) 
        {
          m.add(7, 'd');
        }
        //dststartdate= m.add(7 * (weekNumber - 1), 'd').format('YYYY-MM-DDTHH:mm:ss.fff Z');
        dststartdate= m.add(7 * (weekNumber - 1), 'd');
        dststartdate=moment(dststartdate).add(2, 'hours');
        return dststartdate;            
       
    }

    calculateDSTEndDate()
    {
      const days = {
        Mon: 1,
        Tue: 2,
        Wed: 3,
        Thu: 4,
        Fri: 5,
        Sat: 6,
        Sun: 0
      }
     
      var weekNumber,day,monthMoment;
      var year=new Date().getFullYear();
      var novdate=year+'-11-01';
      var dstenddate;

      // 1st Sunday of November .  
      weekNumber=1;
      day=days.Sun;
      monthMoment=moment(novdate);

      let m = monthMoment.clone()
      .startOf('month')                     
      .day(day)
       if (m.month() !== monthMoment.month()) 
      {
         m.add(7, 'd');
      }
      //dstenddate= m.add(7 * (weekNumber - 1), 'd').format('YYYY-MM-DDTHH:mm:ss.fff Z');
      dstenddate= m.add(7 * (weekNumber - 1), 'd');
      dstenddate=moment(dstenddate).add(2, 'hours');
      return dstenddate;        
       
    }
    //check daylight saving is active or not . 
    checkDateForDaylightSaving(date)
    {
       var DaylightStartDate= this.calculateDSTStartDate();
       var DaylightEndDate=this.calculateDSTEndDate();
       if(date >= DaylightStartDate && date <=DaylightEndDate)
       {
         return true;
       }
       else
       {
         return false;
       }
    }


  /*  onTimezoneChange=async(event) =>{
      this.setState({ timezone:event.target.value });
      console.log(this.state.timezone );
    } */

    onTimezoneChange=async(e) =>{
      var prevselectedtimezone=this.state.timezone;
      this.selectedtimezoneEventData(e.target.value,prevselectedtimezone);
      this.setState({ timezone:e.target.value });

      console.log(this.state.timezone );
    }

    selectedtimezoneEventData(selectedtimezone,prevselectedtimezone)
    {
        if(prevselectedtimezone==="UTC" || prevselectedtimezone===null || prevselectedtimezone==='' || prevselectedtimezone===undefined)
        {
            if(selectedtimezone==="CST")
            {                         
            /*  this.setState({date:Date.parse(new Date(this.convertUTCDateToCST(new Date(this.state.date)))) , 
                            enddate:Date.parse(new Date(this.convertUTCDateToCST(new Date(this.state.enddate)))) }); */
              if((this.state.date!==null && this.state.date!==undefined && this.state.date!=='') && (this.state.enddate!==null && this.state.enddate!==undefined && this.state.enddate!=='' ))
              {
                this.setState({date:this.convertUTCDateToCST(new Date(this.state.date)) , 
                            enddate:this.convertUTCDateToCST(new Date(this.state.enddate)) });  
              }                          
            }
            else if(selectedtimezone==="PST")
            {  
              if((this.state.date!==null && this.state.date!==undefined && this.state.date!=='') && (this.state.enddate!==null && this.state.enddate!==undefined && this.state.enddate!=='' ))
              {                     
                  this.setState({date:this.convertUTCDateToPST(new Date(this.state.date)) , 
                    enddate:this.convertUTCDateToPST(new Date(this.state.enddate)) });
              }
            }
       }
       else if(prevselectedtimezone==="CST")
       {
            if(selectedtimezone==="UTC")
            {   
              if((this.state.date!==null && this.state.date!==undefined && this.state.date!=='') && (this.state.enddate!==null && this.state.enddate!==undefined && this.state.enddate!=='' ))
              {                   
                  this.setState({date:Date.parse(new Date(this.convertCSTDateToUTCTimezone(new Date(this.state.date)))) , 
                    enddate:Date.parse(new Date(this.convertCSTDateToUTCTimezone(new Date(this.state.enddate)))) });
              }      
            }
            else if(selectedtimezone==="PST")
            { 
              if((this.state.date!==null && this.state.date!==undefined && this.state.date!=='') && (this.state.enddate!==null && this.state.enddate!==undefined && this.state.enddate!=='' ))
              {                     
                  this.setState({date:this.convertCSTDateToPST(new Date(this.state.date)) , 
                    enddate:this.convertCSTDateToPST(new Date(this.state.enddate)) });
              }      
            }
       }
       else if(prevselectedtimezone==="PST")
       {
            if(selectedtimezone==="UTC")
            { 
              if((this.state.date!==null && this.state.date!==undefined && this.state.date!=='') && (this.state.enddate!==null && this.state.enddate!==undefined && this.state.enddate!=='' ))
              {                      
                  this.setState({date:Date.parse(new Date(this.convertPSTDateToUTCTimezone(new Date(this.state.date)))) , 
                    enddate:Date.parse(new Date(this.convertPSTDateToUTCTimezone(new Date(this.state.enddate)))) });
              }      
            }
            else if(selectedtimezone==="CST")
            {         
              if((this.state.date!==null && this.state.date!==undefined && this.state.date!=='') && (this.state.enddate!==null && this.state.enddate!==undefined && this.state.enddate!=='' ))
              {
                  this.setState({date:this.convertPSTDateToCST(new Date(this.state.date)) , 
                    enddate:this.convertPSTDateToCST(new Date(this.state.enddate)) }); 
              }            
                            
            }
       }            

    }
  
    checkErrors = () => {
    
        const validEmailRegex = 
        RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);  
      const {holidayName,date,enddate,holidayType,plantName,region,country} = this.state;
      var obj = {};
      const { userEmail} = this.props;
     
          
      if (holidayName.length === 0) {
        this.setState({
          holidayNameError: 'Holiday Name should not be blank.'
        })
        obj.prop = ++obj.prop || 0;
  
      }
      if (holidayType.length === 0 || holidayType===null) {
        this.setState({
          holidayTypeError: 'Holiday Type should be selected.'
        })
        obj.prop = ++obj.prop || 0;
  
      }
    
      if (region.length === 0 || region===null) {
        this.setState({
          regionError: 'Region should be selected.'
        })
        obj.prop = ++obj.prop || 0;
  
      }
      if (country.length === 0 || country===null) {
        this.setState({
          countryError: 'Country should be selected.'
        })
        obj.prop = ++obj.prop || 0;
  
      }
         
      if (date === '') {
        this.setState({
          dateError: 'Start Date field should not be blank.'
        })  
        obj.prop = ++obj.prop || 0;
      }
      else if(date)
      {
     //   var m = moment(date, 'YYYY-MM-DD');
        var m = moment(date, 'dd-mmm-yyyy');   

        if(m.isValid() === false)
        {
            this.setState({
                dateError: 'Start Date format is (mm/dd/yyyy hh(12 hour):mm am/pm)'
              })  
              obj.prop = ++obj.prop || 0; 
        } 
        // if( Date.parse(new Date(new Date().toUTCString()).toUTCString()) > Date.parse(this.convertDateToUTC(new Date(date))))
        // {
        //   this.setState({
        //     dateError: 'Start Datetime is less than current UTC Datetime'
        //   })  
        //   obj.prop = ++obj.prop || 0;
        // }
      } 
  
      if (enddate === '') {
        this.setState({
          enddateError: 'End Date field should not be blank.'
        })  
        obj.prop = ++obj.prop || 0;
      }
      else if(moment(enddate, 'YYYY-MM-DD').isValid() === false)
      {
            this.setState({
              enddateError: 'End Date format is (mm/dd/yyyy hh(12 hour):mm am/pm)'
              })  
              obj.prop = ++obj.prop || 0; 
      
      }
      else if(date && enddate)
      {
        
        if (Date.parse(new Date(this.convertDateToUTC(new Date(date)))) > Date.parse(new Date(this.convertDateToUTC(new Date(enddate))))) {
          this.setState({
            enddateError: 'End Datetime should be greater than or equal to Start Datetime.'
          })  
          obj.prop = ++obj.prop || 0;
        }
        var startdatecompare = moment(date, "YYYY-MM-DD HH:mm:ss");
        var enddatecompare = moment(enddate, "YYYY-MM-DD HH:mm:ss");
        var diff = enddatecompare.diff(startdatecompare, 'h'); // Diff in hours
        if (diff > 72) {
          this.setState({
            enddateError: 'the period should not be superior to 72 hours'
          })  
          obj.prop = ++obj.prop || 0;
        }
       
      }          
    
      if(obj.prop >=0)
      {
      return true;
      }
      else
      {
      return false;
      }
    } 
  
    renderError(name) {
      const { holidayNameError,
        dateError,enddateError,holidayTypeError,regionError,countryError,plantError} = this.state;
      switch(name) {
        case 'holidayName':
          if (holidayNameError) {
            return <div style ={{backgroundColor:'#f8d7da'}}>{holidayNameError}</div>;
          }
          break;        
        case  'holidayType' :
          if (holidayTypeError) {
            return <div style ={{backgroundColor:'#f8d7da'}}>{holidayTypeError}</div>;
          }
          break;         
        case  'date' :
           if (dateError) {
              return <div style ={{backgroundColor:'#f8d7da'}}>{dateError}</div>;
          }
          break;
  
        case  'enddate' :
            if (enddateError) {
               return <div style ={{backgroundColor:'#f8d7da'}}>{enddateError}</div>;
           }
           break; 
        case  'region' :
            if (regionError) {
              return <div style ={{backgroundColor:'#f8d7da'}}>{regionError}</div>;
            }
            break;  
        case  'country' :
                if (countryError) {
                  return <div style ={{backgroundColor:'#f8d7da'}}>{countryError}</div>;
                }
                break;  
       
        default: 
        break;       
          
        }    
      
    }
  
    
    showDialog = async () => {
      this.setState({showDialog : true});
    };

     showErrorDialog = async () => {
        this.setState({showErrorDialog : true});
      };

      showHolidayErrorDialog = async () => {
        this.setState({showHolidayErrorDialog : true});
      };

      flip = async() => 
     {
      this.setState({showNewDialog:!this.state.showNewDialog})
     }

     

      cofirmApproveReject = async() =>
      {      

        const newState = this;
        const bodyvalues = newState.state.addOutage;  
        var token = await fetchToken();  
        var correlationId = uuid.v1(); 
        var bearer = 'Bearer ' + token;   
        var apimVerifyDataUrl = window.env.APIMURL + "/api/outage/verifyholidaydata"; 
        var apimUrlAddEvent = window.env.APIMURL + "/api/outage/addoutage";  
        
        trackPromise(
        
          fetch(apimVerifyDataUrl, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'CorrelationId': correlationId,
            'authorization': bearer,
            },
           body: bodyvalues
          }).then(response => {
            return response.json()
                })              
          .then( async val => {
              this.setState({
              result:val
              }); 
              
              if(this.state.result===0)  //this means there is no such data . 
              {
                fetch(apimUrlAddEvent, {
                  method: 'POST',
                  headers: {
                  //'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'CorrelationId': correlationId,
                  'authorization': bearer,
                  },
                 body: bodyvalues
                }).then(response => {
                  return response.json()
                      })              
                .then(async returnId => {
                  if (this._isMounted) {
                          this.setState({
                    result:returnId,
                    addevent : true                   
                  });
                  var values = JSON.parse(bodyvalues);           
                  values["outageId"] = returnId;
                  values["start"] = new Date(values.StartTime);
                  values["end"] = new Date(values.EndTime);
                  values["holidayName"] = values.title;                         
                  newState.props.addEvent(values);                            
                  newState.setState({
                    date: '',
                    enddate:'',
                    holidayName:''                                                 
                   });              
                   await newState.showDialog();      
                }
               })
               .catch( async error =>
                      {
                        //alert("Error Occured");  
                        await this.showErrorDialog();
                        var err = 
                        {
                            "message": "Exception occured while submitting a CREW Holiday event.",
                            "ComponentId" : COMPONENT_ID,
                            "ComponentName" : COMPONENT_NAME,
                            "EnvironmentName" : window.env.ENVIRONMENT,
                            "ServiceLineName" : SERVICE_LINE,
                            "ServiceName" : SERVICE_NAME,
                            "ServiceOfferingName" : SERVICE_OFFERING,
                            "Component" : "Calendar Add CREW Holiday Event Page",
                            "exception": error.message,
                            "Correlation Id": correlationId
                        }               
                       appInsights.trackException({exception: error,properties : err});  
                     })
              }
              else //this means data already exists .
              {
                await this.showHolidayErrorDialog();
              }                            
          })
          .catch( async error =>
                {
                  //alert("Error Occured");  
                  await this.showErrorDialog();
                  var err = 
                  {
                      "message": "Exception occured while validating holiday event in BI ",
                      "ComponentId" : COMPONENT_ID,
                      "ComponentName" : COMPONENT_NAME,
                      "EnvironmentName" : window.env.ENVIRONMENT,
                      "ServiceLineName" : SERVICE_LINE,
                      "ServiceName" : SERVICE_NAME,
                      "ServiceOfferingName" : SERVICE_OFFERING,
                      "Component" : "Calendar Add Event Page",
                      "exception": error.message,
                      "Correlation Id": correlationId
                  }               
                 appInsights.trackException({exception: error,properties : err});  
               })            
             );           
    }
  
    submitAlert = async(event) =>{
      this.setState({showNewDialog:!this.state.showNewDialog})
     
    }

    approveDialog = async(event) => {
      this.setState( {showNewDialog: false});  
      var event = await this.cofirmApproveReject(event);    
    }

    cancelDialog = async () => {
      const { onClickCancel } = this.props;
      if (typeof onClickCancel === "function") { await onClickCancel(); }
      this.setState( {showNewDialog: false});
    }
    
    
      methodInvoke = async(event) =>
      {  
        let errors = null;
      try {   
        event.preventDefault();
        event.persist();
       
        if(this.checkErrors())
        {
          
          this.props.submitModalPopUpFunc(true);
          return;
        }
        else
        {   
           
                var title = event.target.holidayName.value;
                var businessUser = this.state.isBusinessUser;
                var startdate = moment(this.state.date, 'YYYY-MM-DDTHH:mm:ss.fff Z');
                startdate = startdate.format('YYYY-MM-DDTHH:mm:ss');       
                var EndTime= moment(this.state.enddate, 'YYYY-MM-DDTHH:mm:ss.fff Z');
                EndTime = EndTime.format('YYYY-MM-DDTHH:mm:ss');       
                var org = event.target.organization === undefined ? null : '';
                const { userEmail} = this.props;
                let validEmail = (userEmail !== undefined ? userEmail.toLowerCase().includes("microsoft.com") : null);
                var extcontact = event.target.externalcontact === undefined ? null :  event.target.externalcontact.value; 
                var selectedtimezone=this.state.timezone;                
       
                var bodyvalues = JSON.stringify({
                     title: title,
                     Description: '',
                     StartTime: startdate,
                     EndTime: EndTime,
                     organization: org,
                     ExternalContact: extcontact,
                     outageType: businessUser === true ? 'Informational ' + this.state.role : 'Planned',      
                     Impact : '',
                     engContact: null,
                     pmContact: "samp_bi_pm@microsoft.com",
                     teamgroup:null,
                     service:null,
                     component:null,
                     servicegroup:null,
                     division:"",
                     orgName:"",
                     eventstatus:"",
                     NextSteps:"",
                     NACategory: "CREW Holiday Calendar",
                     category:this.state.holidayType!==''|null?this.state.holidayType.value:'',
                     Location:'',
                     Recurrence:false,
                     factory:this.state.plantName === undefined ? null : this.state.plantName.value,
                     region:this.state.region!==''|null?this.state.region.value:'',
                     country: this.state.country!==''|null?this.state.country.value:'',
                     Timezone:selectedtimezone
                 }) 
                 this.setState({addOutage:bodyvalues});
                 var event = await this.submitAlert(event); 
                 
                }
      
      
    }
    catch (e) {
      errors = e;
  }
     //Catch Block
  }
       
     
      handleChange(e){
        // if(e.target.value.match("^[a-zA-Z ]*$")!=null) {
         this.setState({[e.target.name]: e.target.value, [`${e.target.name}Error`]:  '' })
        //}
      }
     
  
    
    onChange(date)
    {     
      this.setState({ date:date, dateError :''})
    }
  
    onEndChange(date)
    {
      this.setState({enddate:date,enddateError:''})
    }
  
  
    closeDialog= async () => {
      this.setState( {showDialog: false,showErrorDialog: false,showHolidayErrorDialog:false} );
      this.props.submitModalPopUpFunc(false); 
    }
  
     
    componentDidCatch(error, info) {
      
      // Display fallback UI
      this.setState({ hasError: true });
      // You can also log the error to an error reporting service
      //logErrorToMyService(error, info);
    } 


    async updatePlant(value){
      
      this.setState({plantName: value });
      
    }

    async setPlantErrorValue(value){
      
      this.setState({
          plantError: value
         });
    }

    async updateRegion(value){
      
      this.setState({region: value });
      
    }

    async setRegionErrorValue(value){
      
      this.setState({
          regionError: value
         });
    }

    async updateCountry(value){
      
      this.setState({country: value });
      
    }

    async setCountryErrorValue(value){
      
      this.setState({
          countryError: value
         });
    }

        
    handleHolidayTypeChange = async(holidayType) => {
      await this.setState(
        { holidayType,holidayTypeError:''},
        () => console.log(`Option selected:`, this.state.holidayType)
      );
     // await this.props.holidayType(holidayType);
     // await this.props.setHolidayTypeError(this.state.holidayTypeError);
      
    };

    async updateHolidayType(value){
      
      this.setState({holidayType: value });
      
    }

    async setHolidayTypeError(value){
      
      this.setState({
        holidayTypeError: value
         });
    }
    
    
    
   render(){
    var valid = function(currentDate, selectedDate){
       
    };   
    const { userEmail} = this.props;
  //  let validEmail = (userEmail != undefined ? userEmail.toLowerCase().includes("@microsoft.com") : null); 
    const holidayTypeArray=[
      { value: 'Public Holiday', label: 'Public Holiday' },
      { value: 'Plant Holiday', label: 'Plant Holiday' }
    ];

    let inputProps = {
      placeholder: "Select a Date"            
    };
    
     
      return (      
        <div> 
          <AlertDialog title= {'Confirmation Dialog'} message= {'Are you sure you want to submit?'} open={this.state.showNewDialog}
           okButtonLabel = {'Yes'} okButtonType = {ButtonType.Danger} cancelButtonLabel={'No'}
          cancelButtonType={ButtonType.Danger} onClickCancel={this.cancelDialog} onClickOk={this.approveDialog}/>
          <AlertDialog  title= {'Success!'} cancelButtonLabel={'Ok'}
                          message= {'Holiday Event added successfully'} open={this.state.showDialog} onClickCancel={this.closeDialog}/>  
          <AlertDialog  title= {'Error'} cancelButtonLabel={'Ok'}
                          message= {'Insufficient Access'} open={this.state.showErrorDialog} onClickCancel={this.closeDialog}/>    
          <AlertDialog  title= {'Error'} cancelButtonLabel={'Ok'}
                          message= {'Holiday event already exists in MS record.'} open={this.state.showHolidayErrorDialog} onClickCancel={this.closeDialog}/>    
          
          <Modal style = {{'overflow': 'visible'}} role="dialog" aria-label="createnewholidayevent" data-keyboard="false" show={this.props.show} size="lg" onHide ={()=> {this.props.submitModalPopUpFunc(false)}}  backdrop="static" centered autoFocus="true">       
            <Modal.Header closeButton>
              <Modal.Title>Create New Holiday Event</Modal.Title>
            </Modal.Header>
            <LoadingIndicator></LoadingIndicator>
            <form id = "addevent" onSubmit={this.methodInvoke.bind(this)}>
              <Modal.Body style={{'maxHeight': 'calc(100vh - 210px)',  'overflowY': 'auto'}}>                        
              <div className="form-group required">
                <label htmlFor="HolidayName" class="control-label">Holiday Name</label>
                <input required aria-label="HolidayName" type="text" placeholder="HolidayName" className="form-control" name="holidayName" value={this.state.holidayName ||""} onChange={(e) => this.handleChange(e)}/>
                <span style={{color: '#E22335'}}>{this.renderError('holidayName')}</span>
              </div>
              
              <div className="form-group required">
                <label htmlFor="holidayType" class="control-label">Holiday Type</label>
                <Select  required aria-label="HolidayType" name="holidaytype" title="Select Holiday Type" 
                    isMulti = {false}
                    value={this.state.holidayType}
                    onChange={this.handleHolidayTypeChange} 
                    options={holidayTypeArray}  >              
                                 
                </Select>  
                <span style={{color: '#E22335'}}>{this.renderError('holidayType')}</span>            
              </div>  

              <div  className="form-group required">
               
               <label> Timezone : </label>
               &nbsp;
               <input type="radio" value="UTC" name="radio-group"  placeholder="UTC"  defaultChecked onChange={this.onTimezoneChange}/> UTC
               &nbsp;
               <input type="radio" value="PST" name="radio-group" placeholder="PST"  checked={this.state.timezone === "PST"} onChange={this.onTimezoneChange}/> PST 
               &nbsp;
               <input type="radio" value="CST" name="radio-group" placeholder="CST"  checked={this.state.timezone === "CST"} onChange={this.onTimezoneChange}/> CST
               &nbsp;           
             </div> 

              <div className="form-group required">              
                <label htmlFor="date" class="control-label">StartDate</label>      
                  <Datetime required inputProps={inputProps}  calendarAriaLabel="StartDateCalender" yearAriaLabel="Year" monthAriaLabel="Month" dayAriaLabel="Day" 
                 name="date" dateFormat="DD-MMM-YYYY" timeFormat={false}  onChange={this.onChange} value={this.state.date || "" }></Datetime>  
                {/* <DateTimePicker style = {{'z-index': '1600 !important;'}} className="form-control" name="date" onChange={this.onChange} value={this.state.date ||""}/>  */}
                <span style={{color: '#E22335'}}>{this.renderError('date')}</span>
                           
              </div>
              <div className="form-group required">
                <label htmlFor="enddate" class="control-label">EndDate</label>
                <Datetime required inputProps={inputProps}  calendarAriaLabel="EndDateCalender" yearAriaLabel="Year" monthAriaLabel="Month" dayAriaLabel="Day" 
                 name="enddate" dateFormat="DD-MMM-YYYY" timeFormat={false}  onChange={this.onEndChange} value={this.state.enddate || "" }></Datetime>
                {/* <DateTimePicker clearAriaLabel="EndTime" calendarAriaLabel="EndTimeCalender" yearAriaLabel="Year" monthAriaLabel="Month" dayAriaLabel="Day" hourAriaLabel="Hour" minuteAriaLabel="Minute" amPmAriaLabel="ampm"
                className="form-control" name="endtime" onChange={this.onEndChange} value={this.state.enddate ||""}/>  */}
                <span style={{color: '#E22335'}}>{this.renderError('enddate')}</span>
              </div>

                    <div className="form-group">
                      <PlantRegionDropDown
                      countryData={this.updateCountry.bind(this)}                      
                      email = {userEmail}
                      setCountryErrorValue = {this.setCountryErrorValue.bind(this)}                    
                      countryError = {this.renderError('country')}
                      plantData={this.updatePlant.bind(this)} 
                      regionError={this.renderError('region')}                      
                      setRegionErrorValue = {this.setRegionErrorValue.bind(this)}                      
                      regionData={this.updateRegion.bind(this)} 
                      
                    />
                      
                    </div>
                    
              </Modal.Body>
              
            <Modal.Footer>
              <Button aria-label="Close" variant="secondary" onClick={()=> {this.props.submitModalPopUpFunc(false)}}>
                Close
              </Button>
              <Button aria-label="Submit Outage" form = "addevent" type="submit" variant="primary" onClick = {()=> {this.props.submitModalPopUpFunc(true)}}>
                Submit
              </Button>
              
            </Modal.Footer> 
            </form>       
          </Modal>
          
        </div>
    );
    }
  }

export default AddCREWHolidayEvent;