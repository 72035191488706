import moment from 'moment';
import React, {ReactDOM} from 'react';
import { reactAI } from "react-appinsights";
import uuid from 'react-native-uuid';
import {fetchToken} from '../../Util.js';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID,SHIPROOM,HighTide} from "../../common/Constants/Constants";
import ReactRegionDropDown from '../RegionDropdown/Region';
import {appInsights} from '../../common/AppInsights/AppInsights';
import AlertDialog from "../../common/AlertDialog/AlertDialog";
import { Radio, RadioGroup} from 'react-radio-group';
import ToggleButton from 'react-toggle-button'
import ReactDropDown from '../ServiceDropdown/DropDown';
import * as Datetime from 'react-datetime';
import { Modal } from 'react-bootstrap';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactFactoryDropDown from '../FactoryDropDown/Factory';
import {ButtonType} from 'office-ui-fabric-react/lib/Button'; 
import CKEditor from 'ckeditor4-react';
import { trackPromise } from 'react-promise-tracker'
import LoadingIndicator from '../../common/PromiseTracker/PromiseTracker';
import Switch from "react-switch";
import ReactEventStatusDropDown from '../EventStatusDropDown/EventStatus';
import ApproverDropDown from '../ApproverDropdown/ApproverDropdown';
import { now } from 'lodash';
import { getTestparvalue } from '../Calendar/BigCalendar.js';
import { useState } from 'react';
import {createScope, partnerCenterTheme, button, flyInPanel, icon, textField, textArea, select, option  } from '@harmony/enablers/react';
// Create a Harmony Enabler scope
const scope = createScope({
  theme: partnerCenterTheme,
  reactInstance: React,
});

const Button = scope.forReact(button);
const FlyInPanel = scope.forReact(flyInPanel);
const Icon = scope.forReact(icon);
const TextField = scope.forReact(textField);
const TextArea = scope.forReact(textArea);
const Select = scope.forReact(select);
const Option = scope.forReact(option);

// //const flyInButton = document.getElementById('fly-in-button');

// const FlyInPanelBtn = document.querySelector('#fly-in-button');

// FlyInPanelBtn.addEventListener('click', () => {
//   FlyInPanel.show()
// })

 



class AddEventModalPopUp extends React.Component{
    _isMounted = false;
    constructor(props) {
    
      super(props);

      const date = Date.now();
      this.state = {date: '',enddate:'',clicked:false,result:'',userEmail : '',addevent : false,showModal: '',
        outageTitle: '',outageTitleError: '',description: '',descriptionError: '',impact: '',impactError: '',externalcontact: '',
        externalcontactError:'',engcontact :'',engcontactError :'',pmcontact:'',pmcontactError:'',dateError:'',
        enddateError : '',organization : '',organizationError :'',alldayevent :'',alldayeventError :'',hasError:false,
        showDialog: '',region : '',country :'', teamGroup :[],teamGroupError :'', teamComponent:[],teamComponentError :'',teamService:[],teamServiceError :'',
        showErrorDialog : '',serviceGroup:[],serviceGroupError:'',factory:[],servicegroupData:'',showNewDialog:false,newblocking:false, addOutage:[]
        ,factoryError:'',factoryData:'', isBusinessUser : false,division:[],divisionError:'',orgName:[],orgNameError:'',orgData:'', role : this.props.role
        ,nextsteps:'',nextstepsError:'',status:'',outagetypetoggle:'',outagetypetoggleError:'',outagetypeclicked:true,incidentnumber:'',incidentnumberError:'',
        timezone:"UTC",severity:'',severityError:'',bridgeurls:'',bridgeurlsError:'',updatedata:'',updatedataError:'',resolutiondata:'',resolutiondataError:'',
        timelinedata:'',timelinedataError:''
      };
      
      this.handleChange = this.handleChange.bind(this);
      this.onChange = this.onChange.bind(this);
      this.onEndChange = this.onEndChange.bind(this);
      this.methodInvoke = this.methodInvoke.bind(this);
      //this.handleRadioChange = this.handleRadioChange.bind(this);
      this.handleOnChange = this.handleOnChange.bind(this);
      this.close = this.close.bind(this);
      this.cofirmApproveReject = this.cofirmApproveReject.bind(this);
      this.flip = this.flip.bind(this);
      //this.onDataSelect = this.onDataSelect.bind(this);
    }
  
    appInsight = reactAI.appInsights;
    close = () => this.setState({isOpen: false}) 
  
    componentDidMount() {
      this._isMounted = true;
    }
  
    componentWillUnmount() {
      this._isMounted = false;
    }
  
    close(){
      this.setState({ showModal: false });
    }

    convertDateToUTC(date)
    {
      var now_utc =  date;
      now_utc.setHours(now_utc.getHours()+5);
      now_utc.setMinutes(now_utc.getMinutes()+30);
      return now_utc.toUTCString();
    }
    
    // convert CST date to UTC date .
    convertCSTDateToUTC(date)
    {
      var now_cst =  date;
      now_cst.setHours(now_cst.getHours()-8);
      return now_cst.toUTCString();
    }
    convertCSTDateToUTCTimezone(date)
    {
      var now_cst =  date;
      now_cst.setHours(now_cst.getHours()-8);
      return now_cst;
    }
    // convert PST date to UTC date .
    convertPSTDateToUTC(date)
    {
      var isDST=this.checkDateForDaylightSaving(date);
      var now_pst =  date;
      if (isDST===true)
      {
        now_pst.setHours(now_pst.getHours()+7); //during daylight saving months .
      }
      else
      {
        now_pst.setHours(now_pst.getHours()+8);
      }     
      return now_pst.toUTCString();
    }
    convertPSTDateToUTCTimezone(date)
    {
      var isDST=this.checkDateForDaylightSaving(date);
     var now_pst =  date;
     if (isDST===true)
      {
      now_pst.setHours(now_pst.getHours()+7); //during daylight saving months .
      }
      else
      {
        now_pst.setHours(now_pst.getHours()+8);
      }
      return now_pst;
    } 
    // convert UTC date to CST date .
    convertUTCDateToCST(date)
    {
      var now_utc =  date;
      now_utc.setHours(now_utc.getHours()+8);
      return now_utc;
    }
    // convert UTC date to PST date . 
    convertUTCDateToPST(date)
    {
      var isDST=this.checkDateForDaylightSaving(date);
      var now_utc =  date;
      if (isDST===true)
      {
        now_utc.setHours(now_utc.getHours()-7); //during daylight saving months .
      }
      else
      {
        now_utc.setHours(now_utc.getHours()-8);
      }    
      return now_utc;
    }

    convertPSTDateToCST(date)
    {
      var isDST=this.checkDateForDaylightSaving(date);
      var now_pst =  date;
      if (isDST===true)
      {
      now_pst.setHours(now_pst.getHours()+7);
      now_pst.setHours(now_pst.getHours()+8);
      }
      else
      {
        now_pst.setHours(now_pst.getHours()+8);
        now_pst.setHours(now_pst.getHours()+8);
      }
      //return now_cst.toUTCString();
      return now_pst;
    }

    convertCSTDateToPST(date)
    {
      var now_cst =  date;
      var isDST=this.checkDateForDaylightSaving(date);
      if (isDST===true)
      {
      now_cst.setHours(now_cst.getHours()-8);
      now_cst.setHours(now_cst.getHours()-7);
      }
      else
      {
        now_cst.setHours(now_cst.getHours()-8);
        now_cst.setHours(now_cst.getHours()-8);
      }
      //return now_cst.toUTCString();
      return now_cst;
    }

    //calculate daylight saving start date , end date  in a year . 
  
    calculateDSTStartDate()
    {
      const days = {
        Mon: 1,
        Tue: 2,
        Wed: 3,
        Thu: 4,
        Fri: 5,
        Sat: 6,
        Sun: 0
      }
     
      var weekNumber,day,monthMoment;
      var year=new Date().getFullYear();
      var marchdate=year+'-03-01';
      var dststartdate;

      // 2nd Sunday of March . 
      weekNumber=2;
      day=days.Sun;
      monthMoment=moment(marchdate);
     
      let m = monthMoment.clone()
         .startOf('month')                     
         .day(day)
        if (m.month() !== monthMoment.month()) 
        {
          m.add(7, 'd');
        }
        //dststartdate= m.add(7 * (weekNumber - 1), 'd').format('YYYY-MM-DDTHH:mm:ss.fff Z');
        dststartdate= m.add(7 * (weekNumber - 1), 'd');
        dststartdate=moment(dststartdate).add(2, 'hours');
        return dststartdate;            
       
    }

    calculateDSTEndDate()
    {
      const days = {
        Mon: 1,
        Tue: 2,
        Wed: 3,
        Thu: 4,
        Fri: 5,
        Sat: 6,
        Sun: 0
      }
     
      var weekNumber,day,monthMoment;
      var year=new Date().getFullYear();
      var novdate=year+'-11-01';
      var dstenddate;

      // 1st Sunday of November .  
      weekNumber=1;
      day=days.Sun;
      monthMoment=moment(novdate);

      let m = monthMoment.clone()
      .startOf('month')                     
      .day(day)
       if (m.month() !== monthMoment.month()) 
      {
         m.add(7, 'd');
      }
      //dstenddate= m.add(7 * (weekNumber - 1), 'd').format('YYYY-MM-DDTHH:mm:ss.fff Z');
      dstenddate= m.add(7 * (weekNumber - 1), 'd');
      dstenddate=moment(dstenddate).add(2, 'hours');
      return dstenddate;        
       
    }
    //check daylight saving is active or not . 
    checkDateForDaylightSaving(date)
    {
       var DaylightStartDate= this.calculateDSTStartDate();
       var DaylightEndDate=this.calculateDSTEndDate();
       if(date >= DaylightStartDate && date <=DaylightEndDate)
       {
         return true;
       }
       else
       {
         return false;
       }
    }

   

  /*  onTimezoneChange=async(event) =>{
      this.setState({ timezone:event.target.value });
      console.log(this.state.timezone );
    } */

    onTimezoneChange=async(e) =>{
      var prevselectedtimezone=this.state.timezone;
      this.selectedtimezoneEventData(e.target.value,prevselectedtimezone);
      this.setState({ timezone:e.target.value });

      console.log(this.state.timezone );
    }

    selectedtimezoneEventData(selectedtimezone,prevselectedtimezone)
    {
        if(prevselectedtimezone==="UTC" || prevselectedtimezone===null || prevselectedtimezone==='' || prevselectedtimezone===undefined)
        {
            if(selectedtimezone==="CST")
            {                         
            /*  this.setState({date:Date.parse(new Date(this.convertUTCDateToCST(new Date(this.state.date)))) , 
                            enddate:Date.parse(new Date(this.convertUTCDateToCST(new Date(this.state.enddate)))) }); */
                if((this.state.date!==null && this.state.date!==undefined && this.state.date!=='') && (this.state.enddate!==null && this.state.enddate!==undefined && this.state.enddate!=='' ))
                {
                this.setState({date:this.convertUTCDateToCST(new Date(this.state.date)) , 
                            enddate:this.convertUTCDateToCST(new Date(this.state.enddate)) });   
                }         
            }
            else if(selectedtimezone==="PST")
            {   
              if((this.state.date!==null && this.state.date!==undefined && this.state.date!=='') && (this.state.enddate!==null && this.state.enddate!==undefined && this.state.enddate!=='' ))
              {                   
              this.setState({date:this.convertUTCDateToPST(new Date(this.state.date)) , 
                enddate:this.convertUTCDateToPST(new Date(this.state.enddate)) });
              }
            }
       }
       else if(prevselectedtimezone==="CST")
       {
            if(selectedtimezone==="UTC")
            {                      
              if((this.state.date!==null && this.state.date!==undefined && this.state.date!=='') && (this.state.enddate!==null && this.state.enddate!==undefined && this.state.enddate!=='' ))
              {
                this.setState({date:Date.parse(new Date(this.convertCSTDateToUTCTimezone(new Date(this.state.date)))) , 
                enddate:Date.parse(new Date(this.convertCSTDateToUTCTimezone(new Date(this.state.enddate)))) });
              }
            }
            else if(selectedtimezone==="PST")
            {     
              if((this.state.date!==null && this.state.date!==undefined && this.state.date!=='') && (this.state.enddate!==null && this.state.enddate!==undefined && this.state.enddate!=='' ))
              {                 
                this.setState({date:this.convertCSTDateToPST(new Date(this.state.date)) , 
                enddate:this.convertCSTDateToPST(new Date(this.state.enddate)) });
              }
            }
       }
       else if(prevselectedtimezone==="PST")
       {
            if(selectedtimezone==="UTC")
            { 
              if((this.state.date!==null && this.state.date!==undefined && this.state.date!=='') && (this.state.enddate!==null && this.state.enddate!==undefined && this.state.enddate!=='' ))
              {                     
                this.setState({date:Date.parse(new Date(this.convertPSTDateToUTCTimezone(new Date(this.state.date)))) , 
                enddate:Date.parse(new Date(this.convertPSTDateToUTCTimezone(new Date(this.state.enddate)))) });
              }
            }
            else if(selectedtimezone==="CST")
            {         
              if((this.state.date!==null && this.state.date!==undefined && this.state.date!=='') && (this.state.enddate!==null && this.state.enddate!==undefined && this.state.enddate!=='' ))
              {
              this.setState({date:this.convertPSTDateToCST(new Date(this.state.date)) , 
                enddate:this.convertPSTDateToCST(new Date(this.state.enddate)) });  
              }            
                            
            }
       }            

    }

  
    checkErrors = () => {
      const validEmailRegex = 
      RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
      const {outageTitle,description,impact,externalcontact,engcontact,pmcontact,date,enddate,status,outagetypetoggle,incidentnumber,severity,bridgeurls} = this.state;
      var obj = {};
      const { userEmail} = this.props;
      let validEmail = (userEmail != undefined ? userEmail.toLowerCase().includes("microsoft.com") : null);
          
      if (outageTitle.length == 0) {
        this.setState({
          outageTitleError: 'Title field should not be blank.'
        })
        obj.prop = ++obj.prop || 0;
  
      }
      if (this.state.description == null || this.state.description.length == 0) {
        this.setState({
          descriptionError: 'Description field should not be blank.'
        })  
        obj.prop = ++obj.prop || 0;
      }
  
      if (impact.length == 0) {
        this.setState({
          impactError: 'Impact field should not be blank.'
        })  
        obj.prop = ++obj.prop || 0;
      }

     /* if(outagetypetoggle.length===0)
      {
        this.setState({
          outagetypetoggleError: 'Outage Type field should not be blank.'
        })  
        obj.prop = ++obj.prop || 0;
      } */
  
      // if (organization.length == 0) {
      //   this.setState({
      //     organizationError: 'Organization field should not be blank.'
      //   })  
      //   obj.prop = ++obj.prop || 0;
      // }
  
      // if (this.state.clicked.length == 0) {
      //   this.setState({
      //     alldayeventError: 'AllDayEvent field should be selected.'
      //   })  
      //   obj.prop = ++obj.prop || 0;
      // }
  
      if(validEmail) // check for internal users 
      {

        if ( this.state.division == null || this.state.division.length == 0) {
          this.setState({
            divisionError: 'Division should be selected.'
          })  
          obj.prop = ++obj.prop || 0;
        }

        if ( this.state.orgName == null || this.state.orgName.length == 0) {
          this.setState({
            orgNameError: 'Organization name  should be selected.'
          })  
          obj.prop = ++obj.prop || 0;
        }

        if ( this.state.serviceGroup == null || this.state.serviceGroup.length == 0) {
          this.setState({
            serviceGroupError: 'ServiceGroup should be selected.'
          })  
          obj.prop = ++obj.prop || 0;
        }

        if ( this.state.teamGroup == null || this.state.teamGroup.length == 0) {
          this.setState({
            teamGroupError: 'TeamGroup should be selected.'
          })  
          obj.prop = ++obj.prop || 0;
        }
        if (this.state.teamService == null || this.state.teamService.length == 0) {
          this.setState({
            teamServiceError: 'Service should be selected.'
          })  
          obj.prop = ++obj.prop || 0;
        } 
        if (this.state.teamComponent == null || this.state.teamComponent.length == 0) {
          this.setState({
            teamComponentError: 'Component should be selected.'
          })  
          obj.prop = ++obj.prop || 0;
        }      
         
        if ((this.state.factory == null || this.state.factory.length == 0)&&(this.state.serviceGroup!=null && this.state.serviceGroup.map(o=>o.value)!="Supply Chain")&&(this.state.serviceGroup!=null && this.state.serviceGroup.map(o=>o.value)!="Sales, Partner & Supply Chain")) {
          this.setState({
            factoryError: 'Factory should be selected.'
          })  
          obj.prop = ++obj.prop || 0;
        }
        
        
      }
  
      if (date == '') {
        this.setState({
          dateError: 'Start Date field should not be blank.'
        })  
        obj.prop = ++obj.prop || 0;
      }
      else if(date)
      {
        var m = moment(date, 'YYYY-MM-DD');

        if(m.isValid() === false)
        {
            this.setState({
                dateError: 'Start Date format is (mm/dd/yyyy hh(12 hour):mm am/pm)'
              })  
              obj.prop = ++obj.prop || 0; 
        } 
        // crew hightide events , unplanned events can have past date . 
      /*  if((status!== "crew hightide" && this.state.outagetypeclicked!==false)  && (Date.parse(new Date(new Date().toUTCString()).toUTCString()) > Date.parse(this.convertDateToUTC(new Date(date)))))
        {
          this.setState({
            dateError: 'Start Datetime is less than current UTC Datetime'
          })  
          obj.prop = ++obj.prop || 0;
        } */

        if(status!== "crew hightide" && this.state.outagetypeclicked!==false)  
        {
          if(this.state.timezone==='PST')
          {
            if (Date.parse(new Date(new Date().toUTCString()).toUTCString()) > Date.parse(this.convertPSTDateToUTC(new Date(date))))
            {
                this.setState({
                              dateError: 'Start Datetime is less than current UTC Datetime'
                              })  
                obj.prop = ++obj.prop || 0;
            }
          }
          else if (this.state.timezone==='CST')
          {
            if (Date.parse(new Date(new Date().toUTCString()).toUTCString()) > Date.parse(this.convertCSTDateToUTC(new Date(date))))
            {
                this.setState({
                              dateError: 'Start Datetime is less than current UTC Datetime'
                              })  
                obj.prop = ++obj.prop || 0;
            }
          }
          else 
          {
            if (Date.parse(new Date(new Date().toUTCString()).toUTCString()) > Date.parse(this.convertDateToUTC(new Date(date))))
            {
                this.setState({
                              dateError: 'Start Datetime is less than current UTC Datetime'
                              })  
                obj.prop = ++obj.prop || 0;
            }
          }
        }
      } 
  
      
      if(!validEmail)  // check for internal users 
      {
      if (externalcontact.length == 0) {
        
        this.setState({
          externalcontactError: 'External Contact field should not be blank.'
        })  
        obj.prop = ++obj.prop || 0;
      }
      else
      {
        var emailError = validEmailRegex.test(externalcontact) ? '' : 'Email is not valid!';
        this.setState({
          externalcontactError: emailError
        })  
        if(emailError!=='') 
        {
          obj.prop = ++obj.prop || 0;
        }
      }
    }
  
    if(validEmail)  // check for internal users 
    {
      if (engcontact.length == 0) {
        
        this.setState({
          engcontactError: 'Point of Contact field should not be blank.'
        })  
        obj.prop = ++obj.prop || 0;
      }
      else
      {
        var emailError = validEmailRegex.test(engcontact) ? '' : 'Email is not valid!';
        this.setState({
          engcontactError: emailError
        }) 
        if(emailError!=='') 
        {
          obj.prop = ++obj.prop || 0;
        }
        
      }
  
      if (pmcontact.length === 0 || pmcontact===null) {
        
        this.setState({
          pmcontactError: 'Approver Contact field should not be blank.'
        })  
        obj.prop = ++obj.prop || 0;
      }
      else
      {
        var emailError = validEmailRegex.test(pmcontact.value) ? '' : 'Email is not valid!';
        this.setState({
          pmcontactError: emailError
        })  
        if(emailError!=='') 
        {
          obj.prop = ++obj.prop || 0;
        }
      }
    }

    if(this.state.outagetypeclicked===false)
    {
      if(incidentnumber.length===0)
      {
        this.setState({
          incidentnumberError: 'Incident number should be mentioned for Unplanned event.'
        })  
        obj.prop = ++obj.prop || 0;
      }
      if(severity.length===0)
      {
        this.setState({
          incidentnumberError: 'Severity of the Incident number should be mentioned for Unplanned event.'
        })  
        obj.prop = ++obj.prop || 0;
      }
      if(this.state.status.toLowerCase()==="initial") //only when unplanned event initial phase is relevant then bridgeurl check is needed . 
      {
      if(bridgeurls.length===0)
      {
        this.setState({
          incidentnumberError: 'Bridgeurl of the Incident number should be mentioned for Unplanned event.'
        })  
        obj.prop = ++obj.prop || 0;
      }
      }
    }

      if(obj.prop >=0)
      {
      return true;
      }
      else
      {
      return false;
      }
    } 
  
    renderError(name) {
      const { outageTitleError,descriptionError,impactError,externalcontactError,engcontactError,pmcontactError,
        dateError,enddateError,alldayeventError,teamGroupError,teamServiceError,teamComponentError,serviceGroupError,factoryError,divisionError,orgNameError,outagetypetoggleError,incidentnumberError,severityError,bridgeurlsError} = this.state;
      switch(name) {
        case 'outageTitle':
          if (outageTitleError) {
            return <div style ={{backgroundColor:'#f8d7da'}}>{outageTitleError}</div>;
          }
          break;
        case 'teamgroup':
          if (teamGroupError) {
              return <div style ={{backgroundColor:'#f8d7da'}}>{teamGroupError}</div>;
            }
            break;
        case 'teamservice':
            if (teamServiceError) {
                  return <div style ={{backgroundColor:'#f8d7da'}}>{teamServiceError}</div>;
              }
              break;
        case 'teamcomponent':
           if (teamComponentError) {
                 return <div style ={{backgroundColor:'#f8d7da'}}>{teamComponentError}</div>;
             }
              break;
        case  'description' :
          if (descriptionError) {
            return <div style ={{backgroundColor:'#f8d7da'}}>{descriptionError}</div>;
          }
          break;
  
          case  'impact' :
            if (impactError) {
              return <div style ={{backgroundColor:'#f8d7da'}}>{impactError}</div>;
            }
            break;
        // case  'organization' :
        //     if (organizationError) {
        //       return <div style ={{backgroundColor:'#f8d7da'}}>{organizationError}</div>;
        //     }
        //   break;
  
          // case  'alldayevent' :
          //   if (alldayeventError) {
          //     return <div style ={{backgroundColor:'#f8d7da'}}>{alldayeventError}</div>;
          //   }
          // break;  
  
  
        case  'date' :
           if (dateError) {
              return <div style ={{backgroundColor:'#f8d7da'}}>{dateError}</div>;
          }
          break;
  
          case  'enddate' :
            if (enddateError) {
               return <div style ={{backgroundColor:'#f8d7da'}}>{enddateError}</div>;
           }
           break;
  
        case  'externalcontact' :
          if (externalcontactError) {
            return <div style ={{backgroundColor:'#f8d7da'}}>{externalcontactError}</div>;
          }
            break;  
        case  'engcontact' :
          if (engcontactError) {
              return <div style ={{backgroundColor:'#f8d7da'}}>{engcontactError}</div>;
            }
            break;   
        case  'pmcontact' :
            if (pmcontactError) {
                  return <div style ={{backgroundColor:'#f8d7da'}}>{pmcontactError}</div>;
              }
            break; 
        case 'servicegroup':
            if (serviceGroupError) {
                  return <div style ={{backgroundColor:'#f8d7da'}}>{serviceGroupError}</div>;
              }
            break;   
        case 'factory':
            if (factoryError) {
                  return <div style ={{backgroundColor:'#f8d7da'}}>{factoryError}</div>;
              }
            break; 
        case 'division':
            if (divisionError) {
                  return <div style ={{backgroundColor:'#f8d7da'}}>{divisionError}</div>;
              }
            break; 
        case 'orgName':
            if (orgNameError) {
                  return <div style ={{backgroundColor:'#f8d7da'}}>{orgNameError}</div>;
                }
            break; 
        case  'outagetypetoggle' :
            if (outagetypetoggleError) {
               return <div style ={{backgroundColor:'#f8d7da'}}>{alldayeventError}</div>;
             }
           break;    
        case  'incidentnumber' :
            if (incidentnumberError) {
               return <div style ={{backgroundColor:'#f8d7da'}}>{incidentnumberError}</div>;
             }
           break; 
        case  'severity' :
            if (severityError) {
               return <div style ={{backgroundColor:'#f8d7da'}}>{severityError}</div>;
             }
           break;   
        case  'bridgeurls' :
            if (bridgeurlsError) {
               return <div style ={{backgroundColor:'#f8d7da'}}>{bridgeurlsError}</div>;
             }
           break;       
        default: 
        break;
        //return null;
          
        }
      // if(name == 'outageTitle')
      // {
      //   if (outageTitleError) {
      //     return <div>{outageTitleError}</div>;
      //   }
      // }
      //else if(name == 'description')
      //{
        //if (descriptionError) {
          //return <div>{descriptionError}</div>;
        //}
      //}
      
    }
  
    
    showDialog = async () => {
      this.setState({showDialog : true});
    };

     showErrorDialog = async () => {
        this.setState({showErrorDialog : true});
      };

      flip = async() => 
     {
      this.setState({showNewDialog:!this.state.showNewDialog})
     }

      cofirmApproveReject = async() =>
      {      

        const newState = this;
        const bodyvalues = newState.state.addOutage;
        var token = await fetchToken();  
         var correlationId = uuid.v1(); 
        var bearer = 'Bearer ' + token;  
        var apimUrl = window.env.APIMURL + "/api/outage/addoutage";  
        trackPromise(
        fetch(apimUrl, {
          method: 'POST',
          headers: {
          //'Accept': 'application/json',
          'Content-Type': 'application/json',
          'CorrelationId': correlationId,
          'authorization': bearer,
          },
         body: bodyvalues
        }).then(response => {
          return response.json()
              })              
        .then(async returnId => {
          if (this._isMounted) {
                  this.setState({
            result:returnId,
            addevent : true,
           
          });
          var values = JSON.parse(bodyvalues); 
  
          values["outageId"] = returnId;
          values["start"] = new Date(values.StartTime);
          values["end"] = new Date(values.EndTime);
          values["outageTitle"] = values.outageTitle;
          //values["start"] = values.StartTime;
          //values["end"] = values.EndTime;
          values["outageDescription"] = values.Description;
          values["outageImpact"] = values.Impact;
          values["allDay"] = values.AllDayEvent;
          values["extContact"] = values.ExternalContact;
          values["nextsteps"]=values.NextSteps;
          values["outageType"] = values.outageType;
          
          newState.props.addEvent(values);
          //this.props.onClicked(true);
          //this.props.callbackFromParent(this.state.addevent);
          
          newState.setState({
            date: '',
            enddate:'',
            clicked:'',  
            outageTitle:'',
            description:'',
            engcontact:'',
            pmcontact:'',
            teamGroup:'',
            teamService:[],
            teamComponent:'',
            impact : '',
            serviceGroup:[],
            factory:[],
            division:[],
            orgName:[],
            nextsteps:'',
            outageType:''
            //showDialog : true,
            //showDialog: true
            //startDateError:'',
            //endtimeError : '',      
           });              
           await newState.showDialog();      
        }
       })
       .catch( async error =>
              {
                //alert("Error Occured");  
                await this.showErrorDialog();
                var err = 
                {
                    "message": "Exception occured while submitting an outage request",
                    "ComponentId" : COMPONENT_ID,
                    "ComponentName" : COMPONENT_NAME,
                    "EnvironmentName" : window.env.ENVIRONMENT,
                    "ServiceLineName" : SERVICE_LINE,
                    "ServiceName" : SERVICE_NAME,
                    "ServiceOfferingName" : SERVICE_OFFERING,
                    "Component" : "Calendar Add Event Page",
                    "exception": error.message,
                    "Correlation Id": correlationId
                }               
               appInsights.trackException({exception: error,properties : err});  
             }));
          
      }  
  
    submitAlert = async(event) =>{
      this.setState({showNewDialog:!this.state.showNewDialog})
      //var shouldDelete = await this.flip(); 
      //await this.setState({showNewDialog:true});
      //await this.cofirmApproveReject(event); 

      // return new Promise(function (resolve, reject) {
      //   confirmAlert({
      //     title: 'Confirm Dialog',
      //     message: 'Are you sure you want to submit?',
      //     buttons: [
      //       {
      //         label: 'Yes',
      //         onClick: () => resolve(true)
      //       },
      //       {
      //         label: 'No',
      //         onClick: () => resolve(false)
      //       }
      //     ]
      //   });
      // });
    }

    approveDialog = async(event) => {
      this.setState( {showNewDialog: false});  
      var event = await this.cofirmApproveReject(event);    
    }

    cancelDialog = async () => {
      const { onClickCancel } = this.props;
      if (typeof onClickCancel === "function") { await onClickCancel(); }
      this.setState( {showNewDialog: false});
    }
    
    
      methodInvoke = async(event) =>
      {  
        let errors = null; 
      try {   
        event.preventDefault();
        event.persist();
        this.setState({region:event.target.region.value,country:event.target.country.value});     
        if(this.checkErrors())
        {         
          this.props.submitModalPopUpFunc(true);
          return;
        }
        else
        {              
        var title = event.target.outageTitle.value;
        var desc = this.state.description;
        var impact = this.state.impact;
        var businessUser = this.state.isBusinessUser
        const { userEmail} = this.props;
        let validEmail = (userEmail != undefined ? userEmail.toLowerCase().includes("microsoft.com") : null);
        var startdate = moment(this.state.date, 'YYYY-MM-DDTHH:mm:ss.fff Z');
        startdate = startdate.format('YYYY-MM-DDTHH:mm:ss');
        var EndTime='';
        EndTime= moment(this.state.enddate, 'YYYY-MM-DDTHH:mm:ss.fff Z');
        EndTime = EndTime.format('YYYY-MM-DDTHH:mm:ss');
        if(this.state.outagetypeclicked===false && this.state.status.toLowerCase()==="initial")
        {
          EndTime=  moment().endOf('day');
          EndTime = EndTime.format('YYYY-MM-DDTHH:mm:ss');     
        }
       
        var org = event.target.organization == undefined ? null : '';
        var extcontact = event.target.externalcontact == undefined ? null :  event.target.externalcontact.value;
        var engcontact = event.target.engcontact == undefined ? null : event.target.engcontact.value;
        var pmcontact = event.target.pmcontact == undefined ? null :event.target.pmcontact.value;
        var alldayevent = this.state.clicked;
        var nxtsteps=this.state.nextsteps;  
        var outageType=this.state.outagetypeclicked;   
        var incnumber=event.target.incidentnumber===undefined?'':event.target.incidentnumber.value;   
        var selectedtimezone=this.state.timezone; 
        var sevnum=event.target.severity===undefined?"":event.target.severity.value; 
        //sevnum=parseInt(sevnum);
        var bridgeurls=event.target.bridgeurls===undefined?'':event.target.bridgeurls.value;  
        var updatedetails=this.state.updatedata;
        var resolutiondetails=this.state.resolutiondata;
        var timelinedetails=this.state.timelinedata;
        var NACAT = getTestparvalue()
        var bodyvalues = JSON.stringify({
          title: title,
          Description: desc,
          Impact : impact,
          StartTime: startdate,
          EndTime: EndTime,
          organization: org,
          ExternalContact: extcontact,
          engContact: engcontact,
          pmContact: pmcontact,
          outageType: businessUser === true ? 'Informational ' + this.state.role : (outageType===true?'Planned':'UnPlanned'),
          region: event.target.region.value==='--Select Region--'?'':event.target.region.value,
          country: event.target.country.value==='--Select Country--'?'':event.target.country.value,
          teamgroup: event.target.teamgroup === undefined ? null : this.state.teamGroup.map(x=> x.value).toString(),
          service: event.target.teamservice === undefined ? null : this.state.teamService.map(x=> x.value).toString(),
          component: event.target.teamcomponent === undefined ? null : this.state.teamComponent.map(x=> x.value).toString(),
          AllDayEvent: alldayevent,
          servicegroup: event.target.servicegroup === undefined ? null : this.state.serviceGroup.map(x=> x.value).toString(),
          factoryName: event.target.factory === undefined ? null : this.state.factory.value,
          division:event.target.division===undefined?'':event.target.division.value,
          orgName:event.target.organizationname===undefined?'':event.target.organizationname.value,
          eventstatus:event.target.status===undefined?'':event.target.status.value==='--Select Status--'?'Initial':event.target.status.value,
          NextSteps:nxtsteps,
          // NACategory: (!validEmail) ? "Partner" : (businessUser!==true ? "N.A" : this.props.role),
          NACategory: NACAT,
          category:'',
          Location:'',
          Recurrence:false,
          IncidentNumber:incnumber,
          timezone:selectedtimezone,
          severity:sevnum,
          bridgeurls:bridgeurls,
          updatedata:updatedetails,
          resolutiondata:resolutiondetails,
          timelinedata:timelinedetails
        })         
        this.setState({addOutage:bodyvalues});
        var event = await this.submitAlert(event);       
      }
    }
    catch (e) {
      errors = e;
  }
     //Catch Block
  }
       
     
      handleChange(e){
        // if(e.target.value.match("^[a-zA-Z ]*$")!=null) {
         this.setState({[e.target.name]: e.target.value, [`${e.target.name}Error`]:  '' })
        //}
      }
     
  
   handleOnChange(e)
   {     
    this.setState({ clicked:e,alldayeventError:''})
   }

   
   handleOutageTypeChange(e)
   { 

    this.setState({ outagetypeclicked:e,outagetypetoggleError:''})
   }

   handleBusinessUserChange(checked) {
    this.setState({isBusinessUser:checked });
  }
  
    onChange(date)
    { 

      let newenddate = moment(date).endOf('day'); //.format('YYYY-MM-DDTHH:mm:ss.fff Z');
      if(!this.outagetypeclicked)
      {
        this.setState({ date:date, dateError :'',enddate:newenddate})
      } 
      else{
        this.setState({  date:date, dateError :''})
      }
      
    }

   /* onDataSelect(e)
    {     
      const keyD = e.key !== undefined ? e.key : e.keyCode;
      // e.key && e.keycode have mixed support - keycode is deprecated but support is greater than e.key
      // I tested within IE11, Firefox, Chrome, Edge (latest) & all had good support for e.key
    
        if ( (keyD === 'Enter' || keyD === 13) || (['Spacebar', ' '].indexOf(keyD) >= 0 || keyD === 32)) 
        {
        // In IE11 and lower, e.key will equal "Spacebar" instead of ' '
    
        // Default behavior is prevented to prevent the page to scroll when "space" is pressed
        e.preventDefault();
        this.click();
        }
      
    } */
  
    onEndChange(date)
    {
      this.setState({enddate:date,enddateError:''})
    }
  
  
    closeDialog= async () => {
      this.setState( {showDialog: false,showErrorDialog: false} );
      this.props.submitModalPopUpFunc(false); 
    }
  
    // cancelErrorDialog() {
    //   this.setState( {showErrorDialog: false } );
    // }
  
    
  
    componentDidCatch(error, info) {
      
      // Display fallback UI
      this.setState({ hasError: true });
      // You can also log the error to an error reporting service
      //logErrorToMyService(error, info);
    }

    

    async updateServiceGroup(value){
      
      this.setState({serviceGroup: value });
      
    }

    async setServiceGroupErrorValue(value){
      
      this.setState({
          serviceGroupError: value
         });
    }
  
    async updateTeamGroup(value){
      
      this.setState({
          teamGroup: value
         });
    }
  
    async setTeamErrorValue(value){
      
      this.setState({
          teamGroupError: value
         });
    }
  
    async updateComponent(value){
      
      this.setState({
          teamComponent: value
         });
    }
  
    async setComponentErrorValue(value){
     
      this.setState({
          teamComponentError: value
         });
    }
  
    async updateService(value){
     
      this.setState({
          teamService: value
         });
    }

    async updateShiproomStatus(value){
     
      // this.setState({
      //     status: value
      //    });
     var desc = value.toLowerCase() === "shiproom" ?  SHIPROOM : (value.toLowerCase() === "crew hightide" ? HighTide : this.state.description);
     this.setState({description:desc,status:value.toLowerCase()});    
    }

  /*  async updateNextStepsTemplate(value)
    {
     
     var nxtsteps = value===false ?  UnplannedEvent : '';
     this.setState({nextsteps:nxtsteps});    
    } */
  
    async setServiceErrorValue(value){
    
      this.setState({
          teamServiceError: value
         });
    }

    async updateFactoryName(value){
      
      this.setState({
          factory: value
         });
    }

    async setfactoryError(value){
      
      this.setState({
          factoryError: value
         });
    }

    onEditorChange(event) {
      this.setState({[event.editor.config.bodyId] : event.editor.getData(),[`${event.editor.config.bodyId}Error`]:''});
    }

    async updateDivision(value){
      
      this.setState({division: value });
      
    }
    async setDivisionErrorValue(value){
      
      this.setState({
          divisionError: value
         });
    }
    async updateOrgName(value){
      
      this.setState({orgName: value });
      
    }
    async setOrgNameErrorValue(value){
      
      this.setState({
          orgNameError: value
         });
    }
    async setpmContactErrorValue(value){
      
      this.setState({
          pmcontactError: value
         });
    }
    async updateApproverContact(value){
      
      this.setState({pmcontact: value });
      
    }
    async getIncidentDetails()
    {
        var incnum=this.state.incidentnumber!==''?this.state.incidentnumber:0;
        var token = await fetchToken();
        var correlationId = uuid.v1();
        var bearer = 'Bearer ' + token;
        var apimUrl = window.env.APIMURL + "/api/outage/getincidentdetails/?incidentnumber=" + incnum ;

        fetch(apimUrl,{
            method: 'GET',
            headers: {
                'authorization': bearer,
                'Accept' : 'application/json',
                'CorrelationId': correlationId,
                'Content-Type': 'application/json'
            }})
          .then(response => {
            return response.json();
          })        
          .then(data => {   
                     
              this.setState({ severity:data[0].severity , bridgeurls:data[0].bridgeUrls
              });
            })
            .catch(error => {
                var err = 
                {
                 "message": "Exception occured while getting the incident number details - severity , bridge urls from  DB",
                 "ComponentId" : COMPONENT_ID,
                 "ComponentName" : COMPONENT_NAME,
                 "EnvironmentName" : window.env.ENVIRONMENT,
                 "ServiceLineName" : SERVICE_LINE,
                 "ServiceName" : SERVICE_NAME,
                 "ServiceOfferingName" : SERVICE_OFFERING,
                 "Component": 'Region Page',
                 "userName": this.props.email,
                        //roles: this.props.role != null || '' ? this.props.role : "",
                 "exception": error.message,
                 "Correlation Id": correlationId,
                }
                appInsights.trackException({exception: error,properties : err});
            })
    }

    
    
   render(){
    var valid = function(currentDate, selectedDate){
        // var m = moment(selectedDate, 'YYYY-MM-DD');
        // return m.isValid();
    };   
    //const {date, format, mode, inputFormat,errorMessage,servicegroupData,selectedServiceGroup,serviceGroup} = this.state;
    const { userEmail} = this.props;
    let validEmail = (userEmail !== undefined ? userEmail.toLowerCase().includes("microsoft.com") : null); 
    let factoryDrpDwnOption1= ((this.state.serviceGroup!=undefined|''|null && this.state.serviceGroup.length>0) ?(this.state.serviceGroup.map(o=>o.value)=="Sales, Partner & Supply Chain"?false:true):false) && ((this.state.serviceGroup!=undefined|''|null && this.state.serviceGroup.length>0) ?(this.state.serviceGroup.map(o=>o.value)=="Supply Chain"?false:true):false);
    let incnumbershow=(this.state.outagetypeclicked===false?true:false);
    let selectedeventstatus=this.state.status;
    const borderRadiusStyle = { borderRadius: 2 };
    
    let inputProps = {
      placeholder: "Select a Date",
      //readOnly:true        
    };
     
      return (      
        <div> 
          <AlertDialog title= {'Confirmation Dialog'} message= {'Are you sure you want to submit?'} open={this.state.showNewDialog}
           okButtonLabel = {'Yes'} okButtonType = {ButtonType.Danger} cancelButtonLabel={'No'}
          cancelButtonType={ButtonType.Danger} onClickCancel={this.cancelDialog} onClickOk={this.approveDialog}/>
          <AlertDialog  title= {'Success!'} cancelButtonLabel={'Ok'}
                          message= {'Event added successfully'} open={this.state.showDialog} onClickCancel={this.closeDialog}/>  
          <AlertDialog  title= {'Error'} cancelButtonLabel={'Ok'}
                          message= {'Insufficient Access'} open={this.state.showErrorDialog} onClickCancel={this.closeDialog}/>    
          <Modal style = {{'overflow': 'visible'}} role="dialog" aria-label="createnewevent" data-keyboard="false" show={this.props.show} size="lg" onHide ={()=> {this.props.submitModalPopUpFunc(false)}}  backdrop="static" centered autoFocus="true">       
            <Modal.Header closeButton>




              <Modal.Title>Create New Event</Modal.Title>
            </Modal.Header>
            <LoadingIndicator></LoadingIndicator>
            <form id = "addevent" onSubmit={this.methodInvoke.bind(this)}>
              <Modal.Body style={{'maxHeight': 'calc(100vh - 210px)',  'overflowY': 'auto'}}>                        
              <div className="form-group required">
                <label htmlFor="outageTitle" class="control-label">Title</label> 
               <input required aria-label="Title" type="text" placeholder="Title" className="form-control" name="outageTitle" value={this.state.outageTitle ||""} onChange={(e) => this.handleChange(e)}/>
                {/* <TextField label="Title" placeholder="Event Title" name="outageTitle" value={this.state.outageTitle ||""} onChange={(e) => this.handleChange(e)} /> */}
                <span style={{color: '#E22335'}}>{this.renderError('outageTitle')}</span>
              </div>
              
              { validEmail ?
              <div className="form-group">
                <ReactEventStatusDropDown email = {userEmail} status = {this.state.status}
                newstatus={this.updateShiproomStatus.bind(this)} />
              </div>
              :null
              }

              { validEmail ?
              <div className="form-group">
                <label htmlFor="outagetypetoggle" class="control-label">Planned Event</label> &nbsp;&nbsp;
                <Switch            
                name="outagetypetoggle" 
                onChange={(e) => this.handleOutageTypeChange(e)} 
                checked={((this.state.outagetypeclicked===false) ?false:true)}  
                title="outagetypetoggle"              
                />
              </div> 
              :null}

            { (incnumbershow  && validEmail)? 
            <div>
              <div className="form-group required">
                <label htmlFor="incidentnumber" class="control-label">Incident Number</label>
                <input required aria-label="IncidentNumber" type="text" placeholder="IncidentNumber" className="form-control" name="incidentnumber" value={this.state.incidentnumber ||""} onChange={(e) => this.handleChange(e)}/>
                <span style={{color: '#E22335'}}>{this.renderError('incidentnumber')}</span>
                &nbsp;
                <Button variant="secondary" onClick={()=> {this.getIncidentDetails()}}>
                 Get Incident Details
                </Button>
              </div>

              <div className="form-group required">
              <label htmlFor="severity" class="control-label">Severity</label>
              <input required aria-label="Severity" type="text" placeholder="Severity" className="form-control" name="severity" value={this.state.severity ||""} onChange={(e) => this.handleChange(e)}/>
              <span style={{color: '#E22335'}}>{this.renderError('severity')}</span>
              </div>
              <div className="form-group required">
              <label htmlFor="bridgeurls" class="control-label">BridgeUrls</label>
              <textarea required aria-label="BridgeUrls" placeholder="BridgeUrls" className="form-control" name="bridgeurls" value={this.state.bridgeurls || ""} onChange={(e) =>this.handleChange(e)} />
              <span style={{color: '#E22335'}}>{this.renderError('bridgeurls')}</span>
              </div>

            </div>
              : null 
            }
              
              <div className="form-group required">
                <label htmlFor="description" class="control-label">Description</label>
                <CKEditor
                required
                aria-label="Description" 
                data={this.state.description||''}    
                onChange={(e) => this.onEditorChange(e)}
                placeholder="Compose message"
                config={{bodyId:"description",removePlugins:'elementspath,scayt,wsc',removeButtons:'Image,Source,About,Maximize,Anchor' }}
                />
                <span style={{color: '#E22335'}}>{this.renderError('description')}</span>

                {/* <TextArea placeholder="compose message" label="Description" value={this.state.description||''}    
                onChange={(e) => this.onEditorChange(e)} /> */}
              </div>
             
              {this.props.role.includes('BusinessUser') && 
                 <div className="form-group required">
                 <label htmlFor="informational" class="control-label">Informational</label>&nbsp;&nbsp; 
               <Switch   required name="informational" onChange={(e) => this.handleBusinessUserChange(e)} checked={this.state.isBusinessUser|| true} />  
               </div>
              }
            
  
              <div className="form-group required">
                <label htmlFor="impact" class="control-label">Impact</label>
                <CKEditor 
                required
                aria-label="Impact" 
                data={this.state.impact||''}    
                onChange={(e) => this.onEditorChange(e)}
                placeholder="Compose message"
                config={{bodyId:"impact",height:'20vh',removePlugins:'elementspath,scayt,wsc',removeButtons:'Image,Source,About,Maximize,Anchor' }}
                />
                <span style={{color: '#E22335'}}>{this.renderError('impact')}</span>

                {/* <TextArea placeholder="compose message" label="Impact" value={this.state.impact||''}   
                onChange={(e) => this.onEditorChange(e)} /> */}
              </div>

              <div  className="form-group">
               
                <label> Timezone : </label>
                &nbsp;
                <input type="radio" value="UTC" name="radio-group"  placeholder="UTC" defaultChecked onChange={this.onTimezoneChange}/> UTC
                &nbsp;
                <input type="radio" value="PST" name="radio-group" placeholder="PST"  checked={this.state.timezone === "PST"} onChange={this.onTimezoneChange}/> PST 
                &nbsp;
                <input type="radio" value="CST" name="radio-group"  placeholder="CST" checked={this.state.timezone === "CST"} onChange={this.onTimezoneChange}/> CST
                &nbsp;           
              </div> 

              <div className="form-group required">              
                <label htmlFor="date" class="control-label">StartTime</label>              
  
                <Datetime  required inputProps={inputProps}  calendarAriaLabel="StartTimeCalender" yearAriaLabel="Year" monthAriaLabel="Month" dayAriaLabel="Day" hourAriaLabel="Hour" minuteAriaLabel="Minute" amPmAriaLabel="ampm"
                 name="date"  onChange={this.onChange} value={this.state.date ||""} ></Datetime>  
                {/* <DateTimePicker style = {{'z-index': '1600 !important;'}} className="form-control" name="date" onChange={this.onChange} value={this.state.date ||""}/>  */}
                <span style={{color: '#E22335'}}>{this.renderError('date')}</span>
                           
              </div>

              { (!incnumbershow || (incnumbershow && selectedeventstatus.toLowerCase()==="mitigation"))?
              
              <div className="form-group required">
              <label htmlFor="endtime" class="control-label">EndTime</label>
              <Datetime required aria-label="End Time" inputProps={inputProps}  calendarAriaLabel="EndTimeCalender" yearAriaLabel="Year" monthAriaLabel="Month" dayAriaLabel="Day" hourAriaLabel="Hour" minuteAriaLabel="Minute" amPmAriaLabel="ampm"
               name="endtime" onChange={this.onEndChange} value={this.state.enddate ||""} ></Datetime>
              {/* <DateTimePicker clearAriaLabel="EndTime" calendarAriaLabel="EndTimeCalender" yearAriaLabel="Year" monthAriaLabel="Month" dayAriaLabel="Day" hourAriaLabel="Hour" minuteAriaLabel="Minute" amPmAriaLabel="ampm"
              className="form-control" name="endtime" onChange={this.onEndChange} value={this.state.enddate ||""}/>  */}
              <span style={{color: '#E22335'}}>{this.renderError('enddate')}</span>
              </div>
              :null
              }

              <div className="form-group">
                <label htmlFor="alldayevent" class="control-label">AllDay Event</label> &nbsp;&nbsp;
                {/* <BootstrapSwitchButton checked={this.state.clicked} onlabel='Yes' offlabel='No' className="form-control" name="informational"
                 onstyle="outline-success" offstyle="outline-info" size="sm" onChange={(checked: boolean) => {
                  this.setState({ clicked: checked })
              }}/>    */}
                <Switch  name="alldayevent" onChange={(e) => this.handleOnChange(e)} checked={this.state.clicked} title="alldayevent"/>
              </div> 

              { validEmail ?
              <div className="form-group">
                <label htmlFor="nextsteps" class="control-label">Next Steps</label>
                <CKEditor
                data={this.state.nextsteps||''}    
                onChange={(e) => this.onEditorChange(e)}
                config={{bodyId:"nextsteps",removePlugins:'elementspath,scayt,wsc' , removeButtons:'Image,Source,About,Maximize,Anchor' }}
                />   

                {/* <TextArea placeholder="compose message" label="Next Steps" value={this.state.nextsteps||''}    
                onChange={(e) => this.onEditorChange(e)} />             */}
              </div>
              :null
              }

              { (incnumbershow  && validEmail)? 
              <div>
               <div className="form-group">
               <label htmlFor="updatedata" class="control-label">Update</label>
               <CKEditor
               data={this.state.updatedata||''}    
               onChange={(e) => this.onEditorChange(e)}
               config={{bodyId:"updatedata",removePlugins:'elementspath,scayt,wsc' , removeButtons:'Image,Source,About,Maximize,Anchor' }}
               />               
             </div>
             <div className="form-group">
             <label htmlFor="resolutiondata" class="control-label">Resolution</label>
             <CKEditor
             data={this.state.resolutiondata||''}    
             onChange={(e) => this.onEditorChange(e)}
             config={{bodyId:"resolutiondata",removePlugins:'elementspath,scayt,wsc' , removeButtons:'Image,Source,About,Maximize,Anchor' }}
             />               
           </div>
           <div className="form-group">
           <label htmlFor="timelinedata" class="control-label">Timeline</label>
           <CKEditor
           data={this.state.timelinedata||''}    
           onChange={(e) => this.onEditorChange(e)}
           config={{bodyId:"timelinedata",removePlugins:'elementspath,scayt,wsc' , removeButtons:'Image,Source,About,Maximize,Anchor' }}
           />               
         </div>
         </div>
              : null }
              
                
              { validEmail ?
              <div>  
                     
                    <div className="form-group"><ReactDropDown
                      servicegroupData={this.updateServiceGroup.bind(this)} 
                      teamData={this.updateTeamGroup.bind(this)} 
                      teamComponent={this.updateComponent.bind(this)} 
                      teamService={this.updateService.bind(this)} 
                      email = {userEmail}
                      setServiceGroupErrorValue={this.setServiceGroupErrorValue.bind(this)}
                      setTeamErrorValue = {this.setTeamErrorValue.bind(this)}
                      setComponentErrorValue = {this.setComponentErrorValue.bind(this)}
                      setServiceErrorValue = {this.setServiceErrorValue.bind(this)}
                      serviceGroupError={this.renderError('servicegroup')}
                      teamGroupError = {this.renderError('teamgroup')}
                      serviceError = {this.renderError('teamservice')}
                      componentError = {this.renderError('teamcomponent')}
                      divisionError={this.renderError('division')}
                      setDivisionErrorValue = {this.setDivisionErrorValue.bind(this)}
                      divisionData={this.updateDivision.bind(this)} 
                      orgNameError={this.renderError('orgName')}                      
                      setOrgNameErrorValue = {this.setOrgNameErrorValue.bind(this)}                      
                      orgData={this.updateOrgName.bind(this)} 
                      
                    />
                      
                    </div>
              </div> 
              
              : null } 
             
              { (factoryDrpDwnOption1  && validEmail)? 
              
                <div>
                <div className="form-group">
                <ReactFactoryDropDown
                          
                        factoryData={this.updateFactoryName.bind(this)}
                        email = {userEmail}      
                        factoryError = {this.renderError('factory')}
                        setfactoryError={this.setfactoryError.bind(this)}                                        
                />
                </div>
                </div>
              
              :null }
                
              <div className="form-group">
                <ReactRegionDropDown 
                email = {userEmail} 
                country = {this.state.country} 
                region = {this.state.region}/>
              </div>
              
              
              {/* <div className="form-group required">
                <label htmlFor="organization" class="control-label">Organization</label>
                <input type="text" placeholder = "Organization Name" className="form-control" name="organization" value={this.state.organization ||""} onChange={(e) => this.handleChange(e)}  />
                <span style={{color: '#E22335'}}>{this.renderError('organization')}</span>
              </div> */}
             
              { !validEmail ?
              <div className="form-group required">
                <label htmlFor="externalcontact" class="control-label">External Contact</label>
                <input required aria-label="External Contact" type="email" className="form-control" placeholder="Email Address" name="externalcontact" value={this.state.externalcontact ||""} onChange={(e) => this.handleChange(e)}  />
                <span style={{color: '#E22335'}}>{this.renderError('externalcontact')}</span>
              </div>
              : null}
              
              
              { validEmail ?
              <div className="form-group required">
                <label htmlFor="engcontact" class="control-label">Point of Contact</label>
                <input required aria-label="Engineering Contact" type="email" className="form-control" placeholder="Email Address" name="engcontact" value={this.state.engcontact||""} onChange={(e) => this.handleChange(e)}  />
                <span style={{color: '#E22335'}}>{this.renderError('engcontact')}</span>
              </div>
              : null }   

              { validEmail ?
              <div className="form-group required">
              <ApproverDropDown
                 email = {userEmail} 
                 pmContactError={this.renderError('pmcontact')}
                 setpmContactErrorValue = {this.setpmContactErrorValue.bind(this)}
                 pmContactData={this.updateApproverContact.bind(this)} 
              />
             
              </div>            
              : null } 
               
                       
              </Modal.Body>
              
            <Modal.Footer>
              <Button aria-label="Close" variant="secondary" onClick={()=> {this.props.submitModalPopUpFunc(false)}}>
                Close
              </Button>
              <Button aria-label="Submit Outage" form = "addevent" type="submit" variant="primary" onClick = {()=> {this.props.submitModalPopUpFunc(true)}}>
                Submit
              </Button>
              
            </Modal.Footer> 
            </form>       
          </Modal>
          
        </div>
    );
    }
  }

export default AddEventModalPopUp;