import React, { Component } from "react";
import {fetchToken,fetchRoles} from '../../Util.js';
import uuid from 'react-native-uuid';
import {appInsights} from '../../common/AppInsights/AppInsights';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";
import Select from 'react-select';


export class ReactEditDropDown extends Component{

    constructor(props){
        
        super(props)       
        this.state={teams:[],selectedGroup:null,selectedComponent:null,selectedService:null,readOnly:true,selectedServiceGroup:null,servicegroup:[],
        selectedDivision:null , selectedOrgName:null,division:[],orgName:[]
        }
        //this.setState({readOnly:this.props.IsDisabled})
        //this.onChange = this.onChange.bind(this);
    }

    handleDivisionChange = async(selectedDivision) => {
      await this.setState(
        { 
          selectedDivision:(selectedDivision  == null ? [] : selectedDivision),
          selectedOrgName:null,
          selectedServiceGroup:null,
          selectedGroup:null,
          selectedService:null,
          selectedComponent:null},
        () => console.log(`Option selected:`, this.state.selectedDivision)
      );
      await this.props.divisionData(selectedDivision);
    };

    handleOrgNameChange = async(selectedOrgName) => {
      await this.setState(
        {  
          selectedOrgName:(selectedOrgName  == null ? [] : selectedOrgName),
          selectedServiceGroup:null,
          selectedGroup:null,
          selectedService:null,
          selectedComponent:null},
        () => console.log(`Option selected:`, this.state.selectedOrgName)
      );
      await this.props.orgNameData(selectedOrgName);
    };


    handleServiceGroupChange = async(selectedServiceGroup) => {
      await this.setState(
        { selectedServiceGroup:(selectedServiceGroup  == null ? [] : selectedServiceGroup),selectedGroup:null,selectedService:null,selectedComponent:null},
        () => console.log(`Option selected:`, this.state.selectedServiceGroup)
      );
      await this.props.servicegroupData(selectedServiceGroup);
    };

    handleTeamChange = async(selectedGroup) => {
        await this.setState(
          { selectedGroup:(selectedGroup  == null ? [] : selectedGroup),selectedService:null,selectedComponent:null},
          () => console.log(`Option selected:`, this.state.selectedGroup)
        );
        await this.props.teamData(selectedGroup);
      };
    
      handleServiceChange = async(selectedService) => {
       await this.setState(
          { selectedService,selectedComponent:null},
          () => console.log(`Option selected:`, this.state.selectedService)
        );
        await this.props.teamService(selectedService);
      };
    
      handleComponentChange = async(selectedComponent) => {
        await this.setState(
          { selectedComponent },
          () => console.log(`Option selected:`, this.state.selectedComponent)
        );
        await this.props.teamComponent(selectedComponent);
      };


    async componentDidMount() {

        appInsights.trackTrace({ message: 'EditDropDown Component Loaded.' },
                     {
                         userName: this.props.email,
                         "ComponentId" : COMPONENT_ID,
                         "ComponentName" : COMPONENT_NAME,
                         "EnvironmentName" : window.env.ENVIRONMENT,
                         "ServiceLineName" : SERVICE_LINE,
                         "ServiceName" : SERVICE_NAME,
                         "ServiceOfferingName" : SERVICE_OFFERING,
                         "Correlation Id": uuid.v1(),
                         "Component": 'EditDropdown Page',
                     });
        var token = await fetchToken();
        var bearer = 'Bearer ' + token;
        var correlationId = uuid.v1();
        var apimUrl = window.env.APIMURL + "/api/outage/getservicemappings";
        
        fetch(apimUrl,{
            method: 'GET',
            headers: {
                'authorization': bearer,
                'Accept' : 'application/json',
                'CorrelationId': correlationId,
                'Content-Type': 'application/json'
            }})
          .then(response => {
            return response.json();
          })        
          .then(data => {   
                   
          /*  this.setState({ division:data.filter(x=>{if((x.serviceGroupName=="SCE Factory Systems") ||(x.serviceGroupName=="Supply Chain") ||(x.serviceGroupName=="COSINE Devices and Gaming Security")){ return x}})
          }); */
            this.setState({division:data});
            })
            .catch(error => {
              var err =
              {
                "message": "Exception occured in EditDropdown page while getting the Division,Organization,Service Group ,Team Group, Service and Component Mappings from the DB",
                "ComponentId" : COMPONENT_ID,
                "ComponentName" : COMPONENT_NAME,
                "EnvironmentName" : window.env.ENVIRONMENT,
                "ServiceLineName" : SERVICE_LINE,
                "ServiceName" : SERVICE_NAME,
                "ServiceOfferingName" : SERVICE_OFFERING,
                "Component": 'Dropdown Page',
                 "userName": this.props.email,
                 //roles: this.props.role != null || '' ? this.props.role : "",
                 "exception": error.message,
                 "Correlation Id": correlationId
              }
             appInsights.trackException({exception: error,properties : err});
                
            })
        
    }

    returnValues(array)
    {
      const options = array.map(v => ({
        label: v,
        value: v
      }));
      return options;
    }
        render(){ 
            const { selectedDivision,selectedOrgName,selectedServiceGroup,selectedGroup,selectedService,selectedComponent} = this.state;
            const division = [...new Set(this.state.division.map((division)=>division.divisionName))];
            const org = [...new Set(this.state.division.filter( (div)=>{ return (( this.state.selectedDivision!=null ?((this.state.selectedDivision.value!='' && this.state.selectedDivision.value==div.divisionName) ? this.state.selectedDivision.value : ""):"") )} ).map(x=>x.organizationName))];
            const servicegrp = [...new Set(this.state.division.filter((o)=>{ return (( this.state.selectedOrgName!=null?(( this.state.selectedOrgName.value!='' && this.state.selectedOrgName.value==o.organizationName) ? this.state.selectedOrgName.value : ""):""))}).map(x=>x.serviceGroupName))];
        
            const team = [...new Set(this.state.division.filter((servicegroup)=>{ return ((this.state.selectedServiceGroup) != null ? (this.state.selectedServiceGroup.map(x=> x.label).includes(servicegroup.serviceGroupName)) : "")}).map(x=>x.teamGroupName))];
            const service = [...new Set(this.state.division.filter((team)=>{ return ((this.state.selectedGroup) != null ? (this.state.selectedGroup.map(x=> x.label).includes(team.teamGroupName)) : "")}).map(x=>x.serviceName))];
            const component = [...new 
             Set(
                  this.state.division.filter(                        
                  (team)=>{ 
                    return ((this.state.selectedGroup != null ? (this.state.selectedGroup.map(x=> x.label).includes(team.teamGroupName)) : null) && (this.state.selectedService != null ? (this.state.selectedService.map(x=> x.label).includes(team.serviceName)) : ""))
                  })
                  .map(y=>y.componentName)
                )]
          
            const divisionArray = this.returnValues(division);
            const orgNameArray = this.returnValues(org);     
            const serviceGroupArray = this.returnValues(servicegrp);
            const teamArray=this.returnValues(team);
            const serviceArray = this.returnValues(service);
            const componentArray = this.returnValues(component); 
              
            let mapDivision;
            let mapOrgName;
            let mapServiceGroup;
            let mapService; 
            let mapComponent;
            let mapTeamGroup;
                  
            if(selectedDivision!=null)
            {    
              mapDivision = selectedDivision;
              
            } else if(this.props.event.division != undefined || this.props.event.division != null)
            {
                mapDivision = this.returnValues(this.props.event.division.split(","));
            }

            if(selectedDivision!=null || selectedOrgName!=null)
            {    
              mapOrgName = selectedOrgName;
              
            } else if(this.props.event.orgName != undefined || this.props.event.orgName != null)
            {
                mapOrgName = this.returnValues(this.props.event.orgName.split(","));
            }

            if(selectedDivision!=null ||selectedOrgName!=null || selectedServiceGroup != null )
            {    
               mapServiceGroup = selectedServiceGroup;
            } else if(this.props.event.servicegroup != undefined || this.props.event.servicegroup != null)
            {
                mapServiceGroup = this.returnValues(this.props.event.servicegroup.split(","));
            }

            if(selectedDivision!=null ||selectedOrgName!=null ||selectedServiceGroup!=null || selectedGroup != null )
            {    
               mapTeamGroup = selectedGroup;
            } else if(this.props.event.teamgroup != undefined || this.props.event.teamgroup != null)
            {
                mapTeamGroup = this.returnValues(this.props.event.teamgroup.split(","));
            }
            
            if(selectedDivision!=null || selectedOrgName!=null || selectedServiceGroup!=null || selectedGroup != null || selectedService != null)
            {    
               mapService = selectedService;
            } else if(this.props.event.service != undefined || this.props.event.service != null)
            {
                mapService = this.returnValues(this.props.event.service.split(","));
            }
           
            if(selectedDivision!=null || selectedOrgName!=null || selectedServiceGroup!=null || selectedGroup != null || selectedService != null || selectedComponent!= null)
            {    
                mapComponent = selectedComponent;
            } else if(this.props.event.component != undefined || this.props.event.component != null)
            {
                mapComponent = this.returnValues(this.props.event.component.split(","));
            }
            
        
 return(          
    <div>

          <div className="form-group required">
            <label htmlFor="division" class="control-label">Division</label>
              <Select required aria-label="Division" name = "division"
              title="Select Division"
              isDisabled={this.props.readOnly}
              isMulti = {false}
              
              value={mapDivision||""}
              onChange={this.handleDivisionChange}
              options={divisionArray}
              onBlurResetsInput={false}
              isClearable={true}
              >
              </Select>
              <span style={{color: '#E22335'}}></span>
          </div>

          <div className="form-group required">
            <label htmlFor="organizationname" class="control-label">OrganizationName</label>
              <Select required aria-label="OrganizationName" name = "organizationname"
              title="Select OrganizationName"
              isDisabled={this.props.readOnly}
              isMulti = {false}
              
              value={mapOrgName||""}
              onChange={this.handleOrgNameChange}
              options={this.state.selectedDivision!= null ? orgNameArray : []}
              onBlurResetsInput={false}
              isClearable={true}
              >
              </Select>
              <span style={{color: '#E22335'}}></span>
          </div>

          <div className="form-group required">
            <label htmlFor="servicegroup" class="control-label">ServiceGroup</label>
              <Select required aria-label="ServiceGroup" name = "servicegroup"
              title="Select ServiceGroup"
              isDisabled={this.props.readOnly}
              isMulti = {true}
              value={mapServiceGroup || ""}
              onChange={this.handleServiceGroupChange}
              options={this.state.selectedOrgName!= null ? serviceGroupArray : []}
              onBlurResetsInput={false}
              isClearable={true}
              >
              </Select>
              <span style={{color: '#E22335'}}></span>
          </div>

           
           <div className="form-group required">    
            <label htmlFor="teamgroup" class="control-label">TeamGroup</label>
            <Select required aria-label="TeamGroup" name = "teamgroup"
            title="Select TeamGroup"
            isDisabled={this.props.readOnly}
            isMulti = {true}
            value={mapTeamGroup || ""}
            onChange={this.handleTeamChange}
            options={this.state.selectedServiceGroup!= null ? teamArray : []}
            onBlurResetsInput={false}
            isClearable={true}
           >
          </Select>
            <span style={{color: '#E22335'}}></span>
            </div>

            <div className="form-group required">   
            <label htmlFor="teamservice" class="control-label">Service</label>
            <Select required aria-label="Service" name="teamservice" title="Select Service"
             isMulti = {true}
             isDisabled={this.props.readOnly}
             value = {mapService || ""}
             onChange={this.handleServiceChange}
             isClearable={true}
             options={this.state.selectedGroup!= null ? serviceArray : []}
            >
             </Select>
            <span style={{color: '#E22335'}}></span>
            </div>

            <div className="form-group">   
            <label htmlFor="teamcomponent" class="control-label">Component</label>
            <Select aria-label="Component" name="teamcomponent" title="Select Component"
            isMulti = {true}
            isDisabled={this.props.readOnly}
            value = {mapComponent || ""}
            isClearable={true}
            onChange={this.handleComponentChange}
            options={componentArray}
             >
            </Select>
            <span style={{color: '#E22335'}}></span>
            </div>
            </div>
        )      
    }
}
export default ReactEditDropDown;